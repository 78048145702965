import types from "../types";

const initialState = {
  isOpen: false,
  chats: {},
  chatsMessages: {},
  currentChat: {},
  currentChatMessages: {},
  isUploadingFile: false,
  currentWriteMessage: "",
  isTypingBot: false,
};

export const chatReducer = (state = initialState, action) => {
  let chats = "";
  let chat = "";
  let chatID = "";
  let messages = "";
  let messageID = "";
  let newMessage = "";
  let chatsFiltered = {};

  switch (action.type) {
    case types.OPEN_CHAT:
      return {
        ...state,
        isOpen: true,
      };
    case types.CLOSE_CHAT:
      return {
        ...state,
        isOpen: false,
      };

    case types.SET_CURRENT_WRITE_MESSAGE:
      return {
        ...state,
        currentWriteMessage: action.payload,
      };
    case types.SET_CHATS:
      return {
        ...state,
        chats: {
          ...state.chats,
          ...(action.payload || {}),
        },
      };
    case types.SET_CHATS_MESSAGES:
      return {
        ...state,
        chatMessages: action.payload || {},
      };

    case types.SET_CURRENT_CHAT_MESSAGES:
      return {
        ...state,
        chatsMessages: {
          ...state.chatsMessages,
          [action.payload.chatID]: action.payload.messages,
        },
        currentChatMessages: action.payload.messages,
      };
    case types.SET_CURRENT_CHAT:
      return {
        ...state,
        currentChat: action.payload,
        currentChatMessages: state.chatsMessages[action?.payload?.chatID] || {},
        currentWriteMessage: "",
      };
    case types.SET_IS_TYPING_BOT:
      return {
        ...state,
        isTypingBot: !state.isTypingBot,
      };
    case types.ADD_MESSAGE_CHAT:
      newMessage = action.payload.message;
      chatID = action.payload.chatID;
      messageID = action.payload.messageID;
      chats = state.chats || {};
      return {
        ...state,
        currentChatMessages: action.payload.addToCurrentChat
          ? {
              ...state.currentChatMessages,
              [messageID]: newMessage,
            }
          : {
              ...state.currentChatMessages,
            },
        chatsMessages: {
          ...state.chatsMessages,
          [chatID]: { ...state.chatsMessages[chatID], [messageID]: newMessage },
        },
        currentChat: {
          ...state.currentChat,
          lastMessage: newMessage.message,
          lastSender: newMessage.userID,
        },
        chats: {
          ...state.chats,
          [chatID]: {
            ...chats[chatID],
            lastMessage: newMessage.message,
            lastSender: newMessage.userID,
          },
        },
      };
    case types.ADD_MESSAGES_CHAT:
      newMessage = action.payload.message;
      chatID = action.payload.chatID;
      messageID = action.payload.messageID;
      chats = state.chats || {};

      return {
        ...state,
        chatsMessages: {
          ...state.chatsMessages,
          [chatID]: { ...state.chatsMessages[chatID], [messageID]: newMessage },
        },
        chats: {
          ...state.chats,
          [chatID]: {
            ...chats[chatID],
            lastMessage: newMessage.message,
            lastSender: newMessage.userID,
          },
        },
      };
    case types.ADD_MESSAGE_CHAT_IN_LIST:
      newMessage = action.payload.message;
      chatID = action.payload.chatID;
      messages = state.chatsMessages[chatID] || {};
      messageID = action.payload.messageID;

      return {
        ...state,
        chatsMessages: {
          ...state.chatsMessages,
          [chatID]: { ...messages, [messageID]: newMessage },
        },
        chats: {
          ...state.chats,
          [chatID]: {
            ...chats[chatID],
            lastMessage: newMessage.message,
            lastSender: newMessage.userID,
          },
        },
      };

    case types.SET_NEW_CHAT:
      chatID = action.payload.chatID;
      chat = action.payload.chat;
      return {
        ...state,
        chats: { ...state.chats, [chatID]: chat },
        currentChat: chat,
      };

    case types.MARK_READ_CHAT:
      chatID = action.payload;
      return {
        ...state,
        chats: {
          ...state.chats,
          [chatID]: { ...state.chats[chatID], isRead: true },
        },
      };
    case types.MARK_DISABLED_CHAT:
      chatID = action.payload;
      return {
        ...state,
        chats: {
          ...state.chats,
          [chatID]: { ...state.chats[chatID], isDisabled: true },
        },
        currentChat: {
          ...state.currentChat,
          isDisabled: true,
        },
      };

    case types.CLEAR_TEMPORARY_CHATS:
      chats = state.chats || {};
      Object.keys(chats).forEach((chatID) => {
        if (chats[chatID].isTemporary) return false;
        return (chatsFiltered[chatID] = chats[chatID]);
      });
      return {
        ...state,
        chats: chatsFiltered,
        currentChat: {},
        currentChatMessages: {},
      };

    case types.SET_UPLOADING_FILE:
      return {
        ...state,
        isUploadingFile: !state.isUploadingFile,
      };

    case types.GLOBAL_RESET_STATE:
      return initialState;
    case types.GLOBAL_RESET_ADVERTISER_DATA:
      return initialState;

    default:
      return state;
  }
};
