import { useState, useEffect } from "react";

export function useDebounced(func, delay) {
  const [timer, setTimer] = useState(null);

  const debouncedFunc = (...args) => {
    clearTimeout(timer);
    setTimer(
      setTimeout(() => {
        func(...args);
      }, delay)
    );
  };

  useEffect(() => {
    return () => {
      clearTimeout(timer);
    };
  }, [timer]);

  return debouncedFunc;
}
