import { useTranslation } from "react-i18next";
import { useDispatchApp } from "./redux";
import Typography from "@mui/material/Typography";
import { getNameBusinessTheme } from "../actions/getters";

// eslint-disable-next-line react-refresh/only-export-components
export const useTranslationApp = () => useTranslation();

export const ComponentTranslation = ({ keyTranslation }) => {
  const { t } = useTranslationApp();

  const dispatch = useDispatchApp();
  const NAME_BUSINESS = dispatch(getNameBusinessTheme());
  return (
    <Typography
      sx={{
        color: "text.primary",
      }}
      dangerouslySetInnerHTML={{
        __html: t(keyTranslation, { NAME_BUSINESS }),
      }}
    />
  );
};
