import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TableContainer from "../../../components/Tables/TableContainer";
import {
  COLUMN_NAME_ACTIONS,
  COLUMN_NAME_EMAIL,
  COLUMN_NAME_NAME,
  COLUMN_NAME_ROLE,
  DELETED,
  EMPTY_USERS,
  USERS_TABLE_ARIA_LABEL,
} from "../../../i18n/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";
import TableCell from "../../BillingAndPayments/components/TableCell";
import ActionsUser from "./ActionsUser";

const UsersTable = ({ data = {}, loading }) => {
  const { t } = useTranslationApp();

  const keys = Object.keys(data);
  return (
    <Box sx={{ width: "100%" }}>
      <TableContainer
        keys={keys}
        loading={loading}
        emptyText={EMPTY_USERS}
        ariaLabel={t(USERS_TABLE_ARIA_LABEL)}
        hasPagination={false}
      >
        <TableHead>
          <TableRow>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_NAME)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_EMAIL)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_ROLE)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_ACTIONS)}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {keys.map((row, index) => {
            const user = data[row];
            if (user.isHidden) return null;
            return (
              <TableRow key={index}>
                <TableCell align="center" indexRow={index}>
                  <Typography variant="body1">{user.name}</Typography>
                </TableCell>
                <TableCell align="center" indexRow={index}>
                  <Typography variant="body1">{user.email}</Typography>
                </TableCell>
                <TableCell align="center" indexRow={index}>
                  {t(user.role)}
                </TableCell>
                <TableCell align="center" indexRow={index}>
                  {user.isDeleted ? (
                    <Typography variant="body1">{t(DELETED)}</Typography>
                  ) : (
                    <ActionsUser userID={row} />
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </TableContainer>
    </Box>
  );
};

export default UsersTable;
