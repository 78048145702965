import { useEffect, useState } from "react";
import {
  BUTTON_YOU_HAVE_DOUBTS,
  CANCEL_ANYTIME,
  DISCOUNT_VALUE,
  DISCOUNT_CODE_PLACEHOLDER,
  DO_YOU_HAVE_DISCOUNT_CODE,
  IN_A_FEW_MINUTES_YOUR_INVOICE_WILL_BE_AVAILABLE,
  MODAL_SELECT_PACKAGE_AND_PAYMENT_METHOD_REQUIRED,
  NORMAL_PRICE,
  PAY,
  PAYMENT_SUCCESSFUL,
  VALUE_WITH_CURRENCY,
  YOU_WILL_PAY,
} from "../../../../i18n/keysTranslations";
import { useDispatchApp, useSelectorApp } from "../../../../lib/redux";
import { useTranslationApp } from "../../../../lib/i18next";
import { useStripe } from "@stripe/react-stripe-js";
import { onInit3DSecureAuth } from "../../../../services/stripe";
import LoadingButton from "../../../Buttons/LoadingButton";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import {
  startChangeSubscriptionPlanStripe,
  startPaySubscriptionAds,
} from "../../../../actions/payments";
import StripePayForm from "../../../Billing/StripePayForm";
import RadioGroupCards from "../../../Billing/RadioGroupCards";
import Pricing from "../../../Pricing/Pricing";
import {
  GRADIENT_PURPLE_FUCHSIA,
  GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT,
} from "../../../../utils/colors";
import { SimpleAlert } from "../../../Alerts/Alerts";
import {
  ALERT_ICON_TYPE_SUCCESS,
  LINK_LANDING_CONTACT,
} from "../../../../utils/constants";
import { openLink } from "../../../../utils/string";
import DiscountCodeStripeField from "../../../Form/DiscountCodeStripeField";

const SelectSubscription = ({
  onCallbackPayPackage = () => {},
  onChangeStep = () => {},
  onCloseModal = () => {},
  onCallbackFinish = () => {},
}) => {
  const [planSelected, setPlanSelected] = useState(null);
  const [isPaid, setIsPaid] = useState(false);
  const [discountCodeData, setDiscountCodeData] = useState(null);
  const [card, setCard] = useState(0);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatchApp();
  const stripe = useStripe();
  const { t } = useTranslationApp();

  const {
    defaultPaymentMethod: { id },
  } = useSelectorApp((state) => state.payments.stripe);
  const subscriptionPlan = useSelectorApp(
    (state) => state.payments.subscriptionPlan
  );
  const plans = useSelectorApp((state) => state.payments.plans);
  const planSelectedInfo = plans?.[planSelected?.id] || {};
  const [modePayment, setModePayment] = useState(
    id.length > 0 ? "hasPaymentMethod" : "noPaymentMethod"
  );

  const onChangePlanSelected = (plan) => {
    setPlanSelected(plan);
    onChangeStep("paying");
  };
  const onChangeCard = (card) => setCard(card);

  const onPayPackage = async (paymentMethodID = card, paymentIntent) => {
    if (paymentMethodID.length === 0 || planSelected === null)
      return setError(t(MODAL_SELECT_PACKAGE_AND_PAYMENT_METHOD_REQUIRED));
    setError(null);
    setLoading(true);

    let response;

    if (subscriptionPlan) {
      response = await dispatch(
        startChangeSubscriptionPlanStripe({
          planID: planSelected?.id,
          recurrent: planSelected?.recurrent,
          paymentMethodID,
          paymentIntent,
        })
      );
    } else {
      response = await dispatch(
        startPaySubscriptionAds({
          paymentMethodID,
          planID: planSelected.id,
          recurrent: planSelected.recurrent,
          paymentIntent,
          discountID: discountCodeData?.id,
        })
      );
    }
    if (response.ok) {
      setLoading(false);
      setIsPaid(true);
      onChangeStep("paid");
      onCallbackPayPackage();
      onCallbackFinish();
      SimpleAlert({
        title: t(PAYMENT_SUCCESSFUL),
        text: t(IN_A_FEW_MINUTES_YOUR_INVOICE_WILL_BE_AVAILABLE),
        icon: ALERT_ICON_TYPE_SUCCESS,
      });
      return {
        ok: true,
      };
    }
    if (!response.ok && response.status === "requiresAction") {
      const responseVerification = await onInit3DSecureAuth({
        clientSecret: response.clientSecret,
        stripe,
        onCallbackFailed: onCloseModal,
      });
      if (responseVerification.ok) {
        const paymentIntent = responseVerification.paymentIntent;
        await onPayPackage(paymentMethodID, paymentIntent);
        return { ok: true };
      }
    }
    setLoading(false);
    return { ok: false };
  };

  useEffect(() => {
    setModePayment(id.length > 0 ? "hasPaymentMethod" : "noPaymentMethod");
  }, [id]);

  return (
    <Box>
      {!planSelected && <Pricing onChangePlanSelected={onChangePlanSelected} />}
      {planSelected && !isPaid && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            px: {
              xs: 3,
              sm: 5,
            },
            py: 3,
          }}
        >
          {!!error && (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Alert
                severity="error"
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                  width: "auto",
                  align: "center",
                }}
              >
                {error}
              </Alert>
            </Box>
          )}
          {modePayment === "noPaymentMethod" ? (
            <StripePayForm
              textButton={t(PAY)}
              onSubmit={(paymentMethod) => onPayPackage(paymentMethod.id)}
              saveCard={true}
              setDefaultCard={true}
              externalValidation={planSelected === null}
              sx={{ maxWidth: "sm" }}
              onCallbackFailed3DSecure={onCloseModal}
              CustomComponentDetailPayment={
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                  }}
                >
                  {!subscriptionPlan && (
                    <>
                      <Typography variant="body1">
                        {t(DO_YOU_HAVE_DISCOUNT_CODE)}
                      </Typography>
                      <DiscountCodeStripeField
                        productID={planSelectedInfo?.productID}
                        onChangeDiscount={(data) => setDiscountCodeData(data)}
                      />
                    </>
                  )}
                  <DetailPricePlan
                    plan={planSelected}
                    discountCodeData={discountCodeData}
                  />
                </Box>
              }
            />
          ) : (
            <RadioGroupCards
              onSelectCard={onChangeCard}
              showMakeDefaultPaymentMethod={false}
              CustomComponentDetailPayment={
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                  }}
                >
                  {!subscriptionPlan && (
                    <>
                      <Typography variant="body1">
                        {t(DO_YOU_HAVE_DISCOUNT_CODE)}
                      </Typography>
                      <DiscountCodeStripeField
                        productID={planSelectedInfo?.productID}
                        onChangeDiscount={(data) => setDiscountCodeData(data)}
                      />
                    </>
                  )}
                  <DetailPricePlan
                    plan={planSelected}
                    discountCodeData={discountCodeData}
                  />
                </Box>
              }
              sx={{ maxWidth: "sm" }}
            />
          )}

          {modePayment === "hasPaymentMethod" && (
            <LoadingButton
              loading={loading}
              color="secondary"
              onClick={() => onPayPackage()}
              disabled={!card || !planSelected}
              sx={{
                mt: 2,
                minWidth: 130,
                background: GRADIENT_PURPLE_FUCHSIA,
                color: GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT,
              }}
            >
              {t(PAY)}
            </LoadingButton>
          )}

          <Typography variant="caption" sx={{ mt: 0.5 }}>
            {t(CANCEL_ANYTIME)}
          </Typography>

          <Typography
            variant="body1"
            align="center"
            onClick={() => openLink(LINK_LANDING_CONTACT)}
            sx={{
              textDecoration: "underline",
              fontSize: "1.1rem",
              "&:hover": {
                cursor: "pointer",
                textDecoration: "underline",
              },
            }}
          >
            {t(BUTTON_YOU_HAVE_DOUBTS)}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

const DetailPricePlan = ({ plan, discountCodeData }) => {
  const subscriptionPlan = useSelectorApp(
    (state) => state.payments.subscriptionPlan
  );
  const { t } = useTranslationApp();

  const sxContainerItem = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    gap: 2,
    width: "100%",
  };

  const getValueWillPay = () => {
    if (subscriptionPlan) {
      return Number(plan?.price);
    } else {
      const price = Number(plan?.price);
      if (!discountCodeData) return price;

      const discount = (price * discountCodeData.percentageDiscount) / 100;
      return price - discount;
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        mt: 2,
        width: "100%",
      }}
    >
      {discountCodeData && (
        <>
          <Box sx={sxContainerItem}>
            <Typography variant="body1" align="center" sx={{ fontWeight: 600 }}>
              {`${t(NORMAL_PRICE)}:`}
            </Typography>
            <Typography variant="body1" align="center">
              {t(VALUE_WITH_CURRENCY, {
                value: plan.price,
                currency: "USD",
              })}
            </Typography>
          </Box>
          <Box sx={sxContainerItem}>
            <Typography variant="body1" align="center" sx={{ fontWeight: 600 }}>
              {`${t(DISCOUNT_CODE_PLACEHOLDER)}:`}
            </Typography>
            <Typography variant="body1" align="center">
              {discountCodeData.code}
            </Typography>
          </Box>
          <Box sx={sxContainerItem}>
            <Typography variant="body1" align="center" sx={{ fontWeight: 600 }}>
              {`${t(DISCOUNT_VALUE)}:`}
            </Typography>
            <Typography variant="body1" align="center">
              {`-${discountCodeData.percentageDiscount}%`}
            </Typography>
          </Box>
        </>
      )}
      <Box sx={sxContainerItem}>
        <Typography variant="body1" align="center" sx={{ fontWeight: 600 }}>
          {`${t(YOU_WILL_PAY)}:`}
        </Typography>
        <Typography variant="body1" align="center">
          {t(VALUE_WITH_CURRENCY, {
            value: getValueWillPay(),
            currency: "USD",
          })}
        </Typography>
      </Box>
    </Box>
  );
};

export default SelectSubscription;
