import { useState } from "react";
import Box from "@mui/material/Box";
import {
  ADS_CAMPAIGN_STATUS_ACTIVE,
  ADS_CAMPAIGN_STATUS_DEACTIVATED,
  ADS_CAMPAIGN_STATUS_FINISHED,
  ALERT_ICON_TYPE_INFO,
  ALERT_ICON_TYPE_SUCCESS,
  BORDER_RADIUS,
} from "../../../utils/constants";
import {} from "../../../utils/colors";
import {
  ACTIVE,
  ADS_CAMPAIGN_HAS_BEEN_DUPLICATE,
  ALERT_DUPLICATE_CAMPAIGN_DESCRIPTION,
  ALERT_DUPLICATE_CAMPAIGN_TITLE,
  BUTTON_DUPLICATE,
  DEACTIVATED,
  STATISTICS,
} from "../../../i18n/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";
import { useDispatchApp } from "../../../lib/redux";
import { useNavigate } from "react-router-dom";
import { startDuplicateAdsCampaign } from "../../../actions/adsCampaigns";
import { ConfirmAlert, SimpleAlert } from "../../../components/Alerts/Alerts";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import BarChartIcon from "@mui/icons-material/BarChart";
import IconButton from "../../../components/Buttons/IconButton";
import Switch from "../../../components/Form/Switch";
import { useTheme } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { getStatusAdsCampaign } from "../../../services/adsCampaigns";
import { openLink } from "../../../utils/string";

const AdsCampaignButtonsActions = () => {
  const {
    isAllCampaignsPlatformCreated,
    isGeneralDisabledFields,
    onChangeStatusCampaign,
    getValues,
    campaignID,
  } = useFormContext();

  const [isSaving, setIsSaving] = useState(false);
  const [isDuplicating, setIsDuplicating] = useState(false);
  const data = getValues();

  const { t } = useTranslationApp();
  const dispatch = useDispatchApp();
  const navigate = useNavigate();
  const theme = useTheme();

  const onChangeSwitch = async (value) => {
    setIsSaving(true);
    await onChangeStatusCampaign({
      newStatus: value
        ? ADS_CAMPAIGN_STATUS_ACTIVE
        : ADS_CAMPAIGN_STATUS_DEACTIVATED,
      target: "campaign",
    });
    setIsSaving(false);
  };

  const onDuplicateCampaign = async () => {
    const result = await ConfirmAlert({
      title: t(ALERT_DUPLICATE_CAMPAIGN_TITLE),
      text: t(ALERT_DUPLICATE_CAMPAIGN_DESCRIPTION),
      icon: ALERT_ICON_TYPE_INFO,
      confirmButtonText: t(BUTTON_DUPLICATE),
    });

    if (!result.isConfirmed) return;

    setIsDuplicating(true);
    const response = await dispatch(
      startDuplicateAdsCampaign({
        campaign: data,
      })
    );

    if (response.ok) {
      const campaignID = response.data.campaignID;
      SimpleAlert({
        title: t(ADS_CAMPAIGN_HAS_BEEN_DUPLICATE),
        icon: ALERT_ICON_TYPE_SUCCESS,
      });
      navigate(`/ads-campaign?campaignID=${campaignID}`);
    }

    setIsDuplicating(false);
  };

  const statusCampaign = getStatusAdsCampaign({
    states: data.states,
    target: "general",
  });
  return (
    <Box
      sx={{
        display:
          isAllCampaignsPlatformCreated && !isGeneralDisabledFields
            ? "flex"
            : "none",
        gap: 2,
        justifyContent: "center",
        alignItems: "center",
        width: {
          xs: "100%",
          sm: "auto",
        },
        border: `${theme.palette.border.grey} 1px solid`,
        padding: 1,
        borderRadius: BORDER_RADIUS,
        backgroundColor: "#86858d",
      }}
    >
      {statusCampaign !== ADS_CAMPAIGN_STATUS_FINISHED && (
        <Switch
          loading={isSaving}
          isActive={statusCampaign === ADS_CAMPAIGN_STATUS_ACTIVE}
          labelActive={ACTIVE}
          labelDeactivate={DEACTIVATED}
          colorDeactivated="#504F4F"
          sxLabel={{
            ".MuiFormControlLabel-label": {
              fontSize: "1.015rem",
            },
          }}
          onChangeIsActive={(value) => onChangeSwitch(value)}
        />
      )}
      <IconButton
        loading={isDuplicating}
        onClick={onDuplicateCampaign}
        tooltipText={BUTTON_DUPLICATE}
        sx={{
          "&:hover": {
            cursor: "pointer",
          },
        }}
      >
        <ContentCopyIcon
          sx={{
            fontSize: "1.8rem",
          }}
        />
      </IconButton>
      <IconButton
        loading={isSaving}
        onClick={() =>
          openLink(`/ads-campaign-statistics?campaignID=${campaignID}`)
        }
        tooltipText={STATISTICS}
        sx={{
          mr: 0,
          "&:hover": {
            cursor: "default",
          },
        }}
      >
        <BarChartIcon
          sx={{
            fontSize: "1.8rem",
          }}
        />
      </IconButton>
    </Box>
  );
};

export default AdsCampaignButtonsActions;
