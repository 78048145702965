import Box from "@mui/material/Box";
import { useMediaQuery } from "@mui/material";

const AnswerContainer = ({
  step,
  stepPosition,
  children,
  onNextCallback = () => {},
  sx,
}) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  if (step !== stepPosition) {
    return null;
  }
  return (
    <div
      style={{
        width: "100%",
        height: isMobile ? "100%" : "fit-content",
        maxWidth: 780,
      }}
      className={
        step === stepPosition
          ? "animate__animated animate__fadeInUp animate__faster"
          : ""
      }
      onKeyUp={(e) => {
        if (e.code === "Enter") {
          onNextCallback();
        }
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "100%",
          maxWidth: 780,
          px: { xs: 2, md: 4 },
          sx,
          flexDirection: "column",
          // alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        {children}
      </Box>
    </div>
  );
};

export default AnswerContainer;
