import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import { BORDER_RADIUS } from "../../../utils/constants";
import { getUniqueID } from "../../../utils/numbers";
import { COLORS_BY_TYPE_STATISTIC } from "../../../utils/colors";
import { useTranslationApp } from "../../../lib/i18next";
import {
  SPEND,
  VALUE_WITH_CURRENCY_MANUAL_FORMAT,
} from "../../../i18n/keysTranslations";
import { logoPlatform } from "../../../utils/string";
import { getFromStatisticsAdsCampaign } from "../../../services/adsCampaigns";

const grids = {
  xs: 6,
  sm: 4,
  md: 3,
};

const CurrentStateCampaign = ({ data }) => {
  const { from, platforms, statistics } = getFromStatisticsAdsCampaign(data);
  const { t } = useTranslationApp();

  const getSpendAndCurrencyPlatform = (platform) => {
    if (from === "current") {
      return {
        spend: statistics?.[platform]?.spend,
        currency: statistics?.[platform]?.currency,
      };
    }

    return {
      spend: statistics?.[platform]?.campaign?.spend,
      currency: statistics?.[platform]?.campaign?.currency,
    };
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <Grid
        container
        spacing={2}
        sx={{
          width: "100%",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        {platforms.map((platform) => {
          const { spend, currency } = getSpendAndCurrencyPlatform(platform);
          return (
            <Grid key={getUniqueID()} item {...grids}>
              <ContainerStatistic>
                <TypographyValueStatistic>
                  {`${t(VALUE_WITH_CURRENCY_MANUAL_FORMAT, {
                    value: spend,
                    currency,
                  })}`}
                </TypographyValueStatistic>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 1,
                  }}
                >
                  <Avatar
                    src={logoPlatform(platform)}
                    alt={`Logo ${platform}`}
                    variant="square"
                    sx={{
                      height: 25,
                      width: 25,
                      "& .MuiAvatar-img": {
                        objectFit: "contain",
                      },
                    }}
                  />
                  <TypographyLabelStatistic>
                    {t(SPEND)}
                  </TypographyLabelStatistic>
                </Box>
              </ContainerStatistic>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

const TypographyValueStatistic = ({ children, type }) => {
  const color = COLORS_BY_TYPE_STATISTIC[type];
  return (
    <Typography
      variant="h5"
      component="p"
      align="center"
      sx={{
        fontWeight: "bold",
        color,
      }}
    >
      {children}
    </Typography>
  );
};
const TypographyLabelStatistic = ({ children }) => {
  return (
    <Typography variant="body2" align="center" color="text.secondary">
      {children}
    </Typography>
  );
};

const ContainerStatistic = ({ children }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 0.5,
        borderRadius: BORDER_RADIUS,
        border: (theme) => `1px solid ${theme.palette.border.grey}`,
        py: 1,
      }}
    >
      {children}
    </Box>
  );
};

export default CurrentStateCampaign;
