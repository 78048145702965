import ModalBasicLayout from "./ModalBasicLayout";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useTranslationApp } from "../../lib/i18next";
import {
  BUTTON_RELOAD_PAGE,
  NEW_VERSION_DESCRIPTION,
  NEW_VERSION_TITLE,
} from "../../i18n/keysTranslations";
import ContainerModalActions from "../Containers/ContainerModalActions";

const ModalBlockVersionApp = ({ modalOpen }) => {
  const { t } = useTranslationApp();
  return (
    <ModalBasicLayout
      modalOpen={modalOpen}
      onCloseModal={() => {}}
      fullWidth={true}
      maxWidth="sm"
      scroll="paper"
      hasTitle={false}
      sx={{
        zIndex: 9999,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="h5" align="center" sx={{ fontWeight: 600 }}>
          {t(NEW_VERSION_TITLE)}
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          {t(NEW_VERSION_DESCRIPTION)}
        </Typography>
        <ContainerModalActions
          hasPadding={false}
          propsPrimaryButton={{
            showButton: true,
            text: BUTTON_RELOAD_PAGE,
            onClick: () => window.location.reload(),
          }}
        />
      </Box>
    </ModalBasicLayout>
  );
};

export default ModalBlockVersionApp;
