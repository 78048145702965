import { useEffect, useRef, useState } from "react";
import ModalBasicLayout from "../ModalBasicLayout";
import { useTranslationApp } from "../../../lib/i18next";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { MODAL_SELECT_PACKAGE_ARIA_LABEL } from "../../../i18n/keysTranslations";
import SelectSubscription from "./components/SelectSubscription";

const ModalPaySubscription = ({
  modalOpen,
  onCloseModal = () => {},
  onCallbackPayPackage = () => {},
}) => {
  const refContainer = useRef();
  const theme = useTheme();
  const [step, setStep] = useState("selectPlan");

  const { t } = useTranslationApp();

  useEffect(() => {
    if (!refContainer.current) return;
    refContainer.current.scrollTop = 0;
  }, [step]);

  return (
    <ModalBasicLayout
      modalOpen={modalOpen}
      onCloseModal={step !== "paid" ? onCloseModal : () => {}}
      fullWidth
      scroll="paper"
      ariaLabel={t(MODAL_SELECT_PACKAGE_ARIA_LABEL)}
      sxContent={{ p: 0, px: 0 }}
      hasTitle={false}
      refContent={refContainer}
      sxPaper={{
        maxWidth: step === "paying" ? 600 : 1280,
      }}
    >
      <Box
        sx={{
          position: "absolute",
          left: { xs: "10px", sm: "15px" },
          top: { xs: "10px", sm: "15px" },
          zIndex: 1000,
        }}
      >
        {step !== "paid" && (
          <IconButton
            aria-label="close"
            onClick={onCloseModal}
            sx={{
              p: 0.5,
              fontSize: "1.4rem",
              color: "background.paperSecondaryContrastText",
              backgroundColor: "background.paperSecondary",
              "&:hover": {
                color: "background.paperSecondaryContrastText",
                backgroundColor: "background.paperSecondary",
                filter: theme.palette.hover.main,
              },
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
      </Box>
      <SelectSubscription
        onCallbackPayPackage={onCallbackPayPackage}
        onCallbackFinish={() => {
          onCloseModal();
        }}
        onChangeStep={setStep}
        onCloseModal={onCloseModal}
        refContainer={refContainer}
      />
    </ModalBasicLayout>
  );
};

export default ModalPaySubscription;
