import { useState } from "react";
import ModalBasicLayout from "./ModalBasicLayout";
import { Controller, useForm } from "react-hook-form";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "../Form/TextField";
import UploadMediaButtons from "../Buttons/UploadMediaButtons";
import { useDispatchApp } from "../../lib/redux";
import { getPathDatabaseByRole } from "../../actions/auth";
import { IMAGE_FORMATS } from "../../utils/constants";
import {
  BUTTON_CREATE,
  BUTTON_GO_BACK,
  FIELD_MAX_LENGTH,
  PROFILE_IMAGE_PLACEHOLDER,
  PROFILE_NAME_PLACEHOLDER,
} from "../../i18n/keysTranslations";
import { useTranslationApp } from "../../lib/i18next";
import Typography from "@mui/material/Typography";
import ContainerModalActions from "../Containers/ContainerModalActions";
import useFocusErrorForm from "../../hooks/useFocusErrorForm";
import { startCreateIdentityTikTokBusiness } from "../../actions/adsCampaigns";

const ModalCreateIdentityTikTokBusiness = ({
  modalOpen,
  integrationID,
  adAccountID,
  onCloseModal = () => {},
  onCallbackCreate = () => {},
}) => {
  const dispatch = useDispatchApp();
  const [isFetching, setIsFetching] = useState(false);
  const { userID } = dispatch(getPathDatabaseByRole());
  const { t } = useTranslationApp();

  const { control, formState, handleSubmit } = useForm({
    defaultValues: {
      name: "",
      imageURL: "",
    },
  });
  useFocusErrorForm(formState);
  const { errors } = formState;

  const onSubmit = async (data, e) => {
    e?.preventDefault();
    e?.stopPropagation();
    setIsFetching(true);
    const response = await dispatch(
      startCreateIdentityTikTokBusiness({
        name: data.name,
        imageURL: data.imageURL,
        integrationID,
        adAccountID,
      })
    );
    setIsFetching(false);
    if (response.ok) {
      onCloseModal();
      onCallbackCreate(response.data);
    }
  };
  return (
    <ModalBasicLayout
      modalOpen={modalOpen}
      onCloseModal={onCloseModal}
      maxWidth="sm"
      scroll="paper"
    >
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        <TextField
          name="name"
          label={t(PROFILE_NAME_PLACEHOLDER)}
          variant="filled"
          fullWidth
          control={control}
          errors={errors}
          InputProps={{
            startAdornment: <InputAdornment position="start">@</InputAdornment>,
          }}
          rules={{
            required: {
              value: true,
              message: "Required",
            },
            maxLength: {
              value: 40,
              message: t(FIELD_MAX_LENGTH, { value: 40 }),
            },
          }}
        />
        <Box>
          <Typography
            variant="body1"
            color={errors?.imageURL ? "error" : "textPrimary"}
          >
            {t(PROFILE_IMAGE_PLACEHOLDER)}
          </Typography>
          <Controller
            control={control}
            name="imageURL"
            rules={{
              required: {
                value: true,
                message: "Required",
              },
            }}
            render={({ field }) => (
              <UploadMediaButtons
                isHidden={false}
                automaticSetRTDB={false}
                acceptedFormats={IMAGE_FORMATS}
                urlImage={field.value}
                targetName={field.name}
                onReadyUrlImage={field.onChange}
                onRemoveUrlImage={field.onChange}
                pathStorage={`adsCampaigns/${userID}/`}
                mode="replace"
                showImage={true}
                sxPreview={{
                  width: 80,
                  height: 80,
                }}
              />
            )}
          />
          {errors?.imageURL && (
            <Typography variant="caption" color="error">
              {errors?.imageURL?.message}
            </Typography>
          )}
        </Box>
        <ContainerModalActions
          sx={{ mt: 1 }}
          hasPadding={false}
          propsSecondaryButton={{
            showButton: true,
            onClick: onCloseModal,
            disabled: isFetching,
            text: BUTTON_GO_BACK,
          }}
          propsPrimaryButton={{
            showButton: true,
            onClick: handleSubmit(onSubmit),
            loading: isFetching,
            text: BUTTON_CREATE,
            type: "button",
          }}
        />
      </Box>
    </ModalBasicLayout>
  );
};

export default ModalCreateIdentityTikTokBusiness;
