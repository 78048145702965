import Box from "@mui/material/Box";
import {
  BUTTON_FINISH,
  BUTTON_NEXT,
  BUTTON_PREVIOUS,
} from "../../i18n/keysTranslations";
import PreviousButtonStep from "../Buttons/PreviousButtonStep";
import NextButtonStep from "../Buttons/NextButtonStep";
import { useTranslationApp } from "../../lib/i18next";
import { ADS_CAMPAIGN_STATUS_DEACTIVATED } from "../../utils/constants";
import LoadingButton from "../Buttons/LoadingButton";
import Loader from "../Loaders/Loader";
import {
  GRADIENT_PURPLE_FUCHSIA,
  GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT,
} from "../../utils/colors";
import { useMediaQuery } from "@mui/material";
import { useState } from "react";

const ActionsButtons = ({
  onPreviousStep,
  onNextStep,
  onFinish,
  customComponentPreviousSave,
  customComponentPreviousNext,
  showSaveButton = false,
  saveButtonText = BUTTON_FINISH,
  hasAdvancedSettings = false,
  showNextButton = true,
  showPreviousButton = true,
  colorNextButton = "secondary",
  loading,
  showLoadingComponent = false,
  customComponentLoading,
}) => {
  const [isLoadingNextStep, setIsLoadingNextStep] = useState(false);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const { t } = useTranslationApp();

  if (showLoadingComponent && loading) {
    if (customComponentLoading) {
      return customComponentLoading;
    }
    return <Loader size={40} fullWidth />;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        flexWrap:
          hasAdvancedSettings || (showNextButton && showSaveButton)
            ? "wrap"
            : "nowrap",
        gap: { xs: 1, sm: 2 },
        alignItems: "flex-start",
        mt: 1,
        mb: 2,
      }}
    >
      {showPreviousButton && (
        <PreviousButtonStep
          fullWidth={isMobile}
          sx={{ mt: 2, minWidth: 120 }}
          onPreviousStep={onPreviousStep}
        >
          {t(BUTTON_PREVIOUS)}
        </PreviousButtonStep>
      )}
      {customComponentPreviousNext && customComponentPreviousNext}
      {showNextButton && (
        <NextButtonStep
          loading={isLoadingNextStep}
          fullWidth={isMobile}
          sx={{ mt: 2, minWidth: 120 }}
          color={colorNextButton}
          onNextStep={async () => {
            setIsLoadingNextStep(true);
            await onNextStep();
            setIsLoadingNextStep(false);
          }}
        >
          {t(BUTTON_NEXT)}
        </NextButtonStep>
      )}
      {customComponentPreviousSave && customComponentPreviousSave}
      {showSaveButton && (
        <LoadingButton
          fullWidth={isMobile}
          loading={loading}
          onClick={() => onFinish(ADS_CAMPAIGN_STATUS_DEACTIVATED)}
          sx={{
            mt: 2,
            minWidth: 120,
            height: 40,
            background: GRADIENT_PURPLE_FUCHSIA,
            color: GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT,
          }}
          variant="contained"
          color="secondary"
          type="button"
        >
          {t(saveButtonText)}
        </LoadingButton>
      )}
    </Box>
  );
};

export default ActionsButtons;
