import { ConfirmUsageAlert } from "../components/Alerts/Alerts";
import ConfirmUsage from "../components/Billing/ConfirmUsage";
import {
  FACEBOOK,
  META,
  TIKTOK,
  TIKTOK_BUSINESS,
  VIEWER_ROLE,
} from "../utils/constants";
import { setShowModalSelectSubscription } from "./ui";
import { BUTTON_CONTINUE } from "../i18n/keysTranslations";
import i18next from "i18next";

export const getNameBusinessTheme = () => (_, getState) => {
  //eslint-disable-next-line
  return getState()?.ui?.theme?.nameBusiness;
};

export const getUserID = () => (_, getState) => {
  return getState()?.auth?.user?.uid;
};

export const getAdAccountUrlMeta = (adAccountID) => () => {
  return `https://business.facebook.com/adsmanager/manage/accounts?act=${adAccountID}`;
};
export const getCustomAudiencesMeta = () => (_, getState) => {
  const integrations = getState()?.user?.integrations;
  const metaAccountBusinessID = integrations?.facebook?.idBusiness;

  return `https://adsmanager.facebook.com/adsmanager/audiences?act=${
    metaAccountBusinessID ? metaAccountBusinessID.split("act_")[1] : ""
  }`;
};

export const getBillingUrlMeta =
  (adAccountID = "") =>
  () => {
    return `https://business.facebook.com/billing_hub/payment_activity?asset_id=${
      adAccountID.split("act_")[1]
    }&business_id=&placement=ads_manager`;
  };

export const getBillingUrlTikTokBusiness =
  (adAccountID = "") =>
  () => {
    return `https://ads.tiktok.com/i18n/account/payment?aadvid=${adAccountID}`;
  };

export const getIntegrationKey = (
  platform,
  differenceTikTokBusiness = false
) => {
  switch (platform) {
    case META:
      return FACEBOOK;

    case TIKTOK:
      return differenceTikTokBusiness ? TIKTOK_BUSINESS : TIKTOK;

    default:
      return platform;
  }
};

export const getUserIsViewer = () => (_, getState) => {
  return getState()?.auth?.user?.role === VIEWER_ROLE;
};

export const getIsBlockedActions =
  (props = {}) =>
  async (dispatch, getState) => {
    const {
      action,
      showUsageConfirmationAlert = true,
      showPaywall = true,
    } = props;

    const { cost, type } = action;

    const currentUsage =
      getState()?.payments?.subscriptionPlanInfo?.usage || {};
    const usageType = currentUsage[type] || 0;

    const isBlocked = cost > usageType;

    if (showPaywall && isBlocked) {
      dispatch(setShowModalSelectSubscription(true));
    }

    if (showUsageConfirmationAlert && !isBlocked) {
      const result = await ConfirmUsageAlert({
        html: <ConfirmUsage action={action} usage={currentUsage} />,
        confirmButtonText: i18next.t(BUTTON_CONTINUE),
      });
      if (!result.isConfirmed) return true;
    }

    return isBlocked;
  };
