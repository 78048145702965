export const GREY_OUTLINED_COLOR = "#EEE075";
export const GREY_INVOICE_PAID = "#EBEBEB";

export const GREY_DISABLED_COLOR = "#757575";
export const GREY_SLIDER_COLOR = "#b7b7b7";
export const GRAY_DARK_THEME_COLOR = "#66676C";
export const GRAY_DARK_THEME_COLOR_DISABLED = "#515151";
export const GRAY_DARK_BACKGROUND_COLOR = "#171717";
export const GREEN_COLOR = "#1A8925";
export const GREEN_COLOR_LIGHT = "#48ce4b";
export const RED_COLOR = "#ff2b2b";
export const RED_LIGHT_COLOR = "#FF6759";
export const ORANGE_COLOR = "#DB7C1E";
export const WHITE_COLOR = "#FFFFFF";
export const WHITE_DARK_COLOR = "#FAFAFA";
export const DARK_LIGHT_COLOR = "#121116";
export const YELLOW_RATING = "#ffac60";
export const FUCHSIA = "rgba(177, 75, 244)";

//COLORS STATISTICS
export const BAR_ORANGE_COLOR = "#FFA932";
export const BAR_BLACK_COLOR = "#171717";

// Global Colors
export const GREY_COLOR = "#EDEDED";
export const GREY_DARK_COLOR = "#5b5b5b";

//Light Theme
export const PRIMARY_COLOR = "#171717";
export const PRIMARY_COLOR_TEXT = "#fff";
export const SECONDARY_COLOR = "#fdff9e";
export const SECONDARY_COLOR_TEXT = "#000";
export const PAPER_BACKGROUND_COLOR = "#fff";
export const PAPER_CONTRAST_TEXT = "#000";
export const FIELD_PLACEHOLDER_COLOR = "#9f9f9f";

//Dark Theme
export const PRIMARY_COLOR_DARK = "#2b2b2f";
export const PRIMARY_COLOR_TEXT_DARK = "#fff";
export const SECONDARY_COLOR_DARK = "#fdff9e";
export const SECONDARY_COLOR_TEXT_DARK = "#000";
export const TABLE_HEADER_DARK = "#1e1e1e";
export const PAPER_BACKGROUND_COLOR_DARK = "#171717";
export const PAPER_CONTRAST_TEXT_DARK = "#fff";
export const PAPER_SECONDARY_BACKGROUND_COLOR_DARK = "#2b2b2f";
export const PAPER_SECONDARY_CONTRAST_TEXT_DARK = "#fff";
export const FIELD_PLACEHOLDER_COLOR_DARK = "#9f9f9f";

export const GRADIENT_YELLOW_GREEN =
  "linear-gradient(90deg, rgba(253,255,158,1) 0%, rgba(92,236,255,1) 100%)";
export const GRADIENT_PURPLE_FUCHSIA = `linear-gradient(81.02deg, rgb(250, 85, 96) -23.49%, rgb(177, 75, 244) 45.66%, rgb(77, 145, 255) 114.8%)`;
export const GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT = "#fff";

export const GRADIENT_INSTAGRAM = `linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%)`;
export const MAIN_COLOR_META = "#0869e1";
export const MAIN_COLOR_META_TEXT = "#fff";
export const GRADIENT_META = `linear-gradient(45deg, #0869e1 0%, #087ff3 100%)`;

export const GRADIENT_CANVA =
  "linear-gradient(90deg, rgba(10,196,207,1) 0%, rgba(123,46,237,1) 100%)";
export const COLOR_CANVA = "#FFFFFF";

export const BOX_SHADOW = "0px 0px 50px 0px rgba(0,0,0,0.1)";
export const BOX_SHADOW_SOLID =
  "0 3px 12px 0 rgba(0,0,0,0.1),0 1px 2px 0 rgba(0,0,0,0.08)";
export const BOX_SHADOW_ACTIONS_MODAL = "0px -2px 4px rgba(0, 0, 0, 0.1)";

export const COLORS_STATISTICS = ["#00BCD4", "#EF5350", "#FFBB28", "#FF8042"];

export const COLORS_BY_TYPE_STATISTIC = {
  currency: SECONDARY_COLOR_DARK,
  impressions: "hsl(30 80% 55%)",
  click: "hsl(220 76% 56%)",
  mainAction: "hsl(340 75% 55%)",
  average: "hsl(32.1 97.7% 83.1%)",
};
