import React from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import NavBar from "../Containers/NavBar";

const GeneralLayout = ({ children, isLogged }) => {
  return (
    <Box sx={{ display: "flex" }}>
      {isLogged && (
        <React.Fragment>
          <NavBar />
        </React.Fragment>
      )}
      <Box
        component="main"
        sx={{ width: "100%", minHeight: "calc(100vh - 64px)" }}
      >
        {isLogged && <Toolbar />}
        {children}
      </Box>
    </Box>
  );
};

export default GeneralLayout;
