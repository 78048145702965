import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useSelectorApp } from "../../lib/redux";

const ThemeContainer = ({ children }) => {
  const themeSettings = useSelectorApp((state) => state.ui.theme);
  const theme = createTheme({
    ...themeSettings,
  });
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default ThemeContainer;
