import {
  ADS_CAMPAIGNS_TITLE,
  BILLING_AND_PAYMENTS_TITLE,
  CONFIGURATION_TITLE,
  USERS_TITLE,
} from "../i18n/keysTranslations";
import {
  MARKETER_ROLE,
  ROLES_ALLOWED,
  ROLES_WITH_BILLING,
  VIEWER_ROLE,
} from "../utils/constants";
import CampaignIcon from "@mui/icons-material/Campaign";
import SettingsIcon from "@mui/icons-material/Settings";
import PaymentsIcon from "@mui/icons-material/Payments";
import ButtonOpenContentGenerator from "../components/Buttons/ButtonOpenContentGenerator";
import PeopleIcon from "@mui/icons-material/People";

const Menus = [
  {
    nameKey: "empty",
    roles: ROLES_ALLOWED,
    subMenus: [
      {
        nameKey: "ads-campaigns",
        name: ADS_CAMPAIGNS_TITLE,
        link: "/",
        roles: ROLES_ALLOWED,
        icon: <CampaignIcon />,
      },
      {
        nameKey: "configuration",
        name: CONFIGURATION_TITLE,
        link: "/configuration",
        roles: ROLES_ALLOWED.filter((role) => role !== VIEWER_ROLE),
        icon: <SettingsIcon />,
      },
      {
        nameKey: "users",
        name: USERS_TITLE,
        roles: ROLES_ALLOWED.filter(
          (role) => role !== MARKETER_ROLE && role !== VIEWER_ROLE
        ),
        link: "/users",
        icon: <PeopleIcon />,
        isAgencyMenu: true,
      },
      {
        nameKey: "contentGenerator",
        roles: ROLES_ALLOWED.filter((role) => role !== VIEWER_ROLE),
        customComponent: <ButtonOpenContentGenerator />,
      },
      {
        nameKey: "payments",
        name: BILLING_AND_PAYMENTS_TITLE,
        link: "/billing-and-payments",
        roles: ROLES_WITH_BILLING,
        icon: <PaymentsIcon />,
      },
    ],
  },
];
export default Menus;
