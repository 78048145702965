import React, { useState } from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import Collapse from "@mui/material/Collapse";
import { useDispatchApp, useSelectorApp } from "../../lib/redux";
import { useTranslationApp } from "../../lib/i18next";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Link } from "react-router-dom";
import { getNameBusinessTheme } from "../../actions/getters";
import { setOpenSlideMenu } from "../../actions/ui";
import LinkText from "../Texts/LinkText";
import ButtonSlideMenu from "../Buttons/ButtonSlideMenu";
import { getPathDatabaseByRole } from "../../actions/auth";

const MenuGroupItem = ({ menu }) => {
  const [open, setOpen] = useState(true);

  const theme = useTheme();
  const dispatch = useDispatchApp();
  const isTablet = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const slideMenuIsOpen = useSelectorApp((state) => state.ui.slideMenuIsOpen);
  const { role, agencyID } = dispatch(getPathDatabaseByRole());
  const { t } = useTranslationApp();
  const NAME_BUSINESS = dispatch(getNameBusinessTheme());

  const onDrawerToggle = () => {
    dispatch(setOpenSlideMenu(!slideMenuIsOpen));
  };
  const onClick = () => {
    setOpen(!open);
  };

  const sxContainerButtonMenu = {
    display: "flex",
    alignItems: "center",
  };
  return (
    <Box sx={{ mx: 2 }}>
      {menu.roles.includes(role) && menu.name ? (
        <Typography
          variant="body2"
          color={theme.palette.background.paperContrastText}
          sx={{ ml: 1, fontWeight: 300 }}
        >
          {t(menu.name)}
        </Typography>
      ) : null}
      <List sx={{}}>
        {menu.subMenus.map((subMenu) => {
          if (!subMenu.roles.includes(role)) return null;

          if (subMenu.hasSubMenus) {
            return (
              <Box key={subMenu.nameKey}>
                <ButtonSlideMenu
                  startIcon={subMenu.icon}
                  endIcon={open ? <ExpandLess /> : <ExpandMore />}
                  onClick={onClick}
                >
                  {t(subMenu.name, { NAME_BUSINESS })}
                </ButtonSlideMenu>
                <Collapse in={open} timeout="auto" unmountOnExit>
                  {subMenu.subMenus.map((subSubMenu) => {
                    if (subSubMenu.customComponent) {
                      return (
                        <Box
                          key={subSubMenu.nameKey}
                          sx={{
                            pl: 2,
                            mb: 1,
                          }}
                        >
                          {React.cloneElement(subSubMenu.customComponent)}
                        </Box>
                      );
                    }
                    return (
                      <Link
                        to={subSubMenu.link}
                        key={subSubMenu.nameKey}
                        onClick={isTablet && onDrawerToggle}
                        style={{
                          ...sxContainerButtonMenu,
                          textDecoration: "none",
                          paddingLeft: "16px",
                        }}
                      >
                        <ButtonSlideMenu
                          menu={subSubMenu}
                          startIcon={subSubMenu.icon}
                        >
                          {t(subSubMenu.name)}
                        </ButtonSlideMenu>
                      </Link>
                    );
                  })}
                </Collapse>
              </Box>
            );
          }

          if (subMenu.isAgencyMenu && !agencyID) return null;

          if (subMenu.isRedirect)
            return (
              <LinkText
                href={subMenu.link}
                target="_blank"
                key={subMenu.nameKey}
                onClick={() => {
                  if (isTablet) onDrawerToggle();
                }}
                sx={{ ...sxContainerButtonMenu, textDecoration: "none" }}
              >
                <ButtonSlideMenu menu={subMenu} startIcon={subMenu.icon}>
                  {t(subMenu.name, { NAME_BUSINESS })}
                </ButtonSlideMenu>
              </LinkText>
            );

          if (subMenu.customComponent)
            return (
              <Box key={subMenu.nameKey}>
                {React.cloneElement(subMenu.customComponent)}
              </Box>
            );
          return (
            <Link
              to={subMenu.link}
              key={subMenu.nameKey}
              onClick={() => {
                if (isTablet) onDrawerToggle();
              }}
              style={{ ...sxContainerButtonMenu, textDecoration: "none" }}
            >
              <ButtonSlideMenu menu={subMenu} startIcon={subMenu.icon}>
                {t(subMenu.name, { NAME_BUSINESS })}
              </ButtonSlideMenu>
            </Link>
          );
        })}
      </List>
    </Box>
  );
};

export default MenuGroupItem;
