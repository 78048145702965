import React, { useEffect } from "react";
import { Provider } from "react-redux";
import "./App.css";
import { store } from "./app/store";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import es from "date-fns/locale/es";
import { LocalizationProvider } from "@mui/x-date-pickers";
import ThemeContainer from "./components/Containers/ThemeContainer";
import AppRouter from "./router/AppRouter";
import MetaSDKLoader from "./components/Containers/MetaSDKLoader";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { UploadFilesProvider } from "./contexts/UploadFilesContext";
import { trackingPageView } from "./services/tracking";
import * as Sentry from "@sentry/react";
import { ChatProvider } from "./contexts/ChatContext";

const stripePromise = (async () => {
  try {
    return await loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY);
  } catch (err) {
    console.error(err);
    window.location.reload();
  }
})();

if (import.meta.env.VITE_IS_STAGING === "false") {
  Sentry.init({
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 0.2,
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    dsn: import.meta.env.VITE_SENTRY_URL,
  });
}

function App() {
  useEffect(() => {
    trackingPageView();
  }, []);
  return (
    <React.Fragment>
      <Elements stripe={stripePromise}>
        <Provider store={store}>
          <ThemeContainer>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={es}
            >
              <UploadFilesProvider>
                <MetaSDKLoader />
                <ChatProvider>
                  <AppRouter />
                </ChatProvider>
              </UploadFilesProvider>
            </LocalizationProvider>
          </ThemeContainer>
        </Provider>
      </Elements>
    </React.Fragment>
  );
}

export default App;
