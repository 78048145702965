import { createContext } from "react";
import useChat from "../hooks/useChat";

export const ChatContext = createContext();

export const ChatProvider = ({ children }) => {
  const chatState = useChat();

  return (
    <ChatContext.Provider
      value={{
        ...chatState,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};
