import { combineReducers } from "redux";
import { authReducer } from "./authReducer";
import { uiReducer } from "./uiReducer.js";
import { localesReducer } from "./localesReducer";
import { adsCampaignsReducer } from "./adsCampaignsReducer";
import { userReducer } from "./userReducer.js";
import { paymentsReducer } from "./paymentsReducer.js";
import { chatReducer } from "./chatReducer.js";

export const rootReducer = combineReducers({
  auth: authReducer,
  adsCampaigns: adsCampaignsReducer,
  chat: chatReducer,
  locales: localesReducer,
  ui: uiReducer,
  user: userReducer,
  payments: paymentsReducer,
});
