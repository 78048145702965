import ModalBasicLayout from '../Modal/ModalBasicLayout'
import { useTranslationApp } from '../../lib/i18next';
import { PAYMENT_METHODS } from '../../i18n/keysTranslations';
import RadioGroupCards from './RadioGroupCards';

const ModalStripePaymentMethods = ({
    modalOpen,
    onCloseModal,
    showMakeDefaultPaymentMethod,
}) => {
  const { t } = useTranslationApp();

  return (
    <ModalBasicLayout
      modalOpen={modalOpen}
      onCloseModal={onCloseModal}
      fullWidth={true}
      scroll="paper"
      ariaLabel={t(PAYMENT_METHODS)}
      title={t(PAYMENT_METHODS)}
    >
      <RadioGroupCards
        onCallbackMakeDefaultPaymentMethod={onCloseModal}
        showMakeDefaultPaymentMethod={showMakeDefaultPaymentMethod}
      />
    </ModalBasicLayout>
  )
}

export default ModalStripePaymentMethods