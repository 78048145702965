import { useContext } from "react";
import { ConversationHeader, Avatar } from "@chatscope/chat-ui-kit-react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import _ from "lodash";
import Typography from "@mui/material/Typography";
import { ChatContext } from "../../contexts/ChatContext";

const ChatConversationHeader = ({ data, onChangeShowChat }) => {
  const { currentChat } = useContext(ChatContext);

  return (
    <ConversationHeader
      style={{
        border: "none",
        display: "flex",
        justifyContent: _.isEmpty(currentChat) ? "flex-end" : "space-between",
        height: 60,
      }}
    >
      {!_.isEmpty(currentChat) && (
        <Avatar src={data?.imageURL} name={data?.name} />
      )}
      {!_.isEmpty(currentChat) && (
        <ConversationHeader.Content>
          <Typography
            variant="body1"
            sx={{
              fontWeight: 900,
              color: "text.primary",
            }}
          >
            {data?.name}
          </Typography>
        </ConversationHeader.Content>
      )}
      <ConversationHeader.Actions>
        <IconButton
          onClick={onChangeShowChat}
          sx={{
            padding: 0,
          }}
        >
          <CloseIcon sx={{ fontSize: "1.4rem" }} />
        </IconButton>
      </ConversationHeader.Actions>
    </ConversationHeader>
  );
};

export default ChatConversationHeader;
