import { useState } from "react";
import {
  BUTTON_UPLOAD_IMAGE,
  ERROR,
  FIELD_FILE_VALID_REQUIRED,
  FIELD_FILE_MAX_SIZE,
  BUTTON_REPLACE_IMAGE,
} from "../../i18n/keysTranslations";
import {
  ALERT_ICON_TYPE_ERROR,
  IMAGE_FORMATS,
  MAX_SIZE_FILES,
  MAX_SIZE_FILES_VALUE_DISPLAY,
  MAX_SIZE_IMAGE,
  MAX_SIZE_IMAGE_VALUE_DISPLAY,
} from "../../utils/constants";
import imageCompression from "browser-image-compression";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Button from "./Button";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import DeleteIcon from "@mui/icons-material/Delete";
import CardMedia from "../Media/CardMedia";
import {
  getHeightAndWidthFromDataUrl,
  getUUIDFromURL,
} from "../../utils/string";
import { useDispatchApp } from "../../lib/redux";
import { useTranslationApp } from "../../lib/i18next";
import {
  removeImageStorageAndDB,
  saveUrl,
  startDeleteFileAws,
  startUploadFileAws,
} from "../../services/public";
import { SimpleAlert } from "../Alerts/Alerts";
import RemoveMediaButton from "./RemoveMediaButton";
import UploadMediaButton from "./UploadMediaButton";
import ModalMedia from "../Modal/ModalMedia";

const UploadMediaButtons = ({
  urlImage,
  targetName,
  pathStorage,
  pathRTDB,
  hasIcon = true,
  automaticSetRTDB = true,
  disabled = false,
  isCompressed = true,
  hasText = true,
  sx = {},
  sxContainer = {},
  acceptedFormats = IMAGE_FORMATS,
  isHidden = false,
  onCallbackLoading = () => {},

  //UPLOAD
  onReadyUrlImage,
  textButtonUpload = BUTTON_UPLOAD_IMAGE,
  colorUpload = "secondary",
  variantUpload = "contained",
  imageOnboarding = "",
  uploadIcon = <CameraAltIcon />,
  idUploadComponent = "upload-media",
  multiple = false,

  //REMOVE
  deleteFile,
  deleteRTDB,
  onRemoveUrlImage = () => {},
  textButtonRemove = BUTTON_REPLACE_IMAGE,
  colorRemove = "primary",
  variantRemove = "contained",
  showButtonRemove = true,
  showImage = false,
  showImageMode = "container",
  removeIcon = <DeleteIcon />,
  mode = "remove",
  sxPreview = {},

  //SECOND BOTTON
  showSecondButton = false,
  textButtonSecond = "",
  iconSecondButton = null,
  colorSecond = "secondary",
  variantSecond = "contained",
  disabledSecondButton = false,
  onClickSecond = () => {},
  centeredSecondButton = false,
}) => {
  const [modalMediaOpen, setModalMediaOpen] = useState(false);

  const dispatch = useDispatchApp();
  const { t } = useTranslationApp();

  const getMaxWidthOrHeight = (width) => {
    if (width < 300) return width;
    return 1500;
  };

  const onChangeImage = async (e) => {
    const files = e.target.files;
    if (files.length === 0) return;

    let hasFileNotAccepted = false;
    let hasFileToBig = false;
    let valueFileToBig = 0;

    for (const file of files) {
      const fileFormat = file.type.split("/")[1];

      if (!acceptedFormats.includes(fileFormat)) {
        hasFileNotAccepted = true;
        continue;
      }

      const isImage = IMAGE_FORMATS.includes(fileFormat);
      const MAX_SIZE = isImage ? MAX_SIZE_IMAGE : MAX_SIZE_FILES;
      const VALUE_DISPLAY = isImage
        ? MAX_SIZE_IMAGE_VALUE_DISPLAY
        : MAX_SIZE_FILES_VALUE_DISPLAY;

      if (file.size > MAX_SIZE) {
        hasFileToBig = true;
        valueFileToBig = VALUE_DISPLAY;
        continue;
      }
      try {
        let newFile = file;

        if (isCompressed && isImage) {
          const dimensions = await getHeightAndWidthFromDataUrl(file);
          const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: getMaxWidthOrHeight(dimensions.width),
            useWebWorker: true,
            initialQuality: 0.8,
          };
          newFile = await imageCompression(file, options);
        }

        const url = await dispatch(
          startUploadFileAws(newFile, file.type, pathStorage)
        );
        automaticSetRTDB && dispatch(saveUrl(url, pathRTDB));

        if (urlImage) {
          await onRemoveImage();
        }

        onReadyUrlImage({
          target: { name: targetName, value: url },
        });
        continue;
      } catch (error) {
        console.log(error);
        continue;
      }
    }
    if (hasFileNotAccepted) {
      SimpleAlert({
        title: t(ERROR),
        text: t(FIELD_FILE_VALID_REQUIRED, {
          value: acceptedFormats.join(", "),
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }

    if (hasFileToBig) {
      SimpleAlert({
        title: t(ERROR),
        text: t(FIELD_FILE_MAX_SIZE, {
          value: valueFileToBig,
          type: "MB",
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
    return true;
  };

  const onRemoveImage = async () => {
    if (deleteFile) {
      const UUID = getUUIDFromURL(urlImage);
      if (urlImage.includes("amazonaws")) {
        await dispatch(
          startDeleteFileAws(UUID, pathStorage, pathRTDB, deleteRTDB)
        );
      } else {
        await dispatch(
          removeImageStorageAndDB(UUID, pathStorage, pathRTDB, deleteRTDB)
        );
      }
    }
    onRemoveUrlImage({
      target: { name: targetName, value: "" },
    });
  };

  return (
    <>
      {showImage && urlImage && showImageMode === "container" && (
        <CardMedia
          sx={{
            width: "auto",
            height: "auto",
            maxWidth: "100%",
            maxHeight: 300,
            mb: 2,
            borderRadius: 3,
            objectFit: "contain",
            objectPosition: "center",
            position: "relative",
            ...sxPreview,
          }}
          url={urlImage}
          variant="rounded"
        />
      )}
      <Box
        sx={{
          display: isHidden ? "none" : "flex",
          justifyContent: centeredSecondButton ? "center" : "flex-start",
          alignItems: "center",
          gap: 1,
          ...sxContainer,
        }}
      >
        {showSecondButton && showButtonRemove && (
          <Button
            color={colorSecond}
            variant={variantSecond}
            endIcon={iconSecondButton}
            onClick={onClickSecond}
            disabled={disabledSecondButton}
          >
            {t(textButtonSecond)}
          </Button>
        )}
        {urlImage && showButtonRemove ? (
          <RemoveMediaButton
            acceptedFormats={acceptedFormats}
            hasIcon={hasIcon}
            pathStorage={pathStorage}
            pathRTDB={pathRTDB}
            urlImage={urlImage}
            targetName={targetName}
            onRemoveUrlImage={onRemoveUrlImage}
            deleteFile={deleteFile}
            deleteRTDB={deleteRTDB}
            textButton={textButtonRemove}
            color={colorRemove}
            variantRemove={variantRemove}
            disabled={disabled}
            showImage={showImage}
            onChangeImage={onChangeImage}
            onReadyUrlImage={onReadyUrlImage}
            imageOnboarding={imageOnboarding}
            hasText={hasText}
            removeIcon={removeIcon}
            onRemoveImage={onRemoveImage}
            onCallbackLoading={onCallbackLoading}
            mode={mode}
            sx={sx}
          />
        ) : (
          <UploadMediaButton
            multiple={multiple}
            acceptedFormats={acceptedFormats}
            hasIcon={hasIcon}
            pathStorage={pathStorage}
            pathRTDB={pathRTDB}
            targetName={targetName}
            onReadyUrlImage={onReadyUrlImage}
            automaticSetRTDB={automaticSetRTDB}
            textButton={textButtonUpload}
            color={colorUpload}
            variantRemove={variantUpload}
            imageOnboarding={imageOnboarding}
            hasText={hasText}
            uploadIcon={uploadIcon}
            disabled={disabled}
            onChangeImage={onChangeImage}
            idUploadComponent={idUploadComponent}
            onCallbackLoading={onCallbackLoading}
            isHidden={isHidden}
            sx={sx}
          />
        )}
      </Box>

      {showImage && urlImage && showImageMode === "modal" && (
        <IconButton onClick={() => setModalMediaOpen(true)}>
          <VisibilityIcon />
        </IconButton>
      )}
      {modalMediaOpen && (
        <ModalMedia
          url={urlImage}
          modalOpen={modalMediaOpen}
          onCloseModal={(e) => {
            e?.stopPropagation();
            setModalMediaOpen(false);
          }}
          disabledActions={true}
        />
      )}
    </>
  );
};

export default UploadMediaButtons;
