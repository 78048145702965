import { useEffect, useState } from "react";
import { useDispatchApp, useSelectorApp } from "../lib/redux";
import logoPerson from "../assets/img/logo-person.png";
import {
  changeIsTypingBot,
  setCurrentChat,
  startGetMessagesCurrentChat,
  startMarkReadChat,
  startSendBroadcastMessageChat,
  startSendMessageChat,
  uploadingFile,
} from "../actions/chat";
import { off } from "firebase/database";
import { compressImage, isImage, validateFile } from "../utils/string";
import { startUploadFileAws } from "../services/public";
import { startGetBusinessName } from "../actions/user";

const useChat = () => {
  const [currentMessageListener, setCurrentMessageListener] = useState(null);
  const dispatch = useDispatchApp();

  const user = useSelectorApp((state) => state.auth.user);
  const theme = useSelectorApp((state) => state.ui.theme);

  const {
    isTypingBot,
    chats,
    currentChat,
    currentChatMessages,
    isUploadingFile,
    currentWriteMessage,
  } = useSelectorApp((state) => state.chat);

  let logoShop = logoPerson;
  const LOGO_BUSINESS = theme?.logoChat;
  const LOGO_CHATBOT =
    "https://clapp-prod.s3.amazonaws.com/Buzzly/chat-ana.webp";

  useEffect(() => {
    if (!user) return;
    dispatch(startGetBusinessName());
    // eslint-disable-next-line
  }, [user?.uid]);

  useEffect(() => {
    if (currentMessageListener) {
      off(currentMessageListener);
    }

    if (!currentChat) return;

    if (!currentChat?.isTemporary) {
      dispatch(
        startGetMessagesCurrentChat({
          chatID: currentChat.chatID,
          onReferenceAvailable: (refCurrentMessages) =>
            setCurrentMessageListener(refCurrentMessages),
        })
      );
    }
    // eslint-disable-next-line
  }, [currentChat]);

  const onAddMessage = async ({ message, senderID, receiverID, file }) => {
    let urlFile = null;

    if (file) {
      const isValidFile = validateFile(file);
      if (!isValidFile) return false;

      let finalFile = file;
      if (isImage(file)) {
        finalFile = await compressImage(file);
      }
      dispatch(uploadingFile());
      const url = await dispatch(
        startUploadFileAws(finalFile, file.type, `chats/${currentChat.chatID}/`)
      );
      if (!url) {
        dispatch(uploadingFile());
        return false;
      }
      urlFile = url;
    }
    const newMessage = {
      message,
      messageType: urlFile ? "media" : "text",
      mediaURL: urlFile,
      userID: senderID,
    };
    const messageFormatted = await dispatch(
      startSendMessageChat({
        message: newMessage,
        userID: receiverID,
        chatID: currentChat.chatID,
      })
    );
    if (file) dispatch(uploadingFile());
    if (!messageFormatted) return false;
    return messageFormatted;
  };

  const onAddBroadcastMessage = async ({ message, chatsIDs, file }) => {
    let urlFile = null;

    if (file) {
      const isValidFile = validateFile(file);
      if (!isValidFile) return false;

      let finalFile = file;
      if (isImage(file)) {
        finalFile = await compressImage(file);
      }
      const url = await dispatch(
        startUploadFileAws(finalFile, file.type, `chats/${chatsIDs[0]}/`)
      );
      if (!url) return false;
      urlFile = url;
    }

    const response = await dispatch(
      startSendBroadcastMessageChat({
        message,
        chatsIDs,
        file: urlFile,
      })
    );

    return response;
  };

  const onSelectChat = async (chatID) => {
    if (!chatID) {
      return dispatch(setCurrentChat(null));
    }
    dispatch(startMarkReadChat(chatID));
    return dispatch(setCurrentChat(chats[chatID]));
  };
  const onChangeIsTypingBot = () => dispatch(changeIsTypingBot());

  return {
    currentWriteMessage,
    currentChat,
    currentChatMessages,
    isTypingBot,
    isUploadingFile,
    onChangeIsTypingBot,
    onAddMessage,
    onAddBroadcastMessage,
    onSelectChat,
    LOGO_BUSINESS,
    LOGO_CHATBOT,
    logoShop,
    chats,
  };
};

export default useChat;
