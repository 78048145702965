import { useEffect, useState } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { useDispatchApp, useSelectorApp } from "../lib/redux";
import { Helmet } from "react-helmet";
import { CssBaseline } from "@mui/material";
import AdsCampaigns from "../pages/AdsCampaigns/AdsCampaigns";
import Login from "../pages/Login/Login";
import { PublicRoute } from "./routes/PublicRoute";
import { ROLES_ALLOWED, THEME_ID_PROJECT } from "../utils/constants";
import { auth } from "../lib/firebase";
import { onAuthStateChanged } from "firebase/auth";
import {
  checkingFinish,
  getPathDatabaseByRole,
  login,
  startLogout,
} from "../actions/auth";
import Loader from "../components/Loaders/Loader";
import { PrivateRoute } from "./routes/PrivateRoute";
// import Register from "../pages/Register/Register";
import {
  startGetIntegrations,
  startGetLastSelectedIntegrationData,
} from "../actions/user";
import CreateAdsCampaigns from "../pages/CreateAdsCampaigns/CreateAdsCampaigns";
import AdsCampaign from "../pages/AdsCampaign/AdsCampaign";
import GeneralLayout from "../components/Layouts/GeneralLayout";
import Configuration from "../pages/Configuration/Configuration";
import {
  setShowModalSelectSubscription,
  startGetTheme,
  startGetVersionAds,
} from "../actions/ui";
import ModalBlockVersionApp from "../components/Modal/ModalBlockVersionApp";
import { off } from "firebase/database";
import {
  startGetPlansAds,
  startGetStripeInfo,
  startGetSubscriptionPlan,
  startGetSubscriptionPlanInfo,
  startGetSubscriptionPlanInfoUsage,
  startListenerIsActivePlan,
} from "../actions/payments";
import { startGetAllChats } from "../actions/chat";
import ModalPaySubscription from "../components/Modal/ModalPaySubscription/ModalPaySubscription";
import { PHProvider } from "../contexts/PostHogContext";
import PostHogPageView from "../components/Containers/PostHogPageView";
import "@formatjs/intl-displaynames/polyfill";
import "@formatjs/intl-displaynames/locale-data/en";
import "@formatjs/intl-displaynames/locale-data/es";
import OpenChatButton from "../components/Chat/OpenChatButton";
import { usePostHog } from "posthog-js/react";
import BillingAndPayments from "../pages/BillingAndPayments/BillingAndPayments";
import VerifyAuth from "../pages/VerifyAuth/VerifyAuth";
import _ from "lodash";
import useUserIP from "../hooks/useUserIP";
import Backdrop from "../components/Loaders/Backdrop";
import Users from "../pages/Users/Users";
import AdsCampaignStatistics from "../pages/AdsCampaignStatistics/AdsCampaignStatistics";
import Error404 from "../pages/Error404/Error404";

const AppRouter = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingRequiredData, setIsLoadingRequiredData] = useState(true);
  const [versionApp] = useState("1.1.7");
  const [isPlanActiveListener, setIsPlanActiveListener] = useState(null);
  const [chatsListener, setChatsListener] = useState(null);
  const [subscriptionPlanListener, setSubscriptionPlanListener] =
    useState(null);

  const { user, isLoading: isLoadingAuth } = useSelectorApp(
    (state) => state.auth
  );
  useUserIP();

  const dispatch = useDispatchApp();
  const theme = useSelectorApp((state) => state.ui.theme);
  const version = useSelectorApp((state) => state.ui.version);
  const showModalSelectSubscription = useSelectorApp(
    (state) => state.ui.showModalSelectSubscription
  );
  const isOpenVersionBlock = versionApp !== version;
  const { userID } = dispatch(getPathDatabaseByRole());

  const postHog = usePostHog();

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const token = await user.getIdTokenResult();
        if (!ROLES_ALLOWED.includes(token.claims.role)) {
          const isUserRegisteredAndHasClaims = token.claims?.role;
          const providerID = user?.providerData?.[0]?.providerId;
          const isProviderPhone = providerID === "phone";
          const isProviderGoogle = providerID === "google.com";

          if (
            (!isProviderPhone && !isProviderGoogle) ||
            isUserRegisteredAndHasClaims
          ) {
            auth.signOut();
            await dispatch(startLogout());
            postHog.reset();
          }
          setIsLoading(false);
          dispatch(checkingFinish());
        } else {
          const userData = {
            uid: user.uid,
            email: user.email,
            name: token.claims.name || user.displayName,
            role: token.claims?.role,
            token: token.token,
            advertiserID: token.claims?.advertiserID,
            themeID: token.claims?.themeID,
            shopID: token.claims?.shopID,
            agencyID: token.claims?.agencyID,
          };
          dispatch(login(userData));
          const { userID } = dispatch(getPathDatabaseByRole(userData));

          postHog.identify(user.uid, {
            email: user.email,
            name: token.claims.name || user.displayName,
            role: token.claims?.role,
            companyID: userID,
          });
          postHog.group("company", userID);
        }
      } else {
        await dispatch(startLogout());
        postHog.reset();
        dispatch(checkingFinish());
        setIsLoading(false);
      }
    });
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    onClearListeners();
    if (!user?.uid) {
      return;
    }

    getInitialData();

    return () => {
      onClearListeners();
    };
    //eslint-disable-next-line
  }, [user?.uid, userID]);

  useEffect(() => {
    getRequiredData();
    //eslint-disable-next-line
  }, []);

  const getRequiredData = async () => {
    await dispatch(startGetVersionAds());
    if (!_.isEmpty(THEME_ID_PROJECT)) {
      await dispatch(startGetTheme());
    }
    setIsLoadingRequiredData(false);
  };

  const getInitialData = async () => {
    await dispatch(
      startGetSubscriptionPlanInfoUsage((ref) => {
        setSubscriptionPlanListener(ref);
      })
    );
    dispatch(
      startListenerIsActivePlan((refIsPlanActive) => {
        setIsPlanActiveListener(refIsPlanActive);
      })
    );
    dispatch(startGetPlansAds());
    await dispatch(startGetIntegrations());
    await dispatch(startGetLastSelectedIntegrationData());
    dispatch(startGetStripeInfo());
    dispatch(startGetSubscriptionPlan());
    dispatch(startGetSubscriptionPlanInfo());
    dispatch(
      startGetAllChats((refChats) => {
        setChatsListener(refChats);
      })
    );
    setIsLoading(false);
  };
  const onClearListeners = () => {
    if (isPlanActiveListener) {
      off(isPlanActiveListener);
    }
    if (chatsListener) {
      off(chatsListener);
    }
    if (subscriptionPlanListener) {
      off(subscriptionPlanListener);
    }
  };

  window.NAME_BUSINESS = theme.nameBusiness;
  window.primaryColor = theme.palette.primary.main;
  window.secondaryColor = theme.palette.secondary.main;
  window.textPrimaryColor = theme.palette.primary.contrastText;
  window.textSecondaryColor = theme.palette.secondary.contrastText;
  window.paper = theme.palette.background.paper;
  window.paperContrastText = theme.palette.background.paperContrastText;
  window.paperSecondary = theme.palette.background.paperSecondary;
  window.paperSecondaryContrastText =
    theme.palette.background.paperSecondaryContrastText;
  document.documentElement.style.setProperty(
    "--primary-color",
    theme.palette.primary.main
  );
  document.documentElement.style.setProperty(
    "--secondary-color",
    theme.palette.secondary.main
  );
  document.documentElement.style.setProperty(
    "--text-primary-color",
    theme.palette.primary.contrastText
  );
  document.documentElement.style.setProperty(
    "--text-secondary-color",
    theme.palette.secondary.contrastText
  );
  document.documentElement.style.setProperty(
    "--paper",
    theme.palette.background.paper
  );
  document.documentElement.style.setProperty(
    "--paper-contrast-text",
    theme.palette.background.paperContrastText
  );
  document.documentElement.style.setProperty(
    "--paper-secondary",
    theme.palette.background.paperSecondary
  );
  document.documentElement.style.setProperty(
    "--paper-secondary-contrast-text",
    theme.palette.background.paperSecondaryContrastText
  );
  document.documentElement.style.setProperty(
    "--hover-main",
    theme.palette.hover.main
  );
  document.documentElement.style.setProperty(
    "--font-family",
    theme.typography.fontFamily
  );

  if (isLoading || isLoadingAuth || !version || isLoadingRequiredData)
    return <Loader fullScreen fullWidth />;

  return (
    <BrowserRouter>
      <Helmet>
        <title>{`${theme.nameBusiness} - Web Panel`}</title>
        <link rel="icon" href={theme.favicon} />
        <link rel="apple-touch-icon" href={theme.favicon} />
      </Helmet>
      <CssBaseline />
      <GeneralLayout isLogged={user ? true : false}>
        <PHProvider>
          <PostHogPageView />
          <Backdrop />
          <Routes>
            <Route path="*" element={<Error404 />} />
            <Route
              path="/login"
              element={
                <PublicRoute restricted={true} isLogged={user ? true : false}>
                  <Login />
                </PublicRoute>
              }
            />
            {/* <Route
              path="/sign-up"
              element={
                <PublicRoute restricted={true} isLogged={user ? true : false}>
                  <Register />
                </PublicRoute>
              }
            /> */}
            <Route
              path="/"
              element={
                <PrivateRoute restricted={false} isLogged={user ? true : false}>
                  <AdsCampaigns />
                </PrivateRoute>
              }
            />
            <Route
              path="/ads-campaign"
              element={
                <PrivateRoute restricted={false} isLogged={user ? true : false}>
                  <AdsCampaign />
                </PrivateRoute>
              }
            />
            <Route
              path="/ads-campaign-statistics"
              element={
                <PrivateRoute restricted={false} isLogged={user ? true : false}>
                  <AdsCampaignStatistics />
                </PrivateRoute>
              }
            />
            <Route
              path="/configuration"
              element={
                <PrivateRoute restricted={false} isLogged={user ? true : false}>
                  <Configuration />
                </PrivateRoute>
              }
            />
            <Route
              path="/create-ads-campaign"
              element={
                <PrivateRoute restricted={false} isLogged={user ? true : false}>
                  <CreateAdsCampaigns />
                </PrivateRoute>
              }
            />
            <Route
              path="/billing-and-payments"
              element={
                <PrivateRoute restricted={false} isLogged={user ? true : false}>
                  <BillingAndPayments />
                </PrivateRoute>
              }
            />
            <Route
              path="/users"
              element={
                <PrivateRoute restricted={false} isLogged={user ? true : false}>
                  <Users />
                </PrivateRoute>
              }
            />
            <Route
              path="/verify-auth"
              element={
                <PublicRoute restricted={false} isLogged={user ? true : false}>
                  <VerifyAuth />
                </PublicRoute>
              }
            />
          </Routes>
          {isOpenVersionBlock && (
            <ModalBlockVersionApp modalOpen={isOpenVersionBlock} />
          )}
          {showModalSelectSubscription && (
            <ModalPaySubscription
              modalOpen={showModalSelectSubscription}
              onCloseModal={() =>
                dispatch(setShowModalSelectSubscription(false))
              }
            />
          )}
          <OpenChatButton />
        </PHProvider>
      </GeneralLayout>
    </BrowserRouter>
  );
};

export default AppRouter;
