const CreditsIcon = ({
  color = "white",
  width = "22px",
  height = "22px",
  sx = {},
}) => {
  return (
    <svg
      style={{
        ...sx,
      }}
      fill={color}
      width={width}
      height={height}
      viewBox="0 -20 846.66 846.66"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M423.33 8.5c229.1,0 414.83,185.73 414.83,414.83 0,229.1 -185.73,414.83 -414.83,414.83 -229.1,0 -414.83,-185.73 -414.83,-414.83 0,-229.1 185.73,-414.83 414.83,-414.83zm300.17 394.08l72.58 0c-4.79,-87.46 -39.69,-166.91 -94.5,-228.15l-51.33 51.34c41.8,47.97 68.65,109.34 73.25,176.81zm72.58 41.5l-72.58 0c-4.6,67.48 -31.44,128.84 -73.25,176.82l51.33 51.33c54.81,-61.24 89.71,-140.68 94.5,-228.15zm-123.85 257.5l-51.33 -51.33c-47.98,41.81 -109.34,68.64 -176.82,73.24l0 72.59c87.47,-4.79 166.91,-39.68 228.15,-94.5zm-269.65 94.5l0 -72.58c-67.47,-4.6 -128.84,-31.45 -176.81,-73.25l-51.34 51.33c61.24,54.81 140.69,89.71 228.15,94.5zm-257.5 -123.84l51.34 -51.34c-41.81,-47.98 -68.65,-109.34 -73.25,-176.82l-72.59 0c4.79,87.47 39.69,166.92 94.5,228.16zm18.89 -248.91c-2.5,142.22 118.95,259.37 259.36,259.37 143.25,0 259.37,-116.12 259.37,-259.37 0,-143.2 -116.06,-259.3 -259.26,-259.37 -140.47,0 -261.97,117.06 -259.47,259.37zm-113.39 -20.75l72.59 0c4.59,-67.47 31.44,-128.84 73.25,-176.82l-51.33 -51.33c-54.82,61.24 -89.72,140.69 -94.51,228.15zm352 -279.41l0 -72.59c-87.46,4.79 -166.91,39.69 -228.15,94.51l51.33 51.33c47.98,-41.81 109.35,-68.66 176.82,-73.25zm41.5 -72.59l0 72.59c67.48,4.59 128.84,31.44 176.82,73.25l51.33 -51.33c-61.24,-54.82 -140.68,-89.72 -228.15,-94.51zm-41.5 205.29c0,-27.29 41.5,-27.29 41.5,0l0 22.41 33.06 0c27.29,0 27.29,41.51 0,41.51l-33.06 0 0 82.79 19.82 0c34.25,0 62.19,27.94 62.19,62.18l0 41.44c0,34.19 -28.03,62.18 -62.19,62.18l-19.82 0 0 22.41c0,27.3 -41.5,27.3 -41.5,0l0 -22.41 -33.05 0c-27.3,0 -27.3,-41.5 0,-41.5l33.05 0 0 -82.8 -19.82 0c-34.18,0 -62.18,-27.97 -62.18,-62.18l0 -41.43c0,-34.25 27.94,-62.19 62.18,-62.19l19.82 0 0 -22.41zm41.5 188.21l0 82.8 19.82 0c11.39,0 20.68,-9.31 20.68,-20.68l0 -41.44c0,-11.32 -9.35,-20.68 -20.68,-20.68l-19.82 0zm-41.5 -41.5l0 -82.79 -19.82 0c-11.33,0 -20.68,9.35 -20.68,20.68l0 41.43c0,11.38 9.32,20.68 20.68,20.68l19.82 0z" />
    </svg>
  );
};

export default CreditsIcon;
