import { useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import { FONT_FAMILY } from "../../utils/constants";
import { MainContainer } from "@chatscope/chat-ui-kit-react";
import CommonChatContainer from "./CommonChatContainer";
import { useState } from "react";
import { CHAT_WRITE_MESSAGE_HERE } from "../../i18n/keysTranslations";

const CommonChatMainContainer = ({
  onChangeShowChat = () => {},
  showSendFileButton,
  chatConfigurationID,
  chatContext,
  startMode = "all",
  placeholderInput = CHAT_WRITE_MESSAGE_HERE,
  headerTitle = CHAT_WRITE_MESSAGE_HERE,
}) => {
  const [mode, setMode] = useState(startMode);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <Box sx={{ height: "100%" }}>
      <Box
        sx={{
          position: "fixed",
          bottom: { xs: 0, sm: "20px" },
          right: { xs: 0, sm: "16px" },
          zIndex: 1200,
          maxWidth: mode === "all" ? { xs: "100vw", sm: 650 } : 500,
          maxHeight: { xs: "calc(100% - 56px)", sm: "calc(100% - 104px)" },
          height:
            mode === "all" ? { xs: "calc(100% - 56px)", md: 720 } : "auto",
          width: "100%",
          display: "flex",
          fontFamily: `${FONT_FAMILY} !important`,
          borderRadius: isMobile ? 0 : 6,
          pl: { xs: 0, sm: "36px" },
        }}
        className={`animate__animated animate__fadeIn animate__faster`}
      >
        <MainContainer>
          <CommonChatContainer
            onChangeShowChat={() => {
              if (startMode === "all") {
                onChangeShowChat();
                return;
              }

              setMode(startMode);
            }}
            showSendFileButton={showSendFileButton}
            chatConfigurationID={chatConfigurationID}
            chatContext={chatContext}
            mode={mode}
            onChangeMode={(mode) => setMode(mode)}
            placeholderInput={placeholderInput}
            headerTitle={headerTitle}
          />
        </MainContainer>
      </Box>
    </Box>
  );
};

export default CommonChatMainContainer;
