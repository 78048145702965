import { useState } from "react";
import PropTypes from "prop-types";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { IMAGE_FORMATS } from "../../utils/constants";
import { BUTTON_UPLOAD_IMAGE } from "../../i18n/keysTranslations";
import { useTranslationApp } from "../../lib/i18next";
import LoadingButton from "./LoadingButton";

const UploadMediaButton = ({
  acceptedFormats = IMAGE_FORMATS,
  hasIcon = true,
  uploadIcon = <CameraAltIcon />,
  multiple = false,
  hasText = true,
  textButton = BUTTON_UPLOAD_IMAGE,
  color = "secondary",
  variant = "contained",
  disabled,
  onChangeImage,
  onCallbackLoading = () => {},
  idUploadComponent = "upload-media",
  isHidden = false,
  sx = {},
}) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslationApp();

  const getTypeButton = () => {
    return "file";
  };
  return (
    <LoadingButton
      sx={{
        display: isHidden ? "none" : "flex",
        textTransform: "none",
        borderRadius: 3,
        ...sx,
      }}
      endIcon={hasIcon && !loading && uploadIcon}
      loading={loading}
      component="label"
      disabled={disabled}
      color={color}
      disableElevation
      variant={variant}
      type="button"
    >
      {hasText && t(textButton)}
      <input
        id={idUploadComponent}
        multiple={multiple}
        accept={acceptedFormats
          .map((item) => {
            if (IMAGE_FORMATS.includes(item)) return `image/${item},`;
            return `video/${item},`;
          })
          .join(" ")}
        name="image"
        type={getTypeButton()}
        hidden
        onChange={async (e) => {
          setLoading(true);
          onCallbackLoading(true);
          await onChangeImage(e);
          setLoading(false);
          onCallbackLoading(false);
        }}
      />
    </LoadingButton>
  );
};

UploadMediaButton.propTypes = {
  hasIcon: PropTypes.bool,
  textButton: PropTypes.string,
  color: PropTypes.string,
  variant: PropTypes.string,
  pathRTDB: PropTypes.string,
  pathStorage: PropTypes.string.isRequired,
  targetName: PropTypes.string.isRequired,
  onReadyUrlImage: PropTypes.func.isRequired,
};

export default UploadMediaButton;
