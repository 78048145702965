import { useRef, useState } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {
  ACTIVE,
  AD,
  CALL_TO_ACTION_PLACEHOLDER,
  DEACTIVATED,
  DESCRIPTION_PLACEHOLDER,
  DESCRIPTIONS,
  FIELD_MAX_LENGTH,
  FIELD_REQUIRED,
  HEADLINE_PLACEHOLDER,
  HEADLINES,
  NUMBER_ITEM,
  TITLE,
  TITLE_PLACEHOLDER,
  TITLES,
} from "../../../i18n/keysTranslations";
import { Controller, useFormContext } from "react-hook-form";
import { useMediaQuery, useTheme } from "@mui/material";
import {
  ADS_CAMPAIGN_GENERATE_AD_TEXT_ACTION,
  ADS_CAMPAIGN_META_DESCRIPTION_MAX_LENGTH,
  ADS_CAMPAIGN_META_HEADLINE_MAX_LENGTH,
  ADS_CAMPAIGN_META_TITLE_MAX_LENGTH,
  ADS_CAMPAIGN_OBJECTIVE_ENGAGEMENT_OPTION,
  ADS_CAMPAIGN_STATUS_ACTIVE,
  ADS_CAMPAIGN_STATUS_DEACTIVATED,
  ADS_CAMPAIGN_TIKTOK_DESCRIPTION_MAX_LENGTH,
  ADS_CAMPAIGN_TIKTOK_HEADLINE_MAX_LENGTH,
  ADS_CAMPAIGN_TIKTOK_TITLE_MAX_LENGTH,
  META,
  TIKTOK,
} from "../../../utils/constants";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { useTranslationApp } from "../../../lib/i18next";
import { useDispatchApp } from "../../../lib/redux";
import { generateAdsCampaignDescription } from "../../../actions/adsCampaigns";
import Accordion from "../../../components/Containers/Accordion";
import Switch from "../../../components/Form/Switch";
import SelectCallToActionAds from "../../../components/Form/SelectCallToActionAds";
import TextField from "../../../components/Form/TextField";
import Loader from "../../../components/Loaders/Loader";
import { getUniqueID } from "../../../utils/numbers";
import { getReactHookFormNestedError } from "../../../utils/errors";
import AdPreview from "./AdPreview";
import { ModalAdPreview } from "./ModalAdPreview";
import { getIsBlockedActions } from "../../../actions/getters";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {
  getIsSpecialAdWithOnlyTitles,
  getIsSpecialAdWithoutCallToAction,
} from "../../../services/adsCampaigns";

const AdsAdForm = ({ data, platform }) => {
  const refPreview = useRef(null);
  const [isSaving, setIsSaving] = useState(false);
  const [modalAdPreviewOpen, setModalAdPreviewOpen] = useState(false);
  const {
    watch,
    control,
    getValues,
    setValue,
    onAddAd,
    onRemoveAd,
    isGeneralDisabledFields,
    getIsCampaignPlatformActive,
    getShowActionsActionsPlatform,
    onChangeStatusCampaign,
    formState: { errors },
  } = useFormContext();

  const { t } = useTranslationApp();
  const theme = useTheme();
  const dispatch = useDispatchApp();

  const breakColumnFormAndPreview = useMediaQuery(() => "(max-width: 1080px)");
  const adsGroups = watch("adsGroups");
  const indexAdGroup = adsGroups.findIndex(
    (adGroup) => adGroup.id === data.adGroupID
  );
  const ads = adsGroups?.[indexAdGroup]?.ads;
  const adsPlatform = ads.filter((ad) => ad.platform === platform);
  const hasOnlyOneAd = adsPlatform.length === 1;
  const indexAd = ads.findIndex((ad) => ad.id === data.id);
  const BASIC_PATH_AD_GROUP = `adsGroups.[${indexAdGroup}]`;
  const BASIC_PATH = `adsGroups.[${indexAdGroup}].ads.[${indexAd}]`;
  const objective = watch(`objective`);

  const platformData = watch(`platformsData.${META}`);
  const mediaUrl = watch(`${BASIC_PATH}.mediaUrl`);
  const mediaData = watch(`${BASIC_PATH}.mediaData`);
  const callToAction = watch(`${BASIC_PATH}.callToAction`);
  const titles = watch(`${BASIC_PATH}.titles`) || [{ value: "" }];
  const headlines = watch(`${BASIC_PATH}.headlines`) || [""];
  const descriptions = watch(`${BASIC_PATH}.descriptions`) || [""];

  const isCampaignPlatformActivated = getIsCampaignPlatformActive(platform);
  const showActionsPlatform = getShowActionsActionsPlatform(platform);

  const maxLengthTitle =
    platform === META
      ? ADS_CAMPAIGN_META_TITLE_MAX_LENGTH
      : ADS_CAMPAIGN_TIKTOK_TITLE_MAX_LENGTH;
  const maxLengthDescription =
    platform === META
      ? ADS_CAMPAIGN_META_DESCRIPTION_MAX_LENGTH
      : ADS_CAMPAIGN_TIKTOK_DESCRIPTION_MAX_LENGTH;
  const maxLengthHeadline =
    platform === META
      ? ADS_CAMPAIGN_META_HEADLINE_MAX_LENGTH
      : ADS_CAMPAIGN_TIKTOK_HEADLINE_MAX_LENGTH;

  const blockMultipleTexts = platform === META;
  const isSpecialAdWithOnlyTitles = getIsSpecialAdWithOnlyTitles({
    platform,
    destinationType: platformData?.destinationType,
    objective,
  });
  const isSpecialAdWithoutCallToAction = getIsSpecialAdWithoutCallToAction({
    platform,
    destinationType: platformData?.destinationType,
    objective,
  });

  const onGenerateText = async ({ type, index }) => {
    const isBlocked = await dispatch(
      getIsBlockedActions({
        action: ADS_CAMPAIGN_GENERATE_AD_TEXT_ACTION,
      })
    );
    if (isBlocked) {
      return;
    }

    const BASIC_PATH_AD_GROUPS = `adsGroups.[${indexAdGroup}]`;

    const response = await dispatch(
      generateAdsCampaignDescription({
        objective,
        platform,
        websiteURL: getValues("websiteURL"),
        ageRange: getValues(`${BASIC_PATH_AD_GROUPS}.ageRange`),
        genders: getValues(`${BASIC_PATH_AD_GROUPS}.genders`),
        locations: getValues(`${BASIC_PATH_AD_GROUPS}.locations`),
        interests: getValues(`${BASIC_PATH_AD_GROUPS}.interests`),
        behaviors: getValues(`${BASIC_PATH_AD_GROUPS}.behaviors`),
        demographics: getValues(`${BASIC_PATH_AD_GROUPS}.demographics`),
        language: getValues(`language`),
        type,
      })
    );

    if (response) {
      onChangeText(`${BASIC_PATH}.${type}.[${index}].value`, response);
    }
  };

  const onAddText = async ({ type }) => {
    const currentTexts = getValues(`${BASIC_PATH}.${type}`) || [];
    setValue(`${BASIC_PATH}.${type}`, [
      ...currentTexts,
      {
        value: "",
        id: getUniqueID(),
      },
    ]);
  };
  const onRemoveText = async ({ type, index }) => {
    const currentTexts = getValues(`${BASIC_PATH}.${type}`);
    setValue(
      `${BASIC_PATH}.${type}`,
      currentTexts.filter((_, i) => i !== index)
    );
  };

  const onChangeSwitch = async (value) => {
    setIsSaving(true);
    await onChangeStatusCampaign({
      newStatus: value
        ? ADS_CAMPAIGN_STATUS_ACTIVE
        : ADS_CAMPAIGN_STATUS_DEACTIVATED,
      target: "ad",
      platform,
      adGroupID: indexAdGroup,
      adID: indexAd,
    });
    setIsSaving(false);
  };

  const gridItems = {
    xs: 12,
    lg: 6,
  };

  const onChangeText = (name, value) => {
    setValue(name, value);
    if (refPreview?.current) {
      refPreview.current.generatePreview();
    }
  };

  return (
    <Accordion
      id={data.id}
      title={`${t(AD)} ${t(NUMBER_ITEM, { value: indexAd + 1 })}`}
      variantTitle={"body1"}
      initialValue={true}
      sx={{
        border: `${theme.palette.border.grey} 1px solid`,
        borderRadius: `${4 * 4}px !important`,
      }}
      actionsComponent={
        isGeneralDisabledFields ? null : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              flexGrow: 1,
              mr: { xs: 0, sm: 2 },
              pl: { xs: 2, sm: 0 },
            }}
          >
            {showActionsPlatform && (
              <Controller
                name={`${BASIC_PATH}.status`}
                control={control}
                render={({ field }) => (
                  <Switch
                    loading={isSaving}
                    isActive={field.value === ADS_CAMPAIGN_STATUS_ACTIVE}
                    labelActive={ACTIVE}
                    labelDeactivate={DEACTIVATED}
                    onChangeIsActive={(value) => onChangeSwitch(value)}
                  />
                )}
              />
            )}
            <IconButton
              type="button"
              onClick={(e) => {
                e?.stopPropagation();
                onAddAd({ adGroupID: data?.adGroupID, adID: data?.id });
              }}
            >
              <ContentCopyIcon />
            </IconButton>
            {!hasOnlyOneAd && (
              <IconButton
                onClick={(e) => {
                  e?.stopPropagation();
                  onRemoveAd({ adGroupID: data?.adGroupID, adID: data?.id });
                }}
              >
                <DeleteIcon />
              </IconButton>
            )}
          </Box>
        )
      }
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: breakColumnFormAndPreview ? "column" : "row",
          gap: 5,
        }}
      >
        <Grid
          container
          spacing={2}
          sx={{
            height: "min-content",
          }}
        >
          <Grid
            item
            {...gridItems}
            lg={isSpecialAdWithOnlyTitles ? 12 : 6}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <Typography variant="body1">
              {t(platform === META ? TITLES : TITLE)}
            </Typography>
            {titles?.map((title, index) => (
              <TextFieldWithActions
                key={title.id}
                type="titles"
                label={`${t(TITLE_PLACEHOLDER)} ${index + 1}`}
                name={`${BASIC_PATH}.titles.[${index}].value`}
                disabled={
                  isCampaignPlatformActivated || isGeneralDisabledFields
                }
                showAddIcon={index === titles.length - 1}
                showDeleteIcon={blockMultipleTexts ? index > 1 : index !== 0}
                platform={platform}
                onChange={onChangeText}
                onAdd={() => onAddText({ type: "titles" })}
                onRemove={() => onRemoveText({ type: "titles", index })}
                onGenerate={async () => {
                  await onGenerateText({ type: "titles", index });
                }}
                control={control}
                errors={errors}
                rules={{
                  required: {
                    value: true,
                    message: t(FIELD_REQUIRED),
                  },
                  validate: (value) => {
                    if (value.length > maxLengthTitle) {
                      return t(FIELD_MAX_LENGTH, {
                        value: maxLengthTitle,
                      });
                    }
                  },
                }}
                sx={{
                  ".MuiInputBase-root": {
                    pb: 4,
                  },
                }}
              />
            ))}
          </Grid>
          {platform === META && !isSpecialAdWithOnlyTitles && (
            <Grid
              item
              {...gridItems}
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
              }}
            >
              <Typography variant="body1">{t(DESCRIPTIONS)}</Typography>
              {descriptions?.map((description, index) => (
                <TextFieldWithActions
                  key={description.id}
                  type="descriptions"
                  label={`${t(DESCRIPTION_PLACEHOLDER)} ${index + 1}`}
                  name={`${BASIC_PATH}.descriptions.[${index}].value`}
                  disabled={
                    isCampaignPlatformActivated || isGeneralDisabledFields
                  }
                  platform={platform}
                  showAddIcon={index === descriptions.length - 1}
                  showDeleteIcon={blockMultipleTexts ? index > 1 : index !== 0}
                  onChange={onChangeText}
                  onAdd={() => onAddText({ type: "descriptions" })}
                  onRemove={() => onRemoveText({ type: "descriptions", index })}
                  onGenerate={async () => {
                    await onGenerateText({ type: "descriptions", index });
                  }}
                  control={control}
                  errors={errors}
                  rules={{
                    required: {
                      value: true,
                      message: t(FIELD_REQUIRED),
                    },
                    validate: (value) => {
                      if (value.length > maxLengthDescription) {
                        return t(FIELD_MAX_LENGTH, {
                          value: maxLengthDescription,
                        });
                      }
                    },
                  }}
                  sx={{
                    ".MuiInputBase-root": {
                      pb: 4,
                    },
                  }}
                />
              ))}
            </Grid>
          )}
          {platform === META && !isSpecialAdWithOnlyTitles && (
            <Grid
              item
              {...gridItems}
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
              }}
            >
              <Typography variant="body1">{t(HEADLINES)}</Typography>
              {headlines?.map((headline, index) => (
                <TextFieldWithActions
                  key={headline.id}
                  type="headlines"
                  label={`${t(HEADLINE_PLACEHOLDER)} ${index + 1}`}
                  name={`${BASIC_PATH}.headlines.[${index}].value`}
                  disabled={
                    isCampaignPlatformActivated || isGeneralDisabledFields
                  }
                  platform={platform}
                  showAddIcon={index === headlines.length - 1}
                  showDeleteIcon={blockMultipleTexts ? index > 1 : index !== 0}
                  onChange={onChangeText}
                  onAdd={() => onAddText({ type: "headlines" })}
                  onRemove={() => onRemoveText({ type: "headlines", index })}
                  onGenerate={async () => {
                    await onGenerateText({ type: "headlines", index });
                  }}
                  control={control}
                  errors={errors}
                  rules={{
                    required: {
                      value: true,
                      message: t(FIELD_REQUIRED),
                    },
                    validate: (value) => {
                      if (value.length > maxLengthHeadline) {
                        return t(FIELD_MAX_LENGTH, {
                          value: maxLengthHeadline,
                        });
                      }
                    },
                  }}
                  sx={{
                    ".MuiInputBase-root": {
                      pb: 4,
                    },
                  }}
                />
              ))}
            </Grid>
          )}
          {((platform === META && !isSpecialAdWithoutCallToAction) ||
            (platform === TIKTOK &&
              objective !== ADS_CAMPAIGN_OBJECTIVE_ENGAGEMENT_OPTION)) && (
            <Grid item {...gridItems}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                <Typography variant="body1">
                  {t(CALL_TO_ACTION_PLACEHOLDER)}
                </Typography>
                <SelectCallToActionAds
                  disabled={
                    isCampaignPlatformActivated || isGeneralDisabledFields
                  }
                  name={`${BASIC_PATH}.callToAction`}
                  fullWidth
                  objective={objective}
                  platform={platform}
                  destinationType={platformData?.destinationType}
                  doTranslate={true}
                  control={control}
                  errors={errors}
                  rules={{
                    required: {
                      value: true,
                      message: t(FIELD_REQUIRED),
                    },
                  }}
                />
              </Box>
            </Grid>
          )}
        </Grid>
        <AdPreview
          ref={refPreview}
          adID={data.id}
          mediaUrl={mediaUrl}
          mediaData={mediaData}
          callToAction={callToAction}
          titles={titles}
          headlines={headlines}
          descriptions={descriptions}
          platform={platform}
          basicPath={BASIC_PATH}
          basicPathAdGroup={BASIC_PATH_AD_GROUP}
        />
      </Box>
      {modalAdPreviewOpen && (
        <ModalAdPreview
          modalOpen={modalAdPreviewOpen}
          onCloseModal={() => setModalAdPreviewOpen(false)}
          adID={data.id}
          platform={platform}
          mediaUrl={mediaUrl}
          mediaData={mediaData}
          callToAction={callToAction}
          titles={titles}
          headlines={headlines}
          descriptions={descriptions}
          basicPath={BASIC_PATH}
          basicPathAdGroup={BASIC_PATH_AD_GROUP}
        />
      )}
    </Accordion>
  );
};

const TextFieldWithActions = ({
  name,
  label,
  disabled,
  control,
  errors,
  rules,
  sx,
  showAddIcon = true,
  showDeleteIcon = true,
  onChange,
  onAdd = () => {},
  onRemove = () => {},
  onGenerate = () => {},
  type = "titles",
  platform,
}) => {
  const [isGenerating, setIsGenerating] = useState(false);

  const rows = type === "headlines" ? 1 : type === "descriptions" ? 3 : 3;

  const error = getReactHookFormNestedError(errors, name);

  const isMultiple = platform === META;
  const isDisabled = disabled || isGenerating;

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        position: "relative",
      }}
    >
      <TextField
        label={label}
        name={name}
        variant="filled"
        multiline
        minRows={rows}
        maxRows={rows}
        fullWidth
        disabled={disabled}
        doOnChange={false}
        actionsChange={(value) => onChange(name, value)}
        control={control}
        errors={errors}
        rules={rules}
        sx={sx}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-end",
          position: "absolute",
          mb: 1,
          mr: 1,
          right: 0,
          height: "100%",
          mt: error ? -5 : 0,
        }}
      >
        {showDeleteIcon && isMultiple && (
          <IconButton
            disabled={isDisabled}
            size={"small"}
            onClick={() => onRemove()}
            sx={{
              p: 0.4,
            }}
          >
            <DeleteIcon />
          </IconButton>
        )}
        {isGenerating ? (
          <Loader
            size={20}
            hasMarginTop={false}
            sx={{
              mb: 0.75,
            }}
          />
        ) : (
          <IconButton
            disabled={isDisabled}
            size={"small"}
            onClick={async () => {
              setIsGenerating(true);
              await onGenerate();
              setIsGenerating(false);
            }}
            sx={{
              p: 0.4,
            }}
          >
            <AutoAwesomeIcon />
          </IconButton>
        )}
        {showAddIcon && isMultiple && (
          <IconButton
            disabled={isDisabled}
            size={"small"}
            onClick={() => onAdd()}
            sx={{
              p: 0.4,
            }}
          >
            <AddIcon />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};
export default AdsAdForm;
