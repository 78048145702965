import { useEffect } from "react";
import { useState } from "react";
import { useDispatchApp, useSelectorApp } from "../../lib/redux";
import { useTranslationApp } from "../../lib/i18next";
import Box from "@mui/material/Box";
import {
  BILLING_AND_PAYMENTS_DESCRIPTION,
  BILLING_AND_PAYMENTS_TITLE,
} from "../../i18n/keysTranslations";
import TitlePage from "../../components/Texts/TitlePage";
import DescriptionPage from "../../components/Texts/DescriptionPage";
import SubscriptionInformation from "./components/SubscriptionInformation";
import CreditCard from "./components/CreditCard";
import InvoicesTable from "./components/InvoicesTable";
import { startGetPayments } from "../../actions/payments";
import ModalInvoice from "./components/ModalInvoice";
import ModalPayInvoice from "./components/ModalPayInvoice";
import { getPathDatabaseByRole } from "../../actions/auth";
import { useNavigate } from "react-router-dom";
import { ROLES_WITH_BILLING } from "../../utils/constants";

const BillingAndPayments = () => {
  const [dataInvoices, setDataInvoices] = useState({});
  const [invoiceSelected, setInvoiceSelected] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [modalPayInvoiceOpen, setModalPayInvoiceOpen] = useState(false);
  const [modalInvoiceOpen, setModalInvoiceOpen] = useState(false);

  const dispatch = useDispatchApp();
  const { payments } = useSelectorApp((state) => state.payments);
  const { userID, role } = dispatch(getPathDatabaseByRole());
  const navigate = useNavigate();

  const { t } = useTranslationApp();

  const getInitialData = () => {
    if (!ROLES_WITH_BILLING.includes(role)) {
      navigate("/");
      return;
    }
    setIsLoading(true);
    setDataInvoices({});
    dispatch(
      startGetPayments({
        onCallbackFinish: () => setIsLoading(false),
      })
    );
  };

  useEffect(() => {
    getInitialData();
    // eslint-disable-next-line
  }, [userID]);

  useEffect(() => {
    if (isLoading) return;

    let filteredData = {};
    if (Object.keys(payments).length > 0) {
      Object.keys(payments).map((key) => {
        return (filteredData[key] = {
          ...payments[key],
          paidDate: payments[key].paidDate
            ? new Date(payments[key].paidDate)
            : "",
          dueDate: new Date(payments[key].dueDate),
        });
      });
    }

    setDataInvoices(filteredData);
  }, [payments, isLoading]);

  const onChangeStatusModalInvoice = (status, invoice = null) => {
    setModalInvoiceOpen(status);
    setInvoiceSelected(invoice);
  };

  const onChangeStatusModalPayInvoice = (status, invoice = null) => {
    setModalPayInvoiceOpen(status);
    setInvoiceSelected(invoice);
  };

  const onPayInvoice = async (invoiceID) => {
    onChangeStatusModalPayInvoice(true, invoiceID);
  };
  return (
    <Box
      sx={{
        mx: {
          md: 4,
          xs: 3,
        },
        mt: 3,
        mb: 3,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        maxWidth: {
          xs: "100%",
          md: "calc(100vw - 289px)",
        },
      }}
    >
      <TitlePage>{t(BILLING_AND_PAYMENTS_TITLE)}</TitlePage>
      <DescriptionPage>{t(BILLING_AND_PAYMENTS_DESCRIPTION)}</DescriptionPage>
      <Box
        sx={{
          width: "100%",
          mb: 3,
          display: "flex",
          gap: 3,
          flexDirection: {
            xs: "column",
            lg: "row",
          },
          justifyContent: "space-between",
        }}
      >
        <SubscriptionInformation />
        <CreditCard />
      </Box>
      <Box
        sx={{
          width: "100%",
        }}
      >
        <InvoicesTable
          data={dataInvoices}
          onSelectInvoice={(invoice) =>
            onChangeStatusModalInvoice(true, invoice)
          }
          onPayInvoice={onPayInvoice}
        />
      </Box>

      {modalInvoiceOpen && (
        <ModalInvoice
          data={dataInvoices[invoiceSelected]}
          invoice={invoiceSelected}
          modalOpen={modalInvoiceOpen}
          onCloseModal={() => onChangeStatusModalInvoice(false)}
        />
      )}
      {modalPayInvoiceOpen && (
        <ModalPayInvoice
          data={dataInvoices[invoiceSelected]}
          modalOpen={modalPayInvoiceOpen}
          invoiceID={invoiceSelected}
          onCloseModal={() => onChangeStatusModalPayInvoice(false)}
        />
      )}
    </Box>
  );
};

export default BillingAndPayments;
