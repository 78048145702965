import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  BUTTON_UNDERSTOOD,
  BUTTON_USE_THIS_CONTENT,
  INFORMATION,
  MODAL_CANVA_ARIA_LABEL,
  MODAL_CANVA_CREATING_DESIGN,
  MODAL_CANVA_DESCRIPTION,
  MODAL_CANVA_DESIGN_NOT_CREATED,
  MODAL_CANVA_INFORMATION_SAVE_DESIGN,
  MODAL_CANVA_IS_EDITING_IN_CANVA,
  MODAL_CANVA_IS_GETTING_YOUR_DESIGN,
  MODAL_CANVA_TITLE,
} from "../../i18n/keysTranslations";
import Typography from "@mui/material/Typography";
import _ from "lodash";
import DialogActions from "@mui/material/DialogActions";
import Skeleton from "@mui/material/Skeleton";
import { useTranslationApp } from "../../lib/i18next";
import { useDispatchApp } from "../../lib/redux";
import {
  startCreateCanvaDesign,
  startExportCanvaDesign,
} from "../../actions/user";
import { ConfirmAlert } from "../Alerts/Alerts";
import ModalBasicLayout from "./ModalBasicLayout";
import Loader from "../Loaders/Loader";
import CardMedia from "../Media/CardMedia";
import ContainerModalActions from "../Containers/ContainerModalActions";
import { getNameBusinessTheme } from "../../actions/getters";

const ModalCanva = ({
  modalOpen,
  onCloseModal = () => {},
  onCallbackSave = () => {},
}) => {
  const [design, setDesign] = useState({});
  const [hasError, setHasError] = useState(false);
  const [isCreatingDesign, setIsCreatingDesign] = useState(true);
  const [isGettingDesignMedia, setIsGettingDesignMedia] = useState(false);

  const designID = design?.id;
  const editUrl = design?.urls?.edit_url;
  const { t } = useTranslationApp();
  const dispatch = useDispatchApp();
  const NAME_BUSINESS = dispatch(getNameBusinessTheme());

  const getInitialData = async () => {
    const response = await dispatch(startCreateCanvaDesign());
    if (response) {
      setDesign(response);
    } else {
      setHasError(true);
    }
    setIsCreatingDesign(false);
  };

  const onGetDesignUpdated = async () => {
    setIsGettingDesignMedia(true);
    const response = await dispatch(
      startExportCanvaDesign({
        designID,
      })
    );
    if (response) {
      setDesign((prevDesign) => ({
        ...prevDesign,
        mediaUrl: response?.[0],
      }));
    } else {
      setHasError(true);
    }
    setIsGettingDesignMedia(false);
  };

  useEffect(() => {
    if (hasError) return;
    if (!editUrl) return;
    if (design.mediaUrl) return;
    if (isGettingDesignMedia) return;

    let popup;

    ConfirmAlert({
      title: t(INFORMATION),
      text: t(MODAL_CANVA_INFORMATION_SAVE_DESIGN),
      confirmButtonText: t(BUTTON_UNDERSTOOD),
      showCancelButton: false,
    }).then(() => {
      popup = window.open(
        editUrl,
        design.title,
        `width=${window.innerWidth},height=${window.innerHeight}`
      );
    });

    const interval = setInterval(() => {
      if (popup?.closed) {
        clearInterval(interval);
        onGetDesignUpdated();
      }
    }, 500);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, [editUrl, design, onCallbackSave]);

  useEffect(() => {
    getInitialData();
    // eslint-disable-next-line
  }, []);

  const showError = hasError;
  const showIsEditing =
    !hasError && !isCreatingDesign && !_.isEmpty(design) && !design.mediaUrl;
  const showMediaUrl =
    !hasError && !isCreatingDesign && !isGettingDesignMedia && design.mediaUrl;

  return (
    <ModalBasicLayout
      modalOpen={modalOpen}
      onCloseModal={async () => {
        onCloseModal();
      }}
      fullScreen={true}
      scroll="paper"
      ariaLabel={t(MODAL_CANVA_ARIA_LABEL)}
      title={t(MODAL_CANVA_TITLE)}
      description={t(MODAL_CANVA_DESCRIPTION, {
        NAME_BUSINESS,
      })}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: isCreatingDesign ? "flex-start" : "center",
          width: "100%",
          height: "100%",
          maxHeight: {
            xs: "calc(100% - 96px)",
            sm: "calc(100% - 80px)",
          },
        }}
      >
        {isCreatingDesign && (
          <Loader
            hasMessage={true}
            message={t(MODAL_CANVA_CREATING_DESIGN)}
            sx={{
              height: "min-content",
            }}
          />
        )}
        {showError && (
          <Typography variant="h6" textAlign="center">
            {t(MODAL_CANVA_DESIGN_NOT_CREATED)}
          </Typography>
        )}
        {showIsEditing && (
          <>
            <Typography
              variant="h6"
              sx={{
                position: "absolute",
              }}
            >
              {t(
                isGettingDesignMedia
                  ? MODAL_CANVA_IS_GETTING_YOUR_DESIGN
                  : MODAL_CANVA_IS_EDITING_IN_CANVA
              )}
            </Typography>
            <Skeleton
              variant="rectangular"
              width="100%"
              height="100%"
            ></Skeleton>
          </>
        )}
        {showMediaUrl && (
          <CardMedia
            url={design.mediaUrl}
            sx={{
              height: "100%",
              width: "auto",
              maxWidth: "100%",
            }}
          />
        )}
      </Box>
      {showMediaUrl && (
        <DialogActions sx={{ p: 0, mt: 1 }}>
          <ContainerModalActions
            propsPrimaryButton={{
              showButton: true,
              text: BUTTON_USE_THIS_CONTENT,
              onClick: async () => {
                await onCallbackSave(design.mediaUrl);
                onCloseModal();
              },
            }}
          />
        </DialogActions>
      )}
    </ModalBasicLayout>
  );
};

export default ModalCanva;
