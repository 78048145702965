import Grid from "@mui/material/Grid";
import PieChart from "../../../components/Charts/PieChart";
import { useTranslationApp } from "../../../lib/i18next";
import {
  DATE_DAY_AND_MONTH_SHORT,
  RESULTS,
} from "../../../i18n/keysTranslations";
import _ from "lodash";
import {
  getFromStatisticsAdsCampaign,
  mapTitleStatisticsByMetric,
} from "../../../services/adsCampaigns";

const MAP_TITLE_KEY = {
  impressions: "impressionsByPlatform",
  click: "clicksByPlatform",
  mainAction: "mainActionByPlatform",
};

const GlobalPlatformsStatisticsCampaign = ({ data, report }) => {
  const { statistics, from } = getFromStatisticsAdsCampaign(data);

  const { t } = useTranslationApp();

  const hasMoreOnePlatform = Object.keys(statistics).length >= 2;

  const getChartConfigAndDataByMetric = (metric) => {
    const isMainActionMetric = metric === "mainAction";

    const startDateTime = new Date(data?.firstActiveTime);
    const endDateTime = new Date();

    let chartConfig = {
      value: {
        label: t(isMainActionMetric ? RESULTS : metric),
      },
    };
    let chartData = [];

    const platforms = Object.keys(statistics);
    platforms.forEach((platform, index) => {
      const value =
        from === "current"
          ? statistics?.[platform]?.[metric]
          : statistics?.[platform]?.campaign?.[metric];

      if (value === "" || value === null || value === undefined) return;

      chartConfig[platform] = {
        label: t(platform),
      };

      chartData.push({
        label: platform,
        value,
        fill: `hsl(var(--chart-${index + 1}))`,
      });
    });

    const title = mapTitleStatisticsByMetric({ metric: MAP_TITLE_KEY[metric] });
    const subtitle = `${t(DATE_DAY_AND_MONTH_SHORT, {
      date: startDateTime,
    })} - ${t(DATE_DAY_AND_MONTH_SHORT, { date: endDateTime })}`;

    return {
      chartConfig,
      chartData,
      title,
      subtitle,
    };
  };

  if (!hasMoreOnePlatform || _.isEmpty(statistics)) return null;

  const chartImpressions = getChartConfigAndDataByMetric("impressions");
  const chartClicks = getChartConfigAndDataByMetric("click");
  const chartResults = getChartConfigAndDataByMetric("mainAction");

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} lg={4}>
        <PieChart
          chartConfig={chartImpressions.chartConfig}
          chartData={chartImpressions.chartData}
          hasCartesianGrid={false}
          labelKey="label"
          valueKey="value"
          title={chartImpressions.title}
          subtitle={chartImpressions.subtitle}
          description={report?.comparisonPlatforms?.impressions}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <PieChart
          chartConfig={chartClicks.chartConfig}
          chartData={chartClicks.chartData}
          hasCartesianGrid={false}
          labelKey="label"
          valueKey="value"
          title={chartClicks.title}
          subtitle={chartClicks.subtitle}
          description={report?.comparisonPlatforms?.clicks}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <PieChart
          chartConfig={chartResults.chartConfig}
          chartData={chartResults.chartData}
          hasCartesianGrid={false}
          labelKey="label"
          valueKey="value"
          title={chartResults.title}
          subtitle={chartResults.subtitle}
          description={report?.comparisonPlatforms?.results}
        />
      </Grid>
    </Grid>
  );
};

export default GlobalPlatformsStatisticsCampaign;
