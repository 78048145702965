import { deleteObject, ref } from "firebase/storage";
import { db, storage } from "../lib/firebase";
import { push, ref as refDB, remove, set } from "firebase/database";
import { renewToken } from "../actions/auth";
import axios from "../lib/axios";
import { LaunchError } from "../utils/errors";

const url = import.meta.env.VITE_CLOUD_FUNCTIONS_URL;

export const getKeyDatabase = () => () => push(refDB(db, "/")).key;

export const removeImageStorageAndDB =
  (uid, pathStorage, pathRTDB, deleteRTDB) => async () => {
    try {
      const storageRef = ref(storage, `${pathStorage}${uid}`);
      await deleteObject(storageRef);
      if (pathRTDB && deleteRTDB) {
        const dbRef = refDB(db, pathRTDB);
        await remove(dbRef);
      }
      return true;
    } catch (error) {
      console.log(error);
    }
  };

export const saveUrl = (url, pathRTDB) => async () => {
  try {
    await set(refDB(db, pathRTDB), url);
  } catch (error) {
    console.log(error);
  }
};
export const startUploadFileAws =
  (file, mime, path, withAuth = true) =>
  async (dispatch) => {
    try {
      let token;
      if (withAuth) {
        token = await dispatch(renewToken());
      }
      const { data } = await axios({
        method: "post",
        data: { type: mime, path },
        url: `${url}/${
          withAuth ? "generateAwsUploadUrl" : "generateAwsUploadUrlWithoutAuth"
        }`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.ok) {
        await axios({
          method: "put",
          data: file,
          url: data.url,
          headers: {
            "Content-Type": mime,
          },
        });
        return data.url.split("?")[0];
      } else {
        return false;
      }
    } catch (error) {
      dispatch(LaunchError(error));
      return false;
    }
  };
export const startDeleteFileAws =
  (id, pathStorage, pathRTDB, deleteRTDB) => async (dispatch) => {
    try {
      const token = await dispatch(renewToken());
      const { data } = await axios({
        method: "post",
        data: { id, path: pathStorage },
        url: `${url}/deleteFileAws`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.ok) {
        if (pathRTDB && deleteRTDB) {
          const dbRef = refDB(db, pathRTDB);
          await remove(dbRef);
        }
        return true;
      } else {
        return false;
      }
    } catch (error) {
      dispatch(LaunchError(error));
      return false;
    }
  };
