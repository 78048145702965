import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  BUTTON_GENERATE_WITH_AI,
  BUTTON_UPLOAD_CONTENT,
  BUTTON_UPLOAD_CONTENT_FROM_ADS_PLATFORMS,
  MODAL_MAIN_SOCIAL_MEDIA_CONTENT_GALLERY_FROM_COLLABORATIONS_OPTION,
  MODAL_MAIN_SOCIAL_MEDIA_CONTENT_GALLERY_UPLOAD_OPTION,
} from "../../i18n/keysTranslations";
import { IMAGE_FORMATS, VIDEO_FORMATS } from "../../utils/constants";
import { useDispatchApp } from "../../lib/redux";
import { useTranslationApp } from "../../lib/i18next";
import { getNameBusinessTheme } from "../../actions/getters";
import Loader from "../Loaders/Loader";
import Button from "./Button";
import ModalGallery from "../Modal/ModalGallery";
import ModalCanva from "../Modal/ModalCanva";
import ModalGalleryAdsPlatforms from "../Modal/ModalGalleryAdsPlatforms";
import UploadFileButton from "./UploadFileButton";
import {
  startAddContentsToList,
  startGetGalleryContent,
} from "../../actions/user";
import _ from "lodash";
import ModalGenerateVideoPreviewCreatify from "../Modal/ModalGenerateVideoPreviewCreatify";

const IconButtonAddContent = ({
  onAddContent: onAddContentCallback = () => {},
  onCallbackSaveCanva = () => {},
  onCallbackSaveAI = () => {},
  pathStorage,
  pathRTDB,
  modeUpload = "sync",
  mode = "icon",
  multiple = false,
  textButton = BUTTON_UPLOAD_CONTENT,
  acceptedFormats = IMAGE_FORMATS.concat(VIDEO_FORMATS),
  sx = {},
  showButtonUpload = true,
  urlContent = "",
  size = "medium",
  color = "secondary",
  sxButton = {},
  showUploadDeviceOption = true,
  showGalleryOption = true,
  showGalleryAdsPlatformsOption = true,
  propsGalleryAdsPlatforms = {},
  showGenerateWithAIOption = true,
  propsGenerateAI = {},
  id = "add-content-media",
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasContentGallery, setHasContentGallery] = useState(false);
  const [modalGalleryOpen, setModalGalleryOpen] = useState(false);
  const [modalGalleryAdsPlatformOpen, setModalGalleryAdsPlatformOpen] =
    useState(false);
  const [modalCanvaOpen, setModalCanvaOpen] = useState(false);
  const [modalGeneratingVideoPreviewOpen, setModalGeneratingVideoPreviewOpen] =
    useState(false);

  const dispatch = useDispatchApp();
  const { t } = useTranslationApp();

  const open = Boolean(anchorEl);
  const onSelectMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const NAME_BUSINESS = dispatch(getNameBusinessTheme());

  const onAddContent = async (newMedia) => {
    if (modeUpload === "sync") {
      dispatch(
        startAddContentsToList({
          url: newMedia,
          type: "upload",
        })
      );
    }
    await onAddContentCallback([
      {
        url: newMedia,
        from: "upload",
      },
    ]);
  };

  const onAddContentFromCollaborations = async (contents) => {
    setIsLoading(true);
    const contentsFormatted = contents.map((content) => ({
      url: content.url,
      from: content.from,
      id: content.id,
    }));

    await onAddContentCallback(contentsFormatted);

    setIsLoading(false);
  };

  const getFilterModalGallery = () => {
    let hasVideoFormat = "";
    let hasImageFormat = "";

    acceptedFormats.forEach((format) => {
      if (VIDEO_FORMATS.includes(format)) {
        hasVideoFormat = true;
      }
      if (IMAGE_FORMATS.includes(format)) {
        hasImageFormat = true;
      }
    });

    if (hasVideoFormat && hasImageFormat) {
      return null;
    }

    if (hasVideoFormat) {
      return "video";
    }

    if (hasImageFormat) {
      return "image";
    }
  };

  const verifyOptions = async () => {
    const responseGallery = await dispatch(
      startGetGalleryContent({
        limit: 10,
      })
    );

    if (!_.isEmpty(responseGallery)) {
      setHasContentGallery(true);
    }
  };

  useEffect(() => {
    verifyOptions();
    // eslint-disable-next-line
  }, []);

  const filterModalGallery = getFilterModalGallery();

  return (
    <Box
      sx={{
        height: mode === "icon" ? 92 : "auto",
        width: mode === "icon" ? 92 : "auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        ...sx,
      }}
    >
      <UploadFileButton
        id={id}
        value={urlContent}
        onChange={onAddContent}
        acceptedFormats={acceptedFormats}
        multiple={multiple}
        pathStorage={pathStorage}
        pathRTDB={pathRTDB}
        modeUpload={modeUpload}
        onCallbackLoading={(loading) => {
          if (modeUpload === "sync") {
            setIsLoading(loading);
          }
        }}
        isHidden={true}
      />
      {isLoading && (
        <Loader colorCustom="white" hasMarginTop={false} fullWidth={true} />
      )}
      {!isLoading && showButtonUpload && (
        <>
          {mode === "icon" ? (
            <IconButton sx={{ p: 0 }} onClick={onSelectMenu} type="button">
              <AddCircleIcon
                sx={{ color: "white !important", height: 40, width: 40 }}
              />
            </IconButton>
          ) : (
            <Button
              size={size}
              variant="contained"
              color={color}
              onClick={onSelectMenu}
              type="button"
              sx={sxButton}
            >
              {t(textButton)}
            </Button>
          )}
        </>
      )}
      <Menu anchorEl={anchorEl} open={open} onClose={() => setAnchorEl(null)}>
        {showGenerateWithAIOption && (
          <MenuItem
            onClick={() => {
              setModalGeneratingVideoPreviewOpen(true);
              setAnchorEl(null);
            }}
          >
            {t(BUTTON_GENERATE_WITH_AI)}
          </MenuItem>
        )}
        {showUploadDeviceOption && (
          <MenuItem
            onClick={() => {
              document.getElementById(id).click();
              setAnchorEl(null);
            }}
          >
            {t(MODAL_MAIN_SOCIAL_MEDIA_CONTENT_GALLERY_UPLOAD_OPTION)}
          </MenuItem>
        )}
        {hasContentGallery && showGalleryOption && (
          <MenuItem
            onClick={() => {
              setModalGalleryOpen(true);
              setAnchorEl(null);
            }}
          >
            {t(
              MODAL_MAIN_SOCIAL_MEDIA_CONTENT_GALLERY_FROM_COLLABORATIONS_OPTION,
              {
                NAME_BUSINESS,
              }
            )}
          </MenuItem>
        )}
        {showGalleryAdsPlatformsOption &&
          propsGalleryAdsPlatforms?.integrationID &&
          propsGalleryAdsPlatforms?.adAccountID && (
            <MenuItem
              onClick={() => {
                setModalGalleryAdsPlatformOpen(true);
                setAnchorEl(null);
              }}
            >
              {t(BUTTON_UPLOAD_CONTENT_FROM_ADS_PLATFORMS)}
            </MenuItem>
          )}
        {/* <MenuItem>
          <ButtonCanvaAction
            sx={{
              justifyContent: "flex-start",
              background: "unset",
              backgroundColor: "transparent !important",
              p: 0,
              fontSize: "1rem",
              fontWeight: 400,
              borderRadius: 0,
              "&:hover": {
                background: "unset",
                backgroundColor: "transparent !important",
              },
              ".MuiLoadingButton-loadingIndicator": {
                color: "rgba(255, 255, 255, 0.54)",
              },
            }}
            onClick={() => {
              setModalCanvaOpen(true);
              setAnchorEl(null);
            }}
            text={BUTTON_CREATE_WITH_CANVA}
          />
        </MenuItem> */}
      </Menu>
      {modalGalleryOpen && (
        <ModalGallery
          filterByFormat={filterModalGallery}
          acceptedFormats={acceptedFormats}
          multiple={multiple}
          modalOpen={modalGalleryOpen}
          onCloseModal={() => setModalGalleryOpen(false)}
          onCallbackSelectedItems={onAddContentFromCollaborations}
        />
      )}
      {modalGalleryAdsPlatformOpen && (
        <ModalGalleryAdsPlatforms
          filterByFormat={filterModalGallery}
          acceptedFormats={acceptedFormats}
          multiple={multiple}
          modalOpen={modalGalleryAdsPlatformOpen}
          onCloseModal={() => setModalGalleryAdsPlatformOpen(false)}
          onCallbackSelectedItems={onAddContentFromCollaborations}
          {...propsGalleryAdsPlatforms}
        />
      )}
      {modalCanvaOpen && (
        <ModalCanva
          modalOpen={modalCanvaOpen}
          onCloseModal={() => setModalCanvaOpen(false)}
          onCallbackSave={onCallbackSaveCanva}
        />
      )}
      {modalGeneratingVideoPreviewOpen && (
        <ModalGenerateVideoPreviewCreatify
          modalOpen={modalGeneratingVideoPreviewOpen}
          onCloseModal={() => setModalGeneratingVideoPreviewOpen(false)}
          onCallbackSave={onCallbackSaveAI}
          {...propsGenerateAI}
        />
      )}
    </Box>
  );
};

export default IconButtonAddContent;
