import i18next from "i18next";
import { getObjectError } from "../utils/string";
import { SimpleAlert } from "../components/Alerts/Alerts";
import { ERROR, ERROR_DESCRIPTION_GENERIC } from "../i18n/keysTranslations";
import { ALERT_ICON_TYPE_ERROR } from "../utils/constants";

export const onInit3DSecureAuth = async ({
  clientSecret,
  stripe,
  onCallbackFailed = () => {},
}) => {
  const { error, paymentIntent } = await stripe.confirmCardPayment(
    clientSecret
  );
  if (error) {
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    onCallbackFailed();
    return { ok: false };
  }
  if (paymentIntent.status === "succeeded") {
    return { ok: true, paymentIntent: paymentIntent.id };
  }
};
