import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import { CPA_CLARIFICATION } from "../../i18n/keysTranslations";
import Typography from "@mui/material/Typography";
import { useTranslationApp } from "../../lib/i18next";
import { MAP_MAIN_ACTION_LABELS } from "../../utils/constants";

const TooltipMainStatisticAdsCampaign = ({ children, statistics = {} }) => {
  const { t } = useTranslationApp();
  const platforms = Object.keys(statistics);

  return (
    <Tooltip
      componentsProps={{
        tooltip: {
          sx: {
            p: 0,
            borderRadius: 3,
            mt: "8px !important",
            backgroundColor: "#202020",
          },
        },
      }}
      title={
        <Box
          sx={{
            p: 2,
          }}
        >
          <Typography
            variant="body2"
            gutterBottom
            sx={{
              mb: 2,
            }}
          >
            {t(CPA_CLARIFICATION)}
          </Typography>
          {platforms.map((platform) => {
            const statisticPlatform = statistics[platform];
            const MAIN_ACTION_TRANSLATION =
              MAP_MAIN_ACTION_LABELS[statisticPlatform.mainActionLabel];

            if (!MAIN_ACTION_TRANSLATION) return null;

            return (
              <Typography variant="body2" gutterBottom key={platform}>
                {`${t(platform)}: ${t(MAIN_ACTION_TRANSLATION, {
                  value: statisticPlatform.mainAction,
                })}`}
              </Typography>
            );
          })}
        </Box>
      }
    >
      {children}
    </Tooltip>
  );
};

export default TooltipMainStatisticAdsCampaign;
