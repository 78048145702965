import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import Chip from "@mui/material/Chip";
import { useTranslationApp } from '../../../lib/i18next';
import { useMediaQuery } from '@mui/material';
import { PAYMENT_PAID, PAYMENT_PENDING } from '../../../i18n/keysTranslations';

const StatusInvoice = ({
    isPaid
}) => {
    const { t } = useTranslationApp();
    const matches = useMediaQuery("(min-width:1000px)");
    return (
      <Chip
        size={matches ? "medium" : "small"}
        sx={{
          backgroundColor: isPaid ? "background.paper" : "#ffe7c5",
          color: isPaid ? "background.paperContrastText" : "black",
  
          "& .MuiChip-icon": {
            color: isPaid ? "success.main" : "primary.main",
          },
        }}
        icon={isPaid ? <CheckCircleIcon /> : <RemoveCircleIcon />}
        label={isPaid ? t(PAYMENT_PAID) : t(PAYMENT_PENDING)}
      />
    );
}

export default StatusInvoice