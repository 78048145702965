import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Button from "./Button";

const PreviousButtonStep = ({
  onPreviousStep,
  children,
  sx = {},
  fullWidth = false,
}) => {
  return (
    <Button
      fullWidth={fullWidth}
      sx={{ mt: 2, ...sx }}
      color="primary"
      onClick={onPreviousStep}
      startIcon={<ArrowBackIcon />}
    >
      {children}
    </Button>
  );
};

export default PreviousButtonStep;
