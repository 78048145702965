import { useEffect, useState } from "react";
import ContainerPage from "../../components/Containers/ContainerPage";
import TitlePage from "../../components/Texts/TitlePage";
import { useTranslationApp } from "../../lib/i18next";
import { useDispatchApp, useSelectorApp } from "../../lib/redux";
import Loader from "../../components/Loaders/Loader";
import {
  ADS_CAMPAIGNS_TITLE,
  BUTTON_CREATE,
  LOADING_INFORMATION,
} from "../../i18n/keysTranslations";
import {
  ADS_CAMPAIGN_GENERATE_CAMPAIGN_ACTION,
  ADS_CAMPAIGN_STATUS_ACTIVE,
  ADS_CAMPAIGN_STATUS_DEACTIVATED,
} from "../../utils/constants";
import {
  startGetAdsCampaigns,
  startSaveAdsCampaign,
  startUpdateAdsCampaignsInformationFromPlatforms,
} from "../../actions/adsCampaigns";
import Box from "@mui/material/Box";
import _ from "lodash";
import AdsCampaignsIntro from "./components/AdsCampaignsIntro";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import AdsCampaignsList from "./components/AdsCampaignsList";
import {
  GRADIENT_PURPLE_FUCHSIA,
  GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT,
} from "../../utils/colors";
import Button from "../../components/Buttons/Button";
import { getIsBlockedActions, getUserIsViewer } from "../../actions/getters";
import {
  getCampaignUpdatedByStatus,
  getStatusAdsCampaign,
} from "../../services/adsCampaigns";
import { getFormattedCampaign } from "../../utils/string";
import { getPathDatabaseByRole } from "../../actions/auth";

const AdsCampaigns = () => {
  const [campaigns, setCampaigns] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdatedCampaignsActive, setIsUpdatedCampaigns] = useState(false);

  const { t } = useTranslationApp();
  const dispatch = useDispatchApp();
  const { adsCampaigns } = useSelectorApp((state) => state.adsCampaigns);
  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { userID } = dispatch(getPathDatabaseByRole());
  const isViewer = dispatch(getUserIsViewer());

  const onUpdateAdsCampaignInformationFromPlatforms = async (campaignIDs) => {
    const response = await dispatch(
      startUpdateAdsCampaignsInformationFromPlatforms({
        campaignIDs,
      })
    );
    return response;
  };

  const getInitialData = async () => {
    setCampaigns(null);
    setIsLoading(true);
    await dispatch(startGetAdsCampaigns());
    setIsLoading(false);
  };

  const onChangeStatusCampaign = async (campaignID) => {
    const campaign = campaigns[campaignID];
    const statusCampaign = getStatusAdsCampaign({
      states: campaign.states,
      target: "general",
    });
    const newStatus =
      statusCampaign === ADS_CAMPAIGN_STATUS_ACTIVE
        ? ADS_CAMPAIGN_STATUS_DEACTIVATED
        : ADS_CAMPAIGN_STATUS_ACTIVE;

    const campaignFormatted = getFormattedCampaign({
      data: campaign,
      format: "load",
    });
    const objectUpdate = getCampaignUpdatedByStatus({
      campaign: campaignFormatted,
      status: newStatus,
      target: "campaign",
    });

    const response = await dispatch(
      startSaveAdsCampaign({
        campaign: {
          ...objectUpdate,
        },
        campaignID,
      })
    );
    return response.ok;
  };
  useEffect(() => {
    getInitialData();

    // eslint-disable-next-line
  }, [userID]);
  useEffect(() => {
    const data = {};
    const campaignsActive = [];

    Object.keys(adsCampaigns).forEach((campaignID) => {
      const campaign = adsCampaigns[campaignID];
      const statusCampaign = getStatusAdsCampaign({
        states: campaign.states,
        target: "general",
      });
      data[campaignID] = campaign;
      if (statusCampaign === ADS_CAMPAIGN_STATUS_ACTIVE)
        campaignsActive.push(campaignID);
    });
    if (campaignsActive.length > 0 && !isUpdatedCampaignsActive) {
      setIsUpdatedCampaigns(true);
      onUpdateAdsCampaignInformationFromPlatforms(campaignsActive);
    }

    setCampaigns(data);
    // eslint-disable-next-line
  }, [adsCampaigns]);

  if (isLoading)
    return (
      <Loader fullWidth hasMessage={true} message={t(LOADING_INFORMATION)} />
    );
  return (
    <>
      {!campaigns || _.isEmpty(campaigns) ? (
        <AdsCampaignsIntro />
      ) : (
        <ContainerPage hasMarginBottom={true}>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              alignItems: "center",
              justifyContent: "flex-start",
              width: "100%",
              mb: 3,
            }}
          >
            <TitlePage>{t(ADS_CAMPAIGNS_TITLE)}</TitlePage>
            {!isViewer && (
              <Box
                sx={{
                  display: "flex",
                  flexWrap: { xs: "wrap", sm: "nowrap" },
                  gap: 1,
                  width: { xs: "100%", sm: "auto" },
                }}
              >
                <Button
                  fullWidth={isMobile}
                  onClick={async () => {
                    const isBlocked = await dispatch(
                      getIsBlockedActions({
                        action: ADS_CAMPAIGN_GENERATE_CAMPAIGN_ACTION,
                        showUsageConfirmationAlert: false,
                      })
                    );
                    if (isBlocked) return;
                    navigate("/create-ads-campaign");
                  }}
                  sx={{
                    minWidth: 100,
                    background: GRADIENT_PURPLE_FUCHSIA,
                    color: GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT,
                  }}
                >
                  {t(BUTTON_CREATE)}
                </Button>
              </Box>
            )}
          </Box>
          <AdsCampaignsList
            data={campaigns}
            onChangeStatusCampaign={onChangeStatusCampaign}
          />
        </ContainerPage>
      )}
    </>
  );
};

export default AdsCampaigns;
