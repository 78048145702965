import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import LineChart from "../../../../components/Charts/LineChart";
import BarChart from "../../../../components/Charts/BarChart";
import { useTranslationApp } from "../../../../lib/i18next";
import {
  AVERAGE,
  DATE_DAY_AND_MONTH_SHORT,
  VALUE_WITH_CURRENCY_MANUAL_FORMAT,
  WEEK_COUNT,
} from "../../../../i18n/keysTranslations";
import {
  getFromStatisticsAdsCampaign,
  mapTitleStatisticsByMetric,
} from "../../../../services/adsCampaigns";
import { getUniqueID, numberDecimal } from "../../../../utils/numbers";
import { logoPlatform } from "../../../../utils/string";

const HistoricalStatisticsCampaign = ({ data, report }) => {
  const { from, platforms, statistics, historical } =
    getFromStatisticsAdsCampaign(data);

  return (
    <>
      {platforms.map((platform) => (
        <ComponentByPlatform
          key={getUniqueID()}
          campaign={data}
          data={from === "current" ? statistics : historical}
          report={report}
          platform={platform}
          from={from}
        />
      ))}
    </>
  );
};

const ComponentByPlatform = ({
  data,
  campaign,
  report,
  platform,
  from = "current",
}) => {
  const reportByPlatform = report?.reportByPlatforms?.[platform] || {};
  const { t } = useTranslationApp();

  const getChartConfigAndDataByMetric = ({ metric, type = "bar" }) => {
    let chartConfig = {};
    let chartData = [];
    let currency;

    let startDateTime;
    let endDateTime;

    if (from === "current") {
      const campaignStatisticsByPlatform = data[platform];
      startDateTime = new Date(campaign.firstActiveTime);
      endDateTime = new Date(campaignStatisticsByPlatform.lastUpdatedTime);
      currency = campaignStatisticsByPlatform.currency || "USD";

      const currentChartData = {
        label: t(WEEK_COUNT, {
          value: 1,
        }),
        value: numberDecimal(campaignStatisticsByPlatform[metric]),
      };

      if (type === "line") {
        currentChartData.average =
          (metric === "cpm"
            ? reportByPlatform.avgCpm
            : reportByPlatform.avgCpc) || 0;
      }

      chartData.push(currentChartData);

      chartConfig = {
        value: {
          label: mapTitleStatisticsByMetric({ metric, currency }),
          color: "hsl(var(--chart-1))",
          hasLabelList: true,
        },
      };

      if (type === "line") {
        chartConfig.average = {
          label: t(AVERAGE),
          color: "hsl(var(--chart-3))",
          hasLabelList: false,
        };
      }
    } else {
      Object.keys(data).forEach((snapshotKey, index) => {
        const snapshot = data[snapshotKey] || {};
        if (index === 0) {
          startDateTime = new Date(snapshot.startDateTime);
        }
        const {
          endDateTime: endDateTimeValue,
          [platform]: platformStatistics,
        } = snapshot;
        const campaignStatisticsByPlatform = platformStatistics?.campaign || {};
        currency = platformStatistics?.currency || "USD";

        endDateTime = new Date(endDateTimeValue);

        const currentChartData = {
          label: t(WEEK_COUNT, {
            value: index + 1,
          }),
          value: numberDecimal(campaignStatisticsByPlatform[metric]),
        };
        if (type === "line") {
          currentChartData.average =
            (metric === "cpm"
              ? reportByPlatform.avgCpm
              : reportByPlatform.avgCpc) || 0;
        }
        chartData.push(currentChartData);
      });

      chartConfig = {
        value: {
          label: mapTitleStatisticsByMetric({ metric, currency }),
          color: "hsl(var(--chart-1))",
          hasLabelList: true,
        },
      };
      if (type === "line") {
        chartConfig.average = {
          label: t(AVERAGE),
          color: "hsl(var(--chart-3))",
          hasLabelList: false,
        };
      }
    }

    const title = mapTitleStatisticsByMetric({
      metric,
      currency,
    });

    const subtitle = `${t(DATE_DAY_AND_MONTH_SHORT, {
      date: startDateTime,
    })} - ${t(DATE_DAY_AND_MONTH_SHORT, {
      date: endDateTime,
    })}`;

    return {
      chartConfig,
      chartData,
      title,
      subtitle,
      currency,
      xAxisKey: "label",
    };
  };

  const chartSpend = getChartConfigAndDataByMetric({ metric: "spend" });
  const chartImpressions = getChartConfigAndDataByMetric({
    metric: "impressions",
  });
  const chartMainAction = getChartConfigAndDataByMetric({
    metric: "mainAction",
  });
  const chartCpa = getChartConfigAndDataByMetric({
    metric: "costPerMainAction",
  });
  const chartCpm = getChartConfigAndDataByMetric({
    metric: "cpm",
    type: "line",
  });
  const chartCpc = getChartConfigAndDataByMetric({
    metric: "cpc",
    type: "line",
  });

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
        }}
      >
        <Avatar
          src={logoPlatform(platform)}
          alt={`Logo ${platform}`}
          variant="square"
          sx={{
            height: 25,
            width: 25,
            "& .MuiAvatar-img": {
              objectFit: "contain",
            },
          }}
        />
        <Typography
          variant="h6"
          sx={{
            fontWeight: "bold",
          }}
        >
          {t(platform)}
        </Typography>
      </Box>
      <Box>
        <Grid
          container
          spacing={2}
          sx={{
            mt: -1,
          }}
        >
          <Grid item xs={12} sm={6} lg={4}>
            <BarChart
              chartConfig={chartSpend.chartConfig}
              chartData={chartSpend.chartData}
              hasCartesianGrid={false}
              hasLegend={false}
              xAxisKey={chartSpend.xAxisKey}
              title={`${chartSpend.title} (${chartSpend.currency})`}
              subtitle={chartSpend.subtitle}
              description={reportByPlatform.spend}
              valueFormat={{
                translation: VALUE_WITH_CURRENCY_MANUAL_FORMAT,
                key: "value",
                options: {
                  currency: chartSpend.currency,
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <BarChart
              chartConfig={chartImpressions.chartConfig}
              chartData={chartImpressions.chartData}
              hasCartesianGrid={false}
              hasLegend={false}
              xAxisKey={chartImpressions.xAxisKey}
              title={chartImpressions.title}
              subtitle={chartImpressions.subtitle}
              description={reportByPlatform.impressions}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <BarChart
              chartConfig={chartMainAction.chartConfig}
              chartData={chartMainAction.chartData}
              hasCartesianGrid={false}
              hasLegend={false}
              xAxisKey={chartMainAction.xAxisKey}
              title={chartMainAction.title}
              subtitle={chartMainAction.subtitle}
              description={reportByPlatform.results}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <BarChart
              chartConfig={chartCpa.chartConfig}
              chartData={chartCpa.chartData}
              hasCartesianGrid={false}
              hasLegend={false}
              xAxisKey={chartCpa.xAxisKey}
              title={`${chartCpa.title} (${chartSpend.currency})`}
              subtitle={chartCpa.subtitle}
              description={reportByPlatform.costPerMainAction}
              valueFormat={{
                translation: VALUE_WITH_CURRENCY_MANUAL_FORMAT,
                key: "value",
                options: {
                  currency: chartSpend.currency,
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <LineChart
              chartConfig={chartCpm.chartConfig}
              chartData={chartCpm.chartData}
              hasCartesianGrid={false}
              xAxisKey={chartCpm.xAxisKey}
              title={chartCpm.title}
              subtitle={chartCpm.subtitle}
              description={reportByPlatform.cpm}
              valueFormat={{
                translation: VALUE_WITH_CURRENCY_MANUAL_FORMAT,
                key: "value",
                options: {
                  currency: chartSpend.currency,
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <LineChart
              chartConfig={chartCpc.chartConfig}
              chartData={chartCpc.chartData}
              hasCartesianGrid={false}
              xAxisKey={chartCpc.xAxisKey}
              title={chartCpc.title}
              subtitle={chartCpc.subtitle}
              description={reportByPlatform.cpc}
              valueFormat={{
                translation: VALUE_WITH_CURRENCY_MANUAL_FORMAT,
                key: "value",
                options: {
                  currency: chartSpend.currency,
                },
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default HistoricalStatisticsCampaign;
