import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Controller } from "react-hook-form";
import { useSelectorApp } from "../../../lib/redux";
import {
  META,
  TIKTOK,
  GOOGLE,
  BORDER_RADIUS,
  BUTTON_META_ACTION_ID,
  BUTTON_TIKTOK_BUSINESS_ACTION_ID,
  BUTTON_GOOGLE_ACTION_ID,
} from "../../../utils/constants";
import {
  META as META_TRANSLATION,
  TIKTOK as TIKTOK_TRANSLATION,
  GOOGLE as GOOGLE_TRANSLATION,
  BUTTON_CONNECT_GOOGLE,
  COMING_SOON,
  META_TOGGLE_DESCRIPTION,
  GOOGLE_TOGGLE_DESCRIPTION,
  TIKTOK_TOGGLE_DESCRIPTION,
  BUTTON_CONNECTED,
} from "../../../i18n/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";
import ButtonGoogleAction from "../../../components/Buttons/ButtonGoogleAction";
import Checkbox from "@mui/material/Checkbox";
import MetaContainerIntegration from "../../../components/Meta/MetaContainerIntegration";
import TikTokBusinessContainerIntegration from "../../../components/TikTokBusiness/TikTokBusinessContainerIntegration";
import { useMediaQuery } from "@mui/material";

const IDS_PLATFORM_BUTTONS = {
  [META]: BUTTON_META_ACTION_ID,
  [TIKTOK]: BUTTON_TIKTOK_BUSINESS_ACTION_ID,
  [GOOGLE]: BUTTON_GOOGLE_ACTION_ID,
};

const ToggleSelectPlatforms = ({
  name,
  control,
  rules = {},
  onChangeAdAccount = () => {},
  onChangeIntegrationID = () => {},
}) => {
  const integrations = useSelectorApp((state) => state.user.integrations);
  const metaIntegrations = integrations?.facebook || {};
  const isMetaConnected = Object.values(metaIntegrations).some(
    (integration) => integration.isActive
  );
  const tikTokBusinessIntegrations = integrations?.tiktokBusiness || {};
  const isTikTokBusinessConnected = Object.values(
    tikTokBusinessIntegrations
  ).some((integration) => integration.isActive);
  const isGoogleActive = integrations?.google?.isActive || false;

  const isMobileWidth = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isMobileHeight = useMediaQuery("(max-height:650px)");
  const isMobile = isMobileWidth || isMobileHeight;

  const { t } = useTranslationApp();

  const commonSxContainer = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    p: 1,
    width: "auto",
    borderRadius: BORDER_RADIUS,
  };
  const commonSxContainerPlatform = {
    maxWidth: { xs: "100%", sm: "50%" },
  };
  const commonSxTitle = {
    width: "100%",
    mb: 1,
  };
  const commonSxSubLabel = {
    mb: 1,
  };
  const commonSxComingSoon = {
    mt: 0.5,
    color: "GrayText",
    fontStyle: "italic",
  };
  const commonSxCheckbox = {
    ml: 0,
    p: 0,
    pt: 0.3,
  };
  const commonSxButtonPlatform = {
    width: 210,
  };

  const titlePlatformVariant = isMobile ? "body1" : "h6";
  const descriptionPlatformVariant = isMobile ? "body2" : "body1";

  const onClickConnectAccount = async (platform) => {
    const element = document.getElementById(IDS_PLATFORM_BUTTONS[platform]);
    element.click();
    return;
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => (
        <Box
          sx={{
            ...commonSxContainer,
            flexWrap: { xs: "nowrap", sm: "wrap" },
            flexDirection: {
              xs: "column",
              sm: "row",
            },
            alignItems: "flex-start",
            p: 0,
            width: "100%",
          }}
        >
          <Box
            sx={{
              ...commonSxContainer,
              ...commonSxContainerPlatform,
              flexDirection: "row",
              gap: 1,
            }}
          >
            <Checkbox
              edge="start"
              checked={field.value?.includes(META)}
              onChange={() => {
                if (!isMetaConnected) {
                  onClickConnectAccount(META);
                  return;
                }
                field.onChange(
                  field.value?.includes(META)
                    ? field.value.filter((v) => v !== META)
                    : [...field.value, META]
                );
              }}
              name={field.name}
              sx={commonSxCheckbox}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                variant={titlePlatformVariant}
                sx={{ ...commonSxTitle }}
              >
                {t(META_TRANSLATION)}
              </Typography>
              <Typography
                variant={descriptionPlatformVariant}
                sx={{ ...commonSxSubLabel }}
              >
                {t(META_TOGGLE_DESCRIPTION)}
              </Typography>
              <MetaContainerIntegration
                id={BUTTON_META_ACTION_ID}
                sx={{
                  ...commonSxButtonPlatform,
                }}
                onChangeIntegration={({ integrationID, adAccountID }) => {
                  onChangeIntegrationID({ platform: META, integrationID });
                  onChangeAdAccount({ platform: META, adAccountID });
                }}
                onClick={() => {
                  if (isMetaConnected) return;

                  field.onChange(
                    field.value?.includes(META)
                      ? field.value.filter((v) => v !== META)
                      : [...field.value, META]
                  );
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              ...commonSxContainer,
              ...commonSxContainerPlatform,
              flexDirection: "row",
              gap: 1,
            }}
          >
            <Checkbox
              edge="start"
              checked={field.value?.includes(TIKTOK)}
              onChange={() => {
                if (!isTikTokBusinessConnected) {
                  onClickConnectAccount(TIKTOK);
                  return;
                }
                field.onChange(
                  field.value?.includes(TIKTOK)
                    ? field.value.filter((v) => v !== TIKTOK)
                    : [...field.value, TIKTOK]
                );
              }}
              name={field.name}
              sx={commonSxCheckbox}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                variant={titlePlatformVariant}
                sx={{ ...commonSxTitle }}
              >
                {t(TIKTOK_TRANSLATION)}
              </Typography>
              <Typography
                variant={descriptionPlatformVariant}
                sx={{ ...commonSxSubLabel }}
              >
                {t(TIKTOK_TOGGLE_DESCRIPTION)}
              </Typography>
              <TikTokBusinessContainerIntegration
                id={BUTTON_TIKTOK_BUSINESS_ACTION_ID}
                sx={{
                  ...commonSxButtonPlatform,
                }}
                onChangeIntegration={({ integrationID, adAccountID }) => {
                  onChangeIntegrationID({ platform: TIKTOK, integrationID });
                  onChangeAdAccount({ platform: TIKTOK, adAccountID });
                }}
                onClick={() => {
                  if (isMetaConnected) return;

                  field.onChange(
                    field.value?.includes(TIKTOK)
                      ? field.value.filter((v) => v !== TIKTOK)
                      : [...field.value, TIKTOK]
                  );
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              ...commonSxContainer,
              ...commonSxContainerPlatform,
              flexDirection: "row",
              gap: 1,
            }}
          >
            <Checkbox
              edge="start"
              disabled={true}
              checked={field.value?.includes(GOOGLE)}
              onChange={() => {
                if (!isGoogleActive) {
                  onClickConnectAccount(GOOGLE);
                  return;
                }
                field.onChange(
                  field.value?.includes(GOOGLE)
                    ? field.value.filter((v) => v !== GOOGLE)
                    : [...field.value, GOOGLE]
                );
              }}
              name={field.name}
              sx={commonSxCheckbox}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                variant={titlePlatformVariant}
                sx={{ ...commonSxTitle }}
              >
                {t(GOOGLE_TRANSLATION)}
              </Typography>
              <Typography
                variant={descriptionPlatformVariant}
                sx={{ ...commonSxSubLabel }}
              >
                {t(GOOGLE_TOGGLE_DESCRIPTION)}
              </Typography>
              <ButtonGoogleAction
                disabled={true}
                onClick={() => {
                  if (isGoogleActive) return;

                  field.onChange(
                    field.value?.includes(GOOGLE)
                      ? field.value.filter((v) => v !== GOOGLE)
                      : [...field.value, GOOGLE]
                  );
                }}
                logo={GOOGLE}
                text={isGoogleActive ? BUTTON_CONNECTED : BUTTON_CONNECT_GOOGLE}
                sx={{
                  ...commonSxButtonPlatform,
                }}
              />
              <Typography variant="body2" sx={{ ...commonSxComingSoon }}>
                {t(COMING_SOON)}
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
    />
  );
};

export default ToggleSelectPlatforms;
