import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import {
  ADS_CAMPAIGN_CREATION_MODE_ADVANCED,
  ADS_CAMPAIGN_CREATION_MODE_QUICK,
  BORDER_RADIUS,
} from "../../../utils/constants";
import {
  AUTOMATED,
  CREATE_ADS_CAMPAIGN_ADVANCED_MODE,
  CREATE_ADS_CAMPAIGN_ADVANCED_MODE_DESCRIPTION,
  CREATE_ADS_CAMPAIGN_QUICK_MODE,
  CREATE_ADS_CAMPAIGN_QUICK_MODE_DESCRIPTION,
  CUSTOMIZE,
  EASY,
  FAST,
  FULL_CONTROL,
  TAILORED,
} from "../../../i18n/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";

const commonSxText = {
  fontWeight: 700,
  filter: "brightness(1.0)",
};

const ToggleSelectCampaignMode = ({ onClickMode = () => {} }) => {
  const { t } = useTranslationApp();
  return (
    <Box
      sx={{
        display: "flex",
        gap: 2,
        flexDirection: {
          xs: "column",
          sm: "row",
        },
      }}
    >
      <Box
        onClick={() => onClickMode(ADS_CAMPAIGN_CREATION_MODE_QUICK)}
        sx={{
          position: "relative",
          borderRadius: BORDER_RADIUS,
          width: {
            xs: "100%",
            sm: "50%",
          },
          py: { xs: 2, sm: 2 },
          px: { xs: 2, sm: 3 },
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 0,
            borderRadius: BORDER_RADIUS,
            background: "linear-gradient(135deg, #0cb6da, #ca5cf9)",
            filter: "brightness(0.9)",
          },
          "&:hover": {
            cursor: "pointer",
          },
          zIndex: 1,
        }}
      >
        <Typography variant="body1" sx={commonSxText}>
          {t(CREATE_ADS_CAMPAIGN_QUICK_MODE)}
        </Typography>
        <Typography variant="body2" sx={commonSxText}>
          {t(CREATE_ADS_CAMPAIGN_QUICK_MODE_DESCRIPTION)}
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: 1,
            mt: 1,
          }}
        >
          <Chip label={t(FAST)} size="small" sx={commonSxText} />
          <Chip label={t(EASY)} size="small" sx={commonSxText} />
          <Chip label={t(AUTOMATED)} size="small" sx={commonSxText} />
        </Box>
      </Box>
      <Box
        onClick={() => onClickMode(ADS_CAMPAIGN_CREATION_MODE_ADVANCED)}
        sx={{
          position: "relative",
          borderRadius: BORDER_RADIUS,
          width: {
            xs: "100%",
            sm: "50%",
          },
          py: { xs: 2, sm: 2 },
          px: { xs: 2, sm: 3 },
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 0,
            borderRadius: BORDER_RADIUS,
            background: "linear-gradient(138deg, #f84739, #fff494)",
            filter: "brightness(0.9)",
          },
          "&:hover": {
            cursor: "pointer",
          },
          zIndex: 1,
        }}
      >
        <Typography variant="body1" sx={commonSxText}>
          {t(CREATE_ADS_CAMPAIGN_ADVANCED_MODE)}
        </Typography>
        <Typography variant="body2" sx={commonSxText}>
          {t(CREATE_ADS_CAMPAIGN_ADVANCED_MODE_DESCRIPTION)}
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: 1,
            mt: 1,
          }}
        >
          <Chip label={t(FULL_CONTROL)} size="small" sx={commonSxText} />
          <Chip label={t(CUSTOMIZE)} size="small" sx={commonSxText} />
          <Chip label={t(TAILORED)} size="small" sx={commonSxText} />
        </Box>
      </Box>
    </Box>
  );
};

export default ToggleSelectCampaignMode;
