import React, { useState, useEffect } from "react";
import i18next from "i18next";
import {
  CUSTOM_EVENT,
  FIELD_REQUIRED,
  INACTIVE,
  LOADING,
} from "../../i18n/keysTranslations";
import {
  ADS_CAMPAIGN_OBJECTIVE_ENGAGEMENT_OPTION,
  ADS_CAMPAIGN_OBJECTIVE_LEADS_OPTION,
  ADS_CAMPAIGN_OBJECTIVE_SALES_OPTION,
  META,
  TIKTOK,
} from "../../utils/constants";
import {
  optionsCustomEventSalesMeta,
  optionsCustomEventsEngagement,
  optionsCustomEventsLeadsMeta,
  optionsCustomEventsLeadsTikTok,
  optionsCustomEventsSalesTikTok,
} from "../../utils/options";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { Controller, useController } from "react-hook-form";
import { getReactHookFormNestedError } from "../../utils/errors";
import { useTranslationApp } from "../../lib/i18next";
import { useDispatchApp } from "../../lib/redux";
import { startGetTrackingCustomEvents } from "../../actions/adsCampaigns";
import _ from "lodash";
import { capitalizeFirstLetter } from "../../utils/string";

const SelectCustomEventAds = ({
  control,
  errors,
  rules = {
    required: {
      value: true,
      message: i18next.t(FIELD_REQUIRED),
    },
  },
  name,
  label,
  disabled,
  platform,
  integrationID,
  adAccountID,
  fullWidth,
  variant = "filled",
  objective,
  placeholder,
  helperText,
  pixelSelected = {},
  onChangeAction = () => {},
}) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const error = getReactHookFormNestedError(errors, name);

  const { t } = useTranslationApp();
  const dispatch = useDispatchApp();

  const {
    field: { onChange: onChangeField },
  } = useController({
    name,
    control,
  });

  const onGetPixelsEventsActive = () => {
    if (_.isEmpty(pixelSelected) || _.isEmpty(pixelSelected?.events)) {
      return [];
    }

    const events = pixelSelected?.events?.map((event) => {
      if (event.custom_event_type) {
        return event.custom_event_type;
      }

      return event.event_type;
    });

    return events;
  };
  const onGetPixelCustomEvents = () => {
    if (_.isEmpty(pixelSelected) || _.isEmpty(pixelSelected?.events)) {
      return [];
    }

    const customEvents = pixelSelected?.events
      ?.filter((event) => event?.custom_event_type)
      ?.map((event) => {
        return {
          value: event.event_id,
          label: capitalizeFirstLetter(event.custom_event_type),
          isCustomEvent: true,
        };
      });

    return customEvents;
  };
  const pixelsEvents = onGetPixelsEventsActive();

  const getInitialData = async () => {
    if (!adAccountID || !integrationID || !objective || !platform) {
      setLoading(false);
      setOptions([]);
      onChangeField("");
      return;
    }

    setLoading(true);

    let basicOptions = [];

    if (objective === ADS_CAMPAIGN_OBJECTIVE_ENGAGEMENT_OPTION) {
      basicOptions = optionsCustomEventsEngagement;
    }
    if (
      objective === ADS_CAMPAIGN_OBJECTIVE_LEADS_OPTION &&
      platform === META
    ) {
      basicOptions = optionsCustomEventsLeadsMeta;
    }
    if (
      objective === ADS_CAMPAIGN_OBJECTIVE_SALES_OPTION &&
      platform === META
    ) {
      basicOptions = optionsCustomEventSalesMeta;
    }
    if (
      objective === ADS_CAMPAIGN_OBJECTIVE_LEADS_OPTION &&
      platform === TIKTOK
    ) {
      basicOptions = optionsCustomEventsLeadsTikTok;
    }
    if (
      objective === ADS_CAMPAIGN_OBJECTIVE_SALES_OPTION &&
      platform === TIKTOK
    ) {
      basicOptions = optionsCustomEventsSalesTikTok;
    }

    if (platform === TIKTOK) {
      const customEvents = onGetPixelCustomEvents();
      basicOptions = [...customEvents, ...basicOptions];
      //sort options by if is active
      basicOptions = basicOptions.sort((a) => {
        if (pixelsEvents?.includes(a.value)) {
          return -1;
        }
        return 1;
      });
      setOptions(basicOptions);
      setLoading(false);
      return;
    }

    const response = await dispatch(
      startGetTrackingCustomEvents({
        adAccountID,
        integrationID,
        platform,
      })
    );

    if (response.ok) {
      const data = response.data;
      if (data?.length > 0) {
        const customEvents = data.map((customEvent) => ({
          ...customEvent,
          value: customEvent.id,
          label: customEvent.name,
        }));

        basicOptions = [...customEvents, ...basicOptions];
      }
    }

    setOptions(basicOptions);
    setLoading(false);
  };

  useEffect(() => {
    getInitialData();
    // eslint-disable-next-line
  }, [adAccountID, integrationID, objective, platform]);

  const getLabelOption = (option) => {
    if (option.isCustomEvent) {
      return option.label;
    }
    return t(option.label);
  };

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field }) => (
        <Autocomplete
          {...field}
          fullWidth={fullWidth}
          disabled={disabled}
          loadingText={t(LOADING)}
          loading={loading}
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          onChange={(_, value) => {
            field.onChange(value?.value);
            onChangeAction(value);
          }}
          isOptionEqualToValue={(option, value) => {
            return option?.value === value.value;
          }}
          getOptionLabel={(option) => {
            const optionValue = options.find((opt) => opt.value === option);
            if (optionValue) {
              return `${getLabelOption(optionValue)}`;
            }
            if (typeof option === "string") {
              return option ? t(option) : "";
            }
            return `${getLabelOption(option)}`;
          }}
          options={options}
          disableClearable={true}
          renderOption={(props, option) => {
            const isDisabled =
              !pixelsEvents?.includes(option.value) && platform === TIKTOK;
            return (
              <Box
                {...props}
                onClick={(e) => {
                  if (isDisabled) return;
                  props.onClick(e);
                }}
                onMouseOver={(e) => {
                  if (isDisabled) return;
                  props.onMouseOver(e);
                }}
                onTouchStart={(e) => {
                  if (isDisabled) return;
                  props.onTouchStart(e);
                }}
                component="li"
                key={option.value}
                disabled={isDisabled}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: 1,
                  cursor: isDisabled ? "default !important" : "pointer",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    color: isDisabled ? "text.disabled" : "inherit",
                  }}
                >
                  {getLabelOption(option)}
                </Typography>
                {isDisabled && <Chip label={t(INACTIVE)} size="small" />}
                {option.isCustomEvent && (
                  <Chip label={t(CUSTOM_EVENT)} size="small" />
                )}
              </Box>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              variant={variant}
              placeholder={field?.value?.length > 0 ? "" : placeholder}
              error={!!error}
              helperText={
                <Box
                  component="span"
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span>{!!error ? error?.message : helperText}</span>
                </Box>
              }
              sx={{
                ".MuiFormHelperText-root": {
                  ml: "0 !important",
                },
              }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      )}
    />
  );
};

export default SelectCustomEventAds;
