import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatchApp, useSelectorApp } from "../../lib/redux";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import LinearLoaderWithTime from "../../components/Loaders/LinearLoaderWithTime";
import { VERIFYING_YOUR_SESSION } from "../../i18n/keysTranslations";
import { useTranslationApp } from "../../lib/i18next";
import { startGetCustomLoginToken } from "../../actions/user";
import { signInWithCustomToken } from "firebase/auth";
import { auth } from "../../lib/firebase";

const VerifyAuth = () => {
  const [verifyingAuth, setVerifyingAuth] = useState(false);
  const [params] = useSearchParams();

  const user = useSelectorApp((state) => state.auth.user);
  const { theme } = useSelectorApp((state) => state.ui);
  const { t } = useTranslationApp();
  const dispatch = useDispatchApp();

  const navigate = useNavigate();
  const redirect = params.get("redirect");
  const hash = params.get("hash");

  const getRedirectPath = () => {
    const pathFormatted = decodeURIComponent(redirect);

    // Clone the params object to avoid mutating it
    const newParams = new URLSearchParams(params);

    // Remove the `redirect` and `hash` params
    newParams.delete("redirect");
    newParams.delete("hash");

    // Convert the remaining params to string
    const restParams = newParams.toString();
    // Construct the final path with remaining params
    const redirectPathPlusParams = restParams
      ? `${pathFormatted}?${restParams}`
      : pathFormatted;

    return redirectPathPlusParams;
  };

  const onVerifyAuth = async () => {
    try {
      const pathToRedirect = getRedirectPath();
      if (user) {
        navigate(pathToRedirect, { replace: true });
        return;
      }
      setVerifyingAuth(true);
      const response = await dispatch(
        startGetCustomLoginToken({
          hash,
        })
      );

      if (!response.ok) {
        navigate("/login", { replace: true });
        return;
      }

      const customToken = response?.data?.customToken;
      if (!customToken) {
        navigate("/login", { replace: true });
        return;
      }

      const responseLogin = await signInWithCustomToken(auth, customToken);
      if (responseLogin) {
        navigate(pathToRedirect, { replace: true });
        return;
      }
    } catch (error) {
      console.error(error);
      navigate("/login", { replace: true });
      return false;
    }
  };

  useEffect(() => {
    if (!hash) {
      navigate("/", { replace: true });
      return;
    }
    onVerifyAuth();
    // eslint-disable-next-line
  }, [hash]);

  if (!verifyingAuth) {
    return null;
  }

  return (
    <Container
      maxWidth="xs"
      sx={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Avatar
        src={theme.logoLogin}
        alt="Logo"
        variant="square"
        sx={{
          width: "auto",
          height: "auto",
          maxHeight: "60px",
          ".MuiAvatar-img": {
            maxWidth: "160px",
            objectFit: "contain",
          },
          mb: 1,
        }}
      />
      <LinearLoaderWithTime
        sx={{
          mt: 2,
        }}
        label={t(VERIFYING_YOUR_SESSION)}
        time={25 * 1000}
      />
    </Container>
  );
};

export default VerifyAuth;
