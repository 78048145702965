import Box from "@mui/material/Box";
import {
  ADS_ACTION_TYPE_COINS,
  ADS_ACTION_TYPE_CREDITS,
} from "../../utils/constants";
import {
  ACTION_CONFIRM_TEXT,
  COINS,
  CREDITS,
  CURRENT_USAGE_TYPE,
  VALUE_NUMBER,
} from "../../i18n/keysTranslations";
import Typography from "@mui/material/Typography";
import { useTranslationApp } from "../../lib/i18next";
import CoinsIcon from "../../assets/svg/CoinsIcon";
import CreditsIcon from "../../assets/svg/CreditsIcon";

const LABELS_ACTIONS = {
  [ADS_ACTION_TYPE_CREDITS]: CREDITS,
  [ADS_ACTION_TYPE_COINS]: COINS,
};

const ConfirmUsage = ({ action = {}, usage = {} }) => {
  const { t } = useTranslationApp();

  const { type, cost } = action;
  const label = LABELS_ACTIONS[type];

  const coins = usage?.coins || 0;
  const credits = usage?.credits || 0;
  return (
    <Box
      sx={{
        mt: 2,
      }}
    >
      <Typography variant="h6" align="center" sx={{ mb: 2.5 }}>
        {t(ACTION_CONFIRM_TEXT, {
          cost,
          type: t(label),
        })}
      </Typography>
      <Box
        sx={{
          display: "flex",
          gap: 1,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="h6" align="left">
          {`${t(CURRENT_USAGE_TYPE, {
            type: t(label),
          })}:`}
        </Typography>
        {type === ADS_ACTION_TYPE_CREDITS && (
          <Box
            sx={{
              display: "flex",
              gap: 0.5,
              alignItems: "center",
            }}
          >
            <CreditsIcon />
            <Typography variant="h6">
              {t(VALUE_NUMBER, { value: credits })}
            </Typography>
          </Box>
        )}
        {type === ADS_ACTION_TYPE_COINS && (
          <Box
            sx={{
              display: "flex",
              gap: 0.5,
              alignItems: "center",
            }}
          >
            <CoinsIcon />
            <Typography variant="h6">
              {t(VALUE_NUMBER, { value: coins })}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ConfirmUsage;
