export const REGEX_EMAIL =
  /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
export const REGEX_NUMERIC = /^[0-9]+$/;
export const REGEX_TEXT = /^[a-zA-Z]+$/;
export const REGEX_TEXT_WITH_SPACES = /^[a-zA-Z ]+$/;
export const REGEX_ALPHA_NUMERIC = /^[a-zA-Z0-9]+$/;
export const REGEX_ALPHA_NUMERIC_WITH_SPACES = /^[a-zA-Z0-9 ]+$/;
export const REGEX_TEXT_WITH_ACCENTS = /^[a-zA-ZÀ-ÿ\u00f1\u00d1 ]+$/;
export const REGEX_ALPHANUMERIC_WITH_ACCENTS_AND_SPACES =
  /^[a-zA-ZÀ-ÿ\u00f1\u00d10-9 ]+$/;
export const REGEX_NIT_OR_CEDULA = /^[0-9]+[-]{0,1}[0-9]{1}$/;
export const REGEX_PASSWORD = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[\s\S]{6,}$/;
// eslint-disable-next-line
export const REGEX_URL = /^(http(s)?:\/\/)?(www\.)?[a-zA-Z0-9@:%._\+-~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$|^(mailto:)[^\s@]+@[^\s@]+\.[^\s@]+$/;
// export const REGEX_URL =
//   /^(https:\/\/)?(www\.)?([^h][^t][^p]\S+\.[a-zA-Z]{1,6})(\/\S*)?(\?\S*)?$|^mailto:[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const REGEX_NUMBER_IN_STRING = /\b\d{6,}\b/;
export const REGEX_NUMBER_TWO_DIGITS_AND_DECIMALS =
  /^[0-9]{0,2}(\.[0-9]{1,2})?$/;
