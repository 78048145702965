import { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
  ADS_CAMPAIGN_MODE_EDIT,
  ADS_CAMPAIGN_MODE_PREVIEW,
  ADS_CAMPAIGN_STATUS_ACTIVE,
  ADS_CAMPAIGN_STATUS_DEACTIVATED,
  ADS_CAMPAIGN_STATUS_DRAFT,
  ADS_CAMPAIGN_STATUS_FINISHED,
  ALERT_ICON_TYPE_SUCCESS,
} from "../../../utils/constants";
import {
  BUTTON_SAVE_CAMPAIGN,
  ADS_CAMPAIGN_HAS_BEEN_FINISHED,
  ADS_CAMPAIGN_HAS_BEEN_SAVE,
  ERROR_PROCESSING_CAMPAIGNS,
} from "../../../i18n/keysTranslations";
import { FormProvider, useForm } from "react-hook-form";
import useFocusErrorForm from "../../../hooks/useFocusErrorForm";
import { useTranslationApp } from "../../../lib/i18next";
import { useDispatchApp } from "../../../lib/redux";
import {
  startDeleteAdsContentUploaded,
  startListenerAdsContentsUploaded,
  startSaveAdsCampaign,
  startUpdateMediaUrlAd,
} from "../../../actions/adsCampaigns";
import { SimpleAlert } from "../../../components/Alerts/Alerts";
import ContainerPaperSection from "../../../components/Containers/ContainerPaperSection";
import LoadingButton from "../../../components/Buttons/LoadingButton";
import AdsCampaignReasoningContainer from "./AdsCampaignReasoningContainer";
import AdsCampaignForm from "./AdsCampaignForm";
import {
  getCampaignUpdatedByStatus,
  getDefaultPlatformData,
  getStatusAdsCampaign,
} from "../../../services/adsCampaigns";
import AdsReportCampaignState from "./AdsReportCampaignState";
import { off } from "firebase/database";
import { setNestedValue } from "../../../utils/forms";
import { getUserIsViewer } from "../../../actions/getters";
import ModalErrorsCampaigns from "./ModalErrorsCampaigns";
import { getKeyDatabase } from "../../../services/public";
import _ from "lodash";
import { waitDelay } from "../../../utils/date";

const INITIAL_STATE = {
  id: "",
  websiteURL: "",
  destinationURL: "",
  name: "",
  objective: "",
  platforms: [],
  states: {
    general: "",
  },
  platformsData: {},
  useCampaignBudget: false,
  adsGroups: [
    {
      customAudiences: [],
      ageRange: [20, 40],
      behaviors: [],
      budget: "",
      demographics: [],
      genders: {
        female: true,
        male: true,
      },
      id: 0,
      interests: [],
      locations: [],
      name: "",
      startDate: "",
      status: "deactivated",
      typeBudget: "",
      ads: [
        {
          adGroupID: 0,
          callToAction: "",
          creativeID: "",
          id: 1,
          mediaID: "",
          mediaUrl: "",
          name: "",
          status: "deactivated",
          titles: [""],
          headlines: [""],
          descriptions: [""],
        },
      ],
    },
  ],
};
const AdsCampaignInformation = ({
  data = {},
  mode,
  currency,
  onChangeData = () => {},
  onChangeMode = () => {},
  campaignID,
}) => {
  const methods = useForm({
    defaultValues: {
      ...INITIAL_STATE,
      ...data,
    },
  });
  const {
    control,
    watch,
    setValue,
    handleSubmit,
    formState,
    getValues,
    reset,
    trigger,
    setFocus,
  } = methods;

  const containerFormRef = useRef(null);
  const [adsContentsUploadedListener, setAdsContentsUploadedListener] =
    useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [modalErrorsCampaignsOpen, setModalErrorsCampaignsOpen] =
    useState(false);
  const [dataModalErrorsCampaigns, setDataModalErrorsCampaigns] = useState({});
  const errors = formState.errors;

  const isCampaignCreated = mode === ADS_CAMPAIGN_MODE_EDIT;
  const isCampaignFinished =
    data?.states?.general === ADS_CAMPAIGN_STATUS_FINISHED;
  const isModeDraft = mode === ADS_CAMPAIGN_MODE_PREVIEW;
  const hasSomePlatformDraft = Object.keys(data.states || {}).some(
    (key) => data.states[key] === ADS_CAMPAIGN_STATUS_DRAFT
  );

  useFocusErrorForm(formState);

  const { t } = useTranslationApp();
  const dispatch = useDispatchApp();
  const hasStatistics = Object.values(data?.statistics || {})?.some(
    (statistic) => Number(statistic) > 1
  );

  useEffect(() => {
    const keys = Object.keys(data);

    keys.forEach((key) => {
      setValue(key, data[key]);
      if (key === "platforms") {
        const platformsData = data.platformsData || {};
        data[key].forEach((platform) => {
          if (!platformsData[platform])
            setValue(
              `platformsData.${platform}`,
              getDefaultPlatformData(platform)
            );
        });
      }
    });

    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    dispatch(
      startListenerAdsContentsUploaded({
        campaignID,
        onReferenceAvailable: (listener) =>
          setAdsContentsUploadedListener(listener),
        onUpdatedDataCallback: (contents) =>
          onUpdatedAdsContentsUploaded(contents),
      })
    );

    return () => {
      if (adsContentsUploadedListener) {
        off(adsContentsUploadedListener);
      }
    };
    // eslint-disable-next-line
  }, []);

  const onUpdatedAdsContentsUploaded = (data = {}) => {
    const dataKeys = Object.keys(data || {});
    const dataToSearch = {};

    dataKeys.forEach((key) => {
      const content = data[key];
      dataToSearch[content.preview] = { ...content, id: key };
    });

    const adsGroups = getValues("adsGroups");

    adsGroups.forEach((adGroup, indexAdGroup) => {
      adGroup.ads.forEach((ad, indexAd) => {
        const content = dataToSearch[ad.mediaUrl];
        if (!content) return;

        onUpdateMediaUrlAndRemovePreview({
          indexAdGroup,
          indexAd,
          mediaUrl: content.value,
          contentID: content.id,
        });
      });
    });
  };

  const onUpdateMediaUrlAndRemovePreview = async ({
    indexAdGroup,
    indexAd,
    mediaUrl,
    contentID,
  }) => {
    const ad = getValues(`adsGroups.[${indexAdGroup}].ads.[${indexAd}]`);
    const adID = ad.id;

    const responseUpdateMediaUrl = await dispatch(
      startUpdateMediaUrlAd({
        adID,
        mediaUrl,
      })
    );
    if (!responseUpdateMediaUrl) return;

    const responseDeleteAdsContentsUploaded = await dispatch(
      startDeleteAdsContentUploaded({
        campaignID,
        contentID,
      })
    );
    if (!responseDeleteAdsContentsUploaded) return;

    setValue(`adsGroups.[${indexAdGroup}].ads.[${indexAd}].mediaUrl`, mediaUrl);
  };

  const onChangeStatusCampaign = async ({
    newStatus,
    target = "campaign",
    platform,
    adGroupID,
    adID,
    showAlertSave = false,
  }) => {
    const isValid = await trigger();
    if (!isValid) return;

    const objectUpdate = getCampaignUpdatedByStatus({
      campaign: getValues(),
      status: newStatus,
      target,
      platform,
      adGroupID,
      adID,
    });

    setIsFetching(true);
    const response = await dispatch(
      startSaveAdsCampaign({
        campaign: {
          ...objectUpdate,
        },
        campaignID,
      })
    );
    setIsFetching(false);

    if (response.ok) {
      setNestedValue({ data: response.campaign, setValue });
      onChangeData(response.campaign);

      if (showAlertSave) {
        SimpleAlert({
          title: t(ADS_CAMPAIGN_HAS_BEEN_SAVE),
          icon: ALERT_ICON_TYPE_SUCCESS,
        });
      }
    } else if (response.code === ERROR_PROCESSING_CAMPAIGNS) {
      setDataModalErrorsCampaigns({
        createdCampaigns: response?.createdCampaigns,
        failedCampaigns: response?.failedCampaigns,
        errors: response?.errors,
      });
      setModalErrorsCampaignsOpen(true);
      onChangeData(response?.campaign);
      setNestedValue({ data: response.campaign, setValue });
    }
  };

  const onAddAdGroup = async ({ adGroupID }) => {
    const newGroupID = dispatch(getKeyDatabase());

    const adsGroups = getValues("adsGroups");
    const currentAdGroupToCopy = adsGroups.find(
      (adGroup) => adGroup.id === adGroupID
    );

    if (!currentAdGroupToCopy) {
      console.error("Ad group not found");
      return;
    }

    const newAdGroup = _.cloneDeep(currentAdGroupToCopy);
    newAdGroup.id = newGroupID;

    const newAds = newAdGroup.ads.map((ad) => {
      const newAd = _.cloneDeep(ad);
      const newAdID = dispatch(getKeyDatabase());
      newAd.id = newAdID;
      newAd.adGroupID = newGroupID;
      return newAd;
    });

    newAdGroup.ads = newAds;

    setValue(`adsGroups`, [...adsGroups, newAdGroup]);

    await waitDelay(500);
    const nodeItem = document.getElementById(newAdGroup.id);
    if (nodeItem)
      nodeItem.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "start",
      });
  };
  const onRemoveAdGroup = ({ adGroupID }) => {
    const adsGroups = getValues("adsGroups");
    const newAdsGroups = adsGroups.filter(
      (adGroup) => adGroup.id !== adGroupID
    );
    setValue("adsGroups", newAdsGroups);
  };
  const onAddAd = async ({ adGroupID, adID }) => {
    const adGroups = getValues("adsGroups");
    const adGroupIndex = adGroups.findIndex(
      (adGroup) => adGroup.id === adGroupID
    );

    const currentAds = getValues(`adsGroups.[${adGroupIndex}].ads`);
    const adIndex = currentAds.findIndex((ad) => ad.id === adID);

    const currentAdToCopy = getValues(
      `adsGroups.[${adGroupIndex}].ads.[${adIndex}]`
    );
    if (!currentAdToCopy) {
      console.error("Ad not found");
      return;
    }

    const newAdID = dispatch(getKeyDatabase());
    const newAd = _.cloneDeep(currentAdToCopy);

    newAd.id = newAdID;

    setValue(`adsGroups.[${adGroupIndex}].ads`, [...currentAds, newAd]);

    await waitDelay(500);
    const nodeItem = document.getElementById(newAdID);
    if (nodeItem)
      nodeItem.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "start",
      });
  };
  const onRemoveAd = ({ adGroupID, adID }) => {
    const adsGroups = getValues("adsGroups");

    const adGroupIndex = adsGroups.findIndex(
      (adGroup) => adGroup.id === adGroupID
    );
    if (adGroupIndex === -1) {
      console.error("Ad group not found");
      return;
    }

    const adIndex = adsGroups[adGroupIndex].ads.findIndex(
      (ad) => ad.id === adID
    );

    if (adIndex === -1) {
      console.error("Ad not found");
      return;
    }
    const ads = getValues(`adsGroups.[${adGroupIndex}].ads`);
    const newAds = ads.filter((ad, index) => index !== adIndex);
    setValue(`adsGroups.[${adGroupIndex}].ads`, newAds);
  };

  const showForm = isCampaignCreated || isModeDraft;

  const getIsCampaignPlatformActive = (platform) => {
    const state = data?.states?.[platform];
    return (
      state === ADS_CAMPAIGN_STATUS_ACTIVE ||
      state === ADS_CAMPAIGN_STATUS_FINISHED
    );
  };
  const getIsCampaignPlatformCreated = (platform) => {
    const state = data?.states?.[platform];
    return state !== ADS_CAMPAIGN_STATUS_DRAFT;
  };
  const getShowActionsActionsPlatform = (platform) => {
    const isCampaignPlatformCreated = getIsCampaignPlatformCreated(platform);
    const state = data?.states?.[platform];
    return isCampaignPlatformCreated && state !== ADS_CAMPAIGN_STATUS_DRAFT;
  };

  const isGeneralCampaignActivated = getIsCampaignPlatformActive("general");
  const isGeneralCampaignCreated = getIsCampaignPlatformCreated("general");
  const isGeneralDisabledFields = dispatch(getUserIsViewer());

  const isAllCampaignsPlatformCreated = !data?.platforms?.some(
    (platform) => !data?.platformIDs?.[platform]
  );
  return (
    <ContainerPaperSection
      hasAccordion={false}
      hasDividers={false}
      initialValueAccordion={!hasStatistics}
      showTopButton={false}
      showBottomButton={isCampaignCreated && !isGeneralDisabledFields}
      onSubmit={handleSubmit(() =>
        onChangeStatusCampaign({
          newStatus: getValues("states.general"),
          target: "general",
          showAlertSave: true,
        })
      )}
      sx={{
        alignItems: "center",
        mt: 0,
        px: 0,
        py: 0,
      }}
      customBottomButton={
        isGeneralDisabledFields ? null : (
          <ButtonsActionsCampaign
            data={data}
            isFetching={isFetching}
            setIsFetching={setIsFetching}
            onChangeStatusCampaign={onChangeStatusCampaign}
            getValues={getValues}
          />
        )
      }
    >
      <FormProvider
        {...methods}
        campaignID={campaignID}
        currency={currency}
        onAddAdGroup={onAddAdGroup}
        onRemoveAdGroup={onRemoveAdGroup}
        onAddAd={onAddAd}
        onRemoveAd={onRemoveAd}
        getIsCampaignPlatformCreated={getIsCampaignPlatformCreated}
        isGeneralCampaignCreated={isGeneralCampaignCreated}
        getIsCampaignPlatformActive={getIsCampaignPlatformActive}
        isGeneralCampaignActivated={isGeneralCampaignActivated}
        isAllCampaignsPlatformCreated={isAllCampaignsPlatformCreated}
        isModeDraft={isModeDraft}
        isCampaignFinished={isCampaignFinished}
        hasSomePlatformDraft={hasSomePlatformDraft}
        onChangeStatusCampaign={onChangeStatusCampaign}
        getShowActionsActionsPlatform={getShowActionsActionsPlatform}
        isGeneralDisabledFields={isGeneralDisabledFields}
      >
        {(isModeDraft || hasSomePlatformDraft) && (
          <AdsCampaignReasoningContainer
            campaignID={campaignID}
            getCurrentData={getValues}
            validateFields={trigger}
            setFocus={setFocus}
            reasoning={data.reasoning}
            mode={mode}
            control={control}
            errors={errors}
            onChangeMode={onChangeMode}
            onChangeData={(newData) => {
              reset((prev) => {
                return {
                  ...prev,
                  ...newData,
                };
              });
              onChangeData(newData);
            }}
          />
        )}
        {!isModeDraft && <AdsReportCampaignState campaign={data} />}
        {showForm && (
          <Box
            ref={containerFormRef}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              mb: 2,
            }}
          >
            <AdsCampaignForm
              campaignID={campaignID}
              control={control}
              getValues={getValues}
              errors={errors}
              watch={watch}
              setValue={setValue}
            />
          </Box>
        )}
        {modalErrorsCampaignsOpen && (
          <ModalErrorsCampaigns
            modalOpen={modalErrorsCampaignsOpen}
            from="update"
            onCloseModal={() => setModalErrorsCampaignsOpen(false)}
            campaign={getValues()}
            successCampaigns={dataModalErrorsCampaigns.successCampaigns}
            failedCampaigns={dataModalErrorsCampaigns.failedCampaigns}
            errors={dataModalErrorsCampaigns.errors}
          />
        )}
      </FormProvider>
    </ContainerPaperSection>
  );
};

const ButtonsActionsCampaign = ({
  data,
  isFetching,
  onChangeStatusCampaign,
  getValues,
}) => {
  const { t } = useTranslationApp();
  const statusCampaign = getStatusAdsCampaign({
    states: data.states,
    target: "general",
  });
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        flexDirection: { xs: "column", sm: "row" },
        alignItems: "flex-end",
        gap: 2,
      }}
    >
      {statusCampaign === ADS_CAMPAIGN_STATUS_DEACTIVATED && (
        <LoadingButton
          loading={isFetching}
          variant="contained"
          onClick={() => {
            onChangeStatusCampaign({
              newStatus: getValues("states.general"),
              target: "campaign",
              showAlertSave: true,
            });
          }}
          color="primary"
          type="button"
          sx={{
            minWidth: 190,
          }}
        >
          {t(BUTTON_SAVE_CAMPAIGN)}
        </LoadingButton>
      )}
      {statusCampaign === ADS_CAMPAIGN_HAS_BEEN_FINISHED && (
        <Typography variant="h6">
          {t(ADS_CAMPAIGN_HAS_BEEN_FINISHED)}
        </Typography>
      )}
    </Box>
  );
};
export default AdsCampaignInformation;
