import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Button from "../Buttons/Button";
import {
  BUTTON_CONNECT_GOOGLE,
  COMING_SOON,
} from "../../i18n/keysTranslations";
import { useTranslationApp } from "../../lib/i18next";
import { logoPlatform } from "../../utils/string";
import { GOOGLE } from "../../utils/constants";
import Typography from "@mui/material/Typography";

const GoogleSection = ({ sx = {} }) => {
  const { t } = useTranslationApp();
  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 1, ...sx }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Avatar
          src={logoPlatform(GOOGLE)}
          variant="square"
          sx={{
            height: {
              xs: 30,
              sm: 40,
            },
            width: {
              xs: 30,
              sm: 40,
            },
            mr: 1,
            ".MuiAvatar-img": {
              objectFit: "contain",
            },
          }}
        />
        <Button
          disabled={true}
          sx={{
            minWidth: 142,
          }}
        >
          {t(BUTTON_CONNECT_GOOGLE)}
        </Button>
      </Box>
      <Typography
        variant="body1"
        sx={{
          fontStyle: "italic",
        }}
      >
        {t(COMING_SOON)}
      </Typography>
    </Box>
  );
};

export default GoogleSection;
