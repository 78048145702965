import { ADD, ADD_CARD } from "../../i18n/keysTranslations";
import { useTranslationApp } from "../../lib/i18next";
import ModalBasicLayout from "../Modal/ModalBasicLayout";
import StripePayForm from "./StripePayForm";

const ModalStripeAddCard = ({
  modalOpen,
  onCloseModal,
  onSubmit,
  saveCard,
  setDefaultCard,
}) => {
  const { t } = useTranslationApp();
  return (
    <ModalBasicLayout
      modalOpen={modalOpen}
      onCloseModal={onCloseModal}
      fullWidth={true}
      scroll="paper"
      ariaLabel={t(ADD_CARD)}
      title={t(ADD_CARD)}
    >
      <StripePayForm
        textButton={ADD}
        onSubmit={onSubmit}
        saveCard={saveCard}
        setDefaultCard={setDefaultCard}
      />
    </ModalBasicLayout>
  );
};

export default ModalStripeAddCard;
