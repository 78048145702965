import { useEffect, useState } from "react";
import ModalBasicLayout from "../ModalBasicLayout";
import { useDispatchApp } from "../../../lib/redux";
import { startGetAvatarsCreatify } from "../../../actions/user";
import Loader from "../../Loaders/Loader";
import {
  LOADING_INFORMATION,
  WE_CANT_GET_AVATARS,
} from "../../../i18n/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";

const ModalSelectAvatar = ({
  modalOpen,
  onCloseModal,
  onCallbackSelectAvatar = () => {},
}) => {
  const [avatars, setAvatars] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const { t } = useTranslationApp();
  const dispatch = useDispatchApp();

  const getInitialData = async () => {
    setIsLoading(true);
    const response = await dispatch(startGetAvatarsCreatify());
    if (response.ok) {
      setAvatars(response.data);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getInitialData();
    // eslint-disable-next-line
  }, []);

  return (
    <ModalBasicLayout
      modalOpen={modalOpen}
      onCloseModal={onCloseModal}
      fullWidth={true}
      maxWidth="lg"
      scroll="paper"
    >
      {isLoading && (
        <Loader hasMessage={true} message={t(LOADING_INFORMATION)} />
      )}

      {!isLoading && avatars?.length === 0 && (
        <Typography variant="h6" align="center">
          {t(WE_CANT_GET_AVATARS)}
        </Typography>
      )}

      {!isLoading && avatars?.length > 0 && (
        <Grid container spacing={2}>
          {avatars.map((avatar) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={avatar.id}>
              <AvatarItem
                avatar={avatar}
                onCallbackSelectAvatar={onCallbackSelectAvatar}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </ModalBasicLayout>
  );
};

const AvatarItem = ({ avatar, onCallbackSelectAvatar = () => {} }) => {
  return (
    <Card
      sx={{ maxWidth: "100%", cursor: "pointer" }}
      onClick={() => onCallbackSelectAvatar(avatar)}
    >
      <CardMedia
        component="img"
        height="200"
        image={avatar.previewImage1_1}
        alt={avatar.name}
      />
      <CardContent
        sx={{
          p: "8px !important",
        }}
      >
        <Typography variant="h6" component="div">
          {avatar.name}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default ModalSelectAvatar;
