import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useMediaQuery } from "@mui/material";
import {
  CREATE_ADS_CAMPAIGN_INTRO_TITLE,
  CREATE_ADS_CAMPAIGN_INTRO_DESCRIPTION,
  CREATE_ADS_CAMPAIGN_INTRO_FEATURE_CREATE_CAMPAIGN,
  CREATE_ADS_CAMPAIGN_INTRO_FEATURE_OPTIMIZE_WITH_AI,
  CREATE_ADS_CAMPAIGN_INTRO_FEATURE_MANAGE_SIMPLE,
  CREATE_ADS_CAMPAIGN_INTRO_FEATURE_ANALYZE_PERFORMANCE,
  CREATE_ADS_CAMPAIGN_INTRO_FEATURE_ALL_IN_ONE_PLACE,
  CREATE_ADS_CAMPAIGN_INTRO_CALL_TO_ACTION_DESCRIPTION,
  CREATE_ADS_CAMPAIGN_INTRO_CALL_TO_ACTION_BUTTON,
} from "../../../i18n/keysTranslations";
import { useNavigate } from "react-router-dom";
import {
  GRADIENT_PURPLE_FUCHSIA,
  GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT,
} from "../../../utils/colors";
import { useTranslationApp } from "../../../lib/i18next";
import { useDispatchApp } from "../../../lib/redux";
import {
  getIsBlockedActions,
  getNameBusinessTheme,
  getUserIsViewer,
} from "../../../actions/getters";
import Button from "../../../components/Buttons/Button";
import { ADS_CAMPAIGN_GENERATE_CAMPAIGN_ACTION } from "../../../utils/constants";

const AdsCampaignsIntro = ({ onCallback = () => {} }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const sxSubContainers = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: 2,
    width: "100%",
    maxWidth: "sm",
  };
  const sxItems = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 2,
  };

  const variantTextItem = isMobile ? "body2" : "body1";

  const { t } = useTranslationApp();
  const dispatch = useDispatchApp();
  const NAME_BUSINESS = dispatch(getNameBusinessTheme());
  const navigate = useNavigate();

  const isViewer = dispatch(getUserIsViewer());

  return (
    <Box
      className="animate__animated animate__fadeIn"
      sx={{
        display: "flex",
        height: {
          xs: "calc(100vh - 104px)",
          sm: "calc(100vh - 140px)",
        },
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        flexDirection: { xs: "column", lg: "row" },
        px: {
          xs: 1.5,
          md: 4,
        },
      }}
    >
      <Box sx={sxSubContainers}>
        <Box sx={sxItems}>
          <Typography
            variant={isMobile ? "h3" : "h2"}
            sx={{
              fontWeight: 900,
              background: GRADIENT_PURPLE_FUCHSIA,
              backgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            {t(CREATE_ADS_CAMPAIGN_INTRO_TITLE, {
              NAME_BUSINESS,
            })}
          </Typography>
          <Typography variant={variantTextItem}>
            {t(CREATE_ADS_CAMPAIGN_INTRO_DESCRIPTION)}
          </Typography>
        </Box>
        <Box sx={{ ...sxItems, gap: 0 }}>
          <Typography variant={variantTextItem}>
            {t(CREATE_ADS_CAMPAIGN_INTRO_FEATURE_CREATE_CAMPAIGN)}
          </Typography>
          <Typography variant={variantTextItem}>
            {t(CREATE_ADS_CAMPAIGN_INTRO_FEATURE_OPTIMIZE_WITH_AI)}
          </Typography>
          <Typography variant={variantTextItem}>
            {t(CREATE_ADS_CAMPAIGN_INTRO_FEATURE_MANAGE_SIMPLE)}
          </Typography>
          <Typography variant={variantTextItem}>
            {t(CREATE_ADS_CAMPAIGN_INTRO_FEATURE_ANALYZE_PERFORMANCE)}
          </Typography>
          <Typography variant={variantTextItem}>
            {t(CREATE_ADS_CAMPAIGN_INTRO_FEATURE_ALL_IN_ONE_PLACE)}
          </Typography>
        </Box>
        <Box sx={sxItems}>
          <Typography variant={variantTextItem}>
            {t(CREATE_ADS_CAMPAIGN_INTRO_CALL_TO_ACTION_DESCRIPTION)}
          </Typography>
          {!isViewer && (
            <Button
              sx={{
                background: GRADIENT_PURPLE_FUCHSIA,
                color: GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT,
                width: {
                  xs: "100%",
                  sm: "min-content",
                },
              }}
              onClick={async () => {
                const isBlocked = await dispatch(
                  getIsBlockedActions({
                    action: ADS_CAMPAIGN_GENERATE_CAMPAIGN_ACTION,
                    showUsageConfirmationAlert: false,
                  })
                );
                if (isBlocked) return;
                navigate("/create-ads-campaign");
                onCallback();
              }}
            >
              {t(CREATE_ADS_CAMPAIGN_INTRO_CALL_TO_ACTION_BUTTON)}
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default AdsCampaignsIntro;
