import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useDispatchApp, useSelectorApp } from "../../lib/redux";
import {
  AD_ACCOUNTS,
  ALERT_DESCRIPTION_DISCONNECT_FACEBOOK,
  ARE_YOU_SURE,
  BUTTON_CONNECT_META,
  BUTTON_CONNECTED,
  BUTTON_DISCONNECT,
  READY,
} from "../../i18n/keysTranslations";
import { useTranslationApp } from "../../lib/i18next";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Typography from "@mui/material/Typography";
import {
  ALERT_ICON_TYPE_SUCCESS,
  BORDER_RADIUS,
  META,
  META_WHITE,
} from "../../utils/constants";
import { GRADIENT_META } from "../../utils/colors";
import { logoPlatform } from "../../utils/string";
import AddIcon from "@mui/icons-material/Add";
import ButtonMetaAction from "../Buttons/ButtonMetaAction";
import IconButton from "../Buttons/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { ConfirmAlert, SimpleAlert } from "../Alerts/Alerts";
import {
  startRevokeFacebookAccount,
  startSetLastSelectedIntegrationDataByPlatform,
} from "../../actions/user";
import Loader from "../Loaders/Loader";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import _ from "lodash";

const MetaContainerIntegration = ({
  textConnected = BUTTON_CONNECTED,
  id,
  sx = {},
  onClick = () => {},
  modeConnected = "add",
  modeDisconnected = "verify",
  onChangeIntegration = () => {},
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const onOpenMenu = (e) => setAnchorEl(e.currentTarget);
  const onCloseMenu = () => setAnchorEl(null);

  const dispatch = useDispatchApp();
  const {
    isLoading,
    integrations,
    lastSelectedIntegrationData: {
      [META]: {
        adAccountID: adAccountIDSelected,
        integrationID: integrationIDSelected,
      } = {
        adAccountID: null,
        integrationID: null,
      },
    } = {},
  } = useSelectorApp((state) => state.user);

  const metaIntegrations = integrations?.facebook || {};
  const isFacebookConnected = Object.values(metaIntegrations).some(
    (integration) => integration.isActive
  );

  const { t } = useTranslationApp();

  useEffect(() => {
    if (!integrationIDSelected && !adAccountIDSelected) {
      onPreSelectData();
    }
    onChangeIntegration({
      integrationID: integrationIDSelected,
      adAccountID: adAccountIDSelected,
    });
    // eslint-disable-next-line
  }, [integrationIDSelected, adAccountIDSelected]);

  const onChangeSelectedAdAccount = ({ integrationID, adAccountID }) =>
    dispatch(
      startSetLastSelectedIntegrationDataByPlatform({
        platform: META,
        adAccountID,
        integrationID,
      })
    );

  const onPreSelectData = () => {
    if (_.isEmpty(metaIntegrations)) return;

    const integrationID = Object.keys(metaIntegrations || {})[0];
    const adAccountID = Object.keys(
      metaIntegrations?.[integrationID]?.businessAccounts || {}
    )[0];

    onChangeSelectedAdAccount({
      integrationID,
      adAccountID,
    });
  };

  const getAdAccountInformation = () => {
    const integration = metaIntegrations?.[integrationIDSelected] || {};
    const adAccount =
      integration?.businessAccounts?.[adAccountIDSelected] || {};

    return adAccount;
  };
  const searchIntegrationIDByAdAccountID = (adAccountID) => {
    const integration = Object.values(metaIntegrations).find((integration) =>
      Object.keys(integration.businessAccounts).includes(adAccountID)
    );
    return integration?.id;
  };

  const adAccount = getAdAccountInformation();

  if (isLoading) return null;

  return (
    <>
      {isFacebookConnected ? (
        <Box
          onClick={(e) => {
            onOpenMenu(e);
            onClick(e);
          }}
          sx={{
            background: GRADIENT_META,
            display: "flex",
            alignItems: "center",
            px: 2,
            borderRadius: BORDER_RADIUS,
            gap: 1,
            width: "fit-content",
            cursor: "pointer",
            minHeight: 40,
            ...sx,
          }}
        >
          <AvatarMini src={logoPlatform(META_WHITE)} />
          <Typography
            variant="body1"
            sx={{
              minWidth: 80,
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {!_.isEmpty(adAccount) ? adAccount.name : t(textConnected)}
          </Typography>
          <KeyboardArrowDownIcon />
          <Box
            sx={{
              display: "flex",
            }}
          >
            <Divider orientation="vertical" flexItem />
            <ButtonMetaAction
              id={id}
              type="icon"
              mode={modeConnected}
              icon={<AddIcon />}
              disableRipple={true}
              sx={{ mr: -2 }}
              onClick={() => {
                SimpleAlert({
                  title: t(READY),
                  icon: ALERT_ICON_TYPE_SUCCESS,
                });
              }}
            />
          </Box>
        </Box>
      ) : (
        <ButtonMetaAction
          id={id}
          mode={modeDisconnected}
          type="button"
          onClick={onClick}
          logo={META_WHITE}
          text={BUTTON_CONNECT_META}
          sx={{
            background: GRADIENT_META,
            ...sx,
          }}
        />
      )}
      {isFacebookConnected && (
        <FormControl
          sx={{
            position: "absolute",
          }}
        >
          <RadioGroup
            value={adAccountIDSelected}
            onChange={(e, adAccountID) => {
              const integrationID =
                searchIntegrationIDByAdAccountID(adAccountID);
              onChangeSelectedAdAccount({
                integrationID,
                adAccountID,
              });
            }}
          >
            <Menu anchorEl={anchorEl} open={open} onClose={onCloseMenu}>
              {Object.values(metaIntegrations).map((integration) => (
                <AccountIntegrationMenu
                  key={integration.id}
                  integration={integration}
                />
              ))}
            </Menu>
          </RadioGroup>
        </FormControl>
      )}
    </>
  );
};

const AccountIntegrationMenu = ({ integration = {} }) => {
  const [isFetching, setIsFetching] = useState(false);
  const { businessAccounts = {}, name } = integration;

  const { t } = useTranslationApp();
  const dispatch = useDispatchApp();

  const businessAccountsKeys = Object.keys(businessAccounts);

  const commonSxContainerType = {
    width: "100%",
    mt: 1,
    display: "flex",
    flexDirection: "column",
  };
  const onRemoveTokenFacebook = async () => {
    const result = await ConfirmAlert({
      title: t(ARE_YOU_SURE),
      text: t(ALERT_DESCRIPTION_DISCONNECT_FACEBOOK),
      confirmButtonText: t(BUTTON_DISCONNECT),
    });
    if (!result.isConfirmed) return;
    dispatch(
      startRevokeFacebookAccount({
        showGlobalLoading: false,
        onChangeLoading: (loading) => setIsFetching(loading),
        id: integration.id,
      })
    );
  };

  return (
    <MenuItem
      disableTouchRipple={true}
      disableRipple={true}
      sx={{
        display: "flex",
        flexDirection: "column",
        "&:hover": {
          backgroundColor: "inherit",
          cursor: "default",
        },
        mb: 0.5,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          gap: 1,
        }}
      >
        <Typography
          variant="body1"
          textAlign={"left"}
          sx={{
            width: "fit-content",
          }}
        >
          {name}
        </Typography>
        {isFetching ? (
          <Loader size={15} hasMarginTop={false} />
        ) : (
          <IconButton
            onClick={onRemoveTokenFacebook}
            sx={{
              mt: "-1px",
              p: 0,
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
      </Box>
      {businessAccountsKeys.length > 0 && (
        <Box sx={commonSxContainerType}>
          <Typography
            variant="body2"
            sx={{
              fontWeight: 700,
            }}
          >
            {t(AD_ACCOUNTS)}
          </Typography>
          {businessAccountsKeys.map((key) => (
            <FormControlLabel
              key={businessAccounts[key].id}
              value={businessAccounts[key].id}
              control={
                <Radio
                  sx={{
                    p: 0,
                    pt: 0,
                    pb: 0.5,
                    px: 1,
                  }}
                />
              }
              label={businessAccounts[key].name}
            />
          ))}
        </Box>
      )}
    </MenuItem>
  );
};

const AvatarMini = ({ src, sx }) => (
  <Avatar
    alt="Logo de Meta"
    src={src}
    variant="square"
    sx={{
      height: 20,
      width: 20,
      borderRadius: BORDER_RADIUS,
      "& .MuiAvatar-img": {
        objectFit: "contain",
      },
      ...sx,
    }}
  />
);

export default MetaContainerIntegration;
