import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import { useDispatchApp, useSelectorApp } from "../../lib/redux";
import { useTranslationApp } from "../../lib/i18next";
import { startLogout } from "../../actions/auth";
import LoadingButton from "../Buttons/LoadingButton";
import { LOGOUT } from "../../i18n/keysTranslations";
import { getNameBusinessTheme } from "../../actions/getters";
import { Link } from "react-router-dom";
import { IconButton, useMediaQuery } from "@mui/material";
import { PADDING_DESKTOP, PADDING_MOBILE } from "../../utils/constants";
import { setOpenSlideMenu } from "../../actions/ui";
import MenuIcon from "@mui/icons-material/Menu";
import SlideMenu from "../Menus/SlideMenu";
import UsageCounter from "../Billing/UsageCounter";

const NavBar = () => {
  const dispatch = useDispatchApp();
  const { theme, slideMenuIsOpen } = useSelectorApp((state) => state.ui);
  const { t } = useTranslationApp();
  const NAME_BUSINESS = dispatch(getNameBusinessTheme());

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const onLogout = () => {
    dispatch(startLogout());
  };

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          boxShadow: "none",
          backgroundImage: "none",
          backgroundColor: "background.paper",
          color: "background.paperContrastText",
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            px: {
              md: PADDING_DESKTOP,
              xs: PADDING_MOBILE,
            },
            justifyContent: "space-between",
            gap: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {isMobile && (
              <IconButton
                size="large"
                edge="start"
                onClick={() => dispatch(setOpenSlideMenu(!slideMenuIsOpen))}
              >
                <MenuIcon />
              </IconButton>
            )}
            <Link
              to="/"
              style={{
                display: "flex",
                width: "65%",
                justifyContent: "flex-start",
              }}
            >
              <Avatar
                src={theme.logo}
                alt={`Logo ${NAME_BUSINESS}`}
                variant="square"
                sx={{
                  width: "auto",
                  ".MuiAvatar-img": {
                    maxWidth: "105px",
                    objectFit: "contain",
                  },
                }}
              />
            </Link>
          </Box>
          <Box
            className="buttons-nav"
            sx={{
              display: { xs: "none", md: "flex" },
              alignItems: "center",
              gap: 1.5,
            }}
          >
            <UsageCounter />
            <LoadingButton
              color="secondary"
              hasShadow={false}
              sx={{
                height: 42,
                maxHeight: 42,
                backgroundColor: "background.paper",
                color: "background.paperContrastText",
                "&:hover": {
                  backgroundColor: "background.paper",
                  color: "background.paperContrastText",
                  filter: "brightness(0.95)",
                },
              }}
              onClick={onLogout}
            >
              {t(LOGOUT)}
            </LoadingButton>
          </Box>
        </Toolbar>
      </AppBar>
      <SlideMenu />
    </>
  );
};

export default NavBar;
