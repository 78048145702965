import { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTranslationApp } from "../../lib/i18next";
import { getReactHookFormNestedError } from "../../utils/errors";
import { Controller, useController } from "react-hook-form";
import _ from "lodash";
import Button from "../Buttons/Button";
import {
  BUTTON_CREATE_TEMPLATE,
  BUTTON_EDIT_TEMPLATE,
  FIELD_REQUIRED,
  FIRST_USER_MESSAGE,
  GREETING,
} from "../../i18n/keysTranslations";
import ModalMessageTemplate from "./ModalMessageTemplate";

const MessageTemplateContainer = ({ label, name, control, errors = {} }) => {
  const {
    field: { value, onChange },
  } = useController({
    control,
    name,
    defaultValue: "",
  });
  const [modalTemplateOpen, setModalTemplateOpen] = useState(false);
  const { t } = useTranslationApp();
  const error = getReactHookFormNestedError(errors, name);

  const onToggleModalTemplate = () => setModalTemplateOpen((prev) => !prev);

  const isEmptyTemplate = _.isEmpty(value);
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: {
          value: true,
          message: t(FIELD_REQUIRED),
        },
      }}
      render={() => (
        <Box>
          <Typography
            variant="body1"
            gutterBottom
            color={error?.message ? "error" : "default"}
          >
            {label}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            {!isEmptyTemplate && (
              <Box>
                <Typography variant="body2">{`${t(GREETING)}: ${
                  value.greeting
                }`}</Typography>
                <Typography variant="body2">{`${t(FIRST_USER_MESSAGE)}: ${
                  value?.customerTexts?.[0]
                }`}</Typography>

                <Typography
                  variant="body2"
                  onClick={onToggleModalTemplate}
                  sx={{
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                >
                  {t(BUTTON_EDIT_TEMPLATE)}
                </Typography>
              </Box>
            )}
            {isEmptyTemplate && (
              <Button type="button" onClick={onToggleModalTemplate}>
                {t(BUTTON_CREATE_TEMPLATE)}
              </Button>
            )}
          </Box>
          {error?.message && (
            <Typography variant="body2" color="error">
              {error.message}
            </Typography>
          )}
          {modalTemplateOpen && (
            <ModalMessageTemplate
              modalOpen={modalTemplateOpen}
              onCloseModal={onToggleModalTemplate}
              data={value}
              onCallbackSave={(data) => {
                onChange(data);
              }}
            />
          )}
        </Box>
      )}
    />
  );
};

export default MessageTemplateContainer;
