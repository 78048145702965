import { useContext } from "react";
import {
  ChatContainer as ChatContainerLibrary,
  MessageList,
  Message,
  TypingIndicator,
  Avatar,
} from "@chatscope/chat-ui-kit-react";
import ChatConversationHeader from "./ChatConversationHeader";
import ChatMessageInput from "./ChatMessageInput";
import { FONT_FAMILY } from "../../utils/constants";
import { getNameBusinessTheme } from "../../actions/getters";
import ChatCustomMessage from "./ChatCustomMessage";
import _ from "lodash";
import { ChatContext } from "../../contexts/ChatContext";
import { useDispatchApp } from "../../lib/redux";
import { getPathDatabaseByRole } from "../../actions/auth";

const ChatContainer = ({ onChangeShowChat, onOpenModalInfluencer }) => {
  const {
    currentChat,
    currentChatMessages,
    isTypingBot,
    logoShop,
    LOGO_BUSINESS,
    onAddMessage,
  } = useContext(ChatContext);
  const dispatch = useDispatchApp();

  const { userID } = dispatch(getPathDatabaseByRole());
  const NAME_BUSINESS = dispatch(getNameBusinessTheme());

  const currentUserID = userID;
  const allCurrentChatData = {
    name: `${NAME_BUSINESS} Team`,
    userID: currentChat.userID,
    imageURL: LOGO_BUSINESS,
  };

  const sortedMessages = Object.keys(currentChatMessages).sort(
    (a, b) =>
      currentChatMessages[a].creationTime - currentChatMessages[b].creationTime
  );

  const onSendMessageBot = async (message, file) => {
    const newMessage = await onAddMessage({
      message,
      senderID: currentUserID,
      receiverID: currentChat.userID,
      file,
    });
    return newMessage;
  };
  return (
    <ChatContainerLibrary
      style={{
        border: "none",
      }}
    >
      <ChatConversationHeader
        data={allCurrentChatData}
        as="ConversationHeader"
        onChangeShowChat={onChangeShowChat}
        onOpenModalInfluencer={onOpenModalInfluencer}
      />

      <MessageList
        typingIndicator={isTypingBot && <TypingIndicator />}
        style={{ marginTop: 0, fontFamily: FONT_FAMILY }}
      >
        {sortedMessages.map((messageID, index) => {
          const message = currentChatMessages[messageID];

          const getDirection = () => {
            if (currentChat?.from === "help" && currentChat?.helpers) {
              if (currentChat?.helpers?.includes(message.userID)) {
                return "incoming";
              } else {
                return "outgoing";
              }
            } else {
              return message.userID === currentUserID ? "outgoing" : "incoming";
            }
          };

          const direction = getDirection();

          const getImageURL = () => {
            if (
              currentChat?.from === "help" &&
              currentChat?.helpers &&
              currentChat?.helpers?.includes(message.userID)
            ) {
              return LOGO_BUSINESS;
            }
            if (message.userID === userID) {
              return logoShop;
            }
            return allCurrentChatData.imageURL;
          };

          return (
            <Message
              key={index}
              model={{
                message: message.message,
                sender: allCurrentChatData.name,
                direction: direction,
                type: "html",
              }}
              style={{
                marginBottom: 10,
              }}
            >
              <Avatar src={getImageURL()} name={allCurrentChatData.name} />
              <Message.CustomContent>
                <ChatCustomMessage
                  message={message}
                  direction={direction}
                  currentChat={currentChat}
                />
              </Message.CustomContent>
            </Message>
          );
        })}
      </MessageList>
      {!_.isEmpty(currentChat) && (
        <ChatMessageInput
          as="MessageInput"
          disabled={isTypingBot}
          onSendMessage={onSendMessageBot}
        />
      )}
    </ChatContainerLibrary>
  );
};

export default ChatContainer;
