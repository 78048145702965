import { useState, useEffect } from "react";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { startGetProgressValues } from "../../actions/ui";
import { useDispatchApp } from "../../lib/redux";
import { off } from "firebase/database";

const LinearLoaderWithTime = ({
  label,
  labels = [],
  time,
  sx,
  progress: progressValue,
  progressID,
  preAddProgress = 2,
}) => {
  const [reference, setReference] = useState(null);
  const [progress, setProgress] = useState(progressValue || 0);

  const dispatch = useDispatchApp();
  const hasProgressValue = typeof progressValue === "number";

  const getCurrentLabel = () => {
    if (labels.length === 0) return "";

    //Get label based on progress, must be distributed to cover 100% of the progress.
    const stepWidth = 100 / labels.length;
    if (progress === 100) return labels[labels.length - 1];
    let currentLabel = "";
    for (let i = 0; i < labels.length; i++) {
      if (progress < (i + 1) * stepWidth) {
        currentLabel = labels[i];
        break;
      }
    }
    return currentLabel;
  };

  const onUpdateProgress = (data = {}) => {
    const { step, totalSteps } = data;

    if (!step || !totalSteps) return;

    const newProgress = (step / totalSteps) * 100;

    if (newProgress < progress || newProgress >= 100) return;

    setProgress((step / totalSteps) * 100);
  };

  useEffect(() => {
    if (hasProgressValue) return;
    if (!progressID) return;

    dispatch(
      startGetProgressValues({
        progressID,
        onReferenceAvailable: (ref) => setReference(ref),
        onCallbackUpdateProgress: onUpdateProgress,
      })
    );

    setTimeout(() => {
      setProgress((prevProgress) => prevProgress + preAddProgress);
    }, [2000]);

    setTimeout(() => {
      setProgress((prevProgress) => prevProgress + preAddProgress);
    }, [4000]);

    return () => {
      if (reference) {
        off(reference);
      }
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (hasProgressValue) return;
    if (progressID) return;
    const maxProgress = 99; //Max progress value
    const baseIncrement = 100 / (time / 1000); //Increment value based on time
    const timer = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= maxProgress) {
          return prevProgress;
        }

        //Slow down the increment as it approaches 100%
        const slowDownFactor = (100 - prevProgress) / 100;
        const increment = baseIncrement * slowDownFactor;

        return Math.min(prevProgress + increment, maxProgress);
      });
    }, 1000);

    return () => {
      clearInterval(timer);
    };
    // eslint-disable-next-line
  }, [time]);

  useEffect(() => {
    if (hasProgressValue) {
      setProgress(progressValue);
    }
    // eslint-disable-next-line
  }, [progressValue]);

  return (
    <Box sx={{ width: "100%", ...sx }}>
      <Typography variant="body1" align="center" sx={{}}>
        {label ? label : getCurrentLabel()}
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
        <Box sx={{ width: "100%", mr: 1 }}>
          <LinearProgress
            variant="determinate"
            value={progress}
            sx={{ borderRadius: 5 }}
          />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            progress
          )}%`}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default LinearLoaderWithTime;
