import Box from "@mui/material/Box";

const AnswerContainerTitleAndField = ({ children }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "center",
        flexGrow: 1,
        width: "100%",
        mt: { xs: 0, md: -10 },
      }}
    >
      {children}
    </Box>
  );
};

export default AnswerContainerTitleAndField;
