import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelectorApp } from "../../lib/redux";

const Error404 = () => {
  const navigate = useNavigate();
  const { user } = useSelectorApp((state) => state.auth);

  useEffect(() => {
    user ? navigate("/") : navigate("/login");
    // eslint-disable-next-line
  }, []);
  return <div>Error404</div>;
};

export default Error404;
