import i18next from "i18next";
import React, { useEffect, useState } from "react";
import { FIELD_REQUIRED, LOADING } from "../../i18n/keysTranslations";
import { Controller } from "react-hook-form";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import { useTranslationApp } from "../../lib/i18next";
import { getReactHookFormNestedError } from "../../utils/errors";
import { useDispatchApp } from "../../lib/redux";
import { startGetWhatsappBusinessPhoneNumbers } from "../../actions/adsCampaigns";

const SelectWhatsappBusinessNumber = ({
  control,
  errors,
  rules = {
    required: {
      value: true,
      message: i18next.t(FIELD_REQUIRED),
    },
  },
  name,
  label,
  disabled,
  integrationID,
  fullWidth,
  variant = "filled",
  placeholder,
  helperText,
  onChangeAction = () => {},
}) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const error = getReactHookFormNestedError(errors, name);
  const { t } = useTranslationApp();
  const dispatch = useDispatchApp();

  const onGetWhatsappBusinessNumbers = async () => {
    setLoading(true);
    const response = await dispatch(
      startGetWhatsappBusinessPhoneNumbers({
        integrationID,
      })
    );
    if (response.ok) {
      const data = response.data;
      if (data?.length > 0) {
        setOptions(data);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    onGetWhatsappBusinessNumbers();
    // eslint-disable-next-line
  }, [integrationID]);
  return (
    <Controller
      defaultValue={""}
      control={control}
      name={name}
      rules={rules}
      render={({ field }) => (
        <Autocomplete
          {...field}
          fullWidth={fullWidth}
          disabled={disabled}
          loadingText={t(LOADING)}
          loading={loading}
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          onChange={(_, value) => {
            field.onChange(value);
            onChangeAction(value);
          }}
          isOptionEqualToValue={(option, value) => {
            return option?.id === value.id;
          }}
          getOptionLabel={(option) => {
            if (!option) return "";
            const optionValue = options.find((opt) => opt.id === option.id);
            if (optionValue) {
              return `${optionValue.phone} - ${optionValue.name}`;
            }
            return `${option.phone} - ${option.name}`;
          }}
          options={options}
          renderOption={(props, option) => {
            return (
              <Box
                {...props}
                component="li"
                key={option.value}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: 1,
                  cursor: "pointer",
                }}
              >
                <Typography variant="body2">
                  {option.phone} - {option.name}
                </Typography>
              </Box>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              variant={variant}
              placeholder={field?.value?.length > 0 ? "" : placeholder}
              error={!!error}
              helperText={
                <Box
                  component="span"
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span>{!!error ? error?.message : helperText}</span>
                </Box>
              }
              sx={{
                ".MuiFormHelperText-root": {
                  ml: "0 !important",
                },
              }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      )}
    />
  );
};

export default SelectWhatsappBusinessNumber;
