import Typography from "@mui/material/Typography";
import ModalBasicLayout from "./ModalBasicLayout";

const ModalTooltip = ({ isOpen, onCloseModal, title, content }) => {
  return (
    <ModalBasicLayout
      modalOpen={isOpen}
      onCloseModal={onCloseModal}
      fullWidth={true}
      scroll="paper"
      ariaLabel={`Modal de ${title}`}
    >
      <Typography
        variant="h5"
        color="inherit"
        align="left"
        sx={{ fontWeight: 600, mb: 1 }}
      >
        {title}
      </Typography>
      {content}
    </ModalBasicLayout>
  );
};

export default ModalTooltip;
