import { Controller, useForm } from "react-hook-form";
import Box from "@mui/material/Box";
import InputBase from "@mui/material/InputBase";
import { useTranslationApp } from "../../lib/i18next";
import {
  DISCOUNT_CODE_NOT_APPLICABLE_TO_THIS_PRODUCT,
  DISCOUNT_CODE_NOT_FOUND,
  DISCOUNT_CODE_PLACEHOLDER,
} from "../../i18n/keysTranslations";
import { useDebounced } from "../../hooks/useDebounce";
import { useState } from "react";
import { useDispatchApp } from "../../lib/redux";
import { startGetDiscountCode } from "../../actions/payments";
import { DELAY_DEBOUNCE } from "../../utils/constants";
import Typography from "@mui/material/Typography";
import Loader from "../Loaders/Loader";

const DiscountCodeStripeField = ({
  onChangeDiscount = () => {},
  productID,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { control } = useForm({
    defaultValues: {
      discountCode: "",
    },
  });
  const { t } = useTranslationApp();
  const dispatch = useDispatchApp();

  const getDiscountCode = useDebounced(
    async (discountCode) => {
      if (!discountCode || discountCode.length < 3) {
        setIsLoading(false);
        return;
      }
      const response = await dispatch(startGetDiscountCode({ discountCode }));
      if (response.ok) {
        const discountCodeData = response.data;
        const onlyApplyTo = discountCodeData.onlyApplyTo;
        if (
          onlyApplyTo?.length > 0 &&
          (!productID || !onlyApplyTo.includes(productID))
        ) {
          setError(t(DISCOUNT_CODE_NOT_APPLICABLE_TO_THIS_PRODUCT));
          setIsLoading(false);
          return;
        }

        setError(null);

        onChangeDiscount(discountCodeData);
      } else {
        setError(t(DISCOUNT_CODE_NOT_FOUND));
      }
      setIsLoading(false);
    },
    [DELAY_DEBOUNCE]
  );
  return (
    <>
      <Box
        sx={{
          width: "100%",
          backgroundColor: "background.paperSecondary",
          px: 2,
          py: 1,
          borderRadius: 1,
          display: "flex",
          alignItems: "center",
        }}
      >
        <Controller
          control={control}
          name="discountCode"
          render={({ field }) => (
            <>
              <InputBase
                sx={{ ml: 1, flex: 1, width: "100%" }}
                placeholder={t(DISCOUNT_CODE_PLACEHOLDER)}
                name="name"
                background="paperSecondary"
                inputProps={{ "aria-label": t(DISCOUNT_CODE_PLACEHOLDER) }}
                {...field}
                onChange={(e) => {
                  field.onChange(e);
                  setIsLoading(true);
                  setError(null);
                  getDiscountCode(e.target.value);
                }}
              />
              {isLoading && <Loader size={15} hasMarginTop={false} />}
            </>
          )}
        />
      </Box>
      {error && (
        <Typography variant="body2" color="error">
          {t(error)}
        </Typography>
      )}
    </>
  );
};

export default DiscountCodeStripeField;
