import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
  DELIVERY_STATUS,
  OPERATION_STATUS,
  RETENTION_DAYS,
  TYPE,
} from "../../../../i18n/keysTranslations";
import { useTranslationApp } from "../../../../lib/i18next";
import Checkbox from "@mui/material/Checkbox";
import { BORDER_RADIUS } from "../../../../utils/constants";

const CustomAudienceListItem = ({
  customAudience,
  selectedItems,
  onChangeSelectedItems = () => {},
}) => {
  const hasErrorDeliveryStatus = customAudience?.deliveryStatus?.code !== 200;
  const hasErrorOperationStatus = customAudience?.operationStatus?.code !== 200;
  const hasErrorsStatus = hasErrorDeliveryStatus || hasErrorOperationStatus;

  const isChecked = selectedItems.find((v) => v.id === customAudience.id);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        gap: 1,
      }}
    >
      <Checkbox
        disabled={hasErrorsStatus && false}
        edge="start"
        checked={Boolean(isChecked)}
        onChange={() => {
          onChangeSelectedItems(
            isChecked
              ? selectedItems.filter((v) => v.id !== customAudience.id)
              : [...selectedItems, customAudience]
          );
        }}
        sx={{
          ml: 0,
          p: 0,
          pt: 0.3,
        }}
      />
      <CustomAudienceData customAudience={customAudience} />
    </Box>
  );
};

const CustomAudienceData = ({ customAudience }) => {
  const { t } = useTranslationApp();
  const theme = useTheme();

  const hasErrorDeliveryStatus = customAudience?.deliveryStatus?.code !== 200;
  const hasErrorOperationStatus = customAudience?.operationStatus?.code !== 200;
  const hasErrorsStatus = hasErrorDeliveryStatus || hasErrorOperationStatus;
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        border: `${theme.palette.border.grey} 1px solid`,
        borderRadius: BORDER_RADIUS,
        p: 1,
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        <Typography
          variant="body1"
          sx={{
            fontWeight: 600,
          }}
        >
          {customAudience.name}
        </Typography>
        {customAudience.description && (
          <Typography variant="body1" sx={{}}>
            {customAudience.description}
          </Typography>
        )}
        {customAudience.retentionDays && (
          <Typography variant="body1">{`${t(RETENTION_DAYS)}: ${
            customAudience.retentionDays
          }`}</Typography>
        )}
        {hasErrorsStatus && false && (
          <Box>
            {hasErrorDeliveryStatus && (
              <Typography variant="body1">{`${t(DELIVERY_STATUS)}: ${
                customAudience.deliveryStatus.description
              }`}</Typography>
            )}
            {hasErrorOperationStatus && (
              <Typography variant="body1">{`${t(OPERATION_STATUS)}: ${
                customAudience.operationStatus.description
              }`}</Typography>
            )}
          </Box>
        )}
      </Box>
      <Box>
        <Typography variant="body1">{`${t(TYPE)}: ${
          customAudience.type
        }`}</Typography>
      </Box>
    </Box>
  );
};

export default CustomAudienceListItem;
