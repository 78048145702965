import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import enResource from "./en";
import esResource from "./es";
import {
  format as formatDate,
  formatDistance,
  formatRelative,
  isDate,
} from "date-fns";
import en from "date-fns/locale/en-US";
import es from "date-fns/locale/es";
import { capitalizeFirstLetter } from "../utils/string";

const locales = { "en-US": en, es }; // used to look up the required locale

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: "en",
    debug: import.meta.env.VITE_IS_STAGING === "false" ? false : true,
    resources: {
      en: enResource,
      es: esResource,
    },
    interpolation: {
      escapeValue: false,
      format: (value, format, lng, edit) => {
        const locale = locales[lng];

        const isESLocale = lng === "es";

        if (isDate(value)) {
          if (format === "hour")
            return formatDate(value, "hh:mm aaaa", { locale });
          if (format === "day") return formatDate(value, "dd", { locale });
          if (format === "dayShort") return formatDate(value, "d", { locale });
          if (format === "dayText") return formatDate(value, "eee", { locale });
          if (format === "dayTextLong")
            return formatDate(value, "eeee", { locale });
          if (format === "month") return formatDate(value, "MM", { locale });
          if (format === "monthText")
            return capitalizeFirstLetter(formatDate(value, "MMMM", { locale }));
          if (format === "monthTextShort")
            return capitalizeFirstLetter(formatDate(value, "MMM", { locale }));
          if (format === "year") return formatDate(value, "yyyy", { locale });
          if (format === "short") return formatDate(value, "PPP", { locale });
          if (format === "shortWithoutYear")
            return capitalizeFirstLetter(
              formatDate(value, "MMMM d", { locale })
            );
          if (format === "long") return formatDate(value, "Pp", { locale });
          if (format === "relative")
            return formatRelative(value, new Date(), { locale });
          if (format === "ago")
            return formatDistance(value, new Date(), {
              locale,
              addSuffix: true,
            });

          return formatDate(value, format, { locale });
        }

        if (typeof value === "number" || typeof Number(value) === "number") {
          if (format === "number") {
            return Number(value).toLocaleString(lng);
          }

          if (isESLocale) {
            return `$${Number(value).toLocaleString(lng, {
              style: "currency",
              currency: edit.currency,
              currencyDisplay: "code",
            })}`;
          }
          return Number(value).toLocaleString(lng, {
            style: "currency",
            currency: edit.currency,
            currencyDisplay: "symbol",
          });
        }

        return value;
      },
    },
  });
