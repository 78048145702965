import types from "../types";

const initialState = {
  user: null,
  isLoading: true,
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.AUTH_LOGIN:
      return {
        ...state,
        isLoading: false,
        user: action.payload,
      };
    case types.AUTH_UPDATE_USER:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
      };
    case types.AUTH_LOADING_FINISH:
      return {
        ...state,
        isLoading: false,
      };
    case types.AUTH_LOGOUT:
      return {
        ...state,
        user: null,
      };
    case types.CHANGE_ADVERTISER_ID_CUSTOM_CLAIM:
      return {
        ...state,
        user: {
          ...state.user,
          advertiserID: action.payload,
        },
      };
    case types.AUTH_RENEW_TOKEN:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
      };
    case types.GLOBAL_RESET_STATE:
      return initialState;
    default:
      return state;
  }
};
