import { useTranslationApp } from "../../lib/i18next";
import Loader from "../Loaders/Loader";
import LinearLoaderWithTime from "../Loaders/LinearLoaderWithTime";
import Typography from "@mui/material/Typography";
import {
  EMPTY_RESULTS_SHOW,
  LOADING_INFORMATION,
  PAGE_IN_TABLE,
  RECORDS_IN_TABLE,
} from "../../i18n/keysTranslations";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import Paper from "@mui/material/Paper";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import IconButton from "@mui/material/IconButton";
import TableContainerMUI from "@mui/material/TableContainer";

const TableContainer = ({
  children,
  elevation = 1,
  page,
  onPageChange,
  keys,
  rowsPerPage,
  ariaLabel = "",
  hasPagination = true,
  sx,
  additionalValidationNext = false,
  additionalValidationEmpty = false,
  rounded = true,
  borderSelectionTop = false,
  loading = false,
  loadingText = LOADING_INFORMATION,
  hasMarginBottom = true,
  emptyText = EMPTY_RESULTS_SHOW,
  isFiltering = false,
  loaderType = "circular",
  loaderTime = 30,
}) => {
  const { t } = useTranslationApp();

  const getCountRecords = ({ page, rowsPerPage, keys }) => {
    if (keys === 0) return 0;
    var firstRecord = (page - 1) * rowsPerPage + 1;
    var lastRecord = Math.min(page * rowsPerPage, keys);
    return firstRecord + "-" + lastRecord;
  };

  if (loading && loaderType === "circular")
    return (
      <Loader
        size={40}
        fullWidth
        hasMessage={true}
        message={t(loadingText)}
        variantMessage="body1"
      />
    );

  if (loading && loaderType === "linear") {
    return (
      <LinearLoaderWithTime label={t(loadingText)} time={loaderTime * 1000} />
    );
  }

  if ((keys.length === 0 || additionalValidationEmpty) && !loading)
    return (
      <Typography variant="body1" align="center">
        {t(isFiltering ? EMPTY_RESULTS_SHOW : emptyText)}
      </Typography>
    );
  return (
    <Box sx={{ width: "100%", mb: hasMarginBottom ? 14 : 0, overflow: "auto" }}>
      <TableContainerMUI
        component={Paper}
        elevation={elevation}
        sx={{
          backgroundColor: "background.paper",
          borderTopLeftRadius: rounded ? 20 : 0,
          borderTopRightRadius: rounded ? 20 : 0,
          borderColor: borderSelectionTop ? "#000" : "transparent",
          borderWidth: borderSelectionTop ? 2 : 0,
          borderStyle: borderSelectionTop ? "solid" : "none",
          ...sx,
        }}
      >
        <Table stickyHeader sx={{ minWidth: 500 }} aria-label={ariaLabel}>
          {children}
        </Table>
      </TableContainerMUI>
      {hasPagination && (
        <Paper
          sx={{
            borderRadius: 0,
            backgroundColor: "background.paper",
            backgroundImage: "none",
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            borderBottomLeftRadius: 20,
            borderBottomRightRadius: 20,
            gap: 1,
          }}
          elevation={1}
        >
          <Typography variant="body2">{`${getCountRecords({
            page: page + 1,
            rowsPerPage,
            keys: keys.length,
          })} ${t(RECORDS_IN_TABLE)}`}</Typography>
          <Typography variant="body2">{`${t(PAGE_IN_TABLE)} N°${
            page + 1
          }`}</Typography>
          <Box>
            <IconButton
              disabled={page === 0 || loading}
              onClick={() => onPageChange(page - 1)}
            >
              <NavigateBeforeIcon />
            </IconButton>
            <IconButton
              onClick={() => onPageChange(page + 1)}
              disabled={
                keys.length <= rowsPerPage * (page + 1) ||
                additionalValidationNext ||
                loading
              }
            >
              <NavigateNextIcon />
            </IconButton>
          </Box>
        </Paper>
      )}
    </Box>
  );
};

export default TableContainer;
