import Drawer from "@mui/material/Drawer";
import { DRAWER_WIDTH } from "../../../utils/constants";
import { useMediaQuery } from "@mui/material";
import ConfigurationCreatifyForm from "./ConfigurationCreatifyForm";

const DrawerConfigurationCreatify = ({ open, onClose = () => {} }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const variant = isMobile ? "temporary" : "permanent";

  return (
    <Drawer
      open={open}
      onClose={onClose}
      sx={{
        backgroundColor: "background.paper",
        backgroundImage: "none",
        zIndex: 1300,
        maxWidth: DRAWER_WIDTH * 1.75,
        width: "100%",
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          backgroundColor: "background.paper",
          backgroundImage: "none",
          maxWidth: DRAWER_WIDTH * 1.75,
          width: "100%",
          boxSizing: "border-box",
          pl: "28px",
          pr: "24px",
          pt: 1,
        },
      }}
      variant={variant}
      anchor="left"
    >
      <ConfigurationCreatifyForm onCloseDrawer={onClose} />
    </Drawer>
  );
};

export default DrawerConfigurationCreatify;
