import Box from "@mui/material/Box";
import SecurityIcon from "@mui/icons-material/Security";
import Typography from "@mui/material/Typography";
import { PAYMENT_METHODS_ENCRYPTED } from "../../i18n/keysTranslations";
import { useTranslationApp } from "../../lib/i18next";

const StripeInformationCollected = ({ align = "flex-end", sx }) => {
  const { t } = useTranslationApp();
  return (
    <Box sx={{ display: "flex", justifyContent: align, gap: 1, mt: 1, ...sx }}>
      <Typography variant="caption">{t(PAYMENT_METHODS_ENCRYPTED)}</Typography>
      <SecurityIcon fontSize="small" />
    </Box>
  );
};

export default StripeInformationCollected;
