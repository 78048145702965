import addDays from "date-fns/addDays";
import types from "../types";
import {
  ADS_ACTION_TYPE_COINS,
  ADS_ACTION_TYPE_CREDITS,
  RECURRENT_MULTIPLIER,
} from "../utils/constants";
const initialState = {
  stripe: {
    stripeCustomerID: "",
    defaultPaymentMethod: {
      brand: "",
      last4: "",
      id: "",
    },
  },
  subscriptionPlan: "",
  subscriptionDate: "",
  subscriptionPlanInfo: {},
  payments: {},
  plans: {},
  isPlanActive: false,
  subscriptionID: "",
  isLoading: true,
};

export const paymentsReducer = (state = initialState, action) => {
  let planPaid;
  switch (action.type) {
    case types.GET_PAYMENTS_FINISH:
      return {
        ...state,
        payments: action.payload,
        isLoading: false,
      };
    case types.PAY_SUBSCRIPTION_ADS:
      planPaid = state.plans[action.payload.planID];
      return {
        ...state,
        isPlanActive: true,
        subscriptionPlan: action.payload.planID,
        subscriptionPlanInfo: {
          renewDate: addDays(new Date(), 30),
          price: planPaid.price,
          benefits: planPaid?.benefits,
          usage: {
            [ADS_ACTION_TYPE_CREDITS]:
              planPaid?.benefits?.[ADS_ACTION_TYPE_CREDITS] *
              RECURRENT_MULTIPLIER[action.payload.recurrent],
            [ADS_ACTION_TYPE_COINS]:
              planPaid?.benefits?.[ADS_ACTION_TYPE_COINS] *
              RECURRENT_MULTIPLIER[action.payload.recurrent],
          },
        },
      };
    case types.CHANGE_SUBSCRIPTION_ADS:
      planPaid = state.plans[action.payload.planID];
      return {
        ...state,
        isPlanActive: true,
        subscriptionPlan: action.payload.planID,
        subscriptionPlanInfo: {
          ...state.subscriptionPlanInfo,
          price: planPaid.price,
          benefits: planPaid?.benefits,
          usage: action.payload.usage,
        },
      };
    case types.CANCEL_SUBSCRIPTION:
      return {
        ...state,
        subscriptionPlanInfo: {
          ...state.subscriptionPlanInfo,
          cancelDateTime: action.payload,
        },
      };
    case types.REACTIVATE_SUBSCRIPTION:
      delete state.subscriptionPlanInfo.cancelDateTime;
      return {
        ...state,
      };
    case types.SET_DEFAULT_PAYMENT_METHOD:
      return {
        ...state,
        stripe: {
          ...state.stripe,
          defaultPaymentMethod: action.payload,
        },
        isLoading: false,
      };
    case types.SET_CUSTOMER_ID:
      return {
        ...state,
        stripe: {
          ...state.stripe,
          stripeCustomerID: action.payload,
        },
        isLoading: false,
      };
    case types.GET_STRIPE_INFO_FINISH:
      return {
        ...state,
        stripe: { ...state.stripe, ...action.payload },
        isLoading: false,
      };

    case types.PAY_SUBSCRIPTION:
      planPaid = state.plans[action.payload.planID];
      return {
        ...state,
        subscriptionPlan: action.payload.planID,
        subscriptionPlanInfo: {
          price: planPaid?.price,
          benefits: planPaid?.benefits,
        },
      };
    case types.GET_PLANS_FINISH:
      return {
        ...state,
        plans: action.payload,
      };
    case types.GET_SUBSCRIPTION_PLAN:
      return {
        ...state,
        subscriptionPlan: action.payload,
      };
    case types.GET_SUBSCRIPTION_PLAN_INFO:
      return {
        ...state,
        subscriptionPlanInfo: action.payload,
      };
    case types.GET_SUBSCRIPTION_PLAN_INFO_USAGE:
      return {
        ...state,
        subscriptionPlanInfo: {
          ...state.subscriptionPlanInfo,
          usage: action.payload,
        },
      };
    case types.PAY_INVOICE:
      return {
        ...state,
        payments: {
          ...state.payments,
          [action.payload.invoiceID]: {
            ...state.payments[action.payload.invoiceID],
            paymentID: action.payload.paymentID,
            invoiceID: action.payload.invoiceIDStripe,
            paidDate: action.payload.paidDate,
            isPaid: true,
          },
        },
      };

    case types.SET_IS_ACTIVE_PLAN:
      return {
        ...state,
        isPlanActive: action.payload,
      };
    case types.LOADING_PAYMENTS_FINISH:
      return {
        ...state,
        isLoading: false,
      };
    case types.GLOBAL_RESET_STATE:
      return initialState;
    case types.GLOBAL_RESET_ADVERTISER_DATA:
      return initialState;
    default:
      return state;
  }
};
