import IconButtonMUI from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { useTranslationApp } from "../../lib/i18next";

const IconButton = ({
  id = "",
  children,
  sx = {},
  disabled = false,
  disableRipple = false,
  onClick = () => {},
  tooltipText,
  edge = "end",
}) => {
  const { t } = useTranslationApp();
  return (
    <Tooltip title={t(tooltipText)}>
      <IconButtonMUI
        id={id}
        disableRipple={disableRipple}
        disabled={disabled}
        onClick={(e) => onClick(e)}
        sx={sx}
        edge={edge}
      >
        {children}
      </IconButtonMUI>
    </Tooltip>
  );
};

export default IconButton;
