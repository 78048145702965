import ContainerPaperSection from "../../../components/Containers/ContainerPaperSection";
import GoogleSection from "../../../components/Containers/GoogleSection";
import MetaContainerIntegration from "../../../components/Meta/MetaContainerIntegration";
import TikTokBusinessContainerIntegration from "../../../components/TikTokBusiness/TikTokBusinessContainerIntegration";
import { PLATFORMS_CONNECTED_TITLE } from "../../../i18n/keysTranslations";
import Box from "@mui/material/Box";

const ConnectedAccounts = () => {
  return (
    <ContainerPaperSection
      title={PLATFORMS_CONNECTED_TITLE}
      showTopButton={false}
      showBottomButton={false}
      onSubmit={(e) => {
        e.preventDefault();
      }}
      className="platforms-connect-section"
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <MetaContainerIntegration
          sx={{
            width: 205,
          }}
        />
        <TikTokBusinessContainerIntegration
          sx={{
            width: 205,
          }}
        />
        <GoogleSection />
      </Box>
    </ContainerPaperSection>
  );
};

export default ConnectedAccounts;
