import { useState } from "react";
import ModalBasicLayout from "../../../components/Modal/ModalBasicLayout";
import {
  MODAL_PAY_INVOICE_ARIA_LABEL,
  MODAL_PAY_INVOICE_TITLE,
  MODAL_PAY_INVOICE_WILL_PAY,
  PAY,
} from "../../../i18n/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";
import { useDispatchApp, useSelectorApp } from "../../../lib/redux";
import { useStripe } from "@stripe/react-stripe-js";
import { startPayInvoice } from "../../../actions/payments";
import { onInit3DSecureAuth } from "../../../services/stripe";
import StripePayForm from "../../../components/Billing/StripePayForm";
import RadioGroupCards from "../../../components/Billing/RadioGroupCards";
import ContainerModalActions from "../../../components/Containers/ContainerModalActions";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";

const ModalPayInvoice = ({ modalOpen, onCloseModal, invoiceID, data }) => {
  const [card, setCard] = useState(0);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslationApp();

  const dispatch = useDispatchApp();
  const stripe = useStripe();

  const {
    defaultPaymentMethod: { id },
  } = useSelectorApp((state) => state.payments.stripe);

  const onPayInvoice = async (paymentMethodID = card, paymentIntent) => {
    setLoading(true);
    const response = await dispatch(
      startPayInvoice({
        invoiceID,
        paymentMethodID,
        paymentIntent,
      })
    );
    if (response.ok) {
      setLoading(false);
      onCloseModal();
      return { ok: true };
    }
    if (!response.ok && response.status === "requiresAction") {
      const responseVerification = await onInit3DSecureAuth({
        clientSecret: response.clientSecret,
        stripe,
        onCallbackFailed: onCloseModal,
      });
      setLoading(false);
      if (responseVerification.ok) {
        const paymentIntent = responseVerification.paymentIntent;
        await onPayInvoice(paymentMethodID, paymentIntent);
        return { ok: true };
      }
    }
    setLoading(false);
    return { ok: false };
  };
  return (
    <ModalBasicLayout
      modalOpen={modalOpen}
      onCloseModal={onCloseModal}
      maxWidth="sm"
      fullWidth
      scroll="paper"
      ariaLabel={t(MODAL_PAY_INVOICE_ARIA_LABEL)}
      title={t(MODAL_PAY_INVOICE_TITLE, {
        value: invoiceID,
      })}
    >
      {id.length === 0 ? (
        <StripePayForm
          textButton={t(PAY)}
          onSubmit={(paymentMethod) => onPayInvoice(paymentMethod.id)}
          saveCard={false}
          setDefaultCard={false}
        />
      ) : (
        <RadioGroupCards
          onSelectCard={(paymentMethod) => setCard(paymentMethod)}
          onCallbackMakeDefaultPaymentMethod={onCloseModal}
          showMakeDefaultPaymentMethod={false}
        />
      )}
      <Typography variant="body1" align="center" sx={{ mt: 2 }}>
        {t(MODAL_PAY_INVOICE_WILL_PAY, {
          value: data.value,
          currency: data?.currency || "USD",
        })}
      </Typography>
      {id.length > 0 && (
        <DialogActions sx={{ p: 0 }}>
          <ContainerModalActions
            propsPrimaryButton={{
              showButton: true,
              text: PAY,
              loading,
              onClick: () => onPayInvoice(),
              disabled: !card,
            }}
          />
        </DialogActions>
      )}
    </ModalBasicLayout>
  );
};

export default ModalPayInvoice;
