import axios from "axios";
import { FUNCTIONS_URL, getPathDatabaseByRole, renewToken } from "./auth";
import { LaunchError } from "../utils/errors";
import types from "../types";
import { get, onValue, ref } from "firebase/database";
import { db } from "../lib/firebase";
import { trackingPurchase } from "../services/tracking";
import { openLink } from "../utils/string";

export const startCreateStripeSource =
  ({ paymentMethodID, setDefault = false, activatePlan = true }) =>
  async (dispatch) => {
    try {
      const token = await dispatch(renewToken());

      const body = {
        paymentMethodID,
        setDefault,
        activatePlan,
      };

      const { data } = await axios({
        method: "post",
        url: `${FUNCTIONS_URL}/createStripeSource`,
        data: body,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (data.ok) {
        setDefault && dispatch(startSetStripeSourceDefault(data.data));
        setDefault && dispatch(setStripeCustomerID(data.data.customerID));
      }
      return data;
    } catch (error) {
      dispatch(LaunchError(error));
      return { ok: false };
    }
  };
export const startCancelSubscription = () => async (dispatch) => {
  try {
    const token = await dispatch(renewToken());

    const { data } = await axios({
      method: "post",
      url: `${FUNCTIONS_URL}/cancelSubscriptionStripe`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!data.ok) {
      return { ok: false };
    }

    const cancelDateTime = data?.data?.cancelDateTime;
    console.log(data?.data);
    console.log(cancelDateTime);
    dispatch(cancelSubscription(cancelDateTime));

    return { ok: true, cancelDateTime };
  } catch (error) {
    dispatch(LaunchError(error));
    return { ok: false };
  }
};
const cancelSubscription = (data) => ({
  type: types.CANCEL_SUBSCRIPTION,
  payload: data,
});

export const startReactivateSubscription = () => async (dispatch) => {
  try {
    const token = await dispatch(renewToken());

    const { data } = await axios({
      method: "post",
      url: `${FUNCTIONS_URL}/reactiveSubscriptionStripe`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!data.ok) {
      return { ok: false };
    }

    dispatch(reactivateSubscription());

    return { ok: true };
  } catch (error) {
    dispatch(LaunchError(error));
    return { ok: false };
  }
};
const reactivateSubscription = () => ({
  type: types.REACTIVATE_SUBSCRIPTION,
});

export const startSetStripeSourceDefault = (cardData) => async (dispatch) => {
  try {
    dispatch(setStripeSourceDefault(cardData));
    return true;
  } catch (error) {
    dispatch(LaunchError(error));
    return false;
  }
};

const setStripeSourceDefault = (data) => ({
  type: types.SET_DEFAULT_PAYMENT_METHOD,
  payload: data,
});
const setStripeCustomerID = (data) => ({
  type: types.SET_CUSTOMER_ID,
  payload: data,
});

export const startDeleteStripeSource =
  ({ paymentMethodID }) =>
  async (dispatch) => {
    try {
      const token = await dispatch(renewToken());

      const body = {
        paymentMethodID,
      };

      const { data } = await axios({
        method: "post",
        url: `${FUNCTIONS_URL}/deleteStripeSource`,
        data: body,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return data;
    } catch (error) {
      dispatch(LaunchError(error));
      return false;
    }
  };

export const startGetStripeSources = () => async (dispatch) => {
  try {
    const token = await dispatch(renewToken());

    const { data } = await axios({
      method: "post",
      url: `${FUNCTIONS_URL}/getStripeSources`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    dispatch(LaunchError(error));
    return false;
  }
};

export const startMakeStripeSourceDefault =
  ({ paymentMethodID }) =>
  async (dispatch) => {
    try {
      const token = await dispatch(renewToken());

      const body = {
        paymentMethodID,
      };

      const { data } = await axios({
        method: "post",
        url: `${FUNCTIONS_URL}/makeStripeSourceDefault`,
        data: body,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return data;
    } catch (error) {
      dispatch(LaunchError(error));
      return false;
    }
  };

export const startGetStripeInfo = () => async (dispatch) => {
  try {
    const { pathBase } = dispatch(getPathDatabaseByRole());
    const dbRef = ref(db, `${pathBase}/stripe`);

    const snapshot = await get(dbRef);

    if (snapshot.exists()) {
      const data = snapshot.val();
      dispatch(getStripeInfo(data));
      return data;
    }
    dispatch(loadingFinishPayments());

    return true;
  } catch (error) {
    dispatch(LaunchError(error));
    return false;
  }
};

const getStripeInfo = (data) => ({
  type: types.GET_STRIPE_INFO_FINISH,
  payload: data,
});

export const startPayInvoice =
  ({ invoiceID, paymentMethodID, hasSingleCharge = true, paymentIntent }) =>
  async (dispatch) => {
    try {
      const token = await dispatch(renewToken());
      const { data } = await axios({
        method: "post",
        url: `${FUNCTIONS_URL}/payInvoice`,
        data: {
          invoiceID,
          paymentMethodID,
          hasSingleCharge,
          paymentIntent,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.ok) {
        dispatch(
          payInvoice({
            invoiceID,
            paymentID: data.data.paymentID,
            invoiceIDStripe: data.data.invoiceID,
            paidDate: data.data.paidDate,
          })
        );
        return { ok: true };
      }
      if (!data.ok && data.status) {
        return {
          ok: false,
          clientSecret: data.data.clientSecret,
          status: data.status,
        };
      }
      return { ok: false };
    } catch (error) {
      dispatch(LaunchError(error));
      return false;
    }
  };
const payInvoice = (data) => ({
  type: types.PAY_INVOICE,
  payload: data,
});

export const startGetPayments =
  ({ onCallbackFinish = () => {} }) =>
  async (dispatch) => {
    try {
      const { pathPayments } = dispatch(getPathDatabaseByRole());
      const snapshot = await get(ref(db, pathPayments));
      if (snapshot.exists()) {
        const payments = snapshot.val();
        dispatch(getPayments(payments));
        onCallbackFinish();
        return true;
      } else {
        dispatch(loadingFinishPayments());
        onCallbackFinish();
        return false;
      }
    } catch (error) {
      dispatch(LaunchError(error));
      onCallbackFinish();
      return false;
    }
  };
const getPayments = (payments) => ({
  type: types.GET_PAYMENTS_FINISH,
  payload: payments,
});

export const startGetPlansAds = () => async (dispatch, getState) => {
  try {
    const user = getState().auth.user;

    let path;
    if (!user) {
      path = ref(db, `adsPlans`);
    } else {
      const { pathAdsPlans } = dispatch(getPathDatabaseByRole());
      path = ref(db, pathAdsPlans);
    }

    const snapshot = await get(path);
    if (snapshot.exists()) {
      const plans = snapshot.val();
      dispatch(getPlans(plans));
      return true;
    }
    return false;
  } catch (error) {
    dispatch(LaunchError(error));
    return false;
  }
};
const getPlans = (plans) => ({
  type: types.GET_PLANS_FINISH,
  payload: plans,
});
export const startGetSubscriptionPlan = () => async (dispatch) => {
  try {
    const { pathSubscriptionPlan } = dispatch(getPathDatabaseByRole());

    const dbRef = ref(db, pathSubscriptionPlan);

    const snapshot = await get(dbRef);

    if (snapshot.exists()) {
      const subscriptionPlan = snapshot.val();
      dispatch(getSubscriptionPlan(subscriptionPlan));
      return true;
    }
    return false;
  } catch (error) {
    dispatch(LaunchError(error));
    return false;
  }
};
const getSubscriptionPlan = (subscriptionPlan) => ({
  type: types.GET_SUBSCRIPTION_PLAN,
  payload: subscriptionPlan,
});
export const startGetSubscriptionPlanInfo = () => async (dispatch) => {
  try {
    const { pathSubscriptionPlanInfo } = dispatch(getPathDatabaseByRole());

    const dbRef = ref(db, pathSubscriptionPlanInfo);

    const snapshot = await get(dbRef);

    if (snapshot.exists()) {
      const subscriptionPlanInfo = snapshot.val();
      dispatch(getSubscriptionPlanInfo(subscriptionPlanInfo));
      return true;
    }
    return false;
  } catch (error) {
    dispatch(LaunchError(error));
    return false;
  }
};
const getSubscriptionPlanInfo = (subscriptionPlanInfo) => ({
  type: types.GET_SUBSCRIPTION_PLAN_INFO,
  payload: subscriptionPlanInfo,
});

export const startGetSubscriptionPlanInfoUsage =
  (onReferenceAvailable = () => {}) =>
  async (dispatch) => {
    try {
      const { pathSubscriptionPlanInfoUsage } = dispatch(
        getPathDatabaseByRole()
      );

      const dbRef = ref(db, pathSubscriptionPlanInfoUsage);

      onValue(dbRef, (snapshot) => {
        const data = snapshot.val();
        dispatch(getSubscriptionPlanInfoUsage(data));
      });
      onReferenceAvailable(dbRef);
      return false;
    } catch (error) {
      dispatch(LaunchError(error));
      return false;
    }
  };
const getSubscriptionPlanInfoUsage = (subscriptionPlanInfoUsage) => ({
  type: types.GET_SUBSCRIPTION_PLAN_INFO_USAGE,
  payload: subscriptionPlanInfoUsage,
});

export const startListenerIsActivePlan =
  (onReferenceAvailable) => async (dispatch) => {
    try {
      const { pathIsPlanActive } = dispatch(getPathDatabaseByRole());

      const dbRef = ref(db, pathIsPlanActive);
      onValue(dbRef, (snapshot) => {
        const data = snapshot.val();
        dispatch(setIsActivePlan(data));
      });
      onReferenceAvailable(dbRef);
      return true;
    } catch (error) {
      dispatch(setIsActivePlan(false));
      dispatch(LaunchError(error));
      return false;
    }
  };
const setIsActivePlan = (isActive) => ({
  type: types.SET_IS_ACTIVE_PLAN,
  payload: isActive,
});

export const startPaySubscriptionAds =
  ({ planID, recurrent, paymentMethodID, paymentIntent = "", discountID }) =>
  async (dispatch, getState) => {
    try {
      const plans = getState().payments.plans;
      const plan = plans[planID];

      const token = await dispatch(renewToken());
      const { data } = await axios({
        method: "post",
        url: `${FUNCTIONS_URL}/paySubscriptionAds`,
        data: {
          planID,
          recurrent,
          paymentMethodID,
          paymentIntent,
          discountID,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.ok) {
        trackingPurchase(plan.price);
        dispatch(
          paySubscriptionAds({
            planID,
            recurrent,
          })
        );
        return { ok: true };
      }
      if (!data.ok && data.status === "requiresAction") {
        return {
          ok: false,
          clientSecret: data.data.clientSecret,
          status: data.status,
        };
      }
      return {
        ok: false,
      };
    } catch (error) {
      dispatch(LaunchError(error));
      return {
        ok: false,
      };
    }
  };
export const startChangeSubscriptionPlanStripe =
  ({ planID, recurrent, paymentMethodID, paymentIntent }) =>
  async (dispatch) => {
    try {
      const token = await dispatch(renewToken());
      const { data } = await axios({
        method: "post",
        url: `${FUNCTIONS_URL}/changeSubscriptionAdsPlanStripe`,
        data: {
          planID,
          recurrent,
          paymentMethodID,
          paymentIntent,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (data.ok) {
        dispatch(
          changeSubscriptionPlanStripe({
            planID,
            payment: data?.data?.payment,
            usage: data?.data?.usage,
          })
        );

        return { ok: true };
      }

      if (!data.ok && data.status === "requiresAction") {
        return {
          ok: false,
          clientSecret: data.data.clientSecret,
          status: data.status,
        };
      }

      return {
        ok: false,
      };
    } catch (error) {
      dispatch(LaunchError(error));
      return {
        ok: false,
      };
    }
  };

const changeSubscriptionPlanStripe = (data) => ({
  type: types.CHANGE_SUBSCRIPTION_ADS,
  payload: data,
});

export const startGetPreviewProrationAdsSubscription =
  () => async (dispatch) => {
    try {
      const token = await dispatch(renewToken());
      const { data } = await axios({
        method: "post",
        url: `${FUNCTIONS_URL}/getPreviewProrationAdsSubscription`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.ok) {
        dispatch(setProratedPricePlans(data.data));
        return {
          ok: true,
          data: data.data,
        };
      }
      return data.ok;
    } catch (error) {
      dispatch(LaunchError(error));
      return {
        ok: false,
      };
    }
  };
const setProratedPricePlans = (data) => ({
  type: types.SET_PRORATED_PRICE_PLANS,
  payload: data,
});
export const startGetInvoiceUrl =
  ({ paymentID }) =>
  async (dispatch) => {
    try {
      const token = await dispatch(renewToken());
      const { data } = await axios({
        method: "post",
        url: `${FUNCTIONS_URL}/getInvoiceUrl`,
        data: {
          paymentID,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.ok) {
        openLink(data.data.url);
      }
      return data.ok;
    } catch (error) {
      dispatch(LaunchError(error));
      return {
        ok: false,
      };
    }
  };

const paySubscriptionAds = (data) => ({
  type: types.PAY_SUBSCRIPTION_ADS,
  payload: data,
});

export const startGetDiscountCode =
  ({ discountCode }) =>
  async (dispatch) => {
    try {
      const token = await dispatch(renewToken());

      const { data } = await axios({
        method: "post",
        url: `${FUNCTIONS_URL}/getDiscountCode`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          discountCode,
        },
      });

      return data;
    } catch (error) {
      dispatch(LaunchError(error, false));
      return { ok: false };
    }
  };

const loadingFinishPayments = () => ({
  type: types.LOADING_PAYMENTS_FINISH,
});
