import axiosLib from "axios";

const axios = ({ method = "get", url, data = {}, headers = {} }) => {
  return axiosLib({
    method,
    url,
    data,
    headers,
  });
};

export default axios;
