import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";

const SkeletonLoaderStatisticsGraphs = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} lg={4}>
        <Skeleton variant="rounded" width={"100%"} height={300} />
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <Skeleton variant="rounded" width={"100%"} height={300} />
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <Skeleton variant="rounded" width={"100%"} height={300} />
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <Skeleton variant="rounded" width={"100%"} height={300} />
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <Skeleton variant="rounded" width={"100%"} height={300} />
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <Skeleton variant="rounded" width={"100%"} height={300} />
      </Grid>
    </Grid>
  );
};

export default SkeletonLoaderStatisticsGraphs;
