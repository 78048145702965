import { memo } from "react";
import CardMediaMUI from "@mui/material/CardMedia";
import { useInView } from "react-intersection-observer";
import Skeleton from "@mui/material/Skeleton";
import { formatUrlMedia, getExtensionFile } from "../../utils/string";
import { VIDEO_FORMATS } from "../../utils/constants";
import VideoPlayer from "./VideoPlayer";

let propsTypeVideo = [
  "controls",
  "autoplay",
  "loop",
  "controlsList",
  "muted",
  "loop",
];
let propsTypeImage = ["draggable", "alt"];

const CardMedia = memo(function CardMedia({
  propsType = {},
  sx = {},
  sxImage = {},
  sxVideo = {},
  url,
  onFailedLoadUrl = () => {},
}) {
  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: "20px 0px",
  });
  const extension = getExtensionFile(url);
  url = formatUrlMedia(url);
  const type = VIDEO_FORMATS.includes(extension) ? "video" : "img";

  const getPropsType = () => {
    const propsTypeKeysFiltered = Object.keys(propsType).filter((propsType) => {
      if (type === "video" && propsTypeVideo.includes(propsType)) {
        return true;
      }

      if (type === "img" && propsTypeImage.includes(propsType)) {
        return true;
      }

      if (
        !propsTypeImage.includes(propsType) &&
        !propsTypeVideo.includes(propsType)
      ) {
        return true;
      }

      return false;
    });

    const propsTypeData = {};

    propsTypeKeysFiltered.forEach((key) => {
      propsTypeData[key] = propsType[key];
    });

    return propsTypeData;
  };

  const propsTypeFiltered = getPropsType();

  const propsMedia =
    type === "video"
      ? {
          component: type,
          controls: true,
          autoplay: false,
          loop: false,
          src: url,
          ...propsTypeFiltered,
        }
      : {
          component: type,
          src: url,
          ...propsTypeFiltered,
        };

  if (!inView) {
    return (
      <Skeleton
        ref={ref}
        variant="rounded"
        sx={{
          width: "100%",
          height: "100%",
        }}
      />
    );
  }

  if (type === "video") {
    return <VideoPlayer url={url} sx={{ ...sx, ...sxVideo }} {...propsMedia} />;
  }
  return (
    <CardMediaMUI
      {...propsMedia}
      sx={{ ...sx, ...sxImage }}
      onError={onFailedLoadUrl}
    />
  );
});

export default CardMedia;
