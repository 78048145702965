import Typography from "@mui/material/Typography";
import { useTranslationApp } from "../../lib/i18next";
import { useTheme } from "@mui/material";

const ContentCommon = ({ text, interpolations }) => {
  const { t } = useTranslationApp();
  const theme = useTheme();
  return (
    <Typography
      color="inherit"
      variant="body1"
      sx={{
        mb: 1,
        "& a": {
          color: theme.palette.secondary.main,
          textDecoration: "underline",
          fontWeight: "bold",
        },
      }}
      dangerouslySetInnerHTML={{
        __html: t(text, { ...interpolations }),
      }}
    />
  );
};

export default ContentCommon;
