import ModalBasicLayout from "../../../components/Modal/ModalBasicLayout";
import { useTranslationApp } from "../../../lib/i18next";
import {
  ACTIVE_ADS_CAMPAIGN_ACCOUNT_NO_HAVE_BALANCE,
  BUTTON_CONTINUE,
  BUTTON_RECHARGE_BALANCE,
  CAMPAIGN_CREATED,
  CAMPAIGN_UPDATED,
  CREATE_ADS_CAMPAIGN_ACCOUNT_NO_HAVE_BALANCE,
  ERROR,
  ERROR_ON_AD_GROUP_ID,
  ERROR_ON_AD_ID_OF_AD_GROUP_ID,
} from "../../../i18n/keysTranslations";
import Box from "@mui/material/Box";
import DialogActions from "@mui/material/DialogActions";
import ContainerModalActions from "../../../components/Containers/ContainerModalActions";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import { logoPlatform, openLink } from "../../../utils/string";
import { BORDER_RADIUS, META, TIKTOK } from "../../../utils/constants";
import { errorCodeToText } from "../../../utils/errors";
import Button from "../../../components/Buttons/Button";
import {
  getBillingUrlMeta,
  getBillingUrlTikTokBusiness,
} from "../../../actions/getters";
import { useDispatchApp } from "../../../lib/redux";
import { useTheme } from "@mui/material";
import Alert from "@mui/material/Alert";

const ModalErrorsCampaigns = ({
  modalOpen,
  onCloseModal,
  campaign,
  from = "create",
  successCampaigns = [],
  failedCampaigns = [],
  errors = {},
}) => {
  const { t } = useTranslationApp();
  const theme = useTheme();
  const dispatch = useDispatchApp();

  const commonSxContainer = {
    display: "flex",
    alignItems: "center",
    gap: 1,
    border: `${theme.palette.border.grey} 1px solid`,
    p: 2,
    borderRadius: BORDER_RADIUS,
  };

  return (
    <ModalBasicLayout
      modalOpen={modalOpen}
      onCloseModal={async () => {
        onCloseModal();
      }}
      scroll="paper"
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        {successCampaigns.map((platform) => {
          return (
            <Box
              key={platform}
              sx={{
                ...commonSxContainer,
                flexDirection: {
                  xs: "column",
                  md: "row",
                },
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <Avatar
                  sx={{
                    ".MuiAvatar-img": {
                      objectFit: "contain",
                    },
                  }}
                  src={logoPlatform(platform)}
                  variant="square"
                />
                <Typography
                  variant="h6"
                  sx={{
                    flexGrow: 1,
                  }}
                >
                  {t(platform)}
                </Typography>
              </Box>
              <Alert severity="success">
                {t(from === "create" ? CAMPAIGN_CREATED : CAMPAIGN_UPDATED)}
              </Alert>
            </Box>
          );
        })}

        {failedCampaigns.map((platform) => {
          const error = errors[platform];
          const code = error?.code;
          const message =
            errorCodeToText(code) === code
              ? error?.message
              : errorCodeToText(code);
          const platformData = campaign?.platformsData?.[platform];
          const additionalData = error?.additionalData || {};
          const scope = additionalData?.scope || "";
          const isDifferentScopeCampaign = scope !== "campaign" && scope;

          const getTextScope = () => {
            const adGroupID = additionalData?.adGroupID;
            const adID = additionalData?.adID;

            if (!adGroupID && !adID) {
              return "";
            }

            if (adID && adGroupID) {
              const adGroupIndex = campaign?.adsGroups?.findIndex(
                (adGroup) => adGroup.id === adGroupID
              );
              const ads = campaign?.adsGroups?.[adGroupIndex]?.ads;
              const adIndex = ads?.findIndex((ad) => ad.id === adID);

              return t(ERROR_ON_AD_ID_OF_AD_GROUP_ID, {
                adGroupID: adGroupIndex + 1,
                adID: adIndex + 1,
              });
            }

            if (adGroupID) {
              const adGroupIndex = campaign?.adsGroups?.findIndex(
                (adGroup) => adGroup.id === adGroupID
              );
              return t(ERROR_ON_AD_GROUP_ID, {
                adGroupID: adGroupIndex + 1,
              });
            }
          };
          return (
            <Box
              key={platform}
              sx={{
                ...commonSxContainer,
                alignItems: "flex-start",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: {
                    xs: "column",
                    md: "row",
                  },
                  alignItems: "flex-start",
                  width: "100%",
                  gap: 1,
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <Avatar
                    sx={{
                      ".MuiAvatar-img": {
                        objectFit: "contain",
                      },
                    }}
                    src={logoPlatform(platform)}
                    variant="square"
                  />
                  <Typography
                    variant="h6"
                    sx={{
                      flexGrow: 1,
                    }}
                  >
                    {t(platform)}
                  </Typography>
                </Box>
                <Alert severity="error">{t(ERROR)}</Alert>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  alignItems: "center",
                }}
              >
                <Typography variant="body1">{message}</Typography>
                {isDifferentScopeCampaign && (
                  <Typography variant="body1" sx={{ my: 1 }}>
                    {`(${getTextScope()})`}
                  </Typography>
                )}
              </Box>

              {(code === CREATE_ADS_CAMPAIGN_ACCOUNT_NO_HAVE_BALANCE ||
                code === ACTIVE_ADS_CAMPAIGN_ACCOUNT_NO_HAVE_BALANCE) && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "100%",
                  }}
                >
                  <Button
                    onClick={() => {
                      let url;
                      if (platform === META) {
                        url = dispatch(
                          getBillingUrlMeta(platformData.adAccountID)
                        );
                      }
                      if (platform === TIKTOK) {
                        url = dispatch(
                          getBillingUrlTikTokBusiness(platformData.adAccountID)
                        );
                      }
                      openLink(url);
                    }}
                  >
                    {t(BUTTON_RECHARGE_BALANCE)}
                  </Button>
                </Box>
              )}
            </Box>
          );
        })}
      </Box>
      <DialogActions sx={{ p: 0, mt: 1 }}>
        <ContainerModalActions
          propsPrimaryButton={{
            showButton: true,
            text: BUTTON_CONTINUE,
            onClick: onCloseModal,
          }}
        />
      </DialogActions>
    </ModalBasicLayout>
  );
};

export default ModalErrorsCampaigns;
