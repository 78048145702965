import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material";

const ModalBasicLayout = ({
  children,
  modalOpen,
  onCloseModal,
  title,
  ariaLabel = "Modal génerico",
  maxWidth = "sm",
  fullWidth = false,
  scroll = "paper",
  customComponentTitle,
  fullScreen = false,
  sx = {},
  sxContent = {},
  refContent,
  hasTitle = true,
  titleContent = true,
  sxPaper = {},
  sxTitle = {},
  colorTitle = "black !important",
  variantTitle = "h5",
  description = "",
  variantDescription = "body1",
  sxDescription = {},
  dataTour = "",
  onKeyDown = () => {},
  showCloseButton = true,
}) => {
  const theme = useTheme();

  const actions = [];
  const content = [];
  // Separar DialogActions de DialogContent
  React.Children.forEach(children, (child) => {
    if (React.isValidElement(child)) {
      if (child.type === DialogActions) {
        actions.push(child);
      } else {
        content.push(child);
      }
    }
  });

  return (
    <Dialog
      open={modalOpen}
      onClose={(e) => {
        e.stopPropagation();
        onCloseModal();
      }}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      fullScreen={fullScreen}
      scroll={scroll}
      aria-labelledby={ariaLabel}
      aria-describedby={ariaLabel}
      sx={{
        "& .MuiDialog-paper": {
          backgroundImage: "none",
          backgroundColor: "background.paper",
          m: 0,
          maxHeight: fullScreen ? "100%" : "calc(100% - 8%)",
          borderRadius: fullScreen ? 0 : 4,
          width: fullScreen
            ? "100%"
            : { xs: "calc(100% - 24px)", sm: "calc(100% - 64px)" },
          ...sxPaper,
        },
        ...sx,
      }}
      PaperProps={{
        "data-tour": dataTour,
      }}
      onKeyDown={onKeyDown}
    >
      {hasTitle && (
        <DialogTitle
          sx={{
            m: 0,
            px: 2,
            pb: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: 2,
            ...sxTitle,
          }}
          component="div"
        >
          {title && !titleContent && (
            <Typography
              variant={variantTitle}
              color="inherit"
              align="left"
              sx={{ fontWeight: 600, color: `${colorTitle} !important` }}
            >
              {title}
            </Typography>
          )}
          {customComponentTitle}
          {showCloseButton && (
            <IconButton
              className="close-modal-button"
              aria-label="close"
              onClick={(e) => {
                e.stopPropagation();
                onCloseModal();
              }}
              sx={{
                p: 0.5,
                fontSize: "1.4rem",
                color: "background.paperSecondaryContrastText",
                backgroundColor: "background.paperSecondary",
                "&:hover": {
                  color: "background.paperSecondaryContrastText",
                  backgroundColor: "background.paperSecondary",
                  filter: theme.palette.hover.main,
                },
              }}
            >
              <CloseIcon />
            </IconButton>
          )}
        </DialogTitle>
      )}

      <DialogContent
        sx={{
          px: {
            xs: 3,
            sm: 5,
          },
          pb: 2,
          ...sxContent,
        }}
        ref={refContent}
      >
        {((title && titleContent) || description) && (
          <Box
            sx={{
              mb: 2,
            }}
          >
            {title && titleContent && (
              <Typography
                variant={variantTitle}
                color="inherit"
                align="left"
                sx={{
                  fontWeight: 600,
                  color: `${colorTitle} !important`,
                  ...sxTitle,
                }}
              >
                {title}
              </Typography>
            )}
            {description && (
              <Typography
                variant={variantDescription}
                align="left"
                sx={{
                  ...sxDescription,
                }}
              >
                {description}
              </Typography>
            )}
          </Box>
        )}

        {content}
      </DialogContent>
      {actions}
    </Dialog>
  );
};

export default ModalBasicLayout;
