import { useMediaQuery } from "@mui/material";
import ModalBasicLayout from "../../../components/Modal/ModalBasicLayout";
import AdPreview from "./AdPreview";

export const ModalAdPreview = ({
  modalOpen,
  onCloseModal,
  adID,
  platform,
  mediaUrl,
  mediaData,
  callToAction,
  titles,
  headlines,
  descriptions,
  basicPath,
}) => {
  const isFullScreen = useMediaQuery(() => "(max-width: 380px)");

  return (
    <ModalBasicLayout
      modalOpen={modalOpen}
      onCloseModal={async () => {
        onCloseModal();
      }}
      fullScreen={isFullScreen}
      maxWidth="xs"
      scroll="paper"
      sxContent={{
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "center",
      }}
      sxPaper={{
        maxWidth: 400,
      }}
    >
      <AdPreview
        adID={adID}
        mediaUrl={mediaUrl}
        mediaData={mediaData}
        callToAction={callToAction}
        titles={titles}
        headlines={headlines}
        descriptions={descriptions}
        platform={platform}
        basicPath={basicPath}
      />
    </ModalBasicLayout>
  );
};
