import { useState, useEffect } from "react";
import {
  FIELD_BUDGET_REQUIRED,
  FIELD_DESTINATION_URL_NOT_FOUND_IN_EVENTS_PIXEL_REQUIRED,
  FIELD_DESTINATION_URL_REQUIRED,
  FIELD_MAX_LENGTH,
  FIELD_MIN_LENGTH,
  FIELD_PLATFORMS_REQUIRED,
  FIELD_OBJECTIVE_REQUIRED,
  FIELD_TYPE_BUDGET_REQUIRED,
  FIELD_URL_VALID,
  FIELD_CONTENT_REQUIRED,
  FIELD_WEBSITE_URL_REQUIRED,
  FIELD_START_AND_END_DATES_REQUIRED,
  START_DATE_AND_END_DATE_VALIDATION,
  FIELD_LANGUAGE_REQUIRED,
  LOADING_INFORMATION,
  FIELD_TRACKING_IDS_REQUIRED,
  FIELD_NAME_REQUIRED,
  FIELD_AD_ACCOUNTS_REQUIRED,
  ERROR_GETTING_URL_META_DATA_AND_IMAGES,
  FIELD_REQUIRED,
  FIELD_URL_INSTAGRAM,
} from "../../i18n/keysTranslations";
import Box from "@mui/material/Box";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  ADS_CAMPAIGN_OBJECTIVES_WITH_TRACKING_META,
  META,
  TIKTOK,
  ADS_CAMPAIGN_CONTEXT_MAX_LENGTH,
  ADS_CAMPAIGN_CONTEXT_MIN_LENGTH,
  ADS_CAMPAIGN_OBJECTIVES_WITH_TRACKING_TIKTOK,
  ADS_CAMPAIGN_TYPE_BUDGET_LIFETIME_OPTION,
  ADS_CAMPAIGN_CREATION_MODE_QUICK,
  ADS_CAMPAIGN_CREATION_MODE_ADVANCED,
  SOCIAL_NETWORKS_DOMAINS_SUPPORTED_SCRAPPING,
  ADS_CAMPAIGN_GENERATE_CAMPAIGN_ACTION,
  ADS_CAMPAIGN_OBJECTIVE_TRAFFIC_OPTION,
  ADS_CAMPAIGN_META_DESTINATION_TYPE_INSTAGRAM_PROFILE_OPTION,
  ADS_CAMPAIGN_MEDIA_FROM_UPLOAD,
} from "../../utils/constants";
import { REGEX_URL } from "../../utils/regex";
import { useDispatchApp, useSelectorApp } from "../../lib/redux";
import { useTranslationApp } from "../../lib/i18next";
import {
  startGetRecommendedSettingsAdsCampaign,
  startVerifyUrlCanBeScrape,
} from "../../actions/adsCampaigns";
import CreateAdsCampaignForm from "./components/CreateAdsCampaignForm";
import Loader from "../../components/Loaders/Loader";
import { getIsBlockedActions, getUserIsViewer } from "../../actions/getters";
import { getKeyDatabase } from "../../services/public";
import _ from "lodash";
import {
  normalizeUrlToCompare,
  verifyDestinationURLIsABlockedDomain,
} from "../../utils/string";
import {
  formatMediaUrl,
  getDefaultEventTrackingMeta,
  getDefaultEventTrackingTikTok,
  getDefaultPlatformData,
} from "../../services/adsCampaigns";
import { getPathDatabaseByRole } from "../../actions/auth";

const ALL_QUESTIONS = [
  "mode",
  "name",
  "platforms",
  "identities",
  "websiteURL",
  "socialNetworkURL",
  "objective",
  "trackingIDs",
  "typeBudget",
  "budget",
  "dates",
  "locations",
  "language",
  "destinationType",
  "destinationURL",
  "productContext",
  "targetContext",
  "mediaUrls",
];

const QUICK_QUESTIONS = [
  "mode",
  "websiteURL",
  "socialNetworkURL",
  "budget",
  "locations",
  "productContext",
  "targetContext",
];

const getInformationMetaIntegration = (metaIntegrations) => {
  const adAccountsMeta = Object.keys(metaIntegrations)
    .map((integration) => {
      const businessAccounts =
        metaIntegrations?.[integration]?.businessAccounts || {};

      return Object.keys(businessAccounts).map((adAccount) => ({
        id: adAccount,
        integrationID: integration,
      }));
    })
    .flat();
  const hasSingleAdAccountsMeta = adAccountsMeta.length === 1;

  if (!hasSingleAdAccountsMeta) {
    return {
      adAccountsMeta,
      pagesMeta: [],
      instagramMeta: [],
      hasSingleAdAccountsMeta: false,
      hasSinglePageMeta: false,
      hasSingleInstagramMeta: false,
    };
  }
  const integrationID = adAccountsMeta[0].integrationID;

  const pages = metaIntegrations?.[integrationID]?.pages || {};
  const pagesMeta = Object.keys(pages).map((page) => ({
    id: page,
    integrationID,
  }));
  const hasSinglePageMeta = pagesMeta.length === 1;

  if (!hasSinglePageMeta) {
    return {
      adAccountsMeta,
      pagesMeta: pages,
      hasSingleAdAccountsMeta: true,
      hasSinglePageMeta: false,
      hasSingleInstagramMeta: false,
    };
  }

  const pageID = pagesMeta[0].id;

  const instagramAccounts =
    metaIntegrations?.[integrationID]?.pages?.[pageID]?.instagramAccounts;
  const instagramMeta = Object.keys(instagramAccounts).map((instagram) => ({
    id: instagram,
    integrationID,
    pageID,
  }));
  const hasSingleInstagramMeta = instagramMeta.length === 1;

  if (!hasSingleInstagramMeta) {
    return {
      adAccountsMeta,
      pagesMeta,
      instagramMeta,
      hasSingleAdAccountsMeta: true,
      hasSinglePageMeta: true,
      hasSingleInstagramMeta: false,
    };
  }

  return {
    adAccountsMeta,
    pagesMeta,
    instagramMeta,
    hasSingleAdAccountsMeta: true,
    hasSinglePageMeta: true,
    hasSingleInstagramMeta: true,
  };
};

const CreateAdsCampaigns = () => {
  const [params] = useSearchParams();
  const platformParam = params.get("platform");
  const modeParam = params.get("mode");
  const content = params.get("content");
  const [answerOrder, setAnswerOrder] = useState({});
  const [visibleSteps, setVisibleSteps] = useState([]);
  const [lastStep, setLastStep] = useState(1);
  const [error, setError] = useState(null);
  const [isCreating, setIsCreating] = useState(false);
  const [pixels, setPixels] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [campaignID, setCampaignID] = useState(null);
  const [showBrandContextQuestion, setShowBrandContextQuestion] =
    useState(false);
  const { control, watch, setValue, getValues } = useForm({
    defaultValues: {
      step: 1,
      mode: "",
      name: "",
      objective: "",
      destinationURL: "",
      typeBudget: "",
      budget: "",
      startDate: null,
      endDate: null,
      mediaUrls: [],
      language: "en",
      productContext: "",
      targetContext: "",
      brandContext: "",
      socialNetworkURL: "",
      platforms: [],
      platformsData: {},
    },
  });

  const step = watch("step");
  const mode = watch("mode");
  const platforms = watch("platforms");
  const platformsData = watch("platformsData");
  const typeBudget = watch("typeBudget");
  const objective = watch("objective");
  const destinationType = watch("destinationType");

  const currentAnswer = answerOrder[step];
  const stepVisible = visibleSteps.indexOf(step) + 1;

  const { integrations = {} } = useSelectorApp((state) => state.user);

  const metaIntegrations = integrations?.facebook || {};
  const {
    pagesMeta,
    instagramMeta,
    hasSingleAdAccountsMeta,
    hasSinglePageMeta,
    hasSingleInstagramMeta,
  } = getInformationMetaIntegration(metaIntegrations);

  const isCampaignWithTracking =
    (ADS_CAMPAIGN_OBJECTIVES_WITH_TRACKING_META.includes(objective) &&
      platforms.includes(META)) ||
    (ADS_CAMPAIGN_OBJECTIVES_WITH_TRACKING_TIKTOK.includes(objective) &&
      platforms.includes(TIKTOK));

  const dispatch = useDispatchApp();
  const { t } = useTranslationApp();
  const navigate = useNavigate();

  const isViewer = dispatch(getUserIsViewer());

  const getInitialData = async (from) => {
    const newCampaignID = dispatch(getKeyDatabase());
    setCampaignID(newCampaignID);

    if (from === "userID") {
      setValue("platforms", []);
      setValue("platformsData", {});
      setValue("step", visibleSteps[1]);
    }

    setIsLoading(false);
  };

  const { userID } = dispatch(getPathDatabaseByRole());

  const onNextStep = async (name, value, finish = false) => {
    const currentStepPosition = visibleSteps.indexOf(step);
    const nextStep = visibleSteps[currentStepPosition + 1];
    let newVisibleSteps;

    let responseVerificationQuestion;
    let isDestinationURLValidDomain;

    let hasAllPixels = true;
    let hasIntegrationID = true;
    let hasAdAccountID = true;

    if (name === "websiteURL") {
      const isWebsiteURLDomainWithoutAnalyze =
        SOCIAL_NETWORKS_DOMAINS_SUPPORTED_SCRAPPING.some((domain) =>
          value.includes(domain)
        );
      setShowBrandContextQuestion(isWebsiteURLDomainWithoutAnalyze);
      updateAnswerOrder({
        showBrandContextQuestion: isWebsiteURLDomainWithoutAnalyze,
      });
    }

    const pixelsSelected = {};

    platforms.forEach((platform) => {
      if (!platformsData[platform]?.trackingID) hasAllPixels = false;
      else pixelsSelected[platform] = platformsData[platform]?.trackingID;

      if (!platformsData[platform]?.integrationID) hasIntegrationID = false;
      if (!platformsData[platform]?.adAccountID) hasAdAccountID = false;
    });

    let hasIncludedUrlInPixels = false;

    if (name === "destinationURL") {
      Object.keys(pixelsSelected).forEach((platformKey) => {
        const pixelsPlatform = pixels[platformKey] || [];
        pixelsPlatform?.forEach((pixel) => {
          const found = pixelsPlatform?.find((p) => p.id === pixel.id);

          if (found?.urls?.length > 0) {
            const hasIncluded = found?.urls?.some((url) => {
              const urlHostname = normalizeUrlToCompare({
                url: url,
                returnHostname: true,
              });
              const valueHostname = normalizeUrlToCompare({
                url: value,
                returnHostname: true,
              });

              if (!urlHostname || !valueHostname) return false;
              return urlHostname.includes(valueHostname);
            });

            if (hasIncluded) {
              hasIncludedUrlInPixels = true;
            }
          }
        });
      });
    }

    const startDate = new Date(value?.startDate).getTime();
    const endDate = new Date(value?.endDate).getTime();
    switch (name) {
      case "mode":
        if (!value) {
          return setError({
            text: t(FIELD_NAME_REQUIRED),
          });
        }

        newVisibleSteps = updateAnswerOrder({ mode: value }).visibleSteps;
        return setValue("step", newVisibleSteps[currentStepPosition + 1]);
      case "name":
        if (value.length === 0)
          return setError({
            text: t(FIELD_NAME_REQUIRED),
            step: answerOrder[step],
          });

        setError(null);
        return setValue("step", nextStep);

      case "platforms":
        if (value.length === 0)
          return setError({
            text: t(FIELD_PLATFORMS_REQUIRED),
            step: answerOrder[step],
          });

        if (!hasIntegrationID || !hasAdAccountID) {
          return setError({
            text: t(FIELD_AD_ACCOUNTS_REQUIRED),
            step: answerOrder[step],
          });
        }
        setError(null);
        return setValue("step", nextStep);

      case "objective":
        if (value.length === 0)
          return setError({
            text: t(FIELD_OBJECTIVE_REQUIRED),
            step: answerOrder[step],
          });
        setError(null);
        return setValue("step", nextStep);

      case "destinationURL":
        if (value.length === 0) {
          return setError({
            text: t(FIELD_DESTINATION_URL_REQUIRED),
            step: answerOrder[step],
          });
        }
        if (!REGEX_URL.test(encodeURI(value))) {
          return setError({
            text: t(FIELD_URL_VALID),
            step: answerOrder[step],
          });
        }

        isDestinationURLValidDomain =
          verifyDestinationURLIsABlockedDomain(value);
        if (!isDestinationURLValidDomain.ok) {
          return setError({
            text: t(isDestinationURLValidDomain.message),
            step: answerOrder[step],
          });
        }

        if (!hasIncludedUrlInPixels && isCampaignWithTracking) {
          return setError({
            text: t(FIELD_DESTINATION_URL_NOT_FOUND_IN_EVENTS_PIXEL_REQUIRED),
            step: answerOrder[step],
          });
        }

        setError(null);
        return setValue("step", nextStep);

      case "websiteURL":
        if (value.length === 0) {
          return setError({
            text: t(FIELD_WEBSITE_URL_REQUIRED),
            step: answerOrder[step],
          });
        }
        if (!REGEX_URL.test(encodeURI(value))) {
          return setError({
            text: t(FIELD_URL_VALID),
            step: answerOrder[step],
          });
        }

        setError(null);

        if (getValues("mode") === ADS_CAMPAIGN_CREATION_MODE_QUICK) {
          responseVerificationQuestion = await dispatch(
            startVerifyUrlCanBeScrape(value)
          );
          if (!responseVerificationQuestion) {
            setShowBrandContextQuestion(true);
            updateAnswerOrder({
              showBrandContextQuestion: true,
            });
          }
        }

        return setValue("step", nextStep);

      case "trackingIDs":
        if (!hasAllPixels) {
          return setError({
            text: t(FIELD_TRACKING_IDS_REQUIRED),
            step: answerOrder[step],
          });
        }

        setError(null);
        return setValue("step", nextStep);

      case "typeBudget":
        if (!value)
          return setError({
            text: t(FIELD_TYPE_BUDGET_REQUIRED),
            step: answerOrder[step],
          });
        setError(null);
        return setValue("step", nextStep);

      case "budget":
        if (!value)
          return setError({
            text: t(FIELD_BUDGET_REQUIRED),
            step: answerOrder[step],
          });

        setError(null);
        return setValue("step", nextStep);

      case "locations":
        if (!value || value.length === 0) {
          return setError({
            text: t(FIELD_REQUIRED),
            step: answerOrder[step],
          });
        }

        setError(null);
        if (finish) return onCreateCampaign();
        return setValue("step", nextStep);

      case "destinationType":
        if (!value)
          return setError({
            text: t(FIELD_REQUIRED),
            step: answerOrder[step],
          });

        setError(null);
        return setValue("step", nextStep);

      case "dates":
        if (!value.startDate || !value.endDate)
          return setError({
            text: t(FIELD_START_AND_END_DATES_REQUIRED),
            step: answerOrder[step],
          });

        if (startDate > endDate)
          return setError({
            text: t(START_DATE_AND_END_DATE_VALIDATION),
            step: answerOrder[step],
          });

        setError(null);
        return setValue("step", nextStep);

      case "mediaUrls":
        if (value.length === 0) {
          return setError({
            text: t(FIELD_CONTENT_REQUIRED),
            step: answerOrder[step],
          });
        }
        setError(null);

        if (finish) return onCreateCampaign();

        return setValue("step", nextStep);

      case "language":
        if (value.length === 0) {
          return setError({
            text: t(FIELD_LANGUAGE_REQUIRED),
            step: answerOrder[step],
          });
        }
        setError(null);

        return setValue("step", nextStep);

      case "productContext":
        if (value.length === 0) {
          return setError({
            text: t(FIELD_REQUIRED),
            step: answerOrder[step],
          });
        }
        if (value.length < ADS_CAMPAIGN_CONTEXT_MIN_LENGTH) {
          return setError({
            text: t(FIELD_MIN_LENGTH, {
              value: ADS_CAMPAIGN_CONTEXT_MIN_LENGTH,
            }),
            step: answerOrder[step],
          });
        }
        if (value.length > ADS_CAMPAIGN_CONTEXT_MAX_LENGTH) {
          return setError({
            text: t(FIELD_MAX_LENGTH, {
              value: ADS_CAMPAIGN_CONTEXT_MAX_LENGTH,
            }),
            step: answerOrder[step],
          });
        }
        setError(null);

        return setValue("step", nextStep);

      case "targetContext":
        if (value.length === 0) {
          return setError({
            text: t(FIELD_REQUIRED),
            step: answerOrder[step],
          });
        }
        if (value.length < ADS_CAMPAIGN_CONTEXT_MIN_LENGTH) {
          return setError({
            text: t(FIELD_MIN_LENGTH, {
              value: ADS_CAMPAIGN_CONTEXT_MIN_LENGTH,
            }),
            step: answerOrder[step],
          });
        }
        if (value.length > ADS_CAMPAIGN_CONTEXT_MAX_LENGTH) {
          return setError({
            text: t(FIELD_MAX_LENGTH, {
              value: ADS_CAMPAIGN_CONTEXT_MAX_LENGTH,
            }),
            step: answerOrder[step],
          });
        }
        setError(null);

        if (finish) return onCreateCampaign();

        return setValue("step", nextStep);

      case "brandContext":
        if (value.length === 0) {
          return setError({
            text: t(FIELD_REQUIRED),
            step: answerOrder[step],
          });
        }
        if (value.length < 30) {
          return setError({
            text: t(FIELD_MIN_LENGTH, {
              value: 30,
            }),
            step: answerOrder[step],
          });
        }
        if (value.length > 1000) {
          return setError({
            text: t(FIELD_MAX_LENGTH, {
              value: 1000,
            }),
            step: answerOrder[step],
          });
        }
        setError(null);

        if (finish) return onCreateCampaign();

        return setValue("step", nextStep);

      case "socialNetworkURL":
        if (value.length === 0) {
          return setError({
            text: t(FIELD_REQUIRED),
            step: answerOrder[step],
          });
        }
        if (value?.length > 0 && !REGEX_URL.test(encodeURI(value))) {
          return setError({
            text: t(FIELD_URL_VALID),
            step: answerOrder[step],
          });
        }
        if (value?.length > 0 && !value.includes("instagram.com")) {
          return setError({
            text: t(FIELD_URL_INSTAGRAM),
            step: answerOrder[step],
          });
        }
        setError(null);

        if (finish) return onCreateCampaign();

        return setValue("step", nextStep);

      default:
        return setValue("step", nextStep);
    }
  };
  const onPreviousStep = () => {
    const currentStepPosition = visibleSteps.indexOf(step);
    const previousStep = visibleSteps[currentStepPosition - 1];
    setValue("step", previousStep);
  };

  const onCreateCampaign = async () => {
    const isBlocked = await dispatch(
      getIsBlockedActions({
        action: ADS_CAMPAIGN_GENERATE_CAMPAIGN_ACTION,
      })
    );
    if (isBlocked) {
      return;
    }

    const formValues = getValues();

    const finalPlatformsData = {};
    platforms?.forEach((platform) => {
      finalPlatformsData[platform] = formValues?.platformsData?.[platform];
    });

    setIsCreating(true);

    const response = await dispatch(
      startGetRecommendedSettingsAdsCampaign({
        mode: formValues.mode,
        name: formValues.name,
        platforms: formValues.platforms,
        objective: formValues.objective,
        productContext: formValues.productContext,
        targetContext: formValues.targetContext,
        brandContext: showBrandContextQuestion ? formValues.brandContext : "",
        socialNetworkURL: formValues.socialNetworkURL,
        destinationURL: formValues.destinationURL,
        websiteURL: formValues.websiteURL,
        language:
          formValues.mode === ADS_CAMPAIGN_CREATION_MODE_ADVANCED
            ? formValues.language
            : null,
        mediaUrls: formValues.mediaUrls,
        typeBudget: formValues.typeBudget,
        budget: formValues.budget,
        locations: formValues.locations,
        startDate: formValues.startDate,
        endDate: formValues.endDate,
        platformsData: finalPlatformsData,
        campaignID,
      })
    );
    if (response.ok) {
      navigate(`/ads-campaign?campaignID=${campaignID}`);
    } else if (response.code === ERROR_GETTING_URL_META_DATA_AND_IMAGES) {
      setShowBrandContextQuestion(true);
      if (
        currentAnswer !== "brandContext" &&
        currentAnswer !== "socialNetworkURL"
      ) {
        const currentStepPosition = visibleSteps.indexOf(step);
        const newVisibleSteps = updateAnswerOrder({
          showBrandContextQuestion: true,
        }).visibleSteps;
        setValue("step", newVisibleSteps[currentStepPosition + 1]);
      }
    }

    setIsCreating(false);
  };

  useEffect(() => {
    if (isViewer) {
      navigate("/");
      return;
    }
    getInitialData();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (isLoading) return;
    getInitialData("userID");
    // eslint-disable-next-line
  }, [userID]);

  useEffect(() => {
    if (step > lastStep) setLastStep(stepVisible);
    // eslint-disable-next-line
  }, [step]);

  useEffect(() => {
    if (platformParam && (platformParam === META || platformParam === TIKTOK))
      setValue("platforms", [platformParam]);

    if (content) {
      const urlContent = decodeURIComponent(content);
      if (REGEX_URL.test(urlContent)) {
        setValue("mediaUrls", [
          formatMediaUrl({
            url: urlContent,
            from: ADS_CAMPAIGN_MEDIA_FROM_UPLOAD,
          }),
        ]);
      }
    }

    if (modeParam) {
      setValue("mode", modeParam);
      const newVisibleSteps = updateAnswerOrder({
        mode: modeParam,
      }).visibleSteps;
      const currentStepPosition = newVisibleSteps.indexOf(step);
      setValue("step", newVisibleSteps[currentStepPosition + 1]);
    }
    // eslint-disable-next-line
  }, []);

  const updatePlatformsData = () => {
    const finalPlatformsData = _.cloneDeep(platformsData);
    platforms.forEach((platform) => {
      if (platform === META) {
        finalPlatformsData[platform] = {
          ...getDefaultPlatformData(platform),
          ...(platformsData?.[platform] || {}),
        };
        if (hasSinglePageMeta) {
          const page = pagesMeta[0];
          finalPlatformsData[META] = {
            ...finalPlatformsData[META],
            pageID: page.id,
          };
        }
        if (hasSingleInstagramMeta) {
          const instagram = instagramMeta[0];
          finalPlatformsData[META] = {
            ...finalPlatformsData[META],
            instagramID: instagram.id,
          };
        }

        finalPlatformsData[META] = {
          ...finalPlatformsData[META],
          trackingCustomEventID: getDefaultEventTrackingMeta(objective),
        };
      }
      if (platform === TIKTOK) {
        finalPlatformsData[platform] = {
          ...getDefaultPlatformData(platform),
          trackingCustomEventID: getDefaultEventTrackingTikTok(objective),
          ...(platformsData?.[platform] || {}),
        };
        finalPlatformsData[TIKTOK] = {
          ...finalPlatformsData[TIKTOK],
          trackingCustomEventID: getDefaultEventTrackingTikTok(objective),
        };
      }
    });
    setValue("platformsData", finalPlatformsData);
  };

  const updateAnswerOrder = ({
    mode: modeProp = mode,
    showBrandContextQuestion:
      showBrandContextQuestionProp = showBrandContextQuestion,
    isWebsiteURLDomainWithoutAnalyze: isWebsiteURLDomainWithoutAnalyzeProp,
  }) => {
    let answerOrder = {};
    let index = 1;

    if (modeProp === ADS_CAMPAIGN_CREATION_MODE_QUICK) {
      QUICK_QUESTIONS.forEach((question) => {
        answerOrder[index] = question;
        index++;
      });
    } else {
      ALL_QUESTIONS.forEach((question) => {
        if (
          question === "dates" &&
          typeBudget !== ADS_CAMPAIGN_TYPE_BUDGET_LIFETIME_OPTION
        ) {
          return;
        }

        if (question === "trackingIDs" && !isCampaignWithTracking) {
          return;
        }

        if (
          question === "identities" &&
          platforms.includes(META) &&
          hasSinglePageMeta &&
          hasSingleInstagramMeta
        )
          return;

        if (
          question === "destinationType" &&
          (objective !== ADS_CAMPAIGN_OBJECTIVE_TRAFFIC_OPTION ||
            platforms.length > 1 ||
            !platforms.includes(META))
        ) {
          return;
        }

        if (
          question === "destinationURL" &&
          objective === ADS_CAMPAIGN_OBJECTIVE_TRAFFIC_OPTION &&
          destinationType ===
            ADS_CAMPAIGN_META_DESTINATION_TYPE_INSTAGRAM_PROFILE_OPTION
        ) {
          return;
        }

        answerOrder[index] = question;
        index++;
      });
    }

    if (showBrandContextQuestionProp || isWebsiteURLDomainWithoutAnalyzeProp) {
      answerOrder[index] = "brandContext";
      index++;
    }

    setAnswerOrder(answerOrder);
    const visibleSteps = Object.keys(answerOrder).map((n) => Number(n));
    setVisibleSteps(visibleSteps);

    return { answerOrder, visibleSteps };
  };

  useEffect(() => {
    updateAnswerOrder({});
    // eslint-disable-next-line
  }, [
    typeBudget,
    isCampaignWithTracking,
    platforms,
    hasSingleAdAccountsMeta,
    hasSinglePageMeta,
    hasSingleInstagramMeta,
    objective,
    platforms,
    destinationType,
  ]);
  useEffect(() => {
    updatePlatformsData();
    // eslint-disable-next-line
  }, [
    platforms,
    objective,
    hasSingleAdAccountsMeta,
    hasSinglePageMeta,
    hasSingleInstagramMeta,
  ]);

  if (isLoading || _.isEmpty(answerOrder) || _.isEmpty(visibleSteps))
    return (
      <Loader fullWidth hasMessage={true} message={t(LOADING_INFORMATION)} />
    );
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        "@supports (height: 100dvh)": {
          height: { xs: "calc(100dvh - 64px)", md: "100%" },
        },
        height: { xs: "calc(100vh - 64px)", md: "100%" },
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
        }}
      >
        <CreateAdsCampaignForm
          campaignID={campaignID}
          currentAnswer={currentAnswer}
          stepVisible={stepVisible}
          setValue={setValue}
          error={error}
          onNextStep={onNextStep}
          onPreviousStep={onPreviousStep}
          control={control}
          getValues={getValues}
          watch={watch}
          isCreating={isCreating}
          onChangePixels={setPixels}
          showBrandContextQuestion={showBrandContextQuestion}
        />
      </Box>
    </Box>
  );
};

export default CreateAdsCampaigns;
