import FormControl from "@mui/material/FormControl";
import SwitchMui from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { ACTIVATE, DEACTIVATE } from "../../i18n/keysTranslations";
import {
  GREEN_COLOR_LIGHT,
  GREY_DISABLED_COLOR,
  WHITE_COLOR,
} from "../../utils/colors";
import { useTranslationApp } from "../../lib/i18next";
import Loader from "../Loaders/Loader";
import { useState } from "react";

const Switch = ({
  id = "",
  isActive,
  disabled = false,
  size = "medium",
  onChangeIsActive = () => {},
  hasLabel = true,
  labelActive = DEACTIVATE,
  labelDeactivate = ACTIVATE,
  colorActivate = GREEN_COLOR_LIGHT,
  colorDeactivated = GREY_DISABLED_COLOR,
  labelPlacement = "end",
  sx = {},
  sxLabel = {},
  loading = false,
}) => {
  const [isActiveState, setIsActiveState] = useState(isActive);
  const { t } = useTranslationApp();
  const onChangeSwitchActive = (e) => {
    onChangeIsActive(e.target.checked);
    setIsActiveState(e.target.checked);
  };

  if (loading) {
    return <Loader size={20} hasMarginTop={false} />;
  }

  const finalIsActive =
    typeof isActive === "undefined" ? isActiveState : isActive;
  return (
    <FormControl
      disabled={disabled}
      component="fieldset"
      variant="standard"
      sx={{ ...sx }}
    >
      <FormControlLabel
        sx={{
          display: "flex",
          ".MuiFormControlLabel-label": {
            fontSize: size === "medium" ? "0.9rem" : "0.8rem",
            fontWeight: 600,
          },
          ml: 0,
          mr: 0,
          ...sxLabel,
        }}
        onClick={(e) => e.stopPropagation()}
        control={
          <SwitchMui
            id={id}
            size={size}
            edge="start"
            color={finalIsActive ? "primary" : "warning"}
            checked={finalIsActive}
            onChange={(e) => {
              e.stopPropagation();
              onChangeSwitchActive(e);
            }}
            sx={{
              ".MuiSwitch-track": {
                background: `${
                  finalIsActive ? colorActivate : colorDeactivated
                } !important`,
                opacity: "1 !important",
              },
              ".MuiSwitch-thumb": {
                backgroundColor: WHITE_COLOR,
              },
            }}
          />
        }
        labelPlacement={labelPlacement}
        label={
          hasLabel ? (finalIsActive ? t(labelActive) : t(labelDeactivate)) : ""
        }
      />
    </FormControl>
  );
};

export default Switch;
