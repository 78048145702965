import { useContext, useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { IMAGE_FORMATS, VIDEO_FORMATS } from "../../utils/constants";
import CloseIcon from "@mui/icons-material/Close";
import {
  BUTTON_SEND,
  BUTTON_SEND_FILE,
  CHAT_WRITE_MESSAGE_HERE,
} from "../../i18n/keysTranslations";
import { useTranslationApp } from "../../lib/i18next";
import { useDispatchApp } from "../../lib/redux";
import { setCurrentWriteMessage } from "../../actions/chat";
import SendIcon from "@mui/icons-material/Send";
import { BOX_SHADOW_ACTIONS_MODAL } from "../../utils/colors";
import { InputBase } from "@mui/material";
import LoadingButton from "../Buttons/LoadingButton";
import { ChatContext } from "../../contexts/ChatContext";
import Button from "../Buttons/Button";
import UploadFileRoundedIcon from "@mui/icons-material/UploadFileRounded";

const ChatMessageInput = ({
  value = "",
  onSendMessage,
  disabled = false,
  showSendFileButton = true,
}) => {
  const { currentChat, isUploadingFile, currentWriteMessage } =
    useContext(ChatContext);

  const [message, setMessage] = useState(value);
  const [isSending, setIsSending] = useState(false);
  const [file, setFile] = useState(null);

  const { t } = useTranslationApp();
  const dispatch = useDispatchApp();

  useEffect(() => {
    setMessage(currentWriteMessage);
    // eslint-disable-next-line
  }, [currentChat]);

  const onChangeMessage = (message) => {
    setMessage(message);
    dispatch(setCurrentWriteMessage(message));
  };

  const onChangeFile = (e) => {
    setFile(e.target.files[0]);
  };

  const onClickSendMessage = async () => {
    if (!message && file === null) return false;

    let currentMessage = message;
    let currentFile = file;

    onChangeMessage("");
    setFile(null);

    const messageFormatted = currentMessage.replace("&nbsp;", " ").trim();

    const response = await onSendMessage(messageFormatted, currentFile);
    if (!response) {
      onChangeMessage(currentMessage);
      setFile(currentFile);
    }
  };

  return (
    <Box
      sx={{
        boxShadow: BOX_SHADOW_ACTIONS_MODAL,
        mr: "-12px",
        px: 2,
        pt: 2,
        zIndex: 10,
      }}
      onKeyDown={(e) => {
        if ((e.code === "Enter" || e.code === "NumpadEnter") && !e.shiftKey) {
          e.preventDefault();
          onClickSendMessage();
        }
      }}
    >
      <input
        type="file"
        hidden
        id="attachButton"
        onChange={onChangeFile}
        accept={[...IMAGE_FORMATS, ...VIDEO_FORMATS, "pdf"].map((item) => {
          if (item === "pdf") return `application/${item},`;
          if (VIDEO_FORMATS.includes(item)) return `video/${item},`;
          return `image/${item},`;
        })}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: {
            xs: "column",
            sm: "row",
          },
          alignItems: "center",
          gap: 2,
          mb: 1,
        }}
      >
        <InputBase
          value={message}
          disabled={disabled || isUploadingFile}
          placeholder={t(CHAT_WRITE_MESSAGE_HERE)}
          multiline
          minRows={3}
          maxRows={4}
          sx={{
            width: "100%",
            backgroundColor: "primary.main",
            padding: "8px 16px",
          }}
          onChange={(e) => {
            const value = e.target.value;
            onChangeMessage(value);
          }}
        />
        <LoadingButton
          loading={isSending}
          color="secondary"
          disabled={
            (message === "" && file === null) || isUploadingFile || disabled
          }
          onClick={async (e) => {
            setIsSending(true);
            await onClickSendMessage(e);
            setIsSending(false);
          }}
          sx={{
            width: {
              xs: "100%",
              sm: "auto",
            },
            ".MuiLoadingButton-loadingIndicator": {
              color: "white !important",
            },
            "&.Mui-disabled": {
              backgroundColor: "rgba(0, 0, 0, 0.04)",
            },
          }}
        >
          <SendIcon sx={{ mr: 1 }} />
          {t(BUTTON_SEND)}
        </LoadingButton>
      </Box>
      {file && (
        <Box
          sx={{
            px: "22px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            pt: "2px",
            pb: "-2px",
            mb: 1,
          }}
        >
          <Box
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            <Typography
              variant="body2"
              align="center"
              sx={{ mb: "2px", color: "text.primary" }}
            >
              {file.name}
            </Typography>
          </Box>
          <IconButton sx={{ p: 0 }} onClick={() => setFile(null)}>
            <CloseIcon />
          </IconButton>
        </Box>
      )}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: {
            xs: "column",
            md: "row",
          },
          justifyContent: "center",
          alignItems: "center",
          gap: 1,
          pt: "4px",
          pb: 1,
        }}
      >
        {showSendFileButton && (
          <Button
            color="primary"
            sx={{
              maxWidth: "100%",
              width: "100%",
            }}
            onClick={async () => {
              document.getElementById("attachButton")?.click();
            }}
          >
            <UploadFileRoundedIcon sx={{ mr: 1 }} />
            {t(BUTTON_SEND_FILE)}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default ChatMessageInput;
