import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Toolbar from "@mui/material/Toolbar";
import { Link } from "react-router-dom";
import { useDispatchApp, useSelectorApp } from "../../lib/redux";
import { getNameBusinessTheme } from "../../actions/getters";
import { DRAWER_WIDTH } from "../../utils/constants";
import { useMediaQuery } from "@mui/material";
import { setOpenSlideMenu } from "../../actions/ui";
import Menus from "../../data/listMenus.js";
import MenuGroupItem from "./MenuGroupItem.jsx";
import LoadingButton from "../Buttons/LoadingButton.jsx";
import { LOGOUT } from "../../i18n/keysTranslations.js";
import { useTranslationApp } from "../../lib/i18next.jsx";
import { startLogout } from "../../actions/auth.js";
import { SelectAdvertiserID } from "../Form/SelectAdvertiserID.jsx";

const SlideMenu = () => {
  const dispatch = useDispatchApp();
  const theme = useSelectorApp((state) => state.ui.theme);
  const { slideMenuIsOpen } = useSelectorApp((state) => state.ui);
  const NAME_BUSINESS = dispatch(getNameBusinessTheme());

  const { t } = useTranslationApp();

  const isTablet = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const variant = isTablet ? "temporary" : "permanent";
  const open = isTablet ? slideMenuIsOpen : true;

  const onLogout = () => {
    dispatch(startLogout());
  };
  return (
    <Drawer
      open={open}
      variant={variant}
      onClose={() => dispatch(setOpenSlideMenu(!slideMenuIsOpen))}
      anchor="left"
      data-tour="navigation"
      sx={{
        width: DRAWER_WIDTH,
        "& .MuiDrawer-paper": {
          width: DRAWER_WIDTH,
          boxSizing: "border-box",
          backgroundColor: "background.paper",
          color: "background.paperContrastText",
          backgroundImage: "none",
          "&.MuiPaper-root": {
            overflowX: "hidden",
            borderRight: "none",
          },
        },
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          overflowX: "hidden",
          overflow: "hidden",
        }}
      >
        <Link
          to="/"
          style={{
            display: "flex",
            width: "65%",
            justifyContent: "flex-start",
          }}
        >
          <Avatar
            src={theme.logo}
            alt={`Logo ${NAME_BUSINESS}`}
            variant="square"
            sx={{
              width: "auto",
              ".MuiAvatar-img": {
                maxWidth: "105px",
                objectFit: "contain",
              },
            }}
          />
        </Link>
      </Toolbar>
      <SelectAdvertiserID
        sx={{
          mx: 2,
        }}
      />
      {Menus.map((menu) => (
        <MenuGroupItem key={menu.nameKey} menu={menu} />
      ))}
      <Box
        sx={{
          display: { xs: "flex", md: "none" },
          height: "100%",
          width: "100%",
          alignItems: "flex-end",
          mb: 2,
        }}
      >
        <LoadingButton
          color="secondary"
          hasShadow={false}
          sx={{
            height: 42,
            maxHeight: 42,
            backgroundColor: "background.paper",
            color: "background.paperContrastText",
            "&:hover": {
              backgroundColor: "background.paper",
              color: "background.paperContrastText",
              filter: "brightness(0.95)",
            },
          }}
          onClick={onLogout}
        >
          {t(LOGOUT)}
        </LoadingButton>
      </Box>
    </Drawer>
  );
};

export default SlideMenu;
