import { useEffect, useState } from "react";
import ContainerPage from "../../components/Containers/ContainerPage";
import DescriptionPage from "../../components/Texts/DescriptionPage";
import TitlePage from "../../components/Texts/TitlePage";
import {
  BUTTON_CREATE_USER,
  USERS_DESCRIPTION,
  USERS_TITLE,
} from "../../i18n/keysTranslations";
import { useTranslationApp } from "../../lib/i18next";
import { startGetAdsUsers } from "../../actions/user";
import { useDispatchApp, useSelectorApp } from "../../lib/redux";
import { getPathDatabaseByRole } from "../../actions/auth";
import { useNavigate } from "react-router-dom";
import UsersTable from "./components/UsersTable";
import Button from "../../components/Buttons/Button";
import Box from "@mui/material/Box";
import ModalUser from "./components/ModalUser";
import { ADVERTISER_ROLE } from "../../utils/constants";

const Users = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [modalUserOpen, setModalUserOpen] = useState(false);
  const { t } = useTranslationApp();
  const dispatch = useDispatchApp();
  const navigate = useNavigate();
  const { agencyID, role } = dispatch(getPathDatabaseByRole());

  const users = useSelectorApp((state) => state.user.users);

  const getInitialData = async () => {
    if (!agencyID || role !== ADVERTISER_ROLE) {
      navigate("/");
    }
    await dispatch(
      startGetAdsUsers({
        onCallbackLoadingFinish: () => setIsLoading(false),
      })
    );
  };

  useEffect(() => {
    getInitialData();
    // eslint-disable-next-line
  }, []);

  return (
    <ContainerPage mode="padding" hasMarginBottom={true} sx={{}}>
      <TitlePage>{t(USERS_TITLE)}</TitlePage>
      <DescriptionPage>{t(USERS_DESCRIPTION)}</DescriptionPage>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          width: "100%",
          mb: 2,
        }}
      >
        <Button
          onClick={() => setModalUserOpen(true)}
          sx={{
            width: "auto",
          }}
        >
          {t(BUTTON_CREATE_USER)}
        </Button>
      </Box>
      <UsersTable data={users} loading={isLoading} />
      {modalUserOpen && (
        <ModalUser
          open={modalUserOpen}
          onCloseModal={() => setModalUserOpen(false)}
          mode="create"
        />
      )}
    </ContainerPage>
  );
};

export default Users;
