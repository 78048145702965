import Box from "@mui/material/Box";
import CardMedia from "../Media/CardMedia";

const GalleryItem = ({ item, index, onFailedLoadUrl, onSelectPreview }) => {
  return (
    <Box
      sx={{
        cursor: "pointer",
        position: "relative",
        "&:hover .overlay": {
          opacity: 1,
        },
        aspectRatio: "1/1",
      }}
      onClick={() => onSelectPreview(index)}
    >
      <CardMedia
        url={item.url}
        onFailedLoadUrl={() => onFailedLoadUrl(index)}
        sx={{
          objectFit: "cover",
          borderRadius: 3,
          height: 400,
          width: "100%",
        }}
        propsType={{
          controls: false,
          autoplay: true,
          muted: true,
          loop: true,
        }}
      />
    </Box>
  );
};

export default GalleryItem;
