import types from "../types";

const initialState = {
  geoIP: {},
  integrations: {},
  businessName: "",
  users: {},
  isLoading: true,
};
export const userReducer = (state = initialState, action) => {
  let integrations;
  switch (action.type) {
    case types.GET_USER_IP:
      return {
        ...state,
        geoIP: action.payload,
      };
    case types.GET_FACEBOOK_INTEGRATION:
      return {
        ...state,
        integrations: {
          ...state.integrations,
          facebook: {
            ...state.integrations.facebook,
            [action.payload.id]: action.payload,
          },
        },
        isLoading: false,
      };
    case types.REVOKE_FACEBOOK_INTEGRATION:
      integrations = { ...state.integrations?.facebook };

      delete integrations[action.payload.id];
      return {
        ...state,
        integrations: {
          ...state.integrations,
          facebook: integrations,
        },
        isLoading: false,
      };
    case types.GET_BUSINESS_NAME:
      return {
        ...state,
        businessName: action.payload,
      };
    case types.GET_ADS_USERS:
      return {
        ...state,
        users: action.payload,
      };
    case types.MANAGE_USER:
      return {
        ...state,
        users: {
          ...state.users,
          [action.payload.userID]: action.payload.data,
        },
      };
    case types.DELETE_USER:
      return {
        ...state,
        users: {
          ...state.users,
          [action.payload]: {
            ...state.users[action.payload],
            isDeleted: true,
          },
        },
      };
    case types.GET_CANVA_INTEGRATION:
      return {
        ...state,
        integrations: {
          ...state.integrations,
          canva: action.payload,
          isLoading: false,
        },
      };
    case types.REVOKE_CANVA_INTEGRATION:
      integrations = { ...state.integrations };
      delete integrations.canva;
      return {
        ...state,
        integrations: integrations,
        isLoading: false,
      };
    case types.GET_TIKTOK_BUSINESS_INTEGRATION:
      return {
        ...state,
        integrations: {
          ...state.integrations,
          tiktokBusiness: {
            ...state.integrations.tiktokBusiness,
            [action.payload.id]: action.payload,
          },
        },
      };
    case types.REVOKE_TIKTOK_BUSINESS_INTEGRATION:
      integrations = { ...state.integrations?.tiktokBusiness };

      delete integrations[action.payload.id];
      return {
        ...state,
        integrations: {
          ...state.integrations,
          tiktokBusiness: integrations,
        },
        isLoading: false,
      };
    case types.GET_INTEGRATIONS:
      return {
        ...state,
        integrations: action.payload,
        isLoading: false,
      };
    case types.GET_LAST_SELECTED_INTEGRATION_DATA:
      return {
        ...state,
        lastSelectedIntegrationData: action.payload,
        isLoading: false,
      };
    case types.SET_LAST_SELECTED_INTEGRATION_DATA_BY_PLATFORM:
      return {
        ...state,
        lastSelectedIntegrationData: {
          ...state.lastSelectedIntegrationData,
          [action.payload.platform]: action.payload.data,
        },
      };
    case types.GLOBAL_RESET_STATE:
      return { ...initialState, geoIP: state.geoIP };
    default:
      return state;
  }
};
