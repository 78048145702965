import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  GRADIENT_PURPLE_FUCHSIA,
  GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT,
} from "../../utils/colors";
import LoadingButton from "./LoadingButton";

const NextButtonStep = ({
  loading = false,
  fullWidth = false,
  onNextStep,
  color = "secondary",
  children,
  sx = {},
}) => {
  return (
    <LoadingButton
      loading={loading}
      fullWidth={fullWidth}
      sx={{
        mt: 2,
        ...sx,
        background: GRADIENT_PURPLE_FUCHSIA,
        color: GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT,
      }}
      onClick={onNextStep}
      color={color}
      endIcon={<ArrowForwardIcon />}
    >
      {children}
    </LoadingButton>
  );
};

export default NextButtonStep;
