import { useSelectorApp } from "../../lib/redux";
import { Navigate } from "react-router-dom";
import { ROLES_ALLOWED } from "../../utils/constants";

export const PrivateRoute = ({ children, isLogged }) => {
  const { user } = useSelectorApp((state) => state.auth);
  return isLogged && ROLES_ALLOWED.includes(user.role) ? (
    children
  ) : (
    <Navigate to="/login" />
  );
};
