import LoadingButtonMui from "@mui/lab/LoadingButton";
import { BORDER_RADIUS } from "../../utils/constants";

const LoadingButton = ({
  children,
  className = "",
  loading = false,
  variant = "contained",
  color = "primary",
  fullWidth = false,
  disabled = false,
  disableElevation = true,
  component,
  loadingPosition,
  type = "",
  onClick,
  startIcon,
  endIcon,
  sx = {},
  size = "medium",
  dataTour = "",
  id,
}) => {
  return (
    <LoadingButtonMui
      id={id}
      data-tour={dataTour}
      className={className}
      loading={loading}
      startIcon={startIcon}
      endIcon={endIcon}
      size={size}
      variant={variant}
      disabled={disabled}
      fullWidth={fullWidth}
      loadingPosition={loadingPosition}
      component={component}
      disableElevation={disableElevation}
      type={type}
      color={color}
      sx={{
        borderRadius: BORDER_RADIUS,
        fontWeight: 600,
        maxHeight: 40,
        lineHeight: "normal",
        minWidth: "max-content",
        py: "10px",
        textAlign: "center",
        whiteSpace: "nowrap",
        ...sx,
        textTransform: "capitalize",
      }}
      onClick={onClick}
    >
      {children}
    </LoadingButtonMui>
  );
};

export default LoadingButton;
