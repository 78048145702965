import { useEffect } from "react";
import { useState } from "react";
import { useDispatchApp } from "../lib/redux";
import { getUserIP } from "../actions/user";

const useUserIP = () => {
  const [userIP, setUserIP] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useDispatchApp();

  const getIP = async () => {
    let firstApi = "https://api.ipify.org?format=json";
    let secondApi = "https://api64.ipify.org?format=json";
    let response = await fetch(firstApi);

    if (!response.ok) {
      response = await fetch(secondApi);
    }

    const data = await response.json();

    if (!data) {
      console.log("Error getting IP");
      setIsLoading(false);
      return false;
    }

    const responseGeo = await fetch(
      `https://api.ipgeolocation.io/ipgeo?apiKey=${
        import.meta.env.VITE_KEY_GEO_API
      }&ip=${data.ip}`
    );

    const dataGeo = await responseGeo.json();
    if (!dataGeo) {
      console.log("Error getting IP");
      setIsLoading(false);
      return false;
    }

    dispatch(
      getUserIP({
        ip: data?.ip,
        country: dataGeo?.country_name,
        countryCode: dataGeo?.country_code2,
        city: dataGeo?.city,
        callingCode: dataGeo?.calling_code,
        currency: dataGeo?.currency?.code,
        continentCode:
          dataGeo?.country_code2 === "MX" ? "SA" : dataGeo?.continent_code,
      })
    );

    setUserIP({
      ip: data?.ip,
      country: dataGeo?.country_name,
      countryCode: dataGeo?.country_code2,
    });
    setIsLoading(false);
  };

  useEffect(() => {
    getIP();
    // eslint-disable-next-line
  }, []);

  return {
    userIP,
    isLoading,
  };
};

export default useUserIP;
