import { useState } from "react";
import { useTranslationApp } from "../../../lib/i18next";
import { DEFAULT_TABLE_PAGES } from "../../../utils/constants";
import Box from "@mui/material/Box";
import TableContainer from "../../../components/Tables/TableContainer";
import {
  ADS_SUBSCRIPTION_TITLE,
  BILLING_AND_PAYMENTS_TABLE_ARIA_LABEL,
  COLUMN_NAME_ACTION,
  COLUMN_NAME_DUE_DATE,
  COLUMN_NAME_PAID_DATE,
  COLUMN_NAME_REASON,
  COLUMN_NAME_STATUS,
  COLUMN_NAME_VALUE,
  DATE_SHORT,
  EMPTY_INVOICES,
  EMPTY_RESULTS_SHOW,
  VALUE_WITH_CURRENCY,
} from "../../../i18n/keysTranslations";
import TableCell from "./TableCell";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import { capitalizeFirstLetter } from "../../../utils/string";
import StatusInvoice from "./StatusInvoice";
import ActionsInvoicesTable from "./ActionsInvoicesTable";

const InvoicesTable = ({
  data = {},
  loading,
  onSelectInvoice = () => {},
  onPayInvoice = () => {},
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(DEFAULT_TABLE_PAGES);

  const { t } = useTranslationApp();

  const onChangePage = (newPage) => {
    setPage(newPage);
  };

  const keysSorted = Object.keys(data).sort(
    (a, b) => data[b].dueDate.getTime() - data[a].dueDate.getTime()
  );

  return (
    <Box sx={{ width: "100%" }}>
      <TableContainer
        page={page}
        loading={loading}
        emptyText={EMPTY_INVOICES}
        onPageChange={onChangePage}
        rowsPerPage={rowsPerPage}
        keys={keysSorted}
        ariaLabel={t(BILLING_AND_PAYMENTS_TABLE_ARIA_LABEL)}
      >
        <TableHead>
          <TableRow>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_PAID_DATE)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_DUE_DATE)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_VALUE)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_REASON)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_STATUS)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_ACTION)}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? keysSorted.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )
            : keysSorted
          ).map((key, index) => (
            <TableRow key={key}>
              <TableCell align="center" indexRow={index}>
                {data[key].paidDate === ""
                  ? "-"
                  : t(DATE_SHORT, {
                      date: data[key].paidDate,
                    })}
              </TableCell>
              <TableCell align="center" indexRow={index}>
                {data[key].dueDate === ""
                  ? "-"
                  : t(DATE_SHORT, {
                      date: data[key].dueDate,
                    })}
              </TableCell>
              <TableCell align="center" indexRow={index}>
                {t(VALUE_WITH_CURRENCY, {
                  value: data[key].value,
                  currency: data[key]?.currency?.toUpperCase() || "USD",
                })}
              </TableCell>
              <TableCell align="center" indexRow={index}>
                {t(ADS_SUBSCRIPTION_TITLE, {
                  value: capitalizeFirstLetter(data[key].subscription),
                })}
              </TableCell>
              <TableCell align="center" indexRow={index}>
                <StatusInvoice isPaid={data[key].isPaid} />
              </TableCell>
              <TableCell align="center" indexRow={index}>
                <ActionsInvoicesTable
                  data={data[key]}
                  keyInvoice={key}
                  onSelectInvoice={onSelectInvoice}
                  onPayInvoice={onPayInvoice}
                />
              </TableCell>
            </TableRow>
          ))}
          {keysSorted.length === 0 && (
            <TableRow>
              <TableCell colSpan={7} sx={{ textAlign: "center" }}>
                {t(EMPTY_RESULTS_SHOW)}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </TableContainer>
    </Box>
  );
};

export default InvoicesTable;
