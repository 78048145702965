import React, { useEffect, useState } from "react";
import { useTranslationApp } from "../../lib/i18next";
import {
  FIELD_REQUIRED,
  META_DESTINATION_TYPE_INSTAGRAM_PROFILE_CLARIFICATION,
} from "../../i18n/keysTranslations";
import i18next from "i18next";
import Select from "../Form/Select";
import {
  ADS_CAMPAIGN_META_DESTINATION_TYPE_INSTAGRAM_PROFILE_OPTION,
  ADS_CAMPAIGN_OBJECTIVE_ENGAGEMENT_OPTION,
  ADS_CAMPAIGN_OBJECTIVE_LEADS_OPTION,
  ADS_CAMPAIGN_OBJECTIVE_SALES_OPTION,
  ADS_CAMPAIGN_OBJECTIVE_TRAFFIC_OPTION,
} from "../../utils/constants";
import {
  optionsMetaDestinationTypeEngagementObjective,
  optionsMetaDestinationTypeLeadsObjective,
  optionsMetaDestinationTypeSalesObjective,
  optionsMetaDestinationTypeTrafficObjective,
} from "../../utils/options";
import { useController } from "react-hook-form";

const getOptions = (objective) => {
  switch (objective) {
    case ADS_CAMPAIGN_OBJECTIVE_TRAFFIC_OPTION:
      return optionsMetaDestinationTypeTrafficObjective;
    case ADS_CAMPAIGN_OBJECTIVE_ENGAGEMENT_OPTION:
      return optionsMetaDestinationTypeEngagementObjective;
    case ADS_CAMPAIGN_OBJECTIVE_LEADS_OPTION:
      return optionsMetaDestinationTypeLeadsObjective;
    case ADS_CAMPAIGN_OBJECTIVE_SALES_OPTION:
      return optionsMetaDestinationTypeSalesObjective;

    default:
      return [];
  }
};

const SelectDestinationTypeMeta = React.forwardRef(
  function SelectDestinationTypeMeta(props, ref) {
    const {
      control,
      errors = {},
      rules = {
        required: {
          value: true,
          message: i18next.t(FIELD_REQUIRED),
        },
      },
      objective,
      name,
      label,
      disabled = false,
      variant = "filled",
      fullWidth,
      placeholder,
      actionsChange = () => {},
      sx = {},
    } = props;

    const {
      field: { value: valueField },
    } = useController({
      name,
      control,
    });
    const [options, setOptions] = useState([]);
    const { t } = useTranslationApp();

    useEffect(() => {
      if (!objective) return;

      setOptions(getOptions(objective));
      // eslint-disable-next-line
    }, [objective]);

    const getHelperText = () => {
      if (
        valueField ===
        ADS_CAMPAIGN_META_DESTINATION_TYPE_INSTAGRAM_PROFILE_OPTION
      ) {
        return META_DESTINATION_TYPE_INSTAGRAM_PROFILE_CLARIFICATION;
      } else {
        return "";
      }
    };

    return (
      <Select
        ref={ref}
        label={t(label)}
        disabled={disabled}
        name={name}
        variant={variant}
        options={options}
        fullWidth={fullWidth}
        placeholder={placeholder}
        displayEmpty={placeholder ? true : false}
        showEmptyOption={placeholder ? true : false}
        doTranslate={true}
        helperText={getHelperText()}
        control={control}
        errors={errors}
        actionsChange={actionsChange}
        rules={{
          ...rules,
          validate: (value) => {
            if (!options.map((o) => o.value).includes(value)) return t();
          },
        }}
        sx={sx}
      />
    );
  }
);

export default SelectDestinationTypeMeta;
