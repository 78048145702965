import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import { useTranslationApp } from "../../lib/i18next";
import {
  GRADIENT_PURPLE_FUCHSIA,
  GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT,
} from "../../utils/colors";
import TitlePage from "../Texts/TitlePage";
import {
  PRICING_HERO_DESCRIPTION,
  PRICING_HERO_TITLE,
} from "../../i18n/keysTranslations";

const PricingHero = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery("(max-width:600px)");

  const { t } = useTranslationApp();

  return (
    <Box
      sx={{
        pt: 4,
        pb: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: theme.palette.secondary.main,
        width: "100%",
        px: {
          md: 4,
          xs: 3,
        },
        background: GRADIENT_PURPLE_FUCHSIA,
      }}
    >
      <Box
        sx={{
          maxWidth: "md",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <TitlePage
          align="center"
          sx={{
            color: GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT,
          }}
        >
          {t(PRICING_HERO_TITLE)}
        </TitlePage>
        <Typography
          variant={isMobile ? "h6" : "h5"}
          align="center"
          component="p"
          sx={{
            color: GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT,
          }}
        >
          {t(PRICING_HERO_DESCRIPTION)}
        </Typography>
      </Box>
    </Box>
  );
};

export default PricingHero;
