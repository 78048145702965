import { useEffect, useState, useRef } from "react";

export default function useNearScreen({
  distance = "100px",
  externalRef,
  once = true,
  disabledTimeout = false,
} = {}) {
  const [isNearScreen, setIsNearScreen] = useState(false);
  const [loadLastItem, setLoadLastItem] = useState(false);
  const fromRef = useRef();
  const timeRef = useRef();

  useEffect(() => {
    let observer;

    const element = externalRef ? externalRef.current : fromRef.current;

    const onChange = (entries, observer) => {
      const el = entries[0];
      if (el.isIntersecting) {
        setIsNearScreen(true);
        once && observer.disconnect();
      } else {
        !once && setIsNearScreen(false);
      }
    };

    Promise.resolve(
      typeof IntersectionObserver !== "undefined"
        ? IntersectionObserver
        : import("intersection-observer")
    ).then(() => {
      observer = new IntersectionObserver(onChange, {
        rootMargin: distance,
      });

      if (element) observer.observe(element);
    });

    return () => observer && observer.disconnect();
  });

  useEffect(() => {
    if (disabledTimeout) return;
    if (isNearScreen && !loadLastItem) {
      timeRef.current = setTimeout(() => {
        setIsNearScreen((prev) => {
          if (prev) {
            return false;
          }
          return prev;
        });
      }, 1000);
    }
    // eslint-disable-next-line
  }, [isNearScreen]);

  return {
    isNearScreen,
    fromRef,
    loadLastItem,
    onChangeLoadLastItem: setLoadLastItem,
  };
}
