import Box from "@mui/material/Box";
import LoadingButton from "./LoadingButton";
import { useTranslationApp } from "../../lib/i18next";
import {
  ALERT_ICON_TYPE_ERROR,
  IMAGE_FORMATS,
  MAX_SIZE_FILES,
  MAX_SIZE_FILES_VALUE_DISPLAY,
  MAX_SIZE_IMAGE,
  MAX_SIZE_IMAGE_VALUE_DISPLAY,
} from "../../utils/constants";
import { SimpleAlert } from "../Alerts/Alerts";
import {
  BUTTON_CHANGE_CONTENT,
  BUTTON_UPLOAD_CONTENT,
  ERROR,
  FIELD_FILE_MAX_SIZE,
  FIELD_FILE_VALID_REQUIRED,
} from "../../i18n/keysTranslations";
import { UploadFilesContext } from "../../contexts/UploadFilesContext";
import { useContext } from "react";

const UploadFileButton = ({
  id = "upload-media",
  value,
  name,
  disabled = false,
  icon,
  onChange,
  textUpload = BUTTON_UPLOAD_CONTENT,
  colorUpload = "primary",
  textRemove = BUTTON_CHANGE_CONTENT,
  colorRemove = "secondary",
  isHidden = false,

  acceptedFormats = [],
  multiple,
  modeUpload = "sync",

  pathRTDB,
  pathStorage,
  loading = false,
  onCallbackLoading = () => {},
  sx = {},
}) => {
  const { t } = useTranslationApp();
  const { uploadFile } = useContext(UploadFilesContext);

  const getPropsByValue = () => {
    if (value) {
      return {
        loading,
        color: colorUpload,
        variant: "contained",
      };
    }

    return {
      loading,
      color: colorRemove,
      variant: "contained",
    };
  };

  const props = getPropsByValue();

  const validateFiles = (files) => {
    let hasFileNotAccepted = false;
    let hasFileToBig = false;
    let valueFileToBig = 0;

    for (const file of files) {
      const fileFormat = file.type.split("/")[1];

      if (!acceptedFormats.includes(fileFormat)) {
        hasFileNotAccepted = true;
        break;
      }

      const isImage = IMAGE_FORMATS.includes(fileFormat);
      const MAX_SIZE = isImage ? MAX_SIZE_IMAGE : MAX_SIZE_FILES;
      const VALUE_DISPLAY = isImage
        ? MAX_SIZE_IMAGE_VALUE_DISPLAY
        : MAX_SIZE_FILES_VALUE_DISPLAY;

      if (file.size > MAX_SIZE) {
        hasFileToBig = true;
        valueFileToBig = VALUE_DISPLAY;
        break;
      }
    }

    if (hasFileNotAccepted) {
      SimpleAlert({
        title: t(ERROR),
        text: t(FIELD_FILE_VALID_REQUIRED, {
          value: acceptedFormats.join(", "),
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
    if (hasFileToBig) {
      SimpleAlert({
        title: t(ERROR),
        text: t(FIELD_FILE_MAX_SIZE, {
          value: valueFileToBig,
          type: "MB",
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }

    return true;
  };

  const onChangeFile = async (e) => {
    const files = e.target.files;

    if (files.length === 0) return;

    const isValidFiles = validateFiles(files);
    if (!isValidFiles) return;

    let arrayUrls = [];
    if (modeUpload === "async") {
      for (const file of files) {
        let preview = URL.createObjectURL(file);
        preview = `${preview}?ext=${file.type.split("/")[1]}`;

        arrayUrls.push(preview);
        onChange(preview);
      }
    }

    let index = 0;
    for (const file of files) {
      const urlUploaded = await uploadFile({
        file,
        pathStorage,
        pathRTDB,
        onCallbackLoading,
        doCompression: true,
        modeUpload,
        urlPreview: arrayUrls[index],
      });

      index++;

      if (modeUpload === "sync") {
        onChange(urlUploaded);
      }
    }
    return true;
  };

  if (modeUpload === "async" && !pathRTDB)
    throw new Error("When modeUpload is async, pathRTDB is required");

  return (
    <Box
      sx={{
        display: isHidden ? "none" : "flex",
      }}
    >
      <LoadingButton
        {...props}
        type="button"
        component={"label"}
        disabled={disabled}
        endIcon={icon && icon}
        sx={sx}
      >
        {t(value ? textRemove : textUpload)}
        <input
          id={id}
          multiple={multiple}
          accept={acceptedFormats
            .map((item) => {
              if (IMAGE_FORMATS.includes(item)) return `image/${item},`;
              return `video/${item},`;
            })
            .join(" ")}
          name={name}
          type="file"
          hidden
          onChange={onChangeFile}
        />
      </LoadingButton>
    </Box>
  );
};

export default UploadFileButton;
