import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import PricingCard from "./PricingCard";
import Box from "@mui/material/Box";
import { useState } from "react";
import {
  MONTHLY,
  QUARTERLY,
  SEMIYEARLY,
  YEARLY,
} from "../../i18n/keysTranslations";
import { useTranslationApp } from "../../lib/i18next";
import { useMediaQuery } from "@mui/material";
import { BORDER_RADIUS } from "../../utils/constants";

const PricingCardsContainer = ({
  plans = [],
  onChangePlanSelected = () => {},
}) => {
  const [recurrent, setRecurrent] = useState("monthly");

  const { t } = useTranslationApp();
  const isMobile = useMediaQuery("(max-width:480px)");

  const grid = () => {
    if (plans.length === 3) {
      return {
        xs: 1,
      };
    }
  };

  const commonSxTab = (recurrentValue) => ({
    padding: { xs: "12px 24px", sm: "12px 36px", md: "12px 48px" },
    cursor: "pointer",
    background: recurrent === recurrentValue ? "white" : "transparent",
    color: recurrent === recurrentValue ? "black" : "white",
  });

  return (
    <Container
      maxWidth="xlg"
      sx={{
        mt: 3,
        px: {
          md: 4,
          xs: 3,
        },
        mb: 3,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          mb: 5,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            justifyContent: "center",
            border: "1px solid white",
            width: "min-content",
            borderRadius: BORDER_RADIUS,
          }}
        >
          <Box
            onClick={() => setRecurrent("monthly")}
            sx={{
              ...commonSxTab("monthly"),
              borderTopLeftRadius: BORDER_RADIUS * 4,
              borderBottomLeftRadius: isMobile ? 0 : BORDER_RADIUS * 4,
              borderTopRightRadius: isMobile ? BORDER_RADIUS * 4 : 0,
            }}
          >
            {t(MONTHLY)}
          </Box>
          <Box
            onClick={() => setRecurrent("quarterly")}
            sx={{
              ...commonSxTab("quarterly"),
            }}
          >
            {t(QUARTERLY)}
          </Box>
          <Box
            onClick={() => setRecurrent("semiyearly")}
            sx={{
              ...commonSxTab("semiyearly"),
            }}
          >
            {t(SEMIYEARLY)}
          </Box>
          <Box
            onClick={() => setRecurrent("yearly")}
            sx={{
              ...commonSxTab("yearly"),
              borderTopRightRadius: isMobile ? 0 : BORDER_RADIUS * 4,
              borderBottomLeftRadius: isMobile ? BORDER_RADIUS * 4 : 0,
              borderBottomRightRadius: BORDER_RADIUS * 4,
            }}
          >
            {t(YEARLY)}
          </Box>
        </Box>
      </Box>
      <Grid container spacing={5} alignItems="flex-end" justifyContent="center">
        {plans.map((plan) => (
          <Grid item key={plan.id} {...grid()}>
            <PricingCard
              data={plan}
              recurrent={recurrent}
              onChangePlanSelected={onChangePlanSelected}
            />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default PricingCardsContainer;
