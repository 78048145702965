import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";

if (
  typeof window !== "undefined" &&
  import.meta.env.VITE_IS_STAGING === "false"
) {
  posthog.init(import.meta.env.VITE_POSTHOG_KEY, {
    api_host: import.meta.env.VITE_POSTHOG_HOST,
    person_profiles: "identified_only",
    capture_pageview: false,
    session_recording: {
      maskAllInputs: true,
      maskInputFn: (text, element) => {
        const maskTypes = ["email", "password", "phone"];

        if (
          maskTypes.indexOf(element?.attributes["type"]?.value) !== -1 ||
          maskTypes.indexOf(element?.attributes["id"]?.value) !== -1
        ) {
          return "*".repeat(text.length);
        }
        return text;
      },
      maskTextSelector: "*",
      maskTextFn(text) {
        // A simple email regex - you may want to use something more advanced
        const emailRegex = /(\S+)@(\S+\.\S+)/g;

        return text.trim().replace(emailRegex, (match, g1, g2) => {
          // Replace each email with asterisks - ben@posthog.com becomes ***@***********
          return "*".repeat(g1.length) + "@" + "*".repeat(g2.length);
        });
      },
    },
  });
}

export function PHProvider({ children }) {
  return <PostHogProvider client={posthog}>{children}</PostHogProvider>;
}
