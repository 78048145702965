import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import { useTheme } from "@mui/material/styles";
import { useTranslationApp } from "../../lib/i18next";
import { FACEBOOK, INSTAGRAM } from "../../i18n/keysTranslations";
import {
  ADS_CAMPAIGN_MEDIA_FROM_FACEBOOK_POST,
  ADS_CAMPAIGN_MEDIA_FROM_INSTAGRAM_POST,
} from "../../utils/constants";

const ChipsFilterPosts = ({ onChangeFilter, filterSelect = true }) => {
  const theme = useTheme();

  const { t } = useTranslationApp();

  return (
    <Box sx={{ display: "flex", gap: 1 }}>
      <Chip
        label={t(INSTAGRAM)}
        onClick={() => {
          onChangeFilter(ADS_CAMPAIGN_MEDIA_FROM_INSTAGRAM_POST);
        }}
        sx={{
          backgroundColor:
            filterSelect === ADS_CAMPAIGN_MEDIA_FROM_INSTAGRAM_POST
              ? theme.palette.secondary.main
              : "white !important",
          color:
            filterSelect === ADS_CAMPAIGN_MEDIA_FROM_INSTAGRAM_POST
              ? theme.palette.secondary.contrastText
              : "black",
          ":hover": {
            backgroundColor:
              filterSelect === ADS_CAMPAIGN_MEDIA_FROM_INSTAGRAM_POST
                ? theme.palette.secondary.main
                : "white !important",
            filter: "brightness(0.7)",
          },
          ".MuiChip-icon": {
            color:
              filterSelect === ADS_CAMPAIGN_MEDIA_FROM_INSTAGRAM_POST
                ? theme.palette.secondary.contrastText
                : "black",
          },
        }}
      />
      <Chip
        label={t(FACEBOOK)}
        onClick={() => {
          onChangeFilter(ADS_CAMPAIGN_MEDIA_FROM_FACEBOOK_POST);
        }}
        sx={{
          backgroundColor:
            filterSelect === ADS_CAMPAIGN_MEDIA_FROM_FACEBOOK_POST
              ? theme.palette.secondary.main
              : "white !important",
          color:
            filterSelect === ADS_CAMPAIGN_MEDIA_FROM_FACEBOOK_POST
              ? theme.palette.secondary.contrastText
              : "black",
          ":hover": {
            backgroundColor:
              filterSelect === ADS_CAMPAIGN_MEDIA_FROM_FACEBOOK_POST
                ? theme.palette.secondary.main
                : "white !important",
            filter: "brightness(0.7)",
          },
          ".MuiChip-icon": {
            color:
              filterSelect === ADS_CAMPAIGN_MEDIA_FROM_FACEBOOK_POST
                ? theme.palette.secondary.contrastText
                : "black",
          },
        }}
      />
    </Box>
  );
};

export default ChipsFilterPosts;
