import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import cardNumber from "../../assets/img/cardNumber.jpg";
import cardExpired from "../../assets/img/cardExpired.jpg";
import cardCVV from "../../assets/img/cardCVV.jpg";
import {
  CARD_INFORMATION_CVV_EXPLANATION_DESCRIPTION,
  CARD_INFORMATION_CVV_EXPLANATION_TITLE,
  CARD_INFORMATION_EXPIRATION_DATE_EXPLANATION_DESCRIPTION,
  CARD_INFORMATION_EXPIRATION_DATE_EXPLANATION_TITLE,
  CARD_INFORMATION_NUMBER_CARD_EXPLANATION_DESCRIPTION,
  CARD_INFORMATION_NUMBER_CARD_EXPLANATION_TITLE,
} from "../../i18n/keysTranslations";
import { useTranslationApp } from "../../lib/i18next";

const ContentInfoCard = () => {
  const { t } = useTranslationApp();
  return (
    <React.Fragment>
      <Box sx={{ mb: 2 }}>
        <Typography
          color="inherit"
          variant="body1"
          sx={{ fontWeight: 600, mb: 1 }}
        >
          {t(CARD_INFORMATION_NUMBER_CARD_EXPLANATION_TITLE)}
        </Typography>
        <Typography
          color="inherit"
          variant="body1"
          sx={{ fontWeight: 300, mb: 1 }}
        >
          {t(CARD_INFORMATION_NUMBER_CARD_EXPLANATION_DESCRIPTION)}
        </Typography>
        <Avatar
          src={cardNumber}
          sx={{ width: "auto", height: "auto" }}
          variant="square"
        />
      </Box>
      <Box sx={{ mb: 2 }}>
        <Typography
          color="inherit"
          variant="body1"
          sx={{ fontWeight: 600, mb: 1 }}
        >
          {t(CARD_INFORMATION_EXPIRATION_DATE_EXPLANATION_TITLE)}
        </Typography>
        <Typography
          color="inherit"
          variant="body1"
          sx={{ fontWeight: 300, mb: 1 }}
        >
          {t(CARD_INFORMATION_EXPIRATION_DATE_EXPLANATION_DESCRIPTION)}
        </Typography>
        <Avatar
          src={cardExpired}
          sx={{ width: "auto", height: "auto" }}
          variant="square"
        />
      </Box>
      <Box sx={{ mb: 2 }}>
        <Typography
          color="inherit"
          variant="body1"
          sx={{ fontWeight: 600, mb: 1 }}
        >
          {t(CARD_INFORMATION_CVV_EXPLANATION_TITLE)}
        </Typography>
        <Typography
          color="inherit"
          variant="body1"
          sx={{ fontWeight: 300, mb: 1 }}
        >
          {t(CARD_INFORMATION_CVV_EXPLANATION_DESCRIPTION)}
        </Typography>
        <Avatar
          src={cardCVV}
          sx={{ width: "auto", height: "auto" }}
          variant="square"
        />
      </Box>
    </React.Fragment>
  );
};

export default ContentInfoCard;
