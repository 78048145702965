import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { BUTTON_SAVE_CHANGES } from "../../i18n/keysTranslations";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslationApp } from "../../lib/i18next";
import LoadingButton from "../Buttons/LoadingButton";
import TooltipModal from "../Tooltip/TooltipModal";
import Accordion from "./Accordion";

const ContainerPaperSection = ({
  children,
  onSubmit = () => {},
  title = "",
  isFetching = false,
  showTopButton = true,
  buttonTopText = BUTTON_SAVE_CHANGES,
  buttonTopColor = "secondary",
  buttonTopType = "submit",
  buttonTopOnClick = () => {},
  customTopButton = null,
  showBottomButton = true,
  buttonBottomText = BUTTON_SAVE_CHANGES,
  buttonBottomColor = "secondary",
  buttonBottomType = "submit",
  buttonBottomOnClick = () => {},
  customBottomButton = null,
  hasDividers = true,
  elevation = 0,
  sx = {},
  sxBox = {},
  className = "",
  dataTourTitle = "",
  titleTooltip = "",
  contentTooltip = "",
  hasAccordion = false,
  initialValueAccordion = false,
  sxTitle = {},
}) => {
  const { t } = useTranslationApp();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const renderCommonContent = () => {
    return (
      <Paper
        elevation={elevation}
        sx={{
          width: "100%",
          mt: 3,
          py: !hasAccordion ? { xs: 1.5, sm: 3 } : 1,
          px: !hasAccordion ? { xs: 1.5, sm: 3 } : 0,
          borderRadius: 5,
          backgroundImage: "none",
          backgroundColor: "background.paper",
          ...sx,
        }}
        component="form"
        onSubmit={onSubmit}
        className={className}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            gap: 1,
            ...sxBox,
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: 1,
              alignItems: "center",
            }}
          >
            {!hasAccordion && (
              <>
                {title && (
                  <Typography
                    variant={isMobile ? "body1" : "h6"}
                    component="h2"
                    sx={{ fontWeight: 600, fontStyle: "normal", ...sxTitle }}
                    data-tour={dataTourTitle}
                  >
                    {t(title)}
                  </Typography>
                )}
                {titleTooltip && contentTooltip && (
                  <TooltipModal title={titleTooltip} content={contentTooltip} />
                )}
              </>
            )}
          </Box>
          {showTopButton && (
            <>
              {customTopButton ? (
                customTopButton
              ) : (
                <LoadingButton
                  loading={isFetching}
                  color={buttonTopColor}
                  variant="contained"
                  type={buttonTopType}
                  onClick={buttonTopOnClick}
                  sx={{
                    minWidth: 150,
                    maxHeight: 37,
                  }}
                >
                  {t(buttonTopText)}
                </LoadingButton>
              )}
            </>
          )}
        </Box>
        {hasDividers && (
          <Divider
            sx={{ my: !hasAccordion ? 3 : 1, mx: !hasAccordion ? -3 : 0 }}
          />
        )}

        {children}
        {hasDividers && (
          <Divider
            sx={{ my: !hasAccordion ? 3 : 2, mx: !hasAccordion ? -3 : 0 }}
          />
        )}

        {showBottomButton && (
          <>
            {customBottomButton ? (
              customBottomButton
            ) : (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <LoadingButton
                  loading={isFetching}
                  color={buttonBottomColor}
                  variant="contained"
                  type={buttonBottomType}
                  onClick={buttonBottomOnClick}
                  sx={{
                    minWidth: 150,
                    maxHeight: 37,
                  }}
                >
                  {t(buttonBottomText)}
                </LoadingButton>
              </Box>
            )}
          </>
        )}
      </Paper>
    );
  };

  if (hasAccordion) {
    return (
      <Accordion
        initialValue={initialValueAccordion}
        title={title ? t(title) : ""}
        sx={{
          border: "none",
          "&:before": {
            backgroundColor: "unset",
          },
          margin: "0px !important",
          "& .MuiAccordionSummary-root": {
            padding: 0,
            minHeight: "unset !important",
          },
          "& .MuiAccordionSummary-content": {
            margin: "0px !important",
            minHeight: "unset !important",
          },
          "& .MuiAccordionDetails-root": {
            padding: 0,
          },
        }}
        variantTitle={isMobile ? "body1" : "h6"}
        sxTitle={{ fontWeight: 600, fontStyle: "normal", ...sxTitle }}
      >
        {renderCommonContent()}
      </Accordion>
    );
  }

  return renderCommonContent();
};

export default ContainerPaperSection;
