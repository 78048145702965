import { useState } from "react";
import LoadingButton from "./LoadingButton";
import { BUTTON_CONNECT_GOOGLE } from "../../i18n/keysTranslations";
import { BUTTON_GOOGLE_ACTION_ID, GOOGLE } from "../../utils/constants";
import Avatar from "@mui/material/Avatar";
import { logoPlatform } from "../../utils/string";
import { useTranslationApp } from "../../lib/i18next";

const ButtonGoogleAction = ({
  id = BUTTON_GOOGLE_ACTION_ID,
  text = BUTTON_CONNECT_GOOGLE,
  disabled = false,
  onClick = () => {},
  sx = {},
  logo = GOOGLE,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslationApp();
  return (
    <LoadingButton
      id={id}
      disabled={disabled}
      loading={isLoading}
      color="primary"
      sx={{
        textTransform: "none",
        width: "100%",
        ...sx,
      }}
      onClick={async (e) => {
        e.preventDefault();
        setIsLoading(true);
        await onClick();
        setIsLoading(false);
      }}
    >
      <Avatar
        src={logoPlatform(logo)}
        variant="square"
        sx={{
          width: "20px",
          height: "20px",
          mr: 1,
          ".MuiAvatar-img": {
            objectFit: "contain",
          },
        }}
      />
      {t(text)}
    </LoadingButton>
  );
};

export default ButtonGoogleAction;
