import { Controller, useFormContext } from "react-hook-form";
import Accordion from "../../../components/Containers/Accordion";
import { t } from "i18next";
import {
  ACTIVE,
  AD_ACCOUNT_META_PLACEHOLDER,
  AD_ACCOUNT_TIKTOK_PLACEHOLDER,
  ADS_CAMPAIGN_CAMPAIGN_INFORMATION_SUB_TITLE,
  ADS_CAMPAIGNS_PIXELS_DESCRIPTION_EXPLANATION,
  ADS_CAMPAIGNS_PIXELS_TITLE_EXPLANATION,
  ALERT_DELETE_PLATFORM_CAMPAIGN_DESCRIPTION,
  ARE_YOU_SURE,
  BUTTON_DELETE,
  DEACTIVATED,
  FIELD_REQUIRED,
  IDENTITY_TIK_TOK_PLACEHOLDER,
  INSTAGRAM_ACCOUNT_PLACEHOLDER,
  META_DESTINATION_TYPE_PLACEHOLDER,
  PAGE_FACEBOOK_PLACEHOLDER,
  TRACKING_CUSTOM_EVENT_ID_META_PLACEHOLDER,
  TRACKING_CUSTOM_EVENT_ID_TIKTOK_PLACEHOLDER,
  TRACKING_META_ID_PLACEHOLDER,
  TRACKING_TIKTOK_ID_PLACEHOLDER,
  WELCOME_MESSAGE_TEMPLATE_PLACEHOLDER,
  WHATSAPP_BUSINESS_NUMBER_PLACEHOLDER,
} from "../../../i18n/keysTranslations";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import SelectTikTokIdentityCustom from "../../../components/Form/SelectTikTokIdentityCustom";
import {
  ADS_CAMPAIGN_META_DESTINATION_TYPE_INSTAGRAM_PROFILE_OPTION,
  ADS_CAMPAIGN_META_DESTINATION_TYPE_WEBSITE_OPTION,
  ADS_CAMPAIGN_META_DESTINATION_TYPE_WHATSAPP_OPTION,
  ADS_CAMPAIGN_META_DESTINATION_TYPES_WITH_MESSAGE_TEMPLATE,
  ADS_CAMPAIGN_OBJECTIVE_ENGAGEMENT_OPTION,
  ADS_CAMPAIGN_OBJECTIVES_META_WITH_DESTINATION_TYPE,
  ADS_CAMPAIGN_OBJECTIVES_WITH_TRACKING_META,
  ADS_CAMPAIGN_OBJECTIVES_WITH_TRACKING_TIKTOK,
  ADS_CAMPAIGN_STATUS_ACTIVE,
  ADS_CAMPAIGN_STATUS_DEACTIVATED,
  ADS_CAMPAIGNS_OBJECTIVES_WITH_CUSTOM_EVENT_TRACKING_META,
  ADS_CAMPAIGNS_OBJECTIVES_WITH_CUSTOM_EVENT_TRACKING_TIKTOK,
  META,
  STYLES_BY_PLATFORM,
  TIKTOK,
} from "../../../utils/constants";
import SelectTrackingIDField from "../../../components/Form/SelectTrackingIDField";
import TooltipModal from "../../../components/Tooltip/TooltipModal";
import ContentCommon from "../../../components/Tooltip/ContentCommon";
import AdsGroupForm from "./AdsGroupForm";
import { capitalizeFirstLetter } from "../../../utils/string";
import { useState } from "react";
import Switch from "../../../components/Form/Switch";
import SelectBusinessAccount from "../../../components/Meta/SelectBusinessAccount";
import SelectPageFacebook from "../../../components/Meta/SelectPageFacebook";
import SelectInstagramAccount from "../../../components/Meta/SelectInstagramAccount";
import SelectCustomEventAds from "../../../components/Form/SelectCustomEventAds";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import _ from "lodash";
import { ConfirmAlert } from "../../../components/Alerts/Alerts";
import SelectDestinationTypeMeta from "../../../components/Meta/SelectDestinationTypeMeta";
import SelectWhatsappBusinessNumber from "../../../components/Form/SelectWhatsappBusinessNumber";
import MessageTemplateContainer from "../../../components/Meta/MessageTemplateContainer";

const LABELS_TRACKING_ID = {
  [META]: TRACKING_META_ID_PLACEHOLDER,
  [TIKTOK]: TRACKING_TIKTOK_ID_PLACEHOLDER,
};
const AD_ACCOUNT_PLATFORMS_LABELS = {
  [META]: AD_ACCOUNT_META_PLACEHOLDER,
  [TIKTOK]: AD_ACCOUNT_TIKTOK_PLACEHOLDER,
};
const LABELS_TRACKING_CUSTOM_EVENTS = {
  [META]: TRACKING_CUSTOM_EVENT_ID_META_PLACEHOLDER,
  [TIKTOK]: TRACKING_CUSTOM_EVENT_ID_TIKTOK_PLACEHOLDER,
};

const AdsCampaignPlatformForm = ({ platform }) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [pixels, setPixels] = useState([]);
  const {
    currency,
    watch,
    setValue,
    getValues,
    control,
    isGeneralDisabledFields,
    getIsCampaignPlatformCreated,
    getShowActionsActionsPlatform,
    onChangeStatusCampaign,
    formState: { errors },
  } = useFormContext();

  const isCampaignPlatformCreated = getIsCampaignPlatformCreated(platform);
  const showActionsPlatform = getShowActionsActionsPlatform(platform);
  const objective = watch("objective");
  const platformsData = watch(`platformsData.${platform}`);
  const adsGroups = watch("adsGroups")?.filter(
    (adGroup) => adGroup.platform === platform
  );
  const pixelSelected = _.isEmpty(pixels)
    ? {}
    : pixels.find((p) => p.id === platformsData?.trackingID);

  const styles = STYLES_BY_PLATFORM[platform] || {};

  const onChangeSwitch = async (value) => {
    setIsSaving(true);
    await onChangeStatusCampaign({
      newStatus: value
        ? ADS_CAMPAIGN_STATUS_ACTIVE
        : ADS_CAMPAIGN_STATUS_DEACTIVATED,
      target: "platform",
      platform,
    });
    setIsSaving(false);
  };

  const onDeletePlatform = async () => {
    const result = await ConfirmAlert({
      title: t(ARE_YOU_SURE),
      text: t(ALERT_DELETE_PLATFORM_CAMPAIGN_DESCRIPTION, {
        platform: capitalizeFirstLetter(platform),
      }),
      confirmButtonText: t(BUTTON_DELETE),
    });
    if (!result.isConfirmed) return;

    const platforms = getValues("platforms");
    const newPlatforms = platforms.filter((p) => p !== platform);
    const platformsData = { ...getValues("platformsData") };
    const adsGroups = getValues("adsGroups");

    delete platformsData[platform];
    setValue("platforms", newPlatforms);
    setValue("platformsData", platformsData);

    const newAdsGroups = adsGroups.filter(
      (adGroup) => adGroup.platform !== platform
    );
    setValue("adsGroups", newAdsGroups);
  };

  const onChangeDestinationType = (value) => {
    adsGroups.forEach((adGroup, index) => {
      if (adGroup.platform !== META) return;
      const ads = adGroup?.ads || [];

      ads.forEach((ad, indexAd) => {
        const currentCallToAction = ad?.callToAction;
        const callToAction =
          value === ADS_CAMPAIGN_META_DESTINATION_TYPE_INSTAGRAM_PROFILE_OPTION
            ? "VIEW_INSTAGRAM_PROFILE"
            : currentCallToAction === "VIEW_INSTAGRAM_PROFILE"
            ? ""
            : currentCallToAction;
        setValue(
          `adsGroups.[${index}].ads.[${indexAd}].callToAction`,
          callToAction
        );
      });
    });
  };

  const getShowTrackingID = () => {
    if (
      platform === META &&
      !ADS_CAMPAIGN_OBJECTIVES_WITH_TRACKING_META.includes(objective)
    ) {
      return false;
    }
    if (
      platform === TIKTOK &&
      !ADS_CAMPAIGN_OBJECTIVES_WITH_TRACKING_TIKTOK.includes(objective)
    ) {
      return false;
    }

    if (
      platform === META &&
      objective === ADS_CAMPAIGN_OBJECTIVE_ENGAGEMENT_OPTION &&
      platformsData.destinationType !==
        ADS_CAMPAIGN_META_DESTINATION_TYPE_WEBSITE_OPTION
    ) {
      return false;
    }

    return true;
  };
  const getShowTrackingCustomEvent = () => {
    if (
      platform === META &&
      !ADS_CAMPAIGNS_OBJECTIVES_WITH_CUSTOM_EVENT_TRACKING_META.includes(
        objective
      )
    ) {
      return false;
    }
    if (
      platform === TIKTOK &&
      !ADS_CAMPAIGNS_OBJECTIVES_WITH_CUSTOM_EVENT_TRACKING_TIKTOK.includes(
        objective
      )
    ) {
      return false;
    }

    if (
      platform === META &&
      objective === ADS_CAMPAIGN_OBJECTIVE_ENGAGEMENT_OPTION &&
      platformsData.destinationType !==
        ADS_CAMPAIGN_META_DESTINATION_TYPE_WEBSITE_OPTION
    ) {
      return false;
    }

    return true;
  };

  const showTrackingID = getShowTrackingID();
  const showTrackingCustomEvent = getShowTrackingCustomEvent();

  return (
    <Accordion
      title={t(ADS_CAMPAIGN_CAMPAIGN_INFORMATION_SUB_TITLE, {
        platform: capitalizeFirstLetter(t(platform)),
      })}
      variantTitle={"body1"}
      initialValue={true}
      onCallbackChangeExpanded={(value) => setIsExpanded(value)}
      iconTitle={
        <Avatar
          src={styles.logo}
          alt={`Logo ${platform}`}
          variant="square"
          sx={{
            height: 25,
            width: 25,
            "& .MuiAvatar-img": {
              objectFit: "contain",
            },
          }}
        />
      }
      actionsComponent={
        isGeneralDisabledFields ? null : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              flexGrow: 1,
              mr: { xs: 0, sm: 2 },
              pl: { xs: 2, sm: 0 },
            }}
          >
            {showActionsPlatform && (
              <Controller
                name={`states.${platform}`}
                control={control}
                render={({ field }) => (
                  <Switch
                    loading={isSaving}
                    isActive={field.value === ADS_CAMPAIGN_STATUS_ACTIVE}
                    labelActive={ACTIVE}
                    labelDeactivate={DEACTIVATED}
                    onChangeIsActive={(value) => onChangeSwitch(value)}
                  />
                )}
              />
            )}
            <IconButton
              sx={{
                color: styles.color,
              }}
              onClick={(e) => {
                e?.stopPropagation();
                onDeletePlatform();
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        )
      }
      sx={{
        border: `${styles.backgroundColor} 4px solid`,
        color: styles.color,
        borderRadius: `${16}px !important`,
        "& > .MuiAccordionSummary-root": {
          color: styles.color,
          backgroundColor: styles.backgroundColor,
          borderTopRightRadius: 8,
          borderTopLeftRadius: 8,
          borderBottomRightRadius: isExpanded ? 0 : 8,
          borderBottomLeftRadius: isExpanded ? 0 : 8,
        },
        ".MuiAccordionSummary-root": {
          ".MuiAccordionSummary-content": {
            m: 0,
            mr: 1,
          },
          "& .MuiAccordionSummary-expandIconWrapper": {
            color: styles.color,
            mb: 0,
          },
        },
      }}
    >
      <Grid
        container
        spacing={2}
        sx={{
          mt: 0,
        }}
      >
        <Grid item xs={12} lg={6}>
          <SelectBusinessAccount
            variant="filled"
            disabled={
              Boolean(platformsData?.adAccountID) || isGeneralDisabledFields
            }
            label={t(AD_ACCOUNT_PLATFORMS_LABELS[platform])}
            control={control}
            platform={platform}
            name={`platformsData.${platform}.adAccountID`}
            onChangeAction={(value) => {
              setValue(
                `platformsData.${platform}.integrationID`,
                value?.integrationID
              );
              setValue(`platformsData.${platform}.trackingID`, "");
              setValue(`platformsData.${platform}.trackingCustomEventID`, "");
              setValue(`platformsData.${platform}.identityTikTokID`, "");
              setValue(`platformsData.${platform}.pageID`, "");
              setValue(`platformsData.${platform}.instagramID`, "");
              setValue(`platformsData.${platform}.whatsappBusinessNumber`, "");
            }}
            errors={errors}
            fullWidth={true}
            rules={{
              required: {
                value: true,
                message: t(FIELD_REQUIRED),
              },
            }}
          />
        </Grid>
        {platform === META && (
          <Grid item xs={12} lg={6}>
            <SelectPageFacebook
              variant="filled"
              disabled={
                isCampaignPlatformCreated ||
                !platformsData?.adAccountID ||
                isGeneralDisabledFields
              }
              label={t(PAGE_FACEBOOK_PLACEHOLDER)}
              name={`platformsData.${platform}.pageID`}
              integrationID={platformsData?.integrationID}
              adAccountID={platformsData?.adAccountID}
              control={control}
              platform={platform}
              errors={errors}
              fullWidth={true}
              rules={{
                required: {
                  value: true,
                  message: t(FIELD_REQUIRED),
                },
              }}
            />
          </Grid>
        )}
        {platform === META && (
          <Grid item xs={12} lg={6}>
            <SelectInstagramAccount
              variant="filled"
              disabled={
                isCampaignPlatformCreated ||
                !platformsData?.pageID ||
                isGeneralDisabledFields
              }
              label={t(INSTAGRAM_ACCOUNT_PLACEHOLDER)}
              name={`platformsData.${platform}.instagramID`}
              integrationID={platformsData?.integrationID}
              pageID={platformsData?.pageID}
              adAccountID={platformsData?.adAccountID}
              control={control}
              errors={errors}
              platform={platform}
              fullWidth={true}
              rules={{
                required: {
                  value: true,
                  message: t(FIELD_REQUIRED),
                },
              }}
            />
          </Grid>
        )}
        {platform === TIKTOK && (
          <Grid item xs={12} lg={6}>
            <SelectTikTokIdentityCustom
              label={t(IDENTITY_TIK_TOK_PLACEHOLDER)}
              objective={objective}
              adAccountID={platformsData?.adAccountID}
              integrationID={platformsData?.integrationID}
              name={`platformsData.${platform}.identityTikTokID`}
              fullWidth
              variant="filled"
              control={control}
              errors={errors}
              disabled={
                isCampaignPlatformCreated ||
                !platformsData?.adAccountID ||
                isGeneralDisabledFields
              }
              rules={{
                required: {
                  value: true,
                  message: t(FIELD_REQUIRED),
                },
              }}
            />
          </Grid>
        )}
        {platform === META &&
          ADS_CAMPAIGN_OBJECTIVES_META_WITH_DESTINATION_TYPE.includes(
            objective
          ) && (
            <Grid item xs={12} lg={6}>
              <SelectDestinationTypeMeta
                label={META_DESTINATION_TYPE_PLACEHOLDER}
                name={`platformsData.${platform}.destinationType`}
                objective={objective}
                variant="filled"
                fullWidth={true}
                disabled={isCampaignPlatformCreated}
                control={control}
                errors={errors}
                actionsChange={(value) => onChangeDestinationType(value)}
                rules={{
                  required: {
                    value: true,
                    message: t(FIELD_REQUIRED),
                  },
                }}
              />
            </Grid>
          )}
        {platformsData.destinationType ===
          ADS_CAMPAIGN_META_DESTINATION_TYPE_WHATSAPP_OPTION &&
          platform === META && (
            <Grid item xs={12} lg={6}>
              <SelectWhatsappBusinessNumber
                label={t(WHATSAPP_BUSINESS_NUMBER_PLACEHOLDER)}
                name={`platformsData.${platform}.whatsappBusinessNumber`}
                integrationID={platformsData?.integrationID}
                disabled={isCampaignPlatformCreated}
                fullWidth
                variant="filled"
                control={control}
                errors={errors}
                rules={{
                  required: {
                    value: true,
                    message: t(FIELD_REQUIRED),
                  },
                }}
              />
            </Grid>
          )}
        {ADS_CAMPAIGN_META_DESTINATION_TYPES_WITH_MESSAGE_TEMPLATE.includes(
          platformsData.destinationType
        ) && (
          <Grid item xs={12} lg={6}>
            <MessageTemplateContainer
              label={t(WELCOME_MESSAGE_TEMPLATE_PLACEHOLDER)}
              name={`platformsData.${platform}.messageTemplate`}
              disabled={isCampaignPlatformCreated}
              fullWidth
              control={control}
              errors={errors}
              rules={{
                required: {
                  value: true,
                  message: t(FIELD_REQUIRED),
                },
              }}
            />
          </Grid>
        )}
        {showTrackingID && (
          <Grid item xs={12} lg={6}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                gap: 1,
              }}
            >
              <SelectTrackingIDField
                name={`platformsData.${platform}.trackingID`}
                disabled={isCampaignPlatformCreated || isGeneralDisabledFields}
                label={t(LABELS_TRACKING_ID[platform])}
                fullWidth
                variant="filled"
                platform={platform}
                integrationID={platformsData?.integrationID}
                adAccountID={platformsData?.adAccountID}
                onLoadOptions={(newPixels) => {
                  setPixels(newPixels);
                }}
                onChangeAction={() => {
                  setValue(
                    `platformsData.${platform}.trackingCustomEventID`,
                    ""
                  );
                }}
                control={control}
                errors={errors}
                rules={{
                  required: {
                    value: true,
                    message: t(FIELD_REQUIRED),
                  },
                }}
              />
              <TooltipModal
                sx={{
                  mt: 0.5,
                }}
                title={t(ADS_CAMPAIGNS_PIXELS_TITLE_EXPLANATION)}
                content={
                  <ContentCommon
                    text={ADS_CAMPAIGNS_PIXELS_DESCRIPTION_EXPLANATION}
                  />
                }
              />
            </Box>
          </Grid>
        )}
        {showTrackingCustomEvent && (
          <Grid item xs={12} lg={6}>
            <SelectCustomEventAds
              label={t(LABELS_TRACKING_CUSTOM_EVENTS[platform])}
              name={`platformsData.${platform}.trackingCustomEventID`}
              platform={platform}
              variant="filled"
              disabled={isCampaignPlatformCreated || isGeneralDisabledFields}
              integrationID={platformsData?.integrationID}
              adAccountID={platformsData?.adAccountID}
              pixelSelected={pixelSelected}
              objective={objective}
              control={control}
              errors={errors}
              fullWidth={true}
              rules={{
                required: {
                  value: true,
                  message: t(FIELD_REQUIRED),
                },
              }}
            />
          </Grid>
        )}
        {adsGroups.map((adGroup, index) => (
          <Grid item xs={12} key={adGroup.id}>
            <AdsGroupForm
              data={adGroup}
              currency={currency}
              adGroupNumber={index}
              platform={platform}
            />
          </Grid>
        ))}
      </Grid>
    </Accordion>
  );
};

export default AdsCampaignPlatformForm;
