export const setNestedValue = ({ data, path = "", setValue }) => {
  Object.keys(data).forEach((key) => {
    const isArrayIndex = !isNaN(key);
    const keyPath = isArrayIndex ? `[${key}]` : key;
    const fullPath = path ? `${path}.${keyPath}` : key;
    const value = data[key];

    if (typeof value === "object" && value !== null) {
      setNestedValue({ data: value, path: fullPath, setValue });
    } else {
      setValue(fullPath, value);
    }
  });
};
