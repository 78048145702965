import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import mastercardLogo from "../../assets/img/mastercard-logo.png";
import visaLogo from "../../assets/img/visa-logo.png";
import americanExpressLogo from "../../assets/img/americanExpress-logo.png";
import discoverLogo from "../../assets/img/discover-logo.png";
import logoStripe from "../../assets/img/logo-stripe.png";
import { useTranslationApp } from "../../lib/i18next";
import {
  PAYMENT_METHODS_SECURE,
  PAYMENT_METHODS_SECURE_EXPLANATION,
} from "../../i18n/keysTranslations";

const StripeSecurePayments = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const { t } = useTranslationApp();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        mb: 2,
      }}
    >
      {isMobile ? <LogosLess600px /> : <LogosMore600px />}
      <Typography variant="h6" sx={{ fontWeight: 600 }} align="center">
        {t(PAYMENT_METHODS_SECURE)}
      </Typography>
      <Typography variant="body2" align="center">
        {t(PAYMENT_METHODS_SECURE_EXPLANATION)}
      </Typography>
    </Box>
  );
};

const LogosMore600px = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", sm: "row" },
        alignItems: "center",
        gap: 3,
        mb: 1,
      }}
    >
      <Avatar
        src={americanExpressLogo}
        variant="square"
        sx={{ width: "auto", height: 32 }}
      />
      <Avatar
        src={mastercardLogo}
        variant="square"
        sx={{ width: "auto", height: 32 }}
      />
      <Avatar
        src={logoStripe}
        variant="square"
        sx={{ width: "auto", height: 40 }}
      />
      <Avatar
        src={visaLogo}
        variant="square"
        sx={{ width: "auto", height: 32 }}
      />
      <Avatar
        src={discoverLogo}
        variant="square"
        sx={{ width: "auto", height: 32 }}
      />
    </Box>
  );
};

const LogosLess600px = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        mb: 1,
        gap: 1.5,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          gap: 1,
        }}
      >
        <Avatar
          src={americanExpressLogo}
          variant="square"
          sx={{
            width: "auto",
            height: 32,
            ".MuiAvatar-img": {
              objectFit: "contain",
            },
          }}
        />

        <Avatar
          src={mastercardLogo}
          variant="square"
          sx={{
            width: "auto",
            height: 32,
            ".MuiAvatar-img": {
              objectFit: "contain",
            },
          }}
        />

        <Avatar
          src={visaLogo}
          variant="square"
          sx={{
            width: "auto",
            height: 32,
            ".MuiAvatar-img": {
              objectFit: "contain",
            },
          }}
        />

        <Avatar
          src={discoverLogo}
          variant="square"
          sx={{
            width: "auto",
            height: 32,
            ".MuiAvatar-img": {
              objectFit: "contain",
            },
          }}
        />
      </Box>
      <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Avatar
          src={logoStripe}
          variant="square"
          sx={{
            width: 88,
            height: 30,
            ".MuiAvatar-img": {
              objectFit: "contain",
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default StripeSecurePayments;
