import { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Backdrop from "@mui/material/Backdrop";
import { useDispatchApp } from "../../lib/redux";
import { useTranslationApp } from "../../lib/i18next";
import { startGetStatusVideoFromCreatify } from "../../actions/user";
import {
  ALL_VIDEO_FINISHED_STATUS_CREATIFY,
  VIDEO_DONE_STATUS_CREATIFY,
} from "../../utils/constants";
import LinearLoaderWithTime from "./LinearLoaderWithTime";
import Typography from "@mui/material/Typography";
import {
  CONTENT_NUMERATION,
  CREATIFY_VIDEO_RENDERING_CLARIFICATION,
  ERROR_OCURRED,
  FINISHED,
} from "../../i18n/keysTranslations";

const BackdropLoaderRenderMediaCreatify = ({
  open,
  data = [],
  mainLabel = CREATIFY_VIDEO_RENDERING_CLARIFICATION,
  itemLabel = CONTENT_NUMERATION,
  sx = {},
  onRenderMediaFinished = () => {},
}) => {
  const [dataState] = useState(data);
  const [status, setStatus] = useState([]);
  const [progress, setProgress] = useState([]);
  const isUpdating = useRef([]);
  const timersRef = useRef([]); // Cambiamos timers a useRef para mantener la referencia actualizada

  const dispatch = useDispatchApp();
  const { t } = useTranslationApp();

  const getStatus = (index) => {
    return status[index];
  };
  const updateStatusState = (index, newStatus) => {
    setStatus((prev) => {
      const newStatusState = [...prev];
      newStatusState[index] = newStatus;
      return newStatusState;
    });
  };

  const getProgress = (index) => {
    return progress[index] || 0;
  };
  const updateProgressState = (index, newProgress) => {
    setProgress((prev) => {
      const newProgressState = [...prev];
      const currentProgress = newProgressState[index];
      if (currentProgress && currentProgress > newProgress)
        return newProgressState;

      newProgressState[index] = newProgress;
      return newProgressState;
    });
  };

  const getIsUpdating = (index) => {
    return isUpdating?.current?.[index] || false;
  };
  const updateIsUpdatingState = (index, newIsUpdating) => {
    isUpdating.current[index] = newIsUpdating;
  };

  const onUpdateProgress = async (renderDataItem) => {
    if (getIsUpdating(renderDataItem.index)) return;

    updateIsUpdatingState(renderDataItem.index, true);
    const response = await dispatch(
      startGetStatusVideoFromCreatify({
        adIDs: renderDataItem.adIDs,
        videoID: renderDataItem.videoID,
      })
    );
    updateIsUpdatingState(renderDataItem.index, false);
    if (!response.ok) return;
    const status = response?.data?.status;
    const newProgress = response?.data?.progress;

    updateProgressState(renderDataItem.index, newProgress);

    if (!ALL_VIDEO_FINISHED_STATUS_CREATIFY.includes(status)) return;

    updateStatusState(renderDataItem.index, status);
    if (timersRef.current[renderDataItem.index]) {
      clearInterval(timersRef.current[renderDataItem.index]);
      timersRef.current[renderDataItem.index] = null;
    }
    onRenderMediaFinished({
      ...renderDataItem,
      videoData: response?.data,
    });
  };

  useEffect(() => {
    if (dataState?.length === 0 || !dataState) return;

    timersRef.current.forEach((timer) => {
      if (timer) clearInterval(timer);
    });
    timersRef.current = [];

    dataState.forEach((item, index) => {
      const timer = setInterval(() => {
        onUpdateProgress({ ...item, index });
      }, 5000);
      timersRef.current[index] = timer;
    });

    return () => {
      timersRef.current.forEach((timer) => {
        if (timer) clearInterval(timer);
      });
      timersRef.current = [];
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Backdrop
      sx={() => ({
        color: "#fff",
        zIndex: 1300,
        backgroundColor: "rgba(0, 0, 0, 0.8)",
      })}
      open={open}
    >
      <Box
        sx={{
          ...sx,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 1,
        }}
      >
        <Typography
          variant="h6"
          align="center"
          sx={{
            mb: 1,
          }}
        >
          {t(mainLabel)}
        </Typography>

        {dataState.map((_, index) => {
          const progressItem = getProgress(index);
          const status = getStatus(index);

          const completeLabel = ALL_VIDEO_FINISHED_STATUS_CREATIFY.includes(
            status
          )
            ? ` -  ${
                status === VIDEO_DONE_STATUS_CREATIFY
                  ? t(FINISHED)
                  : t(ERROR_OCURRED)
              }`
            : "";
          return (
            <Box
              key={index}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                width: "100%",
              }}
            >
              <LinearLoaderWithTime
                progress={progressItem}
                color={
                  ALL_VIDEO_FINISHED_STATUS_CREATIFY.includes(status)
                    ? status === VIDEO_DONE_STATUS_CREATIFY
                      ? "success"
                      : "error"
                    : "inherit"
                }
                label={
                  t(itemLabel, {
                    value: index + 1,
                  }) + completeLabel
                }
              />
            </Box>
          );
        })}
      </Box>
    </Backdrop>
  );
};

export default BackdropLoaderRenderMediaCreatify;
