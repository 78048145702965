import "@formatjs/intl-numberformat/polyfill";
import "@formatjs/intl-numberformat/locale-data/en";

export const numberCurrencyFormat = (number = 0) => {
  number = number || 0;
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

export const numberCompactFormat = (number) =>
  new Intl.NumberFormat("en", { notation: "compact" }).format(number);

export const numberDecimal = (number) => {
  if (typeof number !== "number") return number;
  return Math.round((number + Number.EPSILON) * 100) / 100;
};

export const numberRounded = (number) => {
  if (typeof number !== "number") return number;
  return Math.round(number);
};
export const numberRoundedToGreater = (number) => {
  if (typeof number !== "number") return number;
  return Math.ceil(number);
};

export function textNumberCompactFormat(value) {
  return `${numberCompactFormat(value)}`;
}

export const getUniqueID = () =>
  new Date().getTime().toString() + Math.random().toString(36).substring(2);
