import Box from "@mui/material/Box";
import ModalBasicLayout from "../../../components/Modal/ModalBasicLayout";
import { useTranslationApp } from "../../../lib/i18next";
import {
  BUTTON_CLOSE,
  BUTTON_CREATE,
  BUTTON_SAVE,
  EMAIL_PLACEHOLDER,
  FIELD_EMAIL_REGISTERED,
  FIELD_EMAIL_VALID,
  FIELD_PASSWORD_INSECURE,
  FIELD_REQUIRED,
  MODAL_USER_ARIA_LABEL,
  MODAL_USER_DESCRIPTION,
  MODAL_USER_DESCRIPTION_EDIT,
  MODAL_USER_TITLE,
  MODAL_USER_TITLE_EDIT,
  NAME_PLACEHOLDER,
  PASSWORD_CLARIFICATION_IN_EDIT_USER,
  PASSWORD_PLACEHOLDER,
  READY,
  ROLE_PLACEHOLDER,
  USERS_USER_CREATED,
  USERS_USER_SAVED,
} from "../../../i18n/keysTranslations";
import { useDispatchApp, useSelectorApp } from "../../../lib/redux";
import { getNameBusinessTheme } from "../../../actions/getters";
import ContainerModalActions from "../../../components/Containers/ContainerModalActions";
import { useForm } from "react-hook-form";
import useFocusErrorForm from "../../../hooks/useFocusErrorForm";
import TextField from "../../../components/Form/TextField";
import { REGEX_EMAIL, REGEX_PASSWORD } from "../../../utils/regex";
import { useEffect, useState } from "react";
import IconButton from "../../../components/Buttons/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Select from "../../../components/Form/Select";
import { optionsAdsRoles } from "../../../utils/options";
import { auth } from "../../../lib/firebase";
import { fetchSignInMethodsForEmail } from "firebase/auth";
import { startManageUser } from "../../../actions/user";
import { SimpleAlert } from "../../../components/Alerts/Alerts";
import { ALERT_ICON_TYPE_SUCCESS } from "../../../utils/constants";

const ModalUser = ({ open, onCloseModal, mode, userID }) => {
  const [isFetching, setIsFetching] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { control, formState, handleSubmit, setValue, setError } = useForm({
    defaultValues: {
      name: "",
      email: "",
      role: "",
      password: "",
    },
  });

  useFocusErrorForm(formState);
  const { errors } = formState;

  const dispatch = useDispatchApp();
  const { t } = useTranslationApp();
  const NAME_BUSINESS = dispatch(getNameBusinessTheme());
  const users = useSelectorApp((state) => state.user.users);
  const user = users?.[userID];

  const onChangeShowPassword = () => setShowPassword((prev) => !prev);

  const onSubmit = async (formValues) => {
    const { email } = formValues;

    setIsFetching(true);

    if (
      mode === "create" ||
      (mode === "edit" && user && user?.email !== email)
    ) {
      const result = await fetchSignInMethodsForEmail(auth, email);
      if (result.length > 0) {
        setError("email", {
          type: "manual",
          message: t(FIELD_EMAIL_REGISTERED),
        });
        setIsFetching(false);
        return;
      }
    }

    const response = await dispatch(
      startManageUser({
        ...formValues,
        userID,
      })
    );
    if (response) {
      SimpleAlert({
        title: t(READY),
        text: t(mode === "create" ? USERS_USER_CREATED : USERS_USER_SAVED),
        icon: ALERT_ICON_TYPE_SUCCESS,
      });
      onCloseModal();
    }
    setIsFetching(false);
  };

  useEffect(() => {
    if (!userID) return;

    if (!user) {
      onCloseModal();
      return;
    }

    const { name, email, role } = user;
    setValue("name", name);
    setValue("email", email);
    setValue("role", role);

    // eslint-disable-next-line
  }, []);

  return (
    <ModalBasicLayout
      modalOpen={open}
      onCloseModal={onCloseModal}
      fullWidth={true}
      maxWidth="xs"
      scroll="paper"
      ariaLabel={t(MODAL_USER_ARIA_LABEL)}
      title={t(mode === "create" ? MODAL_USER_TITLE : MODAL_USER_TITLE_EDIT)}
      description={t(
        mode === "create"
          ? MODAL_USER_DESCRIPTION
          : MODAL_USER_DESCRIPTION_EDIT,
        { NAME_BUSINESS }
      )}
    >
      <Box
        onSubmit={handleSubmit(onSubmit)}
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1.5,
        }}
      >
        <TextField
          fullWidth
          label={t(NAME_PLACEHOLDER)}
          name="name"
          variant="filled"
          color="primary"
          InputLabelProps={{ shrink: true }}
          control={control}
          rules={{
            required: {
              value: true,
              message: t(FIELD_REQUIRED),
            },
          }}
          errors={errors}
        />
        <TextField
          fullWidth
          id="email"
          label={t(EMAIL_PLACEHOLDER)}
          name="email"
          variant="filled"
          color="primary"
          InputLabelProps={{ shrink: true }}
          control={control}
          rules={{
            required: {
              value: true,
              message: t(FIELD_REQUIRED),
            },
            validate: {
              emailValid: (value) => {
                if (!REGEX_EMAIL.test(value)) {
                  return t(FIELD_EMAIL_VALID);
                }
              },
            },
          }}
          errors={errors}
        />
        <Select
          fullWidth
          id="role"
          label={t(ROLE_PLACEHOLDER)}
          name="role"
          variant="filled"
          color="primary"
          doTranslate={true}
          options={optionsAdsRoles}
          control={control}
          rules={{
            required: {
              value: true,
              message: t(FIELD_REQUIRED),
            },
          }}
          errors={errors}
        />
        <TextField
          fullWidth
          id="password"
          name="password"
          label={t(PASSWORD_PLACEHOLDER)}
          type={showPassword ? "text" : "password"}
          variant="filled"
          color="primary"
          InputLabelProps={{ shrink: true }}
          InputProps={{
            endAdornment: (
              <IconButton onClick={onChangeShowPassword}>
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            ),
          }}
          helperText={mode === "edit" && t(PASSWORD_CLARIFICATION_IN_EDIT_USER)}
          control={control}
          rules={{
            required: {
              value: false,
              message: t(FIELD_REQUIRED),
            },
            validate: (value) => {
              if (mode === "create") {
                if (!value) {
                  return t(FIELD_REQUIRED);
                }
                if (!REGEX_PASSWORD.test(value)) {
                  return t(FIELD_PASSWORD_INSECURE);
                }
              }
              if (mode === "edit" && value && !REGEX_PASSWORD.test(value)) {
                return t(FIELD_PASSWORD_INSECURE);
              }
            },
          }}
          errors={errors}
        />
        <ContainerModalActions
          hasPadding={false}
          propsPrimaryButton={{
            showButton: true,
            text: mode === "create" ? BUTTON_CREATE : BUTTON_SAVE,
            loading: isFetching,
            onClick: () => {},
          }}
          propsSecondaryButton={{
            showButton: true,
            text: BUTTON_CLOSE,
            loading: isFetching,
            onClick: onCloseModal,
          }}
        />
      </Box>
    </ModalBasicLayout>
  );
};

export default ModalUser;
