import ReactMarkdown from "react-markdown";

const Markdown = ({ children }) => {
  return (
    <ReactMarkdown
      components={{
        h1: ({ ...props }) => (
          <h1
            {...props}
            style={{
              margin: "8px 0px",
              fontSize: "revert",
            }}
          />
        ),
        h2: ({ ...props }) => (
          <h2
            {...props}
            style={{
              margin: "8px 0px",
              fontSize: "revert",
            }}
          />
        ),
        h3: ({ ...props }) => (
          <h3
            {...props}
            style={{
              margin: "8px 0px",
              fontSize: "revert",
            }}
          />
        ),
        h4: ({ ...props }) => (
          <h4
            {...props}
            style={{
              margin: "8px 0px",
              fontSize: "revert",
            }}
          />
        ),
        h5: ({ ...props }) => (
          <h5
            {...props}
            style={{
              margin: "8px 0px",
              fontSize: "revert",
            }}
          />
        ),
        h6: ({ ...props }) => (
          <h6
            {...props}
            style={{
              margin: "8px 0px",
              fontSize: "revert",
            }}
          />
        ),
        p: ({ ...props }) => (
          <p
            {...props}
            style={{
              margin: "8px 0px",
              fontSize: "revert",
            }}
          />
        ),
        pre: ({ ...props }) => (
          <pre
            {...props}
            style={{
              margin: "8px 0px",
              fontSize: "revert",
            }}
          />
        ),
        ul: ({ ...props }) => (
          <ul
            {...props}
            style={{
              fontSize: "revert",
              margin: "revert",
              padding: "revert",
            }}
          />
        ),
      }}
    >
      {children}
    </ReactMarkdown>
  );
};

export default Markdown;
