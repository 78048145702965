import { useEffect, useState } from "react";
import { useTranslationApp } from "../../lib/i18next";
import { useDispatchApp } from "../../lib/redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getPathDatabaseByRole } from "../../actions/auth";
import {
  startGetAdsCampaign,
  startGetAdsCampaignStatisticsHistorical,
  startGetDetailedCampaignReport,
} from "../../actions/adsCampaigns";
import Loader from "../../components/Loaders/Loader";
import {
  CHAT_ASK_A_QUESTION_ABOUT_YOUR_CAMPAIGN,
  CONCLUSION,
  DATE_SHORT_2,
  EMPTY_STATISTICS_FOR_DETAILED_REPORT,
  LOADING_INFORMATION,
  RECOMMENDATIONS,
} from "../../i18n/keysTranslations";
import ContainerPage from "../../components/Containers/ContainerPage";
import Box from "@mui/material/Box";
import HistoricalStatisticsCampaign from "./components/HistoricalStatisticsCampaign.jsx/HistoricalStatisticsCampaign";
import Typography from "@mui/material/Typography";
import GlobalPlatformsStatisticsCampaign from "./components/GlobalPlatformsStatisticsCampaign";
import { Skeleton } from "@mui/material";
import SkeletonLoaderStatisticsGraphs from "./components/SkeletonLoaderStatisticsGraphs";
import Markdown from "../../components/Texts/Markdown";
import CurrentStateCampaign from "./components/CurrentStateCampaign";
import {
  ADS_CAMPAIGN_GENERATE_DETAILED_REPORT_ACTION,
  CHAT_CONFIGURATION_ADS_DETAILED_REPORT_ID,
} from "../../utils/constants";
import { getIsBlockedActions } from "../../actions/getters";
import { getFromStatisticsAdsCampaign } from "../../services/adsCampaigns";
import CommonChatMainContainer from "../../components/CommonChat/CommonChatMainContainer";
import _ from "lodash";

const AdsCampaignStatistics = () => {
  const [campaign, setCampaign] = useState({});
  const [report, setReport] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isGeneratingReport, setIsGeneratingReport] = useState(true);
  const [isOpenChat, setIsOpenChat] = useState(true);

  const { t } = useTranslationApp();
  const dispatch = useDispatchApp();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const campaignID = params.get("campaignID");
  const { userID } = dispatch(getPathDatabaseByRole());

  const getInitialData = async () => {
    setIsLoading(true);
    const response = await dispatch(startGetAdsCampaign(campaignID));
    const responseHistoricalStatistics = await dispatch(
      startGetAdsCampaignStatisticsHistorical(campaignID)
    );

    if (!response) {
      navigate("/");
    }

    const historicalStatistics = responseHistoricalStatistics || {};
    const campaignObject = {
      ...response,
      historicalStatistics,
    };
    setCampaign(campaignObject);

    const { from } = getFromStatisticsAdsCampaign(campaignObject);
    if (from !== "empty") {
      getDetailedCampaignReport();
    }

    setIsLoading(false);
  };

  const getDetailedCampaignReport = async () => {
    const isBlocked = await dispatch(
      getIsBlockedActions({
        action: ADS_CAMPAIGN_GENERATE_DETAILED_REPORT_ACTION,
      })
    );
    if (isBlocked) {
      navigate(`/ads-campaign?campaignID=${campaignID}`);
      return;
    }

    setIsGeneratingReport(true);
    const response = await dispatch(
      startGetDetailedCampaignReport({
        campaignID,
      })
    );
    if (response.ok) {
      setReport(response.data);
    }
    setIsGeneratingReport(false);
  };

  useEffect(() => {
    getInitialData();
    // eslint-disable-next-line
  }, [campaignID, userID]);

  if (isLoading) {
    return (
      <Loader fullWidth hasMessage={true} message={t(LOADING_INFORMATION)} />
    );
  }

  const { from } = getFromStatisticsAdsCampaign(campaign);

  return (
    <ContainerPage
      mode="padding"
      hasMarginBottom={true}
      sx={{
        minHeight: "calc(100vh - 64px)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 3,
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              alignItems: { xs: "flex-start", sm: "center" },
              justifyContent: "space-between",
              gap: 1,
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontWeight: "bold",
              }}
            >
              {campaign.name}
            </Typography>
            <Typography variant="body1" color="text.secondary">
              {t(DATE_SHORT_2, { date: new Date() })}
            </Typography>
          </Box>
          {from !== "empty" && (
            <>
              {isGeneratingReport ? (
                <Skeleton variant="rounded" width={"100%"} height={100} />
              ) : (
                <Typography variant="body1">{report.overview}</Typography>
              )}
            </>
          )}
        </Box>

        {from === "empty" && (
          <Typography variant="body1" align="center">
            {t(EMPTY_STATISTICS_FOR_DETAILED_REPORT)}
          </Typography>
        )}
        {from !== "empty" && (
          <>
            {isGeneratingReport ? (
              <SkeletonLoaderStatisticsGraphs />
            ) : (
              <>
                <CurrentStateCampaign data={campaign} />
                <GlobalPlatformsStatisticsCampaign
                  data={campaign}
                  report={report}
                />
                <HistoricalStatisticsCampaign data={campaign} report={report} />
                <Box>
                  <Typography
                    variant="h5"
                    gutterBottom
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    {t(CONCLUSION)}
                  </Typography>
                  <Markdown>{report.conclusion}</Markdown>
                </Box>
                <Box>
                  <Typography
                    variant="h5"
                    gutterBottom
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    {t(RECOMMENDATIONS)}
                  </Typography>
                  <Markdown>{report.recommendations}</Markdown>
                </Box>
              </>
            )}
          </>
        )}
      </Box>
      {!_.isEmpty(campaign) && !_.isEmpty(report) && (
        <CommonChatMainContainer
          showChat={isOpenChat}
          onChangeShowChat={() => setIsOpenChat(false)}
          showSendFileButton={false}
          chatConfigurationID={CHAT_CONFIGURATION_ADS_DETAILED_REPORT_ID}
          chatContext={{
            campaign,
            report,
          }}
          startMode="messageInput"
          placeholderInput={CHAT_ASK_A_QUESTION_ABOUT_YOUR_CAMPAIGN}
          headerTitle={CHAT_ASK_A_QUESTION_ABOUT_YOUR_CAMPAIGN}
        />
      )}
    </ContainerPage>
  );
};

export default AdsCampaignStatistics;
