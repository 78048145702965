import ModalBasicLayout from "../Modal/ModalBasicLayout";
import {
  BUTTON_GO_BACK,
  BUTTON_SAVE,
  CUSTOMER_TEXT_PLACEHOLDER,
  CUSTOMER_TEXTS_PLACEHOLDER,
  CUSTOMER_TYPE_MESSAGE_PLACEHOLDER,
  CUSTOMER_TYPE_PREFILL_MESSAGE,
  CUSTOMER_TYPE_QUICK_QUESTIONS_MESSAGE,
  FIELD_MAX_LENGTH,
  FIELD_REQUIRED,
  GREETING_MESSAGE_PLACEHOLDER,
  MODAL_MESSAGE_TEMPLATE_DESCRIPTION,
  MODAL_MESSAGE_TEMPLATE_TITLE,
} from "../../i18n/keysTranslations";
import { useTranslationApp } from "../../lib/i18next";
import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import _ from "lodash";
import useFocusErrorForm from "../../hooks/useFocusErrorForm";
import Box from "@mui/material/Box";
import TextField from "../Form/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import ContainerModalActions from "../Containers/ContainerModalActions";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import IconButton from "@mui/material/IconButton";
import { getReactHookFormNestedError } from "../../utils/errors";

const ModalMessageTemplate = ({
  modalOpen,
  onCloseModal = () => {},
  onCallbackSave = () => {},
  data = {},
}) => {
  const [isFetching] = useState(false);
  const { control, setValue, getValues, watch, formState, handleSubmit } =
    useForm({
      defaultValues: {
        greeting: "",
        customerTypeMessage: "preFill",
        customerTexts: [""],
      },
    });

  useFocusErrorForm(formState);
  const { errors } = formState;

  const customerTexts = watch("customerTexts");
  const customerTypeMessage = watch("customerTypeMessage");
  const { t } = useTranslationApp();

  const onSubmit = () => {
    const formValues = getValues();
    onCallbackSave(formValues);
    onCloseModal();
  };

  const onAdd = () => {
    setValue("customerTexts", [...customerTexts, ""]);
  };
  const onRemove = (index) => {
    setValue(
      "customerTexts",
      customerTexts.filter((_, i) => i !== index)
    );
  };

  useEffect(() => {
    if (_.isEmpty(data)) return;

    const { greeting, customerTypeMessage, customerTexts } = data;

    setValue("greeting", greeting);
    setValue("customerTypeMessage", customerTypeMessage);
    setValue("customerTexts", customerTexts);

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (customerTypeMessage === "preFill" && customerTexts.length > 1) {
      setValue("customerTexts", [customerTexts[0]]);
    }
    // eslint-disable-next-line
  }, [customerTypeMessage]);

  return (
    <ModalBasicLayout
      modalOpen={modalOpen}
      onCloseModal={onCloseModal}
      fullWidth={true}
      scroll="paper"
      maxWidth="xs"
      title={t(MODAL_MESSAGE_TEMPLATE_TITLE)}
      description={t(MODAL_MESSAGE_TEMPLATE_DESCRIPTION)}
    >
      <Box
        component="form"
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          handleSubmit(onSubmit)();
        }}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1.5,
        }}
      >
        <TextField
          label={t(GREETING_MESSAGE_PLACEHOLDER)}
          name={"greeting"}
          variant="filled"
          multiline
          minRows={2}
          maxRows={2}
          fullWidth
          control={control}
          errors={errors}
          rules={{
            required: {
              value: true,
              message: t(FIELD_REQUIRED),
            },
            maxLength: {
              value: 200,
              message: t(FIELD_MAX_LENGTH, {
                value: 200,
              }),
            },
          }}
        />
        <Controller
          name="customerTypeMessage"
          control={control}
          rules={{
            required: {
              value: true,
              message: t(FIELD_REQUIRED),
            },
          }}
          render={({ field }) => (
            <FormControl>
              <FormLabel>{t(CUSTOMER_TYPE_MESSAGE_PLACEHOLDER)}</FormLabel>
              <RadioGroup
                row
                name={field.name}
                value={field.value}
                onChange={field.onChange}
              >
                <FormControlLabel
                  value={"preFill"}
                  control={<Radio />}
                  label={t(CUSTOMER_TYPE_PREFILL_MESSAGE)}
                />
                <FormControlLabel
                  value={"quickQuestions"}
                  control={<Radio />}
                  label={t(CUSTOMER_TYPE_QUICK_QUESTIONS_MESSAGE)}
                />
              </RadioGroup>
            </FormControl>
          )}
        />

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          {customerTexts.map((_, index) => {
            const isPreFill = customerTypeMessage === "preFill";
            const error = getReactHookFormNestedError(
              errors,
              `customerTexts.[${index}]`
            );

            const showAddIcon =
              index === customerTexts.length - 1 && !isPreFill && index < 4;
            const showDeleteIcon = index !== 0 && !isPreFill;

            return (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <TextField
                  label={t(
                    isPreFill
                      ? CUSTOMER_TEXT_PLACEHOLDER
                      : CUSTOMER_TEXTS_PLACEHOLDER,
                    {
                      value: index + 1,
                    }
                  )}
                  name={`customerTexts.[${index}]`}
                  variant="filled"
                  multiline
                  minRows={2}
                  maxRows={2}
                  fullWidth
                  control={control}
                  errors={errors}
                  sx={{
                    ".MuiInputBase-root": {
                      pb: 4,
                    },
                  }}
                  rules={{
                    required: {
                      value: true,
                      message: t(FIELD_REQUIRED),
                    },
                    maxLength: {
                      value: 80,
                      message: t(FIELD_MAX_LENGTH, {
                        value: 80,
                      }),
                    },
                  }}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-end",
                    position: "absolute",
                    mb: 1,
                    mr: 1,
                    right: 0,
                    height: "100%",
                    mt: error ? -5 : 0,
                  }}
                >
                  {showDeleteIcon && (
                    <IconButton
                      size={"small"}
                      onClick={() => onRemove(index)}
                      sx={{
                        p: 0.4,
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}

                  {showAddIcon && (
                    <IconButton
                      size={"small"}
                      onClick={() => onAdd()}
                      sx={{
                        p: 0.4,
                      }}
                    >
                      <AddIcon />
                    </IconButton>
                  )}
                </Box>
              </Box>
            );
          })}
        </Box>

        <ContainerModalActions
          sx={{ mt: 1 }}
          hasPadding={false}
          propsSecondaryButton={{
            showButton: true,
            onClick: onCloseModal,
            disabled: isFetching,
            text: BUTTON_GO_BACK,
          }}
          propsPrimaryButton={{
            showButton: true,
            loading: isFetching,
            text: BUTTON_SAVE,
            type: "submit",
            onClick: () => {},
          }}
        />
      </Box>
    </ModalBasicLayout>
  );
};

export default ModalMessageTemplate;
