import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTranslationApp } from "../../lib/i18next";
import { useDispatchApp, useSelectorApp } from "../../lib/redux";
import {
  BUTTON_UPGRADE_PLAN_2,
  BUTTON_UPGRADE_TO_PAID_PLAN,
  CREDITS_EXPLANATION,
  CREDITS_VALUE,
  EXPIRE_ON,
  VALUE_NUMBER,
  YOUR_TRIAL_CREDITS_END,
  YOUR_TRIAL_END,
} from "../../i18n/keysTranslations";
import Tooltip from "@mui/material/Tooltip";
import Button from "../Buttons/Button";
import {
  GRADIENT_PURPLE_FUCHSIA,
  GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT,
} from "../../utils/colors";
import CreditsIcon from "../../assets/svg/CreditsIcon";
import { setShowModalSelectSubscription } from "../../actions/ui";
import { getPathDatabaseByRole } from "../../actions/auth";
import { ROLES_WITH_BILLING } from "../../utils/constants";

const CreditsCounter = ({
  variant = "h6",
  credits: creditsProps,
  colorIcon = "white",
  widthIcon = "22px",
  heightIcon = "22px",
  sxIcon = {},
  sxTypography = {},
}) => {
  const { t } = useTranslationApp();
  const dispatch = useDispatchApp();

  const subscriptionPlanInfo = useSelectorApp(
    (state) => state.payments.subscriptionPlanInfo
  );
  const { role } = dispatch(getPathDatabaseByRole());
  const subscriptionPlan = useSelectorApp(
    (state) => state.payments.subscriptionPlan
  );
  const usage = subscriptionPlanInfo?.usage || {};
  const credits = creditsProps || usage?.credits || 0;

  const finishTrialDate = subscriptionPlanInfo?.finishTrialDate;
  const now = new Date();
  const isTrialExpired = finishTrialDate
    ? new Date(finishTrialDate).getTime() < now.getTime()
    : false;

  const getSubTextTooltip = () => {
    if (subscriptionPlan && subscriptionPlanInfo?.renewDate) {
      return t(EXPIRE_ON, {
        date: new Date(subscriptionPlanInfo?.renewDate),
      });
    }

    if (isTrialExpired) {
      return t(YOUR_TRIAL_END);
    }

    if (subscriptionPlanInfo?.finishTrialDate) {
      return t(YOUR_TRIAL_CREDITS_END, {
        date: new Date(subscriptionPlanInfo?.finishTrialDate),
      });
    }
    return "";
  };

  return (
    <Tooltip
      componentsProps={{
        tooltip: {
          sx: {
            p: 0,
            borderRadius: 3,
            mt: "8px !important",
            backgroundColor: "#202020",
          },
        },
      }}
      title={
        <Box
          sx={{
            p: 2,
          }}
        >
          <Typography variant="body1">
            {t(CREDITS_VALUE, {
              value: credits,
            })}
          </Typography>
          <Typography
            variant="body2"
            sx={{ mt: 1, mb: 2 }}
            dangerouslySetInnerHTML={{
              __html: t(CREDITS_EXPLANATION),
            }}
          />
          <Typography variant="body2">{getSubTextTooltip()}</Typography>
          {((subscriptionPlanInfo.subscriptionID && subscriptionPlan) ||
            !subscriptionPlan) &&
            ROLES_WITH_BILLING.includes(role) && (
              <Button
                onClick={() => {
                  dispatch(setShowModalSelectSubscription(true));
                }}
                size="small"
                fullWidth
                sx={{
                  mt: 1,
                  background: GRADIENT_PURPLE_FUCHSIA,
                  color: GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT,
                }}
              >
                {t(
                  subscriptionPlan
                    ? BUTTON_UPGRADE_PLAN_2
                    : BUTTON_UPGRADE_TO_PAID_PLAN
                )}
              </Button>
            )}
        </Box>
      }
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 0.5,
        }}
      >
        <CreditsIcon
          color={colorIcon}
          width={widthIcon}
          height={heightIcon}
          sx={sxIcon}
        />
        <Typography
          variant={variant}
          sx={{
            mb: -0.5,
            ...sxTypography,
          }}
        >
          {t(VALUE_NUMBER, {
            value: credits,
          })}
        </Typography>
      </Box>
    </Tooltip>
  );
};

export default CreditsCounter;
