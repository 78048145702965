import { useState } from "react";
import {
  ChatContainer as ChatContainerLibrary,
  MessageList,
  Message,
  TypingIndicator,
  Avatar,
} from "@chatscope/chat-ui-kit-react";
import { FONT_FAMILY } from "../../utils/constants";
import ChatCustomMessage from "../Chat/ChatCustomMessage";
import { useDispatchApp, useSelectorApp } from "../../lib/redux";
import { getNameBusinessTheme } from "../../actions/getters";
import { getPathDatabaseByRole } from "../../actions/auth";
import CommonChatConversationHeader from "./CommonChatConversationHeader";
import CommonChatMessageInput from "./CommonChatMessageInput";
import { getKeyDatabase } from "../../services/public";
import { startSendMessageChatBot } from "../../actions/chat";

const CommonChatContainer = ({
  onChangeShowChat = () => {},
  showSendFileButton,
  chatConfigurationID,
  chatContext,
  mode = "all",
  onChangeMode,
  placeholderInput,
  headerTitle,
}) => {
  const [isTyping, setIsTyping] = useState(false);
  const [messages, setMessages] = useState({});

  const dispatch = useDispatchApp();
  const theme = useSelectorApp((state) => state.ui.theme);
  const LOGO_BUSINESS = theme?.logoChat;
  const NAME_BUSINESS = dispatch(getNameBusinessTheme());
  const { userID } = dispatch(getPathDatabaseByRole());

  const sortedMessages = Object.keys(messages).sort(
    (a, b) => messages[a].creationTime - messages[b].creationTime
  );

  const allCurrentChatData = {
    name: `${NAME_BUSINESS} Bot`,
    userID: "bot",
    imageURL: LOGO_BUSINESS,
  };

  const onSendMessage = async (message) => {
    if (mode !== "all") onChangeMode("all");
    const newMessage = {
      message,
      messageType: "text",
      userID,
      creationTime: new Date().getTime(),
    };
    const key = dispatch(getKeyDatabase());

    const newMessages = {
      ...messages,
      [key]: newMessage,
    };
    setMessages(newMessages);
    generateBotMessage(newMessages);

    return true;
  };

  const generateBotMessage = async (messages) => {
    const messagesFormatted = Object.keys(messages).map((key) => {
      const message = messages[key];
      return {
        content: message.message,
        role: message.userID === userID ? "user" : "assistant",
      };
    });

    setIsTyping(true);
    const response = await dispatch(
      startSendMessageChatBot({
        messages: messagesFormatted,
        chatConfigurationID,
        chatContext,
      })
    );
    if (response.ok) {
      const newMessageBot = {
        message: response.data,
        messageType: "text",
        userID: "bot",
        creationTime: new Date().getTime(),
      };
      const key = dispatch(getKeyDatabase());

      setMessages((prevMessages) => ({
        ...prevMessages,
        [key]: newMessageBot,
      }));
    }
    setIsTyping(false);
  };

  return (
    <ChatContainerLibrary
      style={{
        border: "none",
      }}
    >
      {mode === "all" && (
        <CommonChatConversationHeader
          headerTitle={headerTitle}
          as="ConversationHeader"
          onChangeShowChat={onChangeShowChat}
        />
      )}

      {mode === "all" && (
        <MessageList
          typingIndicator={isTyping && <TypingIndicator />}
          style={{ marginTop: 0, fontFamily: FONT_FAMILY }}
        >
          {sortedMessages.map((messageID, index) => {
            const message = messages[messageID];

            const getDirection = () =>
              message.userID === userID ? "outgoing" : "incoming";

            const direction = getDirection();

            const getImageURL = () => allCurrentChatData.imageURL;

            return (
              <Message
                key={index}
                model={{
                  message: message.message,
                  sender: allCurrentChatData.name,
                  direction: direction,
                  type: "html",
                }}
                style={{
                  marginBottom: 10,
                }}
              >
                {direction === "incoming" && (
                  <Avatar src={getImageURL()} name={allCurrentChatData.name} />
                )}
                <Message.CustomContent>
                  <ChatCustomMessage message={message} direction={direction} />
                </Message.CustomContent>
              </Message>
            );
          })}
        </MessageList>
      )}

      {(mode === "all" || mode === "messageInput") && (
        <CommonChatMessageInput
          as="MessageInput"
          disabled={isTyping}
          onSendMessage={onSendMessage}
          showSendFileButton={showSendFileButton}
          placeholderInput={placeholderInput}
          hasMessages={
            Object.keys(messages).length > 0 && mode === "messageInput"
          }
          onExpandChat={() => onChangeMode("all")}
        />
      )}
    </ChatContainerLibrary>
  );
};

export default CommonChatContainer;
