import { useTranslationApp } from "../../../lib/i18next";
import { useDispatchApp, useSelectorApp } from "../../../lib/redux";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Loader from "../../../components/Loaders/Loader";
import {
  ADS_SUBSCRIPTION_DESCRIPTION,
  ADS_SUBSCRIPTION_TITLE,
  ALERT_CANCEL_SUBSCRIPTION_DESCRIPTION,
  ALERT_CANCEL_SUBSCRIPTION_DESCRIPTION_CONFIRMATION,
  ALERT_CANCEL_SUBSCRIPTION_TITLE,
  ALERT_CANCEL_SUBSCRIPTION_TITLE_CONFIRMATION,
  ALERT_REACTIVATE_SUBSCRIPTION_TITLE,
  BILLING_AND_PAYMENT_MODEL_PACKAGE_ACTIVATE_PACKAGE,
  BUTTON_CANCEL_SUBSCRIPTION,
  BUTTON_REACTIVATE_SUBSCRIPTION,
} from "../../../i18n/keysTranslations";
import { capitalizeFirstLetter } from "../../../utils/string";
import Button from "../../../components/Buttons/Button";
import {
  GRADIENT_PURPLE_FUCHSIA,
  GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT,
} from "../../../utils/colors";
import { setShowModalSelectSubscription } from "../../../actions/ui";
import LoadingButton from "../../../components/Buttons/LoadingButton";
import {
  startCancelSubscription,
  startReactivateSubscription,
} from "../../../actions/payments";
import { useState } from "react";
import { ConfirmAlert, SimpleAlert } from "../../../components/Alerts/Alerts";
import { ALERT_ICON_TYPE_SUCCESS } from "../../../utils/constants";

const SubscriptionInformation = () => {
  const [isFetchingActionSubscription, setIsFetchingActionSubscription] =
    useState(false);
  const { t } = useTranslationApp();

  const { subscriptionPlan, subscriptionPlanInfo, isLoading } = useSelectorApp(
    (state) => state.payments
  );

  const dispatch = useDispatchApp();

  const onCancelSubscription = async () => {
    const result = await ConfirmAlert({
      title: t(ALERT_CANCEL_SUBSCRIPTION_TITLE_CONFIRMATION),
      text: t(ALERT_CANCEL_SUBSCRIPTION_DESCRIPTION_CONFIRMATION),
      confirmButtonText: t(BUTTON_CANCEL_SUBSCRIPTION),
    });

    if (!result.isConfirmed) return;

    setIsFetchingActionSubscription(true);
    const response = await dispatch(startCancelSubscription());
    if (response.ok) {
      SimpleAlert({
        title: t(ALERT_CANCEL_SUBSCRIPTION_TITLE),
        text: t(ALERT_CANCEL_SUBSCRIPTION_DESCRIPTION, {
          date: response?.cancelDateTime
            ? new Date(response?.cancelDateTime)
            : new Date(),
        }),
        icon: ALERT_ICON_TYPE_SUCCESS,
      });
    }
    setIsFetchingActionSubscription(false);
  };
  const onReactivateSubscription = async () => {
    setIsFetchingActionSubscription(true);
    const response = await dispatch(startReactivateSubscription());
    if (response.ok) {
      SimpleAlert({
        title: t(ALERT_REACTIVATE_SUBSCRIPTION_TITLE),
        icon: ALERT_ICON_TYPE_SUCCESS,
      });
    }
    setIsFetchingActionSubscription(false);
  };

  const showButtonReactivate =
    new Date().getTime() < subscriptionPlanInfo.cancelDateTime;

  return (
    <Paper
      elevation={0}
      sx={{
        width: { xs: "100%", lg: "45%" },
        py: 2,
        px: 2,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        backgroundColor: "background.paperSecondary",
        boxShadow: "0px 0px 50px 0px rgba(0,0,0,0.1)",
        borderRadius: 4,
      }}
    >
      {isLoading && <Loader />}
      {subscriptionPlan && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "flex-start",
            height: "100%",
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: 600, mb: 1 }}>
            {`${t(ADS_SUBSCRIPTION_TITLE, {
              value: capitalizeFirstLetter(subscriptionPlan),
            })} ${
              subscriptionPlanInfo.recurrent
                ? `(${t(subscriptionPlanInfo.recurrent)})`
                : ""
            }`}
          </Typography>
          <Typography variant="subtitle2" sx={{ mb: 1 }}>
            {t(ADS_SUBSCRIPTION_DESCRIPTION)}
          </Typography>
          {!subscriptionPlanInfo?.cancelDateTime &&
            subscriptionPlanInfo?.subscriptionID && (
              <Box
                sx={{
                  display: "flex",
                  height: "100%",
                  width: "100%",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                }}
              >
                {isFetchingActionSubscription ? (
                  <Loader
                    size={20}
                    sx={{
                      width: 120,
                    }}
                  />
                ) : (
                  <Typography
                    variant="body1"
                    onClick={onCancelSubscription}
                    sx={{
                      textDecoration: "underline",
                    }}
                  >
                    {t(BUTTON_CANCEL_SUBSCRIPTION)}
                  </Typography>
                )}
              </Box>
            )}
          {subscriptionPlanInfo?.cancelDateTime && showButtonReactivate && (
            <Box
              sx={{
                display: "flex",
                height: "100%",
                width: "100%",
                justifyContent: "flex-end",
              }}
            >
              <LoadingButton
                loading={isFetchingActionSubscription}
                color="secondary"
                onClick={onReactivateSubscription}
                sx={{
                  alignSelf: "flex-end",
                  background: GRADIENT_PURPLE_FUCHSIA,
                  color: GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT,
                }}
              >
                {t(BUTTON_REACTIVATE_SUBSCRIPTION)}
              </LoadingButton>
            </Box>
          )}
        </Box>
      )}
      {!subscriptionPlan && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "flex-start",
            height: "100%",
          }}
        >
          <Typography variant="subtitle1" sx={{ mb: 1 }}>
            {t(ADS_SUBSCRIPTION_DESCRIPTION)}
          </Typography>
          <Button
            sx={{
              width: "auto",
              alignSelf: "flex-end",
              background: GRADIENT_PURPLE_FUCHSIA,
              color: GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT,
            }}
            onClick={() => dispatch(setShowModalSelectSubscription(true))}
          >
            {t(BILLING_AND_PAYMENT_MODEL_PACKAGE_ACTIVATE_PACKAGE)}
          </Button>
        </Box>
      )}
    </Paper>
  );
};

export default SubscriptionInformation;
