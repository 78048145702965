import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import { META, TIKTOK } from "../../utils/constants";
import { logoPlatform } from "../../utils/string";
import Typography from "@mui/material/Typography";
import { useTranslationApp } from "../../lib/i18next";
import {
  ALERT_CONNECT_ACCOUNT_SECURE_DESCRIPTION,
  META as META_TRANSLATION,
  TIKTOK as TIKTOK_TRANSLATION,
} from "../../i18n/keysTranslations";

const PLATFORMS_TRANSLATIONS = {
  [META]: META_TRANSLATION,
  [TIKTOK]: TIKTOK_TRANSLATION,
};

const AdsPlatformsLogos = ({ platform, NAME_BUSINESS }) => {
  const logoMeta = logoPlatform(META);
  const logoTikTok = logoPlatform(TIKTOK);

  const { t } = useTranslationApp();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 2,
        }}
      >
        <Avatar
          src={logoMeta}
          variant="square"
          sx={{
            width: 50,
            height: 50,
            ".MuiAvatar-img": {
              objectFit: "contain",
            },
          }}
        />
        <Avatar
          src={logoTikTok}
          variant="square"
          sx={{
            width: 45,
            height: 45,
            ".MuiAvatar-img": {
              objectFit: "contain",
            },
          }}
        />
      </Box>
      <Typography variant="body1" sx={{ mt: 1 }}>
        {t(ALERT_CONNECT_ACCOUNT_SECURE_DESCRIPTION, {
          platform: t(PLATFORMS_TRANSLATIONS[platform]),
          NAME_BUSINESS,
        })}
      </Typography>
    </Box>
  );
};

export default AdsPlatformsLogos;
