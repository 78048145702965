import { ConversationHeader } from "@chatscope/chat-ui-kit-react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { useTranslationApp } from "../../lib/i18next";

const CommonChatConversationHeader = ({ onChangeShowChat, headerTitle }) => {
  const { t } = useTranslationApp();
  return (
    <ConversationHeader
      style={{
        border: "none",
        display: "flex",
        justifyContent: "space-between",
        height: 60,
      }}
    >
      <ConversationHeader.Content>
        <Typography
          variant="body1"
          sx={{
            fontWeight: 900,
            color: "text.primary",
          }}
        >
          {t(headerTitle)}
        </Typography>
      </ConversationHeader.Content>
      <ConversationHeader.Actions>
        <IconButton
          onClick={onChangeShowChat}
          sx={{
            padding: 0,
          }}
        >
          <CloseIcon sx={{ fontSize: "1.4rem" }} />
        </IconButton>
      </ConversationHeader.Actions>
    </ConversationHeader>
  );
};

export default CommonChatConversationHeader;
