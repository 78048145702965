import Avatar from "@mui/material/Avatar";
import logoGoogle from "../../assets/img/logo-google.webp";
import LoadingButton from "./LoadingButton";

const ButtonGoogle = ({
  loading = false,
  onClick = () => {},
  sx = {},
  sxLogo = {},
  label,
}) => {
  return (
    <LoadingButton
      loading={loading}
      type="button"
      sx={{ ...sx }}
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
    >
      <Avatar
        src={logoGoogle}
        alt="google logo"
        variant="square"
        sx={{
          width: 24,
          height: 24,
          mr: 1,
          ...sxLogo,
        }}
      />
      {label}
    </LoadingButton>
  );
};

export default ButtonGoogle;
