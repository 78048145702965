import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatchApp, useSelectorApp } from "../../../lib/redux";
import {
  ConfirmAlert,
  SimpleAlert,
  TimerAlert,
} from "../../../components/Alerts/Alerts";
import {
  BUTTON_DELETE,
  USERS_USER_DELETE_CONFIRMATION_DESCRIPTION,
  USERS_USER_DELETE_CONFIRMATION_TITLE,
  USERS_USER_DELETED,
  USERS_USER_DELETING,
} from "../../../i18n/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";
import { startDeleteUser } from "../../../actions/user";
import { ALERT_ICON_TYPE_SUCCESS } from "../../../utils/constants";
import { useState } from "react";
import ModalUser from "./ModalUser";

const ActionsUser = ({ userID }) => {
  const [modalUserOpen, setModalUserOpen] = useState(false);
  const dispatch = useDispatchApp();
  const currentUserID = useSelectorApp((state) => state.auth.user.uid);
  const { t } = useTranslationApp();

  const onDeleteUser = async () => {
    const result = await ConfirmAlert({
      title: t(USERS_USER_DELETE_CONFIRMATION_TITLE),
      text: t(USERS_USER_DELETE_CONFIRMATION_DESCRIPTION),
      confirmButtonText: t(BUTTON_DELETE),
    });
    if (result.isConfirmed) {
      TimerAlert({
        title: t(USERS_USER_DELETING),
        timer: 12000,
      });
      const response = await dispatch(startDeleteUser(userID));
      if (response) {
        SimpleAlert({
          title: t(USERS_USER_DELETED),
          icon: ALERT_ICON_TYPE_SUCCESS,
        });
      }
    }
  };
  const onSelectUser = () => {
    setModalUserOpen(true);
  };

  return (
    <Box>
      <IconButton
        color="primary.contrastText"
        onClick={() => onDeleteUser()}
        sx={{
          display: "none !important",
        }}
        disabled={currentUserID === userID && true}
      >
        <DeleteIcon />
      </IconButton>
      <IconButton
        color="primary.contrastText"
        onClick={() => {
          onSelectUser();
        }}
      >
        <EditIcon />
      </IconButton>
      {modalUserOpen && (
        <ModalUser
          open={modalUserOpen}
          userID={userID}
          onCloseModal={() => setModalUserOpen(false)}
          mode="edit"
        />
      )}
    </Box>
  );
};

export default ActionsUser;
