import { useEffect, useState } from "react";
import ModalBasicLayout from "./ModalBasicLayout";
import { useDispatchApp } from "../../lib/redux";
import {
  startGenerateScriptAdVideo,
  startGenerateVideoPreviewCreatify,
  startGetAwsDownloadUrl,
  startGetCreatifyVideoData,
  startRenderVideoFromCreatify,
} from "../../actions/user";
import {
  BUTTON_DOWNLOAD,
  BUTTON_GO_BACK,
  BUTTON_SELECT_CONTENT,
  CREATIFY_VIDEO_ERROR_RENDER_CLARIFICATION,
  CREATIFY_VIDEO_PREVIEW_CLARIFICATION,
  CREATIFY_VIDEO_RENDERING_CLARIFICATION,
  ERROR,
  ERROR_OCURRED,
  GENERATING_VIDEO_MESSAGE,
} from "../../i18n/keysTranslations";
import { useTranslationApp } from "../../lib/i18next";
import LinearLoaderWithTime from "../Loaders/LinearLoaderWithTime";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Alert from "@mui/material/Alert";
import { useMediaQuery, Typography } from "@mui/material";
import Button from "../Buttons/Button";
import DownloadIcon from "@mui/icons-material/Download";
import LoadingButton from "../Buttons/LoadingButton";
import BackdropLoaderRenderMediaCreatify from "../Loaders/BackdropLoaderRenderMediaCreatify";
import {
  ADS_CAMPAIGN_GENERATE_AD_TEXT_ACTION,
  ADS_CAMPAIGN_RENDERED_VIDEO_ACTION,
  ALERT_ICON_TYPE_ERROR,
  VIDEO_DONE_STATUS_CREATIFY,
} from "../../utils/constants";
import { SimpleAlert } from "../Alerts/Alerts";
import CardMedia from "../Media/CardMedia";
import DrawerConfigurationCreatify from "./ModalGenerateVideoPreviewCreatify/DrawerConfigurationCreatify";
import IconButton from "../Buttons/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { FormProvider, useForm } from "react-hook-form";
import { waitDelay } from "../../utils/date";
import _ from "lodash";
import { getIsBlockedActions } from "../../actions/getters";

const SX_COMMON_BUTTONS = {
  minWidth: 140,
  width: "100%",
  ml: "0px !important",
};

const ModalGenerateVideoPreviewCreatify = ({
  modalOpen,
  onCloseModal = () => {},
  onCallbackSave = () => {},
  videoData: videoDataProp,
  showButtonSelectContent = true,
  websiteURL,
  socialNetworkURL,
  language = "en",
  integrationID,
  adAccountID,
  campaignContext = {},
}) => {
  const methods = useForm({
    defaultValues: {
      logoURL: "",
      websiteURL,
      socialNetworkURL,
      language,
      avatar: null,
      script: "",
      mediaUrls: [],
    },
    mode: "onSubmit",
  });
  const { setValue, getValues, trigger } = methods;

  const [openDrawer, setOpenDrawer] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [isGeneratingScript, setIsGeneratingScript] = useState(false);
  const [isUpdatingFormData, setIsUpdatingFormData] = useState(false);
  const [videoData, setVideoData] = useState(videoDataProp);
  const [isDownloading, setIsDownloading] = useState(false);
  const [renderMediaData, setRenderMediaData] = useState(null);
  const [error, setError] = useState(null);

  const dispatch = useDispatchApp();
  const { t } = useTranslationApp();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const isPreview = videoData?.preview && !videoData?.videoOutput;

  const onToggleDrawer = () => setOpenDrawer((prev) => !prev);

  const onGenerateVideoPreview = async ({ mode }) => {
    const isValid = await trigger();
    if (!isValid) {
      if (isMobile && !openDrawer) setOpenDrawer(true);
      return;
    }

    const isBlocked = await dispatch(
      getIsBlockedActions({
        action: ADS_CAMPAIGN_GENERATE_AD_TEXT_ACTION,
      })
    );
    if (isBlocked) {
      return;
    }

    if (isMobile && openDrawer) setOpenDrawer(false);

    setIsGenerating(true);
    setVideoData({});
    setError(null);

    const formValues = getValues();

    const basicGenerateObject = {
      websiteURL: formValues.websiteURL,
      language: formValues.language,
      socialNetworkURL: formValues.socialNetworkURL,
      campaignContext,
    };

    if (mode === "settings") {
      basicGenerateObject.avatar = formValues.avatar?.id;
      basicGenerateObject.script = formValues.script;
      basicGenerateObject.mediaUrls = formValues.mediaUrls;
      basicGenerateObject.logoURL = formValues.logoURL;
    }

    const response = await dispatch(
      startGenerateVideoPreviewCreatify(basicGenerateObject)
    );
    if (response.ok) {
      setVideoData(response.data);
      if (response.data.avatar) {
        setValue("avatar", response.data.avatar);
      }
      if (response.data.script) {
        setValue("script", response.data.script);
      }
    } else {
      setError(t(ERROR_OCURRED));
    }
    setIsGenerating(false);
  };

  const onRenderCreatifyVideo = async () => {
    const isBlocked = await dispatch(
      getIsBlockedActions({
        action: ADS_CAMPAIGN_RENDERED_VIDEO_ACTION,
      })
    );
    if (isBlocked) {
      return;
    }
    setIsDownloading(true);
    if (videoData?.videoOutput) {
      await dispatch(startGetAwsDownloadUrl(videoData.videoOutput));
      setIsDownloading(false);
      return;
    }
    const renderItemData = {
      videoID: videoData?.id,
      adIDs: [],
    };
    const response = await dispatch(
      startRenderVideoFromCreatify(renderItemData)
    );
    setIsDownloading(false);
    if (!response.ok) return;

    setRenderMediaData([renderItemData]);
  };

  const onRenderMediaFinished = async (renderItemData) => {
    const videoDataRendered = renderItemData.videoData;
    const newStatus = videoDataRendered.status;
    const videoOutput = videoDataRendered.videoOutput;

    if (newStatus !== VIDEO_DONE_STATUS_CREATIFY) {
      SimpleAlert({
        title: t(ERROR),
        text: t(CREATIFY_VIDEO_ERROR_RENDER_CLARIFICATION),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      setRenderMediaData(null);
      return;
    }

    await dispatch(startGetAwsDownloadUrl(videoOutput));
    setRenderMediaData(null);
    setVideoData(videoDataRendered);
  };

  const onGenerateScript = async () => {
    const isBlocked = await dispatch(
      getIsBlockedActions({
        action: ADS_CAMPAIGN_GENERATE_AD_TEXT_ACTION,
      })
    );
    if (isBlocked) {
      return;
    }

    const formValues = getValues();
    setIsGeneratingScript(true);
    const basicGenerateObject = {
      websiteURL: formValues.websiteURL,
      language: formValues.language,
      campaignContext,
    };
    const response = await dispatch(
      startGenerateScriptAdVideo(basicGenerateObject)
    );
    if (response.ok) {
      setValue("script", response.data.script);
    }
    setIsGeneratingScript(false);
  };

  const onGetVideoData = async () => {
    setIsUpdatingFormData(true);
    const response = await dispatch(
      startGetCreatifyVideoData({
        videoID: videoDataProp?.id,
      })
    );
    setIsUpdatingFormData(false);

    if (response.ok) {
      const responseVideoData = response.data;
      if (responseVideoData.avatar)
        setValue("avatar", responseVideoData.avatar);
      if (responseVideoData.script)
        setValue("script", responseVideoData.script);
      if (responseVideoData.logoUrl)
        setValue("logoURL", responseVideoData.logoUrl);
      if (responseVideoData.language)
        setValue("language", responseVideoData.language);
    }
  };

  useEffect(() => {
    if (!videoDataProp?.id) return;
    onGetVideoData();
    // eslint-disable-next-line
  }, [videoDataProp?.id]);

  return (
    <ModalBasicLayout
      modalOpen={modalOpen}
      onCloseModal={onCloseModal}
      fullWidth={true}
      fullScreen
      scroll="paper"
      sxContent={{
        display: "flex",
        p: "0px !important",
        mt: 1,
        overflowX: "hidden",
        "::-webkit-scrollbar": {
          width: 0,
          height: 0,
          background: "transparent",
        },
      }}
      sxTitle={{
        justifyContent: "space-between",
      }}
      customComponentTitle={
        <IconButton onClick={onToggleDrawer}>
          <MenuIcon />
        </IconButton>
      }
    >
      <FormProvider
        {...methods}
        isGenerating={isGenerating}
        onGenerateVideoPreview={onGenerateVideoPreview}
        isGeneratingScript={isGeneratingScript}
        onGenerateScript={onGenerateScript}
        isUpdatingFormData={isUpdatingFormData}
        adAccountID={adAccountID}
        integrationID={integrationID}
      >
        <DrawerConfigurationCreatify
          open={openDrawer}
          onClose={() => setOpenDrawer(false)}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            justifyContent: "space-between",
            pt: 0.5,
            pb: 1,
            px: 1,
          }}
        >
          <VideoPreviewCreatify
            videoData={videoData}
            renderMediaData={renderMediaData}
            onRenderMediaFinished={onRenderMediaFinished}
            isGenerating={isGenerating}
            isPreview={isPreview}
            error={error}
          />
          <Box
            sx={{
              display: "flex",
              gap: 1,
              justifyContent: "center",
              alignItems: "center",
              flexDirection: {
                xs: "column",
                md: "row",
              },
            }}
          >
            <Button onClick={onCloseModal} sx={SX_COMMON_BUTTONS}>
              {t(BUTTON_GO_BACK)}
            </Button>
            <LoadingButton
              loading={isDownloading}
              type="button"
              startIcon={<DownloadIcon />}
              onClick={onRenderCreatifyVideo}
              disabled={isGenerating || !videoData}
              sx={SX_COMMON_BUTTONS}
            >
              {t(BUTTON_DOWNLOAD)}
            </LoadingButton>
            {showButtonSelectContent && (
              <Button
                disabled={isGenerating || _.isEmpty(videoData)}
                onClick={() => {
                  onCallbackSave(videoData);
                  onCloseModal();
                }}
                color="secondary"
                sx={SX_COMMON_BUTTONS}
              >
                {t(BUTTON_SELECT_CONTENT)}
              </Button>
            )}
          </Box>
        </Box>
      </FormProvider>
    </ModalBasicLayout>
  );
};

const VideoPreviewCreatify = ({
  videoData,
  renderMediaData,
  onRenderMediaFinished,
  isGenerating,
  isPreview,
  error,
}) => {
  const [isLoadingIframe, setIsLoadingIframe] = useState(true);
  const [isLoadedFrameData, setIsLoadedFrameData] = useState(false);
  const { t } = useTranslationApp();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const height = isMobile ? "calc(100% - 84px)" : "calc(100% - 64px)";

  useEffect(() => {
    if (isGenerating) {
      setIsLoadedFrameData(false);
      setIsLoadingIframe(true);
    }
  }, [isGenerating]);
  return (
    <>
      {renderMediaData && (
        <BackdropLoaderRenderMediaCreatify
          open={Boolean(renderMediaData)}
          data={renderMediaData}
          onRenderMediaFinished={onRenderMediaFinished}
          mainLabel={CREATIFY_VIDEO_RENDERING_CLARIFICATION}
          sx={{
            maxWidth: 720,
            width: "100%",
          }}
        />
      )}
      {isGenerating && !error && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <LinearLoaderWithTime
            label={t(GENERATING_VIDEO_MESSAGE)}
            time={40000}
          />
        </Box>
      )}
      {!isGenerating && error && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Typography variant="h6" align="center">
            {error}
          </Typography>
        </Box>
      )}
      {!isGenerating && !error && !videoData && (
        <Box
          sx={{
            height: "100%",
          }}
        ></Box>
      )}
      {!isGenerating && !error && videoData && isPreview && (
        <Box
          sx={{
            width: "100%",
            height,
          }}
        >
          <Alert
            variant="outlined"
            severity="info"
            sx={{
              mb: 1,
              px: 1,
              fontSize: {
                xs: "0.775rem",
                md: "0.875rem",
              },
            }}
          >
            {t(CREATIFY_VIDEO_PREVIEW_CLARIFICATION)}
          </Alert>
          {isLoadingIframe && (
            <Skeleton variant="rectangular" width={"100%"} height={height} />
          )}
          <iframe
            src={videoData.preview}
            onLoad={async () => {
              setIsLoadingIframe(false);
              await waitDelay(1000);
              setIsLoadedFrameData(true);
            }}
            style={{
              display: isLoadingIframe ? "none" : "block",
              opacity: isLoadedFrameData ? 1 : 0,
              border: "none",
              overflow: "hidden",
              height,
              width: "100%",
            }}
          />
        </Box>
      )}
      {!isGenerating && !error && videoData && !isPreview && (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            mb: 2,
          }}
        >
          <CardMedia
            url={videoData.videoOutput}
            sx={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
            }}
          />
        </Box>
      )}
    </>
  );
};

export default ModalGenerateVideoPreviewCreatify;
