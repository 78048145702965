import { GRADIENT_PURPLE_FUCHSIA } from "../../utils/colors";
import Box from "@mui/material/Box";
import Button from "./Button";
import { useLocation } from "react-router-dom";

const ButtonSlideMenu = ({
  menu = {},
  children,
  onClick,
  startIcon,
  endIcon,
  sx,
}) => {
  const location = useLocation();

  const showBorder = location.pathname === menu.link;
  return (
    <>
      {showBorder && (
        <Box
          sx={{
            width: 2,
            height: 40,
            position: "absolute",
            background: GRADIENT_PURPLE_FUCHSIA,
            left: -16,
          }}
        ></Box>
      )}
      <Button
        hasShadow={false}
        variant="contained"
        disableElevation
        fullWidth
        startIcon={startIcon}
        endIcon={endIcon}
        sx={{
          borderLeft: `2px solid ${GRADIENT_PURPLE_FUCHSIA}`,
          backgroundColor: "background.paper",
          color: "background.paperContrastText",
          justifyContent: "left",
          width: "min-content",
          fontSize: "0.905rem",
          fontWeight: 600,
          pl: 1,
          marginLeft: showBorder ? "-0px" : 0,
          "&:hover": {
            backgroundColor: "background.paper",
            color: "background.paperContrastText",
          },
          ...sx,
        }}
        onClick={onClick}
      >
        {children}
      </Button>
    </>
  );
};

export default ButtonSlideMenu;
