import Accordion from "../../../components/Containers/Accordion";
import Box from "@mui/material/Box";
import { useTranslationApp } from "../../../lib/i18next";
import { useMediaQuery, useTheme, Typography } from "@mui/material";
import {
  ADS_CAMPAIGN_OPTIMIZE_CAMPAIGN_ACTION_ID,
  ADS_CAMPAIGN_STATUS_ACTIVE,
  ADS_CAMPAIGN_STATUS_DEACTIVATED,
  ADS_OPTIMIZATION_CAMPAIGN_ACTION,
  ADS_OPTIMIZATION_FULL_CAMPAIGN_TYPE,
  ADS_OPTIMIZATION_TYPES,
  ALERT_ICON_TYPE_SUCCESS,
  BORDER_RADIUS,
  TIME_LOADER_GENERATING_CAMPAIGN_ADVANCED,
  TIME_LOADER_GENERATING_REPORT_CAMPAIGN,
  TIME_LOADER_OPTIMIZE_CAMPAIGN,
} from "../../../utils/constants";
import {
  AN_ERROR_OCCURRED_GETTING_THE_REPORT,
  ANALYZING_CAMPAIGN_STATISTICS,
  APPLYING_OPTIMIZATIONS,
  BUTTON_APPLY_OPTIMIZATIONS,
  CAMPAIGN_OPTIMIZED,
  CAMPAIGN_OPTIMIZED_GENERATED,
  CAMPAIGN_OPTIMIZED_NAME,
  COMPARING_RESULTS_OBTAINED,
  CREATE_ADS_CAMPAIGN_LOADING_ANALYZING_INFORMATION,
  CREATE_ADS_CAMPAIGN_LOADING_FINALIZING_DETAILS,
  CREATE_ADS_CAMPAIGN_LOADING_GENERATING_RECOMMENDATIONS,
  CREATE_ADS_CAMPAIGN_LOADING_GETTING_REQUIRED_INFORMATION,
  ERROR_PROCESSING_CAMPAIGNS,
  GENERATING_OPTIMIZATIONS,
  HOW_IS_YOUR_CAMPAIGN_GOING,
} from "../../../i18n/keysTranslations";
import { useEffect, useState } from "react";
import TypingText from "../../../components/Texts/TypingText";
import {
  startGetCampaignReportState,
  startGetRecommendedSettingsAdsCampaign,
  startSaveAdsCampaign,
  startSaveReportOptimizationDone,
  startVerifyAndSetAdsUsage,
} from "../../../actions/adsCampaigns";
import { useDispatchApp } from "../../../lib/redux";
import { useFormContext } from "react-hook-form";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import differenceInDays from "date-fns/differenceInDays";
import {
  getCampaignUpdatedByStatus,
  getGeneralStatistics,
  mapStatusFromOptimization,
} from "../../../services/adsCampaigns";
import LoadingButton from "../../../components/Buttons/LoadingButton";
import LinearLoaderWithTime from "../../../components/Loaders/LinearLoaderWithTime";
import { getKeyDatabase } from "../../../services/public";
import { useNavigate } from "react-router-dom";
import { SimpleAlert } from "../../../components/Alerts/Alerts";
import { setNestedValue } from "../../../utils/forms";
import {
  GRADIENT_PURPLE_FUCHSIA,
  GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT,
} from "../../../utils/colors";
import { getIsBlockedActions } from "../../../actions/getters";
import isSameDay from "date-fns/isSameDay";
import startOfDay from "date-fns/startOfDay";
import ModalErrorsCampaigns from "./ModalErrorsCampaigns";
import _ from "lodash";

const AdsReportCampaignState = () => {
  const [allTextExplanation, setAllTextExplanation] = useState("");
  const { campaignID, isGeneralCampaignActivated, setValue, watch, getValues } =
    useFormContext();
  const [isGeneratingCampaignOptimized, setIsGeneratingCampaignOptimized] =
    useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [
    isUpdatingCampaignByOptimization,
    setIsUpdatingCampaignByOptimization,
  ] = useState(false);
  const [modalErrorsCampaignsOpen, setModalErrorsCampaignsOpen] =
    useState(false);
  const [dataModalErrorsCampaigns, setDataModalErrorsCampaigns] = useState({});
  const [hasError, setHasError] = useState(false);

  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatchApp();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslationApp();
  const now = new Date();
  const report = watch("report") || {};
  const lastReportTime = report?.lastReportTime;
  const hasOptimizations =
    report?.optimizations &&
    ADS_OPTIMIZATION_TYPES.includes(report.optimizations.type);
  const optimizationDone = report?.optimizations?.optimizationDone;
  const firstActiveTime = getValues("firstActiveTime");
  const statistics = getValues("statistics");
  const states = getValues("states");

  const commonSxAccordion = {
    border: `1px solid ${theme.palette.background.paperSecondary}`,
    borderRadius: BORDER_RADIUS,
    "::before": {
      backgroundColor: "transparent",
    },
    m: "0 !important",
    mb: "16px !important",
  };
  const onGenerateReport = async () => {
    setIsGenerating(true);
    setHasError(false);

    const response = await dispatch(
      startGetCampaignReportState({
        campaignID,
      })
    );
    if (response) {
      setValue("report", response);
      const reportExplanation = response?.explanation || "";
      const optimizationExplanation =
        response?.optimizations?.explanation || "";
      setAllTextExplanation(`${reportExplanation}\n${optimizationExplanation}`);
    } else {
      setHasError(true);
    }
    setIsGenerating(false);
  };

  const onApplyFullCampaignOptimization = async () => {
    setIsGeneratingCampaignOptimized(true);
    const dataToGenerate = report?.optimizations?.dataToGenerate;
    const newCampaignID = dispatch(getKeyDatabase());
    const response = await dispatch(
      startGetRecommendedSettingsAdsCampaign({
        name: `${dataToGenerate.name} - ${t(CAMPAIGN_OPTIMIZED_NAME)}`,
        platforms: dataToGenerate.platforms,
        websiteURL: dataToGenerate.websiteURL,
        objective: dataToGenerate.objective,
        productContext: dataToGenerate.productContext,
        targetContext: dataToGenerate.targetContext,
        optimizationContext: dataToGenerate.optimizationContext,
        destinationURL: dataToGenerate.destinationURL,
        typeBudget: dataToGenerate.typeBudget,
        budget: dataToGenerate.budget,
        startDate: dataToGenerate.startDate,
        endDate: dataToGenerate.endDate,
        mediaUrls: dataToGenerate.mediaUrls,
        campaignID: newCampaignID,
        language: dataToGenerate.language,
        platformsData: dataToGenerate.platformsData,
        mode: dataToGenerate.mode,
        brandContext: dataToGenerate.brandContext,
        socialNetworkURL: dataToGenerate.socialNetworkURL,
        locations: dataToGenerate.locations,
      })
    );
    if (response.ok) {
      setValue("report.optimizations.optimizationDone", true);
      startSaveReportOptimizationDone({
        campaignID,
        optimizationDone: true,
      });
      const objectUpdate = getCampaignUpdatedByStatus({
        campaign: getValues(),
        status: ADS_CAMPAIGN_STATUS_DEACTIVATED,
        target: "campaign",
      });

      await dispatch(
        startSaveAdsCampaign({
          campaign: objectUpdate,
          campaignID,
        })
      );
      SimpleAlert({
        title: t(CAMPAIGN_OPTIMIZED_GENERATED),
        icon: ALERT_ICON_TYPE_SUCCESS,
      });
      navigate(`/ads-campaign?campaignID=${newCampaignID}`);
    }
    setIsGeneratingCampaignOptimized(false);
  };

  const onApplyAdsGroupsBudgetsOptimization = async () => {
    const campaign = getValues();
    const adsGroups = campaign.adsGroups;
    const actions = report?.optimizations?.actions;

    let newAdsGroupsActive = 0;
    let adsGroupsDeactivatedBudget = 0;

    adsGroups.forEach((adGroup, index) => {
      const adGroupOptimization = actions.find(
        (action) => action.adGroupIndex === index
      );

      if (!adGroupOptimization) {
        if (adGroup.status === ADS_CAMPAIGN_STATUS_ACTIVE)
          newAdsGroupsActive += 1;
        return;
      }

      const newStatus = mapStatusFromOptimization(adGroupOptimization.status);
      const isDeactivatedStatus = newStatus === ADS_CAMPAIGN_STATUS_DEACTIVATED;

      if (!isDeactivatedStatus) {
        newAdsGroupsActive += 1;
        return;
      }

      const objectUpdate = getCampaignUpdatedByStatus({
        campaign,
        status: newStatus,
        target: "adGroup",
        platform: adGroup.platform,
        adGroupID: index,
      });

      adsGroupsDeactivatedBudget += Number(adGroup.budget);

      const newAdGroup = { ...objectUpdate.adsGroups[index] };
      setNestedValue({
        data: newAdGroup,
        setValue: setValue,
        path: `adsGroups.[${index}]`,
      });

      adsGroups[index] = newAdGroup;
    });

    if (adsGroupsDeactivatedBudget > 0) {
      const budgetRepartition = adsGroupsDeactivatedBudget / newAdsGroupsActive;
      adsGroups.forEach((adGroup, index) => {
        if (adGroup.status === ADS_CAMPAIGN_STATUS_ACTIVE) {
          const newBudget = Number(adGroup.budget) + budgetRepartition;
          setValue(`adsGroups.[${index}].budget`, newBudget);
          adsGroups[index].budget = newBudget;
        }
      });
    }

    setIsUpdatingCampaignByOptimization(true);
    const response = await dispatch(
      startSaveAdsCampaign({
        campaign: getValues(),
        campaignID,
        from: "optimization",
      })
    );
    if (response.ok) {
      await dispatch(
        startVerifyAndSetAdsUsage({
          actionID: ADS_CAMPAIGN_OPTIMIZE_CAMPAIGN_ACTION_ID,
        })
      );
      dispatch(
        startSaveReportOptimizationDone({
          campaignID,
          optimizationDone: true,
        })
      );
      setNestedValue({
        data: response.campaign,
        setValue: setValue,
      });
      SimpleAlert({
        title: t(CAMPAIGN_OPTIMIZED),
        icon: ALERT_ICON_TYPE_SUCCESS,
      });
      setValue("report.optimizations.optimizationDone", true);
    } else if (response.code === ERROR_PROCESSING_CAMPAIGNS) {
      setDataModalErrorsCampaigns({
        createdCampaigns: response?.createdCampaigns,
        failedCampaigns: response?.failedCampaigns,
        errors: response?.errors,
      });
      setModalErrorsCampaignsOpen(true);
      setNestedValue({ data: response.campaign, setValue });
    }
    setIsUpdatingCampaignByOptimization(false);
  };

  const onApplyOptimizations = async () => {
    const isBlocked = await dispatch(
      getIsBlockedActions({
        action: ADS_OPTIMIZATION_CAMPAIGN_ACTION,
      })
    );
    if (isBlocked) {
      return;
    }

    const optimizationType = report.optimizations?.type;

    if (optimizationType === ADS_OPTIMIZATION_FULL_CAMPAIGN_TYPE)
      await onApplyFullCampaignOptimization();
    else {
      await onApplyAdsGroupsBudgetsOptimization();
    }
  };

  useEffect(() => {
    if (!firstActiveTime) return;
    if (!statistics) return;

    const reportExplanation = report?.explanation || "";
    const optimizationExplanation = report?.optimizations?.explanation || "";
    setAllTextExplanation(`${reportExplanation}\n${optimizationExplanation}`);

    if (states.general !== ADS_CAMPAIGN_STATUS_ACTIVE || !states) {
      return;
    }

    const generalStatistics = getGeneralStatistics(statistics);

    if (!generalStatistics.impressions) {
      return;
    }

    const differenceDays = differenceInDays(now, firstActiveTime);
    //only if the campaign is active for more than 1 day
    if (differenceDays < 1) return;

    if (!lastReportTime && !report && isGeneralCampaignActivated) {
      onGenerateReport();
    }
    const lastReport = new Date(lastReportTime);

    //verify if the last report was generated yesterday (since start of the day)
    const isNewDay = !isSameDay(startOfDay(lastReport), startOfDay(now));
    if (!isNewDay) return;

    onGenerateReport();

    // eslint-disable-next-line
  }, []);

  const showButtonApplyOptimizations =
    !optimizationDone &&
    hasOptimizations &&
    !isUpdatingCampaignByOptimization &&
    !isGeneratingCampaignOptimized &&
    !isGenerating;

  if (_.isEmpty(report) && !isGenerating) return null;

  return (
    <Accordion
      initialValue={true}
      title={t(HOW_IS_YOUR_CAMPAIGN_GOING)}
      iconTitle={<AnalyticsIcon />}
      variantTitle={isMobile ? "body1" : "h6"}
      sx={commonSxAccordion}
      sxTitle={{
        fontWeight: 900,
      }}
      sxContainerTitle={{
        ".MuiAccordionSummary-content": {
          display: "flex",
          alignItems: "center",
          flexDirection: {
            xs: "column",
            sm: "row",
          },
        },
        ".MuiAccordionSummary-expandIconWrapper": {},
        pt: 0,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          height: "auto",
        }}
      >
        {hasError ? (
          <Typography variant="body1">
            {t(AN_ERROR_OCCURRED_GETTING_THE_REPORT)}
          </Typography>
        ) : null}
        {isGenerating ? (
          <LinearLoaderWithTime
            time={TIME_LOADER_GENERATING_REPORT_CAMPAIGN}
            labels={[
              t(CREATE_ADS_CAMPAIGN_LOADING_GETTING_REQUIRED_INFORMATION),
              t(ANALYZING_CAMPAIGN_STATISTICS),
              t(COMPARING_RESULTS_OBTAINED),
              t(GENERATING_OPTIMIZATIONS),
            ]}
            sx={{ mt: 1.5 }}
          />
        ) : null}
        {isGeneratingCampaignOptimized ? (
          <LinearLoaderWithTime
            time={TIME_LOADER_GENERATING_CAMPAIGN_ADVANCED}
            labels={[
              t(CREATE_ADS_CAMPAIGN_LOADING_GETTING_REQUIRED_INFORMATION),
              t(CREATE_ADS_CAMPAIGN_LOADING_ANALYZING_INFORMATION),
              t(CREATE_ADS_CAMPAIGN_LOADING_GENERATING_RECOMMENDATIONS),
              t(CREATE_ADS_CAMPAIGN_LOADING_FINALIZING_DETAILS),
            ]}
            sx={{ mt: 1.5 }}
          />
        ) : null}

        {!_.isEmpty(report) &&
        !isGenerating &&
        !isGeneratingCampaignOptimized &&
        !hasError ? (
          <TypingText
            text={allTextExplanation}
            sx={{
              maxHeight: 200,
            }}
          />
        ) : null}
        {showButtonApplyOptimizations && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <LoadingButton
              type="button"
              onClick={onApplyOptimizations}
              sx={{
                background: GRADIENT_PURPLE_FUCHSIA,
                color: GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT,
              }}
            >
              {t(BUTTON_APPLY_OPTIMIZATIONS)}
            </LoadingButton>
          </Box>
        )}
        {isUpdatingCampaignByOptimization ? (
          <LinearLoaderWithTime
            time={TIME_LOADER_OPTIMIZE_CAMPAIGN}
            labels={[
              t(CREATE_ADS_CAMPAIGN_LOADING_GETTING_REQUIRED_INFORMATION),
              t(APPLYING_OPTIMIZATIONS),
              t(CREATE_ADS_CAMPAIGN_LOADING_FINALIZING_DETAILS),
            ]}
            sx={{ mt: 1.5 }}
          />
        ) : null}
        {modalErrorsCampaignsOpen && (
          <ModalErrorsCampaigns
            modalOpen={modalErrorsCampaignsOpen}
            from="update"
            onCloseModal={() => setModalErrorsCampaignsOpen(false)}
            campaign={getValues()}
            successCampaigns={dataModalErrorsCampaigns.successCampaigns}
            failedCampaigns={dataModalErrorsCampaigns.failedCampaigns}
            errors={dataModalErrorsCampaigns.errors}
          />
        )}
      </Box>
    </Accordion>
  );
};

export default AdsReportCampaignState;
