import ButtonSlideMenu from "./ButtonSlideMenu";
import { useTranslationApp } from "../../lib/i18next";
import { CONTENT_GENERATOR } from "../../i18n/keysTranslations";
import { useState } from "react";
import ModalGenerateVideoPreviewCreatify from "../Modal/ModalGenerateVideoPreviewCreatify";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";

const ButtonOpenContentGenerator = () => {
  const [modalGeneratingVideoPreviewOpen, setModalGeneratingVideoPreviewOpen] =
    useState(false);
  const { t } = useTranslationApp();
  return (
    <>
      <ButtonSlideMenu
        startIcon={<AutoAwesomeIcon />}
        onClick={() => setModalGeneratingVideoPreviewOpen(true)}
      >
        {t(CONTENT_GENERATOR)}
      </ButtonSlideMenu>
      {modalGeneratingVideoPreviewOpen && (
        <ModalGenerateVideoPreviewCreatify
          modalOpen={modalGeneratingVideoPreviewOpen}
          showButtonSelectContent={false}
          onCloseModal={() => setModalGeneratingVideoPreviewOpen(false)}
        />
      )}
    </>
  );
};

export default ButtonOpenContentGenerator;
