import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { BORDER_RADIUS } from "../../utils/constants";
import { useTheme } from "@emotion/react";
import {
  ChartContainer,
  ChartLegend,
  ChartLegendContent,
  ChartTooltip,
  ChartTooltipContent,
} from "./chart";
import {
  CartesianGrid,
  LabelList,
  PieChart as PieChartLib,
  Pie,
} from "recharts";
import { useMemo } from "react";

const PieChart = ({
  chartData = {},
  chartConfig = {},
  labelKey = "label",
  valueKey = "value",
  title,
  subtitle,
  description,
  hasTooltip = true,
  hasLegend = true,
  hasCartesianGrid = true,
}) => {
  const theme = useTheme();

  const totalValue = useMemo(() => {
    return chartData.reduce((acc, curr) => acc + curr[valueKey], 0);
    // eslint-disable-next-line
  }, [valueKey]);

  return (
    <Box
      sx={{
        padding: 2,
        borderRadius: BORDER_RADIUS,
        border: `1px solid ${theme.palette.border.grey}`,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box>
          {title && <Typography variant="body1">{title}</Typography>}
          {subtitle && (
            <Typography variant="body2" color="text.secondary">
              {subtitle}
            </Typography>
          )}
        </Box>
        <Typography variant="body1">{`${totalValue} ${chartConfig[valueKey].label}`}</Typography>
      </Box>
      <ChartContainer config={chartConfig}>
        <PieChartLib>
          {hasCartesianGrid && <CartesianGrid vertical={false} />}

          {hasTooltip && (
            <ChartTooltip
              cursor={false}
              className="bg-black"
              content={<ChartTooltipContent hideLabel nameKey={labelKey} />}
            />
          )}
          {hasLegend && (
            <ChartLegend
              content={<ChartLegendContent nameKey={labelKey} hideLabel />}
            />
          )}
          <Pie data={chartData} dataKey={valueKey} blendStroke="#000">
            <LabelList
              dataKey={labelKey}
              className="fill-foreground"
              fontSize={12}
              formatter={(value) => chartConfig[value]?.label}
            />
          </Pie>
        </PieChartLib>
      </ChartContainer>
      {description && (
        <Typography
          variant="body2"
          sx={{
            mt: 2,
          }}
        >
          {description}
        </Typography>
      )}
    </Box>
  );
};

export default PieChart;
