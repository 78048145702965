/* eslint-disable react/no-unknown-property */
import { useContext, useEffect } from "react";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import "../../assets/css/chat.css";
import { MainContainer } from "@chatscope/chat-ui-kit-react";
import ChatContainer from "./ChatContainer";
import Box from "@mui/material/Box";
import { FONT_FAMILY } from "../../utils/constants";
import { closeChat } from "../../actions/chat";
import { useMediaQuery } from "@mui/material";
import _ from "lodash";
import { getPathDatabaseByRole, resetState } from "../../actions/auth";
import { ChatContext } from "../../contexts/ChatContext";
import { useDispatchApp, useSelectorApp } from "../../lib/redux";
import { startGetBusinessName } from "../../actions/user";

const ChatMainContainer = ({ onChangeShowChat }) => {
  const { chats, onSelectChat, currentChat } = useContext(ChatContext);

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const dispatch = useDispatchApp();
  const { isActivePlan } = useSelectorApp((state) => state.auth);

  const { userID, role } = dispatch(getPathDatabaseByRole());

  useEffect(() => {
    dispatch(startGetBusinessName());
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (_.isEmpty(currentChat) && Object.keys(chats).length > 0) {
      onSelectChat(Object.keys(chats)[0]);
    }
    //eslint-disable-next-line
  }, [chats]);

  useEffect(() => {
    if (!role || !userID) {
      dispatch(closeChat());
      dispatch(resetState());
    }
    //eslint-disable-next-line
  }, [role, userID]);

  if (!role || !userID) return null;

  return (
    <Box sx={{ height: "100%" }}>
      <Box
        sx={{
          position: "fixed",
          bottom: { xs: 0, sm: "20px" },
          right: { xs: 0, sm: "20px" },
          zIndex: isActivePlan ? 1200 : 900,
          maxWidth: { xs: "100vw", sm: 650 },
          maxHeight: { xs: "calc(100% - 56px)", sm: "calc(100% - 104px)" },
          height: { xs: "calc(100% - 56px)", md: 720 },
          width: "100%",
          display: "flex",
          fontFamily: `${FONT_FAMILY} !important`,
          borderRadius: isMobile ? 0 : 6,
          pl: { xs: 0, sm: "36px" },
        }}
        className={`animate__animated animate__fadeIn animate__faster`}
      >
        <MainContainer>
          <ChatContainer onChangeShowChat={onChangeShowChat} />
        </MainContainer>
      </Box>
    </Box>
  );
};

export default ChatMainContainer;
