import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import {
  companyInformation,
  LINK_LANDING_CONTACT,
  PLAN_ADVANCED,
  PLAN_GROWTH,
  PLAN_PRO,
  PLAN_STARTER,
} from "../../utils/constants";
import Typography from "@mui/material/Typography";
import { useSelectorApp } from "../../lib/redux";
import _ from "lodash";
import {
  ADS_SUBSCRIPTION_BENEFIT_CAMPAIGNS,
  ADS_SUBSCRIPTION_BENEFIT_DEDICATED_ACCOUNT_MANAGER,
  ADS_SUBSCRIPTION_BENEFIT_EMAIL_SUPPORT,
  ADS_SUBSCRIPTION_BENEFIT_OPTIMIZATIONS,
  ADS_SUBSCRIPTION_BENEFIT_PLATFORMS_CAMPAIGNS,
  ADS_SUBSCRIPTION_BENEFIT_PRIORITY_SUPPORT,
  BUTTON_YOU_HAVE_DOUBTS,
  ADS_SUBSCRIPTION_COINS_BENEFIT,
  ADS_SUBSCRIPTION_CREDITS_BENEFIT,
  LOADING_PLAN_INFORMATION,
  ERROR_PROCESSING_REQUEST,
} from "../../i18n/keysTranslations";
import { capitalizeFirstLetter, openLink } from "../../utils/string";
import PricingHero from "./PricingHero";
import PricingCardsContainer from "./PricingCardsContainer";
import Loader from "../Loaders/Loader";
import { useTranslationApp } from "../../lib/i18next";

const ORDER_PLANS = [PLAN_STARTER, PLAN_GROWTH, PLAN_ADVANCED, PLAN_PRO];

const Pricing = ({ onChangePlanSelected = () => {} }) => {
  const [plans, setPlans] = useState([]);
  const [error] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { plans: allPlans } = useSelectorApp((state) => state.payments);

  const { t } = useTranslationApp();
  // const dispatch = useDispatchApp();

  const subscriptionPlan = useSelectorApp(
    (state) => state.payments.subscriptionPlan
  );

  const onGetInitialData = async () => {
    if (_.isEmpty(allPlans)) {
      setPlans([
        {
          id: "enterprise",
          name: "Enterprise",
          benefits: [
            {
              translation: ADS_SUBSCRIPTION_BENEFIT_PLATFORMS_CAMPAIGNS,
            },
            {
              translation: ADS_SUBSCRIPTION_BENEFIT_PRIORITY_SUPPORT,
            },
            {
              translation: ADS_SUBSCRIPTION_BENEFIT_DEDICATED_ACCOUNT_MANAGER,
            },
          ],
        },
      ]);
      setIsLoading(false);
      return;
    }

    // let proratedPlansData;
    // if (subscriptionPlan) {
    //   const proratedPlansResponse = await dispatch(
    //     startGetPreviewProrationAdsSubscription()
    //   );
    //   if (!proratedPlansResponse.ok) {
    //     setError(true);
    //     setIsLoading(false);

    //     return;
    //   }
    //   proratedPlansData = proratedPlansResponse.data;
    // }
    // console.log(proratedPlansData);

    const plansFormatted = [];

    const finalBenefit = {
      [PLAN_STARTER]: ADS_SUBSCRIPTION_BENEFIT_EMAIL_SUPPORT,
      [PLAN_GROWTH]: ADS_SUBSCRIPTION_BENEFIT_PRIORITY_SUPPORT,
      [PLAN_ADVANCED]: ADS_SUBSCRIPTION_BENEFIT_DEDICATED_ACCOUNT_MANAGER,
      [PLAN_PRO]: ADS_SUBSCRIPTION_BENEFIT_DEDICATED_ACCOUNT_MANAGER,
    };

    Object.keys(allPlans).forEach((key) => {
      //If you have an active plan, only show plans you can upgrade to
      if (
        subscriptionPlan &&
        ORDER_PLANS.indexOf(key) <= ORDER_PLANS.indexOf(subscriptionPlan)
      ) {
        return;
      }

      const plan = allPlans[key];
      const benefits = plan.benefits;
      const benefitsFormatted = [];

      benefitsFormatted.push(
        {
          translation: ADS_SUBSCRIPTION_CREDITS_BENEFIT,
          value: benefits.credits,
        },
        {
          translation: ADS_SUBSCRIPTION_COINS_BENEFIT,
          value: benefits.coins,
        },
        {
          translation: ADS_SUBSCRIPTION_BENEFIT_PLATFORMS_CAMPAIGNS,
        },
        {
          translation: ADS_SUBSCRIPTION_BENEFIT_CAMPAIGNS,
          value: benefits.campaigns,
        },
        {
          translation: ADS_SUBSCRIPTION_BENEFIT_OPTIMIZATIONS,
          value: benefits.optimizations,
        },
        {
          translation: finalBenefit[key],
        }
      );

      // let priceProrated = proratedPlansData?.[key]?.price;

      plansFormatted.push({
        ...plan,
        id: key,
        name: capitalizeFirstLetter(key),
        benefits: benefitsFormatted,
        credits: benefits.credits,
        coins: benefits.coins,
      });
    });

    plansFormatted.sort((a, b) => {
      return ORDER_PLANS.indexOf(a.id) - ORDER_PLANS.indexOf(b.id);
    });

    setPlans(plansFormatted);
    setIsLoading(false);
  };

  useEffect(() => {
    onGetInitialData();
    // eslint-disable-next-line
  }, [allPlans, subscriptionPlan]);

  return (
    <Box>
      <PricingHero />
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: 370,
          }}
        >
          <Loader
            size={40}
            hasMessage={true}
            message={t(LOADING_PLAN_INFORMATION)}
          />
        </Box>
      ) : error ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: 370,
          }}
        >
          <Typography variant="body1" color="error">
            {t(ERROR_PROCESSING_REQUEST)}
          </Typography>
        </Box>
      ) : (
        <PricingCardsContainer
          plans={plans}
          onChangePlanSelected={onChangePlanSelected}
        />
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          my: 2,
          mx: 1,
          gap: 2,
        }}
      >
        <Typography
          variant="body1"
          align="center"
          onClick={() => openLink(LINK_LANDING_CONTACT)}
          sx={{
            textDecoration: "underline",
            fontSize: "1.1rem",
            "&:hover": {
              cursor: "pointer",
              textDecoration: "underline",
            },
          }}
        >
          {t(BUTTON_YOU_HAVE_DOUBTS)}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="body2" align="center">
            {`${companyInformation.name} - A Delaware LLC`}
          </Typography>
          <Typography variant="body2" align="center">
            {companyInformation.address}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Pricing;
