import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { usePostHog } from "posthog-js/react";

export default function PostHogPageView() {
  const location = useLocation();
  const postHog = usePostHog();

  // Track pageviews
  useEffect(() => {
    if (postHog) {
      postHog.capture("$pageview", {
        $current_url: window.location.href,
      });
    }
    // eslint-disable-next-line
  }, [location]);

  return null;
}
