import { useState } from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import DownloadIcon from "@mui/icons-material/Download";
import {
  BUTTON_DOWNLOAD,
  MODAL_MEDIA_ARIA_LABEL,
} from "../../i18n/keysTranslations";
import { getExtensionFile } from "../../utils/string";
import { useTranslationApp } from "../../lib/i18next";
import { VIDEO_FORMATS } from "../../utils/constants";
import ModalBasicLayout from "./ModalBasicLayout";
import LoadingButton from "../Buttons/LoadingButton";
import VideoPlayer from "../Media/VideoPlayer";
import {
  GRADIENT_PURPLE_FUCHSIA,
  GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT,
} from "../../utils/colors";
import { startGetAwsDownloadUrl } from "../../actions/user";
import { useDispatchApp } from "../../lib/redux";

const ModalMedia = ({
  modalOpen = false,
  onCloseModal,
  url,
  disabledActions = false,
  customActions,
}) => {
  const [isFetching, setIsFetching] = useState(false);
  const extension = getExtensionFile(url);

  const { t } = useTranslationApp();
  const dispatch = useDispatchApp();

  return (
    <ModalBasicLayout
      modalOpen={modalOpen}
      onCloseModal={onCloseModal}
      fullWidth={true}
      fullScreen
      scroll="paper"
      ariaLabel={t(MODAL_MEDIA_ARIA_LABEL)}
      sx={{ ".MuiPaper-root": { backgroundColor: "#000" } }}
      sxContent={{
        p: 0,
        pl: 3,
        pb: 2,
        mt: 1,
      }}
      sxTitle={{
        py: 1,
      }}
    >
      <Box
        sx={{
          height: { xs: "70vh", sm: "80vh" },
          display: "flex",
          justifyContent: "center",
          px: 1,
        }}
        className="modal-media-content"
      >
        {VIDEO_FORMATS.includes(extension) ? (
          <VideoPlayer
            url={url}
            controls={true}
            sx={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
            }}
            controlsList="nodownload"
            autoplay
            loop
            muted
          />
        ) : (
          <Avatar
            variant="rounded"
            src={url}
            alt={`Media`}
            sx={{
              height: "100%",
              width: "100%",
              ".MuiAvatar-img": {
                objectFit: "contain",
                objectPosition: "top",
              },
            }}
          />
        )}
      </Box>
      <Box
        sx={{
          height: { xs: "22vh", sm: "13vh" },
          display: "flex",
          alignItems: { xs: "flex-end", sm: "center" },
          justifyContent: "space-between",
          gap: 2,
          width: "100%",
          px: 2,
          pb: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: 2,
            justifyContent: "center",
            width: "100%",
          }}
        >
          {customActions}
          {!disabledActions && (
            <>
              <LoadingButton
                loading={isFetching}
                startIcon={<DownloadIcon />}
                sx={{
                  textTransform: "none",
                  background: GRADIENT_PURPLE_FUCHSIA,
                  color: GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT,
                }}
                onClick={async () => {
                  setIsFetching(true);
                  await dispatch(startGetAwsDownloadUrl(url));
                  setIsFetching(false);
                }}
              >
                {t(BUTTON_DOWNLOAD)}
              </LoadingButton>
            </>
          )}
        </Box>
      </Box>
    </ModalBasicLayout>
  );
};

export default ModalMedia;
