import { useDispatchApp } from "../../../lib/redux";
import { useTranslationApp } from "../../../lib/i18next";
import {
  BUTTON_CLOSE,
  BUTTON_VIEW_INVOICE,
  MODAL_INVOICE_ARIA_LABEL,
  MODAL_INVOICE_DISCLAIMER,
  MODAL_INVOICE_SUBSCRIPTION,
  MODAL_INVOICE_TITLE,
  PAY,
  TOTAL,
  VALUE_WITH_CURRENCY,
} from "../../../i18n/keysTranslations";
import { capitalizeFirstLetter, openLink } from "../../../utils/string";
import addMonths from "date-fns/addMonths";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import DialogActions from "@mui/material/DialogActions";
import ModalBasicLayout from "../../../components/Modal/ModalBasicLayout";
import ContainerModalActions from "../../../components/Containers/ContainerModalActions";
import { startGetInvoiceUrl } from "../../../actions/payments";
import { RECURRENT_MULTIPLIER } from "../../../utils/constants";

const ModalInvoice = ({ data, modalOpen, onCloseModal, invoice }) => {
  const dispatch = useDispatchApp();

  const { t } = useTranslationApp();

  const onGetInvoiceUrl = async () => {
    await dispatch(
      startGetInvoiceUrl({
        paymentID: invoice,
      })
    );
  };

  const showPayButton = !data?.isPaid && data?.paymentLink;
  const showDownloadInvoiceButton = data.isPaid;
  const showActionButton = showPayButton || showDownloadInvoiceButton;
  return (
    <ModalBasicLayout
      modalOpen={modalOpen}
      onCloseModal={onCloseModal}
      fullWidth={true}
      scroll="paper"
      ariaLabel={t(MODAL_INVOICE_ARIA_LABEL)}
      title={t(MODAL_INVOICE_TITLE, {
        value: invoice,
      })}
    >
      <LayoutDescriptionInvoice data={data} />
      <Typography variant="subtitle1" align="center" sx={{ mt: 5 }}>
        {t(MODAL_INVOICE_DISCLAIMER)}
      </Typography>
      <DialogActions
        sx={{
          p: 0,
        }}
      >
        <ContainerModalActions
          propsSecondaryButton={{
            showButton: true,
            text: BUTTON_CLOSE,
            onClick: onCloseModal,
          }}
          propsPrimaryButton={{
            showButton: showActionButton,
            text: showPayButton ? PAY : BUTTON_VIEW_INVOICE,
            onClick: showPayButton
              ? () => openLink(data.paymentLink)
              : onGetInvoiceUrl,
          }}
        />
      </DialogActions>
    </ModalBasicLayout>
  );
};

const LayoutDescriptionInvoice = ({ data }) => {
  const { t } = useTranslationApp();

  const getDescriptionTranslation = () => {
    const subscription = data.subscription;

    const translation = MODAL_INVOICE_SUBSCRIPTION;
    const recurrent = data?.params?.recurrent || "monthly";
    const period = RECURRENT_MULTIPLIER?.[recurrent] || 1;

    const paidDate = data?.paidDate ? data?.paidDate : new Date().getTime();
    const dataTranslation = {
      name: capitalizeFirstLetter(subscription),
      startDate: new Date(paidDate),
      endDate: new Date(addMonths(paidDate, period)),
    };

    return {
      translation,
      dataTranslation,
    };
  };

  const { translation, dataTranslation } = getDescriptionTranslation();

  return (
    <>
      <Box
        sx={{ display: "flex", justifyContent: "space-between", my: 2, gap: 2 }}
      >
        <Box>
          <Typography variant="h6" sx={{ fontWeight: 600 }}>
            {`${t(translation, dataTranslation)}:`}
          </Typography>
        </Box>
        <Box sx={{ textAlign: "end" }}>
          <Typography variant="h6" sx={{ fontWeight: 600 }}>
            {t(VALUE_WITH_CURRENCY, {
              value: data.value,
              currency: data?.currency || "USD",
            })}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", mt: 5 }}>
        <Box>
          <Typography variant="h6" sx={{ fontWeight: 600 }}>
            {t(TOTAL)}
          </Typography>
        </Box>
        <Box sx={{ textAlign: "end" }}>
          <Typography variant="h6" sx={{ fontWeight: 600 }}>
            {t(VALUE_WITH_CURRENCY, {
              value: data.value,
              currency: data?.currency || "USD",
            })}
          </Typography>
        </Box>
      </Box>
    </>
  );
};
export default ModalInvoice;
