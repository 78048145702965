import { get, onValue, ref } from "firebase/database";
import types from "../types";
import { db } from "../lib/firebase";
import { THEME_ID_PROJECT } from "../utils/constants";

export const setOpenNotification = (state) => ({
  type: types.UI_SET_OPEN_NOTIFICATION,
  payload: {
    open: state.open,
    message: state.message ?? "",
    horizontal: state.horizontal ?? "right",
    vertical: state.vertical ?? "bottom",
    action: state.action,
  },
});
export const setShowBackdrop = (state) => ({
  type: types.UI_SET_SHOW_BACKDROP,
  payload: state,
});
export const setOpenSlideMenu = (open) => ({
  type: types.UI_SET_OPEN_SLIDE_MENU,
  payload: open,
});
export const resetUIReducer = () => ({
  type: types.RESET_UI_STATE,
});
export const setShowModalSelectSubscription = (open) => ({
  type: types.UI_SHOW_MODAL_SELECT_SUBSCRIPTION,
  payload: open,
});

export const startGetVersionAds = () => async (dispatch) => {
  try {
    const dbRef = ref(db, `versions/ads`);
    onValue(dbRef, (snapshot) => {
      const data = snapshot.val();
      dispatch(setVersion(data));
    });
  } catch (error) {
    console.log(error);
    return false;
  }
};
const setVersion = (data) => ({
  type: types.SET_VERSION,
  payload: data,
});

export const startGetProgressValues =
  ({ progressID, onCallbackUpdateProgress, onReferenceAvailable }) =>
  async () => {
    try {
      const dbRef = ref(db, progressID);
      onValue(dbRef, (snapshot) => {
        const data = snapshot.val();

        if (!data) return;
        onCallbackUpdateProgress(data);
      });
      onReferenceAvailable(dbRef);
    } catch (error) {
      console.log(error);
      return false;
    }
  };

export const startGetTheme = () => async (dispatch) => {
  try {
    const dbRef = ref(db, `themes/${THEME_ID_PROJECT}`);

    const snapshot = await get(dbRef);

    if (snapshot.exists()) {
      dispatch(setTheme(snapshot.val()));
    }

    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};
const setTheme = (data) => ({
  type: types.UI_SET_THEME,
  payload: data,
});
