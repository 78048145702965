import { useEffect, useState } from "react";
import useNearScreen from "./useNearScreen";

const usePagination = ({
  rowsPerPageValue = 50,
  onCallBackNextPage = () => ({}),
}) => {
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageValue);
  const [page, setPage] = useState(0);
  const [lastKey, setLastKey] = useState(null);

  const { isNearScreen, fromRef, loadLastItem, onChangeLoadLastItem } =
    useNearScreen({
      distance: "700px",
      once: false,
    });

  const onRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  const onPageChange = (newPage) => {
    setPage(newPage);
    if (newPage > page) onFetchNextPage();
  };

  const onChangeLastKey = (lastKey) => {
    setLastKey(lastKey);
  };

  const onFetchNextPage = async () => {
    if (loadLastItem) return;
    await onCallBackNextPage();
  };

  useEffect(() => {
    if (isNearScreen && !loadLastItem) onCallBackNextPage();

    // eslint-disable-next-line
  }, [isNearScreen]);

  return {
    rowsPerPage,
    page,
    lastKey,
    onRowsPerPageChange,
    onPageChange,
    onChangeLastKey,
    onFetchNextPage,
    isNearScreen,
    fromRef,
    onChangeLoadLastItem,
  };
};

export { usePagination };
