import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { MODAL_CUSTOM_AUDIENCES_EMPTY_TEXT } from "../../../../i18n/keysTranslations";
import { useTranslationApp } from "../../../../lib/i18next";
import CustomAudienceListItem from "./CustomAudienceListItem";
import Loader from "../../../Loaders/Loader";

const CustomAudiencesList = ({
  customAudiences = [],
  loadLastItem,
  selectedItems,
  onChangeSelectedItems,
  lastItemRef,
}) => {
  const { t } = useTranslationApp();

  if (customAudiences.length === 0) {
    return (
      <Box
        sx={{
          mt: 4,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="body1">
          {t(MODAL_CUSTOM_AUDIENCES_EMPTY_TEXT)}
        </Typography>
      </Box>
    );
  }
  return (
    <Box
      sx={{
        display: "flex",
        gap: 1.5,
        flexDirection: "column",
      }}
    >
      {customAudiences.map((customAudience, index) => {
        if (index === customAudiences.length - 1) {
          return (
            <Box key={customAudience.id}>
              <CustomAudienceListItem
                customAudience={customAudience}
                selectedItems={selectedItems}
                onChangeSelectedItems={onChangeSelectedItems}
              />
              {!loadLastItem && <Loader size={20} />}
              <div ref={lastItemRef} />
            </Box>
          );
        }
        return (
          <CustomAudienceListItem
            key={customAudience.id}
            customAudience={customAudience}
            selectedItems={selectedItems}
            onChangeSelectedItems={onChangeSelectedItems}
          />
        );
      })}
    </Box>
  );
};

export default CustomAudiencesList;
